export function parseNumber(value) {
  const parse = Number(value);
  return !isNaN(parse) ? parse.toFixed(2) : "0.00";
}
const currencies = {
  R$: "BRL",
  BRL: "BRL",
  USD: "USD",
};
export function currency(value, currencyCode, defaultCurrency = "BRL") {
  const data = parseNumber(value);

  if (!defaultCurrency && !(currencyCode in currencies)) {
    return data;
  }

  const formatter = new Intl.NumberFormat("en", {
    style: "currency",
    currency: currencies?.[currencyCode?.toUpperCase()] || defaultCurrency,
  });
  return formatter.format(data);
}
