<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ modalTitle }}
      </ModalTitle>
    </ModalHeader>

    <div class="grid gap-4">
      <NbCard id="general-data" :title="$t('generalData')">
        <template #body>
          <div class="grid gap-4 grid-cols-2">
            <NbTextInput
              v-model="form.name"
              id="form.name"
              :name="$t('sellersPage.name')"
              :error="[errors.get('name')]"
              required
            />

            <NbTextInput
              v-model="form.phone"
              id="form.phone"
              :name="$t('sellersPage.phone')"
              :error="[errors.get('phone')]"
            />

            <NbTextInput
              v-model="form.email"
              id="form.email"
              :name="$t('sellersPage.email')"
              :error="[errors.get('email')]"
              required
            />

            <NbTextInput
              v-model="form.site"
              id="form.site"
              :name="$t('sellersPage.site')"
              :error="[errors.get('site')]"
              required
            />

            <NbTextInput
              v-model="form.tax_number"
              id="form.tax_number"
              :name="$t('sellersPage.taxnumber')"
              :error="[errors.get('tax_number')]"
            />

            <NbTextInput
              v-model="form.legal_name"
              id="form.legal_name"
              :name="$t('sellersPage.legalName')"
              :error="[errors.get('legal_name')]"
            />

            <NbTextInput
              v-model="form.rfb_code"
              id="form.rfb_code"
              :name="$t('sellersPage.rfbCode')"
              :error="[errors.get('rfb_code')]"
              placeholder="RFB(Receita Federal Brasileira) code .."
            />

            <NbTextInput
              v-model="form.billing_email"
              id="form.billing_email"
              :name="$t('sellersPage.billingEmail')"
              :error="[errors.get('billing_email')]"
              placeholder="RFB(Receita Federal Brasileira) code .."
            />

            <NbTextInput
              v-model="form.responsable_contact"
              id="form.responsable_contact"
              :name="$t('sellersPage.responsableContact')"
              :error="[errors.get('responsable_contact')]"
              class="col-span-2"
            />

            <div class="col-span-2 grid grid-cols-8">
              <NbSwitch
                v-model="form.is_subsidiary"
                id="form.is_subsidiary"
                :name="$t('sellersPage.issubsidiary')"
              />
              <NbSwitch
                v-model="form.is_distribution_center"
                id="form.is_subsidiary"
                :name="$t('sellersPage.isdistributioncenter')"
              />
              <NbSwitch
                v-model="form.is_multiple"
                id="form.is_multiple"
                :name="$t('sellersPage.ismultiple')"
              />
              <NbSwitch
                v-model="form.is_carrier"
                id="form.is_carrier"
                :name="$t('sellersPage.fields.isCarrier')"
              />

              <NbSwitch
                v-model="form.include_logo_cn23"
                id="form.is_carrier"
                :name="$t('sellersViewPage.includeLogo')"
              />
            </div>

            <!-- <div class="col-span-2">
              <NbDropFile
                v-model="signature"
                :label="$t('sellersPage.signature')"
              />
            </div> -->
          </div>
        </template>
      </NbCard>

      <NbCard id="address" :title="$t('sellersPage.address')">
        <template #body>
          <div class="grid grid-cols-2 gap-4">
            <NbTextInput
              v-model="form.zip_code"
              id="form.zip_code"
              :name="$t('sellersPage.postalcode')"
              :error="[errors.get('zip_code')]"
              required
            />
            <NbTextInput
              v-model="form.address"
              id="form.address"
              :name="$t('sellersPage.address')"
              :error="[errors.get('address')]"
              required
            />

            <NbTextInput
              v-model="form.address_number"
              id="form.address_number"
              :name="$t('sellersPage.addressnumber')"
              :error="[errors.get('address_number')]"
            />

            <NbTextInput
              v-model="form.address_complement"
              id="form.address_complement"
              :name="$t('sellersPage.addresscomplement')"
              :error="[errors.get('address_complement')]"
            />

            <NbSelectInput
              v-model="form.country"
              :options="countriesOptions"
              :name="$t('sellersPage.country')"
              id="form.country"
              :error="[errors.get('country')]"
              required
            />

            <NbTextInput
              v-model="form.state"
              id="form.state"
              :name="$t('sellersPage.state')"
              :error="[errors.get('state')]"
            />

            <NbTextInput
              v-model="form.city"
              id="form.city"
              :name="$t('sellersPage.city')"
              :error="[errors.get('city')]"
              class="col-span-2"
              required
            />
          </div>
        </template>
      </NbCard>

      <NbCard id="devolution" :title="$t('sellersViewPage.devolutionAddress')">
        <template #body>
          <div>
            <div class="grid grid-cols-2 gap-4">
              <NbTextInput
                v-model="form.devolution_name"
                id="form.devolution_name"
                :name="$t('sellersViewPage.name')"
                :error="[errors.get('devolution_name')]"
              />
              <NbTextInput
                v-model="form.devolution_email"
                id="form.devolution_email"
                :name="$t('sellersViewPage.email')"
                :error="[errors.get('devolution_email')]"
              />

              <NbTextInput
                v-model="form.devolution_ddd"
                id="form.devolution_ddd"
                name="DDD"
                :error="[errors.get('devolution_ddd')]"
              />

              <NbTextInput
                v-model="form.devolution_phone"
                id="form.devolution_phone"
                :name="$t('sellersViewPage.phone')"
                :error="[errors.get('devolution_phone')]"
              />
              <NbTextInput
                v-model="form.devolution_postal_code"
                id="form.devolution_postal_code"
                :name="$t('sellersViewPage.postalCode')"
                :error="[errors.get('devolution_postal_code')]"
              />

              <NbTextInput
                v-model="form.devolution_address"
                id="form.devolution_address"
                :name="$t('sellersViewPage.address')"
                :error="[errors.get('devolution_address')]"
              />

              <NbTextInput
                v-model="form.devolution_address_number"
                id="form.devolution_address_number"
                :name="$t('sellersViewPage.addressNumber')"
                :error="[errors.get('devolution_address_number')]"
              />

              <NbTextInput
                v-model="form.devolution_address_complement"
                id="form.devolution_address_complement"
                :name="$t('sellersViewPage.addressComplement')"
                :error="[errors.get('devolution_address_complement')]"
              />

              <NbSelectInput
                v-model="form.devolution_country"
                :options="countriesOptions"
                id="form.devolution_country"
                :name="$t('sellersViewPage.country')"
                :error="[errors.get('devolution_country')]"
              />

              <NbTextInput
                v-model="form.devolution_state"
                id="form.devolution_state"
                :name="$t('sellersViewPage.state')"
                :error="[errors.get('devolution_state')]"
              />

              <NbTextInput
                v-model="form.devolution_city"
                id="form.devolution_city"
                :name="$t('sellersViewPage.city')"
                :error="[errors.get('devolution_city')]"
              />

              <NbTextInput
                v-model="form.devolution_address_reference"
                id="form.devolution_address_reference"
                :name="$t('sellersViewPage.addressReference')"
                :error="[errors.get('devolution_address_reference')]"
              />

              <NbTextInput
                v-model="form.devolution_address_quarter"
                id="form.devolution_address_quarter"
                :name="$t('sellersViewPage.addressQuarter')"
                :error="[errors.get('devolution_address_quarter')]"
              />
            </div>

            <NbSwitch
              v-model="form.use_seller_devolution_address"
              variant="primary"
              :name="$t('sellersViewPage.useSellerDevolutionAddress')"
              id="seller.use_seller_devolution_address"
            />
          </div>
        </template>
      </NbCard>

      <NbCard id="contracts" :title="$t('sellersPage.allowedcontracts')">
        <template #body>
          <Spinner size="md" class="mx-auto" v-if="loadingContracts" />

          <div v-else class="d-flex align-items-end gap-4">
            <NbSelectInput
              v-model="newContractId"
              id="selectedContracts"
              :name="$t('components.searchData.contracts')"
              :options="contractsOptions"
              class="flex-grow-1"
            />
            <NbButton class="mb-1" @click="onAddContract">
              <NbIcon icon="plus" size="24" />
            </NbButton>
          </div>

          <div class="contracts-badge d-flex gap-4 mt-4">
            <NbBadge
              v-for="item in seller.allowed_contracts"
              :key="item.id"
              type="success"
              class="item"
              v-b-tooltip.hover
              :title="item.slug"
            >
              <span>{{ item.slug }}</span>
              <button class="delete" @click="onRemoveContract(item.id)">
                <NbIcon icon="x" />
              </button>
            </NbBadge>
          </div>
        </template>
      </NbCard>
    </div>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose class="mr-2" />
      <NbButton @click="openUpdateWarning" :disabled="loading">
        {{ $t("save") }}
      </NbButton>
    </ModalFooter>

    <Modal v-model="showUpdateWarningModal">
      <SellerUpdateWarning @confirm="update" />
    </Modal>

    <Modal v-model="showConfirmAddContractModal">
      <SellerConfirmAddContract
        :seller="seller"
        :contract="contractToBeChanged"
        @success="onRequestAddContract"
      />
    </Modal>

    <Modal v-model="showConfirmRemoveContractModal">
      <SellerConfirmRemoveContract
        :seller="seller"
        :contract="contractToBeChanged"
        @success="onRequestRemoveContract"
      />
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbSwitch from "@/components/buttons/NbSwitch.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
// import NbDropFile from "@/components/input/drop-file/NbDropFile.vue";
import Errors from "@/utils/Errors.js";
import ContractService from "@/services/ContractService";
import Spinner from "@/components/Spinner.vue";
import SellerService from "@/services/SellerService";
import SellerUpdateWarning from "./SellerUpdateWarning.vue";
import SellerConfirmAddContract from "./SellerConfirmAddContract.vue";
import SellerConfirmRemoveContract from "./SellerConfirmRemoveContract.vue";
import NbBadge from "../../../../components/alerts/NbBadge.vue";

const contractService = new ContractService();
const sellersService = new SellerService();

export default {
  components: {
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    NbCard,
    NbSelectInput,
    NbTextInput,
    // NbDropFile,
    NbSwitch,
    Spinner,
    SellerUpdateWarning,
    SellerConfirmAddContract,
    SellerConfirmRemoveContract,
    NbIcon,
    NbBadge,
  },
  props: {
    seller: {
      type: Object,
      required: true,
    },
  },
  inject: ["context"],
  data() {
    return {
      showConfirmAddContractModal: false,
      showConfirmRemoveContractModal: false,
      contractToBeChanged: null,
      showUpdateWarningModal: false,
      loadingContracts: false,
      newContractId: null,
      contracts: [],
      countriesOptions: [],
      currentContracts: this.seller.allowed_contracts,
      errors: new Errors(),
      signature: "",
      loading: false,
      form: {
        address: this.seller.address,
        address_number: this.seller.address_number,
        address_complement: this.seller.address_complement,
        city: this.seller.city,
        zip_code: this.seller.zip_code,
        state: this.seller.state,
        country: this.seller.country,
        name: this.seller.name,
        email: this.seller.email,
        phone: this.seller.phone,
        site: this.seller.site,
        tax_number: this.seller.tax_number,
        rfb_code: this.seller.rfb_code,
        legal_name: this.seller.legal_name,
        is_active: this.seller.is_active || false,
        is_carrier: this.seller.is_carrier || false,
        is_subsidiary: this.seller.is_subsidiary || false,
        is_distribution_center: this.seller.is_distribution_center || false,
        is_multiple: this.seller.is_multiple || false,
        users: this.seller.users || [],
        allowed_contracts: this.seller.allowed_contracts || [],
        include_logo_cn23: this.seller.include_logo_cn23 || false,

        devolution_name: this.seller.devolution_name,
        devolution_address: this.seller.devolution_address,
        devolution_address_number: this.seller.devolution_address_number,
        devolution_address_quarter: this.seller.devolution_address_quarter,
        devolution_address_reference: this.seller.devolution_address_reference,
        devolution_address_complement:
          this.seller.devolution_address_complement,
        devolution_country: this.seller.devolution_country,
        devolution_city: this.seller.devolution_city,
        devolution_state: this.seller.devolution_state,
        devolution_postal_code: this.seller.devolution_postal_code,
        devolution_email: this.seller.devolution_email,
        devolution_ddd: this.seller.devolution_ddd,
        devolution_phone: this.seller.devolution_phone,
        use_seller_devolution_address:
          this.seller.use_seller_devolution_address || false,
      },
    };
  },
  computed: {
    contractsOptions() {
      return this.contracts.map((item) => ({
        text: item.name,
        value: item.id,
        disabled: !!this.seller.allowed_contracts.find(
          (data) => data.id === item.id,
        ),
      }));
    },
    modalTitle() {
      if (this.seller.name) {
        return `${this.$t("edit")} ${this.seller.name}`;
      }

      return "-";
    },
    countries() {
      return this.$store.state.countries.all_items;
    },
  },
  methods: {
    onAddContract() {
      if (!this.newContractId) {
        return;
      }

      const contract = this.contracts.find(
        (item) => item.id === this.newContractId,
      );
      if (!contract) {
        return;
      }

      const currentContractIds = this.currentContracts.map((item) => item.id);
      if (!currentContractIds.includes(contract.id)) {
        this.contractToBeChanged = contract;
        this.showConfirmAddContractModal = true;
        return;
      }
    },
    onRequestAddContract(contractId) {
      const contract = this.contracts.find((item) => item.id === contractId);
      this.seller.allowed_contracts.push(contract);
      this.newContractId = null;
    },
    onRemoveContract(contractId) {
      const contract = this.contracts.find((item) => item.id === contractId);
      if (!contract) {
        return;
      }

      const currentContractIds = this.currentContracts.map((item) => item.id);
      if (currentContractIds.includes(contract.id)) {
        this.contractToBeChanged = contract;
        this.showConfirmRemoveContractModal = true;
        return;
      }
    },
    onRequestRemoveContract(contractId) {
      const contractIndex = this.seller.allowed_contracts.findIndex(
        (item) => item.id === contractId,
      );
      if (contractIndex !== -1) {
        this.seller.allowed_contracts.splice(contractIndex, 1);
      }
    },
    sellerIsCarrier() {
      const platform = localStorage.getItem("platform");
      return platform === "total" && this.seller.is_carrier;
    },
    openUpdateWarning() {
      if (this.sellerIsCarrier()) {
        this.showUpdateWarningModal = true;
        return;
      }
      this.update();
    },
    async update() {
      try {
        this.loading = true;
        const response = await sellersService.updateSeller(this.seller.id, {
          ...this.form,
          allowed_contracts: this.seller.allowed_contracts.map(
            (item) => item.id,
          ),
        });
        this.$emit("updated", response.data.data);
        this.context?.close();
      } catch (error) {
        if (this.sellerIsCarrier()) {
          this.showUpdateWarningModal = true;
        }

        if (error?.response?.data?.messages?.length) {
          this.errors.record(error.response.data.messages?.[0]);
        }
      } finally {
        this.loading = false;
      }
    },
    setCountriesOptions() {
      this.countriesOptions = this.countries.map((country) => ({
        text: country.name,
        value: country.alpha2_code,
      }));
    },
    async loadContracts() {
      try {
        this.loadingContracts = true;
        const { data } = await contractService.getContracts();
        this.contracts = data.data;
      } finally {
        this.loadingContracts = false;
      }
    },
  },
  created() {
    this.setCountriesOptions();
    this.loadContracts();
  },
};
</script>

<style lang="scss" scoped>
.contracts-badge {
  .item {
    position: relative;
    width: 250px;
    span {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .delete {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
