<template>
  <div>
    <NbModal
      v-show="modalActive"
      id="flight-modal"
      @close="closeModal"
      width="1024px"
    >
      <template #header>
        <div v-if="flight?.id" class="d-flex">
          <NbPageTitle
            v-if="flight?.airline"
            :title="`${$t('flightsPage.fields.airline')} - ${flight?.airline}`"
          />
          <FlightStatus class="ml-4" :status="flight.status" />
        </div>
      </template>

      <template #body>
        <div class="grid grid-cols-3">
          <NbCard
            v-if="labelUrl"
            id="documents"
            :title="$t('flightsPage.documentsAndLabels')"
          >
            <template #body>
              <div class="grid">
                <NbButton
                  @click="downloadLabel"
                  variant="secondary"
                  :disabled="isDownloading"
                  v-b-tooltip.hover
                  :title="
                    isDownloading ? $t('download') : $t('clickToDownload')
                  "
                >
                  {{ $t("flightsPage.fields.flightLabel") }}
                </NbButton>
              </div>
            </template>
          </NbCard>
          <NbCard id="actions" :title="$t('actions')">
            <template #body>
              <div class="grid">
                <NbButton variant="secondary" @click="openModalEdit">
                  {{ $t("flightsPage.editFlightData") }}
                </NbButton>
                <NbButton
                  v-if="
                    flight.is_correios && flight.status === 'manifest_closed'
                  "
                  variant="secondary"
                  @click="showModalConfirmDeparture"
                >
                  {{ $t("flightsPage.confirmDeparture") }}
                </NbButton>
                <NbButton
                  v-if="flight.status === 'open_manifest'"
                  variant="secondary"
                  @click="showModalConfirmCloseManifest"
                >
                  {{ $t("flightsPage.closeManifest") }}
                </NbButton>
                <NbButton
                  v-if="flight.status === 'open_manifest'"
                  variant="secondary"
                  @click="showModalConfirmDelete"
                >
                  {{ $t("delete") }}
                </NbButton>
              </div>
            </template>
          </NbCard>

          <NbCard
            v-if="labelUrl"
            id="other"
            :title="$t('flightsPage.suggestedActions')"
          >
            <template #body>
              <div class="grid">
                <NbButton
                  v-copy="flight.mawb_number"
                  v-b-tooltip.hover
                  :title="$t('clickToCopy')"
                >
                  {{ $t("flightsPage.copyLabelCode") }}
                </NbButton>
              </div>
            </template>
          </NbCard>
        </div>

        <div class="cards my-4">
          <NbCard :title="$t('flightsPage.generalData')" id="general-data">
            <template #body>
              <div class="grid grid-cols-3">
                <NbTextInput
                  id="airline"
                  :placeholder="$t('flightsViewPage.airline')"
                  :name="$t('flightsViewPage.airline')"
                  v-model="flight.airline"
                  disabled
                  variant="borderless-gray-10"
                />
                <NbTextInput
                  id="flight_number"
                  :placeholder="$t('flightsViewPage.flightNumber')"
                  :name="$t('flightsViewPage.flightNumber')"
                  type="number"
                  v-model="flight.flight_number"
                  disabled
                  variant="borderless-gray-10"
                />
                <NbTextInput
                  id="mawb_number"
                  :placeholder="$t('flightsViewPage.mawbNumber')"
                  :name="$t('flightsViewPage.mawbNumber')"
                  v-model="flight.mawb_number"
                  disabled
                  variant="borderless-gray-10"
                />
              </div>
            </template>
          </NbCard>

          <NbCardBox v-if="hasDepartureAndArrival">
            <FlightTimeline :flight="flight" />
          </NbCardBox>

          <NbCard :title="$t('flightsPage.taxesAndOthers')" id="tax">
            <template #body>
              <div class="grid grid-cols-3">
                <NbTextInput
                  id="freight_value"
                  placeholder="0.00"
                  :name="$t('ordersViewPage.freightValue')"
                  type="money"
                  :decimals="2"
                  :min="0.0"
                  v-model="flight.freight_value"
                  disabled
                  variant="borderless-gray-10"
                />
                <NbTextInput
                  id="sub_total"
                  :placeholder="$t('flightsViewPage.subtotal')"
                  :name="$t('flightsViewPage.subtotal')"
                  type="money"
                  :decimals="2"
                  :min="0.0"
                  v-model="flight.sub_total"
                  disabled
                  variant="borderless-gray-10"
                />
                <NbTextInput
                  id="charges_per_kilo"
                  :placeholder="$t('flightsViewPage.chargesKilo')"
                  :name="$t('flightsViewPage.chargesKilo')"
                  type="number"
                  v-model="flight.charges_per_kilo"
                  disabled
                  variant="borderless-gray-10"
                />
                <NbTextInput
                  id="other_charges"
                  :placeholder="$t('flightsViewPage.otherCharges')"
                  :name="$t('flightsViewPage.otherCharges')"
                  type="number"
                  v-model="flight.other_charges"
                  disabled
                  variant="borderless-gray-10"
                />
              </div>
            </template>
          </NbCard>

          <NbCard id="masterboxes" :title="$t('flightsPage.masterboxes')">
            <template #body>
              <NbTablev2
                class="mt-5"
                tableOf="masterboxesFlight"
                :hasParentItens="true"
                :hiddeTableOptions="true"
                height="fit-content"
                :allFields="masterboxesTableAllFields"
                :fields="masterboxesTableFields"
                @reloadFields="masterboxesTableFields = $event"
                :startItems="masterboxes"
              >
                <template #cell(actions)="data">
                  <NbButton
                    variant="tertiary"
                    @click="redirectToMasterbox(data.item.id)"
                  >
                    {{ $t("seeDetails") }} >
                  </NbButton>
                </template>
              </NbTablev2>
            </template>
          </NbCard>

          <NbCard id="shipments" title="Shipments">
            <template #body>
              <NbTablev2
                class="mt-5"
                tableOf="shipmentsFlight"
                :hasParentItens="true"
                :hiddeTableOptions="true"
                height="fit-content"
                :allFields="shipmentsTableAllFields"
                :fields="shipmentsTableFields"
                @reloadFields="shipmentsTableFields = $event"
                :startItems="shipments"
              >
                <template #cell(actions)="data">
                  <NbButton
                    variant="tertiary"
                    @click="redirectToShipment(data.item.id)"
                  >
                    {{ $t("seeDetails") }} >
                  </NbButton>
                </template>
              </NbTablev2>
            </template>
          </NbCard>
        </div>
      </template>
    </NbModal>
    <UpdateFlightModal
      v-if="showEditModal"
      :flight="flight"
      :has_mawb_file="hasMawbFile"
      @loadCurrentFlight="loadFlight"
      @generateFlightLabel="generateLabel"
      @close="closeEditModal"
    />

    <NbModal id="confirm-departure" @close="modalActive = true">
      <template #header>
        <h3 class="heading-3">{{ $t("flightsPage.confirmDeparture") }}</h3>
      </template>

      <template #footer>
        <div class="d-flex justify-content-between">
          <NbButton
            variant="secondary"
            class="ml-2"
            @click="resetModal('confirm-departure')"
          >
            {{ $t("cancel") }}
          </NbButton>
          <NbButton @click="confirmDeparture" :disabled="loading">
            {{ $t("confirm") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <NbModal id="close-manifest" prevent-close>
      <template #header>
        <h3 class="heading-3">{{ $t("flightsPage.closeManifest") }}</h3>
      </template>

      <template #body>
        <div>
          <p class="body-4">
            {{ manifestMessage }}
          </p>

          <NbButton v-if="request_id" v-copy="request_id">
            {{ $t("clickToCopy") }}
          </NbButton>
        </div>
      </template>

      <template #footer>
        <NbButton
          v-if="request_id"
          variant="secondary"
          class="d-flex ml-auto"
          @click="backToFlights"
        >
          {{ $t("close") }}
        </NbButton>
        <div v-else class="d-flex justify-content-between">
          <NbButton
            variant="secondary"
            class="ml-2"
            @click="resetModal('close-manifest')"
          >
            {{ $t("cancel") }}
          </NbButton>
          <NbButton :disabled="request_id || loading" @click="closeManifest">
            {{ $t("confirm") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <NbModal id="delete-flight" @close="modalActive = true">
      <template #header>
        <h3 class="heading-3">{{ $t("flightsPage.deleteFlight") }}</h3>
      </template>

      <template #footer>
        <div class="d-flex justify-content-between">
          <NbButton
            variant="secondary"
            class="ml-2"
            @click="resetModal('delete-flight')"
          >
            {{ $t("cancel") }}
          </NbButton>
          <NbButton @click="deleteFlight" :disabled="loading">
            {{ $t("confirm") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbCardBox from "@/components/cards/NbCardBox.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import UpdateFlightModal from "./UpdateFlightModal.vue";
import { copyClipboard } from "@/directives/copy-clipboard";
import FlightStatus from "./FlightStatus.vue";
import FlightTimeline from "./FlighTimeline.vue";
import FlightService from "../../../../services/FlightService";

const flightService = new FlightService();

export default {
  components: {
    NbModal,
    NbPageTitle,
    NbCard,
    NbButton,
    NbTextInput,
    NbTablev2,
    NbCardBox,
    UpdateFlightModal,
    FlightStatus,
    FlightTimeline,
  },
  directives: {
    copy: copyClipboard,
  },
  props: {
    flightId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isDownloading: false,
      loading: false,
      modalActive: true,
      request_id: null,
      showEditModal: false,
      flight: {
        stopover_airport: "",
        departure_airport: "",
        arrival_airport: "",
        departure_time: "",
        departure_date: "",
        arrival_time: "",
        arrival_date: "",
        mawb_number: "",
        mawb_file: "",
        mawb_file_temp: "",
        mawb_file_format: "",
        airline: "",
        flight_number: "",
        sub_total: "",
        charges_per_kilo: "",
        other_charges: "",
        freight_value: "",
      },
      masterboxes: [],
      masterboxesTableFields: [],
      shipments: [],
      shipmentsTableFields: [],
      labelUrl: "",
      hasMawbFile: false,
      message: "",
    };
  },
  computed: {
    manifestMessage: {
      get() {
        return this.message || this.$t("flightsPage.closeManifestConfirm");
      },
      set(value) {
        this.message = value;
      },
    },
    hasDepartureAndArrival() {
      return this.flight.departure_date && this.flight.arrival_date;
    },
    shipmentsTableAllFields() {
      return [
        { key: "id", label: "ID" },
        {
          key: "seller_weight",
          label: this.$t("shipmentsPage.fields.sSellerWeight"),
        },
        {
          key: "actual_weight",
          label: this.$t("shipmentsPage.fields.actualWeight"),
        },
        {
          key: "category",
          label: this.$t("shipmentsPage.fields.category"),
        },
        {
          key: "flight_id",
          label: this.$t("shipmentsPage.fields.flightID"),
        },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ];
    },
    masterboxesTableAllFields() {
      return [
        { key: "id", label: "ID" },
        {
          key: "dispatch_id",
          label: this.$t("masterboxesPage.fields.dispatchID"),
        },
        {
          key: "reference",
          label: this.$t("masterboxesPage.fields.reference"),
        },
        {
          key: "shipment_id",
          label: this.$t("masterboxesPage.fields.shipmentID"),
        },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ];
    },
  },
  methods: {
    backToFlights() {
      this.$helpers.closeModal("close-manifest");
      this.closeModal(true);
    },
    redirectToMasterbox(id) {
      this.$helpers.closeModal("flight-modal");
      this.$router.push(`/shipping/masterboxes?id=${id}`);
    },
    redirectToShipment(id) {
      this.$helpers.closeModal("flight-modal");
      this.$router.push(`/shipping/shipments?id=${id}`);
    },
    resetModal(modal) {
      this.$helpers.closeModal(modal);
      this.modalActive = true;
    },
    showModalConfirmDeparture() {
      this.modalActive = false;
      this.$helpers.openModal("confirm-departure");
    },
    showModalConfirmCloseManifest() {
      this.modalActive = false;
      this.$helpers.openModal("close-manifest");
    },
    showModalConfirmDelete() {
      this.modalActive = false;
      this.$helpers.openModal("delete-flight");
    },
    openModalEdit() {
      this.modalActive = false;
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
      this.modalActive = true;
    },
    closeModal(loadItem = false) {
      this.$helpers.closeModal("flight-modal");
      this.$emit("close", loadItem);
    },
    setMasterboxes() {
      this.shipments = this.flight?.shipments;
      this.masterboxes = this.flight?.shipments?.reduce((acc, current) => {
        acc = acc.concat(current.masterboxes);
        return acc;
      }, []);
    },
    deleteFlight() {
      this.loading = true;
      flightService
        .deleteFlight(this.flight.id)
        .then(() => {
          this.resetModal("delete-flight");
          this.closeModal(true);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeManifest() {
      this.loading = true;
      flightService
        .closeManifest(this.flight.id)
        .then((response) => {
          this.request_id = response.data.data.request_id;
          this.manifestMessage = response.data.messages[0].replace(
            "request_id",
            this.request_id,
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    confirmDeparture() {
      this.loading = true;
      flightService
        .confirmDeparture(this.flightId)
        .then(() => {
          this.resetModal("confirm-departure");
          this.loadFlight();
        })
        .catch((error) => {
          this.errors = error.response.data.messages[0];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async loadFlight() {
      this.modalActive = true;
      try {
        this.loading = true;
        const response = await flightService.getFlight(this.flightId);
        const { data } = response.data;
        this.flight = {
          ...response.data.data,
          stopover_airport: data.stopover_airport || "",
          departure_airport: data.departure_airport || "",
          arrival_airport: data.arrival_airport || "",
          departure_time: data.departure_time || "",
          departure_date: data.departure_date || "",
          arrival_time: data.arrival_time || "",
          arrival_date: data.arrival_date || "",
          mawb_number: data.mawb_number || "",
          mawb_file: data.mawb_file || "",
          mawb_file_temp: data.mawb_file_temp || "",
          mawb_file_format: data.mawb_file_format || "link",
          airline: data.airline || "",
          flight_number: data.flight_number || "",
          sub_total: data.sub_total || "",
          charges_per_kilo: data.charges_per_kilo || "",
          other_charges: data.other_charges || "",
          freight_value: data.freight_value || "",
        };
        this.setMasterboxes();
        this.generateLabel();
      } catch (error) {
        this.$bvToast.toast(this.$t("errorMessages.genericError"), {
          variant: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    async downloadLabel() {
      if (!this.labelUrl) {
        this.generateLabel();
        return;
      }
      window.open(this.labelUrl);
    },
    async generateLabel() {
      try {
        this.isDownloading = true;
        const response = await flightService.generateFlightLabelUntoasted(
          this.flightId,
        );
        if (response?.data?.data?.download_url) {
          this.labelUrl = response.data.data.download_url;
          this.hasMawbFile = true;
          this.flight.mawb_file = response.data.data.download_url;
          this.flight.mawb_file_temp = response.data.data.download_url;
        }
      } finally {
        this.isDownloading = false;
      }
    },
  },
  mounted() {
    this.$helpers.openModal("flight-modal");
    this.loadFlight();
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  gap: 1rem;
  &.grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  &.grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.cards {
  display: grid;
  gap: 1rem;
}
</style>
