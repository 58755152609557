<template>
  <div>
    <NbTablev2
      tableOf="trackingNotifications"
      namespace="tracking_notifications"
      ref="trackingNotifications"
      :allFields="allFields"
      :fields="fields"
      :searchBy="1"
      :filterOptions="filterOptions"
      @reloadFields="fields = $event"
      @total="$emit('total', $event)"
    >
      <template #cell(volume_checkpoint_id)="data">
        <div
          class="link-1"
          @click="loadModalData(data.item.volume_checkpoint_id)"
        >
          {{ data.item.volume_checkpoint_id }}
        </div>
      </template>
      <template #cell(sent)="data">
        <IsCheckedIcon :checked="data.item.sent" />
      </template>
    </NbTablev2>

    <ModalVolumeCheckpointsView
      id="ModalTrackingVolumeCheckpoints"
      :itemId="currentItemId"
    />
  </div>
</template>
<script>
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import ModalVolumeCheckpointsView from "@/views/checkpoints/volume_checkpoints/components/ModalVolumeCheckpointsView.vue";
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";

export default {
  name: "TrackingNotifications",
  components: {
    NbTablev2,
    ModalVolumeCheckpointsView,
    IsCheckedIcon,
  },
  props: {},
  data: () => {
    return {
      fields: [],
      currentItemId: "",
    };
  },
  created() {},
  methods: {
    checkQuery() {
      if (this.$route.query.volumeCheckpointId) {
        this.$emit("switchTab", "volumeCheckpoints");
        this.loadModalData(this.$route.query.volumeCheckpointId);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query.volumeCheckpointId) {
          this.$router.push({ query: { volumeCheckpointId: id } });
        }

        this.$helpers.openModal("ModalTrackingVolumeCheckpoints");
        return;
      }
    },
  },
  computed: {
    allFields() {
      return [
        { key: "id", label: "ID" },
        {
          key: "notification_type",
          label: this.$t("trackingNotificationsPage.fields.notificationType"),
        },
        {
          key: "volume_checkpoint_id",
          label: this.$t("trackingNotificationsPage.fields.volumeCheckpointID"),
        },
        {
          key: "data",
          label: this.$t("trackingNotificationsPage.fields.data"),
        },
        {
          key: "sent",
          label: this.$t("trackingNotificationsPage.fields.sent"),
        },
      ];
    },
    filterOptions() {
      return [
        {
          key: "notification_type",
          label: this.$t("trackingNotificationsPage.fields.notificationType"),
        },
        {
          key: "volume_checkpoint_id",
          label: this.$t("trackingNotificationsPage.fields.volumeCheckpointID"),
          type: "number",
        },
        {
          key: "data",
          label: this.$t("trackingNotificationsPage.fields.data"),
        },
        {
          key: "sent",
          label: this.$t("trackingNotificationsPage.fields.sent"),
          type: "boolean",
        },
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
      ];
    },
  },
  watch: {},
};
</script>
