<template>
  <table>
    <thead :class="thClass">
      <th>{{ $t("carriersPage.deliveryMethodFields.name") }}</th>
      <th>{{ $t("carriersPage.deliveryMethodFields.service") }}</th>
      <th>{{ $t("carriersPage.fields.creationDate") }}</th>
      <th>{{ $t("actions") }}</th>
    </thead>
    <tbody>
      <tr v-for="item in deliveryMethods" :key="item.id">
        <td>
          <NbButton variant="tertiary" @click="goToDeliveryMethod(item.id)">
            {{ item.name }}
          </NbButton>
        </td>
        <td>{{ item.service || "-" }}</td>
        <td>{{ item.created_at | formattedDate }}</td>
        <td>
          <NbButton variant="tertiary" @click="goToDeliveryMethod(item.id)">
            {{ $t("carriersPage.seeMethod") }} >
          </NbButton>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import moment from "moment";
export default {
  name: "CarrierDeliveryMethods",
  components: {
    NbButton,
  },
  props: {
    deliveryMethods: {
      type: Array,
      required: true,
    },
    thClass: {
      type: String,
      default: "",
    },
  },
  filters: {
    formattedDate(value) {
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    goToDeliveryMethod(id) {
      this.$helpers.closeAllModals();
      this.$router.push(`/contracts/delivery_methods?deliveryMethodId=${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1rem;
  thead {
    &::after {
      border: none;
    }
    th {
      text-align: center;
    }
  }

  tr {
    background-color: #e1e1f5;
    td {
      padding: 1rem 0.75rem;
      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}
</style>
