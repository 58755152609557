<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("sellersPage.addseller") }}
      </ModalTitle>
    </ModalHeader>

    <div class="grid gap-4">
      <NbCard id="carrier" :title="$t('sellersPage.carrierSelect')">
        <template #body>
          <Spinner size="md" class="mx-auto" v-if="loadingCarriers" />
          <div v-else class="d-flex align-items-end gap-2">
            <NbSelectInput
              :name="$t('sellersPage.carriers')"
              id="carriers"
              v-model="selectedCarrierId"
              :options="carriersOptions"
              class="flex-grow-1"
            />
            <NbButton
              class="mb-1"
              :disabled="!selectedCarrierId"
              @click="fillCarrierToSellerData"
            >
              Fill
            </NbButton>
          </div>
        </template>
      </NbCard>

      <NbCard id="general-data" :title="$t('generalData')">
        <template #body>
          <div class="grid gap-4 grid-cols-2">
            <NbTextInput
              v-model="form.name"
              id="form.name"
              :name="$t('sellersPage.name')"
              :error="[errors.get('name')]"
              required
            />

            <NbTextInput
              v-model="form.phone"
              id="form.phone"
              :name="$t('sellersPage.phone')"
              :error="[errors.get('phone')]"
            />

            <NbTextInput
              v-model="form.email"
              id="form.email"
              :name="$t('sellersPage.email')"
              :error="[errors.get('email')]"
              required
            />

            <NbTextInput
              v-model="form.site"
              id="form.site"
              :name="$t('sellersPage.site')"
              :error="[errors.get('site')]"
              required
            />

            <NbTextInput
              v-model="form.tax_number"
              id="form.tax_number"
              :name="$t('sellersPage.taxnumber')"
              :error="[errors.get('tax_number')]"
            />

            <NbTextInput
              v-model="form.legal_name"
              id="form.legal_name"
              :name="$t('sellersPage.legalName')"
              :error="[errors.get('legal_name')]"
            />

            <NbTextInput
              v-model="form.rfb_code"
              id="form.rfb_code"
              :name="$t('sellersPage.rfbCode')"
              :error="[errors.get('rfb_code')]"
              placeholder="RFB(Receita Federal Brasileira) code .."
            />

            <NbTextInput
              v-model="form.billing_email"
              id="form.billing_email"
              :name="$t('sellersPage.billingEmail')"
              :error="[errors.get('billing_email')]"
              placeholder="RFB(Receita Federal Brasileira) code .."
            />

            <NbTextInput
              v-model="form.responsable_contact"
              id="form.responsable_contact"
              :name="$t('sellersPage.responsableContact')"
              :error="[errors.get('responsable_contact')]"
              class="col-span-2"
            />

            <div class="col-span-2 grid grid-cols-8">
              <NbSwitch
                v-model="form.is_subsidiary"
                id="form.is_subsidiary"
                :name="$t('sellersPage.issubsidiary')"
              />
              <NbSwitch
                v-model="form.is_distribution_center"
                id="form.is_subsidiary"
                :name="$t('sellersPage.isdistributioncenter')"
              />
              <NbSwitch
                v-model="form.is_multiple"
                id="form.is_multiple"
                :name="$t('sellersPage.ismultiple')"
              />
              <NbSwitch
                v-model="form.is_carrier"
                id="form.is_carrier"
                :name="$t('sellersPage.fields.isCarrier')"
              />
            </div>

            <!-- <div class="col-span-2">
              <NbDropFile
                v-model="signature"
                :label="$t('sellersPage.signature')"
              />
            </div> -->
          </div>
        </template>
      </NbCard>

      <NbCard id="address" :title="$t('sellersPage.address')">
        <template #body>
          <div class="grid grid-cols-2 gap-4">
            <NbTextInput
              v-model="form.zip_code"
              id="form.zip_code"
              :name="$t('sellersPage.postalcode')"
              :error="[errors.get('zip_code')]"
              required
            />
            <NbTextInput
              v-model="form.address"
              id="form.address"
              :name="$t('sellersPage.address')"
              :error="[errors.get('address')]"
              required
            />

            <NbTextInput
              v-model="form.address_number"
              id="form.address_number"
              :name="$t('sellersPage.addressnumber')"
              :error="[errors.get('address_number')]"
            />

            <NbTextInput
              v-model="form.address_complement"
              id="form.address_complement"
              :name="$t('sellersPage.addresscomplement')"
              :error="[errors.get('address_complement')]"
            />

            <NbSelectInput
              v-model="form.country"
              :options="countriesOptions"
              :name="$t('sellersPage.country')"
              id="form.country"
              :error="[errors.get('country')]"
              required
            />

            <NbTextInput
              v-model="form.state"
              id="form.state"
              :name="$t('sellersPage.state')"
              :error="[errors.get('state')]"
            />

            <NbTextInput
              v-model="form.city"
              id="form.city"
              :name="$t('sellersPage.city')"
              :error="[errors.get('city')]"
              class="col-span-2"
              required
            />
          </div>
        </template>
      </NbCard>

      <NbCard id="contracts" :title="$t('sellersPage.allowedcontracts')">
        <template #body>
          <Spinner size="md" class="mx-auto" v-if="loadingContracts" />
          <template v-else>
            <NbSelecMultipletInput
              v-model="form.allowed_contracts"
              id="selectedContracts"
              :name="$t('components.searchData.contracts')"
              :options="contractsOptions"
            />
          </template>
        </template>
      </NbCard>
    </div>

    <ModalFooter
      v-if="!loadCarriers && !loadingContracts"
      class="d-flex justify-content-end"
    >
      <ModalClose class="mr-2" />
      <NbButton @click="create" :disabled="loading">
        {{ $t("save") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbSwitch from "@/components/buttons/NbSwitch.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbSelecMultipletInput from "@/components/input/select/NbSelecMultipletInput.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import Errors from "@/utils/Errors.js";
import CarrierService from "@/services/CarrierService";
import ContractService from "@/services/ContractService";
import Spinner from "@/components/Spinner.vue";
import SellerService from "@/services/SellerService";

const contractService = new ContractService();
const carrierService = new CarrierService();
const sellersService = new SellerService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    NbCard,
    NbSelectInput,
    NbTextInput,
    // NbDropFile,
    NbSwitch,
    NbSelecMultipletInput,
    Spinner,
  },
  data() {
    return {
      selectedCarrierId: null,
      carriersOptions: [],
      countriesOptions: [],
      contractsOptions: [],
      errors: new Errors(),
      signature: "",
      loading: false,
      loadingContracts: false,
      loadingCarriers: false,
      form: {
        address: "",
        address_number: "",
        address_complement: "",
        city: "",
        zip_code: "",
        state: "",
        country: "",
        name: "",
        email: "",
        phone: "",
        site: "",
        tax_number: "",
        rfb_code: "",
        legal_name: "",
        is_active: true,
        is_carrier: false,
        is_subsidiary: false,
        is_distribution_center: false,
        is_multiple: false,
        users: [],
        allowed_contracts: [],
      },
    };
  },
  computed: {
    countries() {
      return this.$store.state.countries.all_items;
    },
  },
  methods: {
    async create() {
      try {
        this.loading = true;
        await sellersService.addSeller(this.form);
      } catch (error) {
        if (error?.response?.data?.messages?.length) {
          this.errors.record(error.response.data.messages?.[0]);
        }
      } finally {
        this.loading = false;
      }
    },
    setCountriesOptions() {
      this.countriesOptions = this.countries.map((country) => ({
        text: country.name,
        value: country.alpha2_code,
      }));
    },
    setCarrierOptions() {
      this.carriersOptions =
        this.carriers.map((carrier) => ({
          value: carrier.id,
          text: `(${carrier.id}) ${carrier.name}`,
        })) || [];
    },
    async loadCarriers() {
      try {
        this.loadingCarriers = true;
        const { data } = await carrierService.getCarriers();
        this.carriers = data?.data?.elements || [];
        this.setCarrierOptions();
      } finally {
        this.loadingCarriers = false;
      }
    },
    async loadContracts() {
      try {
        this.loadingContracts = true;
        const { data } = await contractService.getContracts();
        this.contractsOptions = data.data?.map((item) => ({
          text: item.name,
          value: item.id,
        }));
      } finally {
        this.loadingContracts = false;
      }
    },
    fillCarrierToSellerData() {
      if (this.selectedCarrierId) {
        const carrier = this.carriers.find(
          (carrier) => carrier.id === this.selectedCarrierId,
        );

        this.form = {
          ...this.form,
          address: carrier.address,
          address_number: carrier.address_number,
          address_complement: carrier.address_complement,
          city: carrier.city,
          zip_code: carrier.zip_code,
          state: carrier.state,
          country: carrier.country,
          name: carrier.name,
          email: carrier.email,
          phone: carrier.phone,
          site: carrier.site,
          tax_number: carrier.tax_number,
          rfb_code: carrier.rfb_code,
          legal_name: carrier.legal_name,
          users: carrier.unarchived_users,
          is_active: carrier.is_active,
          is_carrier: true,
        };
      }
    },
    async loadData() {
      this.setCountriesOptions();
      this.loadContracts();
      this.loadCarriers();
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped></style>
