<template>
  <div>
    <div
      v-if="selectedRows.length > 0 || buttonOptions.length > 0"
      class="floating-buttons-wrapper"
    >
      <div :class="tabs ? 'floating-buttons' : 'button-on-table'">
        <div class="d-flex relative">
          <NbButton
            v-for="buttonTable in buttonsTable"
            :key="buttonTable.id"
            :variant="buttonTable.variant || 'secondary'"
            size="sm"
            class="ml-2"
            :icon="buttonTable.icon || ''"
            data-toggle="modal"
            :data-target="
              buttonTable.value.includes('#') ? buttonTable.value : ''
            "
            @click="optionClicked(buttonTable.value)"
            :disabled="buttonTable?.disabled"
          >
            {{ buttonTable.text }}
          </NbButton>
          <NbOption
            :id="tableOf + 'actions-btn'"
            v-model="buttonOptionChosen"
            class="ml-3"
            classes="mr-3"
            :disabled="selectedRows.length > 0 ? false : true"
            :options="buttonOptions"
            :options-width="optionsWidth"
            expand-to="right"
            icon="chevron-down"
            @input="optionClicked($event)"
          >
            {{ $t("actions") }}
          </NbOption>
        </div>
      </div>
    </div>
    <div :class="hiddeTableOptions ? 'display-none' : 'd-flex'">
      <div class="flex-grow-1">
        <NbSearchInput
          :id="`filter-input-${tableOf}`"
          v-model="filterInput"
          class="mr-1"
          :help-text="
            $t('components.nbTable.worksOnlyCurrentPage', {
              onThisPageItems: perPage,
              totalItems: total,
            })
          "
          :placeholder="allFields[searchBy].label"
        />
      </div>
      <div class="mr-1" style="margin-top: 0.5px">
        <NbTableFilters
          v-if="filterOptions.length > 0"
          :width="590"
          :has-pagination="true"
          :filter-options="filterOptions"
          :page-name="`filters_${tableOf}`"
          @emitFilter="submitFilterForm($event)"
        />
      </div>
      <div class="mr-1" style="margin-top: 0.5px">
        <NbSelectTableColumnsModal
          :id="`table-columns-options-${tableOf}`"
          :width="460"
          icon="list"
          :all-fields="allFields"
          :page-name="`${tableOf}`"
          @selectedFields="reloadFields($event)"
        />
      </div>
      <div class="">
        <NbButton
          v-if="vistaBtn"
          variant="quaternary"
          :icon="
            isVista ? 'fas fa-compress-arrows-alt' : 'fas fa-expand-arrows-alt'
          "
          @click="switchVista()"
        >
          {{ $t("view") }}
        </NbButton>
      </div>
    </div>
    <!-- table -->
    <div class="nb-table-wrapper" :style="`max-height: ${height};`">
      <table :class="`table shadow-sm rounded ${tdClass}`">
        <thead class="tr-nbtable" :class="shortThead ? 'short-thead' : ''">
          <tr style="height: 3rem">
            <th v-if="selectable" class="text-th select-th pr-0">
              <div class="custom-checkbox d-flex">
                <input
                  :id="'itemAll' + tableOf"
                  v-model="isCheckAll"
                  type="checkbox"
                  class="nb-checkbox"
                  :value="items"
                  @click="checkAll()"
                />
                <label class="text-th ml-1" :for="'itemAll' + tableOf">
                  {{ $t("selectAll") }}
                </label>
              </div>
            </th>
            <th
              v-for="field in sortableFields"
              :key="field.key"
              :class="['text-th bg-gray-05', field.class ?? '']"
            >
              <slot :name="`label(${removeSpaces(field.label)})`" :item="field">
                {{ field.label ? field.label : "" }}
                <NbHelpText
                  v-if="field.helpText"
                  :id="`${field.key}-popover`"
                  class="mr-1"
                  :size="field.helpTextSize || 'sm'"
                  placement="topright"
                >
                  {{ field.helpText }}
                  <a
                    v-if="field.helpTextLink"
                    :href="field.helpTextLink.href"
                    target="_blank"
                    class="link-2"
                    style="color: white !important"
                  >
                    {{ field.helpTextLink.text }}
                  </a>
                </NbHelpText>
                <span
                  v-if="field.sortable"
                  class="pointer"
                  :class="sortControl.key === field.key ? '' : 'sort-deactived'"
                  @click="sortBy(field.key)"
                >
                  <NbIcon
                    :id="`${field.key}helptext`"
                    icon="arrow-down"
                    class="sort-control"
                    :attributes="{
                      class: `mb-1 ${rollUp(field.key)} sort-control`,
                      'stroke-width': 3,
                      width: '1rem',
                      height: '1rem',
                    }"
                  />
                  <b-popover
                    placement="right"
                    :target="`${field.key}helptext`"
                    triggers="hover focus"
                    variant="dark"
                  >
                    {{ $t("components.nbTable.sortBtn") }}
                  </b-popover>
                </span>
              </slot>
            </th>
          </tr>
        </thead>
        <tbody class="avoid-break-line">
          <tr
            v-for="(item, index) in sortedItems"
            :key="index"
            style="height: 3rem"
            :class="{ hover: clickable }"
            @click="handleClick($event, item, index)"
          >
            <td v-if="selectable" class="text-td align-middle">
              <div class="custom-checkbox">
                <input
                  :id="'item-' + item.id + tableOf"
                  v-model="selectedRows"
                  type="checkbox"
                  class="nb-checkbox"
                  :value="item.id"
                  @click.stop
                  @change="updateCheckall()"
                />
              </div>
            </td>

            <td
              v-for="field in fields"
              :key="field.key"
              class="text-td align-middle"
              :class="`${field?.class} ${tdClass}`"
            >
              <div v-if="index == editingItem">
                <input
                  v-if="checkType(item[field.key])"
                  :id="`edit-${field.key + tableOf}`"
                  v-model="item[`${field.key}`]"
                  class="form-control"
                  :class="{ 'is-invalid': errors[`${field.key}`] }"
                />
                <div v-else>
                  <label :for="`edit-${field.key + tableOf}`" class="mr-1 pb-1">
                    {{ field.label }}</label
                  >
                  <input
                    :id="`edit-${field.key + tableOf}`"
                    v-model="item[`${field.key}`]"
                    class="mr-1"
                    type="checkbox"
                    autocomplete="off"
                  />
                </div>
                <div
                  v-for="error in errors[`${field.key}`]"
                  :key="error.id"
                  class="invalid-feedback"
                >
                  {{ error }}
                </div>
              </div>

              <slot
                v-else
                :name="`cell(${field.key})`"
                :item="setItem(item, index)"
              >
                <p class="m-0">
                  {{ item[field.key] ? item[field.key] : "-" }}
                </p>
              </slot>
              <slot
                v-if="isVista"
                :name="`vista(${field.key})`"
                :item="setItem(item, index)"
              >
              </slot>
            </td>

            <td v-if="deletable" class="align-middle">
              <button
                class="btn btn-danger px-2 py-1 m-1"
                data-toggle="modal"
                :data-target="'#modalDelete' + tableOf"
                @click="deletingItem = index"
              >
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      :class="
        hiddeTableOptions ? 'display-none' : 'd-flex justify-content-between'
      "
    >
      <NbMaxRows
        class="mt-1"
        :per-page="perPage"
        :save-to="`${rowsControl ? rowsControl : tableOf}rows`"
        @emitValue="updatePerpage($event)"
      />
      <div style="width: 44%">
        <div v-if="selectable">
          <div v-if="isCheckAll" class="">
            <div class="d-flex total-of justify-content-center">
              <span class="mr-1"
                ><span v-if="total != selectedRows.length" class="mr-1">{{
                  $t("components.nbTable.all")
                }}</span
                ><span class="font-weight-bold">{{ selectedRows.length }}</span>
                {{ $t(`items`) }}
                <span v-if="total != selectedRows.length" class=" ">{{
                  $t("components.nbTable.onThisPage")
                }}</span>
                {{
                  $tc("components.nbTable.isSelected", selectedRows.length)
                }}.</span
              >
              <a
                v-if="total == selectedRows.length"
                href=""
                class="link-1"
                @click.prevent="unselectAll"
              >
                {{ $t("clear") }}</a
              >
              <a v-else href="" class="link-1" @click.prevent="selectAll">
                {{ $t("selectAll") }} {{ total }} {{ $t(`items`) }}
              </a>
            </div>
          </div>
          <div v-else-if="selectedRows.length > 0" class="mt-1">
            <div class="d-flex total-of justify-content-center">
              <span class="mr-1"
                ><span class="font-weight-bold">{{ selectedRows.length }}</span>
                {{ $t(`items`) }} {{ $t("selected") }}.</span
              >
              <a href="" class="link-1" @click.prevent="unselectAll">
                {{ $t("clear") }}</a
              >
            </div>
          </div>
          <div v-else class="mt-1"></div>
        </div>
      </div>
      <div class="d-flex justify-content-start">
        <span class="total-of mr-2">{{ currentItems }} of {{ total }}</span>
        <b-pagination
          v-model="currentPage"
          class="pb-0 mb-0 nb-pagination"
          aria-controls="my-table"
          size="sm"
          limit="7"
          :total-rows="total"
          :per-page="perPage"
          first-text="|<"
          prev-text="<"
          next-text=">"
          last-text=">|"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NbMaxRows from "@/components/tables/NbMaxRows.vue";
import NProgress from "nprogress";
import NbSearchInput from "@/components/input/text/NbSearchInput.vue";
import NbTableFilters from "@/components/filters/NbTableFilters.vue";
import NbSelectTableColumnsModal from "@/components/tables/NbSelectTableColumnsModal.vue";
import NbOption from "@/components/buttons/NbOption.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  name: "NbTablev2",

  components: {
    NbMaxRows,
    NbSearchInput,
    NbTableFilters,
    NbSelectTableColumnsModal,
    NbOption,
    NbButton,
    NbHelpText,
    NbIcon,
  },
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    showBtnOptions: {
      type: Boolean,
      required: false,
    },
    tableOf: {
      type: String,
      required: true,
    },
    rowsControl: {
      type: String,
      default: "",
    },
    namespace: {
      type: String,
      default: "",
    },
    startPerPage: {
      type: [String, Number],
      default: null,
    },
    filterOptions: {
      type: Array,
      default: () => [],
    },
    allFields: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      required: false,
    },
    clickable: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
    deletable: {
      type: Boolean,
      required: false,
    },
    errors: {
      type: Array,
      default: null,
    },
    shortThead: {
      type: Boolean,
      required: false,
    },
    startItems: {
      type: [Array, Object],
      default: null,
    },
    hasParentItens: {
      type: Boolean,
      default: false,
    },
    hiddeTableOptions: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
      default: "calc(100vh - 200px)",
    },
    optionsWidth: {
      type: [Number, String],
      default: null,
    },
    buttonsTable: {
      type: Array,
      default: () => [],
    },
    buttonOptions: {
      type: Array,
      default: () => [],
    },
    hiddeSelectColumns: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Boolean,
      default: true,
    },
    vistaBtn: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    searchBy: {
      type: Number,
      default: 0,
    },
    tdClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedRows: this.selectedItems,
      isCheckAll: false,
      editingItem: null,
      editingItemCopy: {},
      deletingItem: {},
      itemsIds: [],
      /* items: this.value, */
      filters: {},
      filterInput: "",
      filterSelected: "",
      columnsSelected: "",
      sortControl: { sort: 2, key: "" },
      sortableFields: [],
      isVista: false,
      buttonOptionChosen: "",
      perPage: 20,
      currentLocalPage: 1,
    };
  },
  computed: {
    items() {
      if (this.hasParentItens) {
        if (this.startItems.elements) {
          return this.startItems.elements;
        }
        return this.startItems;
      }
      return this.$store.state[this.namespace].items;
    },
    currentItems() {
      if (this.perPage < 10) {
        return;
      }
      let end = 0;
      if (this.namespace) {
        end = this.currentPage * this.perPage;
      } else {
        end = this.currentLocalPage * this.perPage;
      }
      let begin = end - (this.perPage - 1);
      if (end > this.total) {
        end = this.total;
      }
      return `${begin} - ${end}`;
    },
    sortedItems() {
      let finalItems = JSON.parse(JSON.stringify(this.items));
      if (this.filterInput.length > 0) {
        finalItems = this.isSearching(
          finalItems,
          this.filterInput.toLowerCase(),
        );
      }
      if (this.sortControl.sort < 2) {
        return this.sortItems(finalItems);
      }
      return finalItems;
    },
    total() {
      let total = 0;
      if (this.items) {
        if (
          this.$store.state[this.namespace] &&
          this.$store.state[this.namespace].totalRows > 0
        ) {
          this.$emit("total", this.$store.state[this.namespace].totalRows);
          return this.$store.state[this.namespace].totalRows;
        }
        if (this.items.length > 0) {
          total = this.items.length;
          this.$emit("total", total);
          return total;
        }
      }
      this.$emit("total", total);
      return total;
    },

    currentPage: {
      get() {
        if (
          this.namespace &&
          this.$store.state[this.namespace].currentPage > 0
        ) {
          return this.$store.state[this.namespace].currentPage;
        }
        return this.currentLocalPage;
      },
      set(value) {
        if (this.namespace) {
          this.$store.commit(this.namespace + "/SET_CURRENT_PAGE", value);
          return;
        }
        this.currentLocalPage = value;
      },
    },
  },
  watch: {
    selectedItems(newValue) {
      this.selectedRows = newValue;
      this.updateCheckall();
      this.isCheckAll = newValue?.length === this.sortedItems?.length;
    },
    selectedRows(newValue) {
      this.$emit("update:selectedItems", newValue);
    },
    /* avoid reloadField when allfield change
    allFields(newVal) {
      this.reloadFields(newVal);
    },
    */
    fields(newValue) {
      this.sortableFields = JSON.parse(JSON.stringify(newValue));
    },

    /* //altera o input (way data bind ->)
    items(newValue) {
      this.$emit('input', newValue);
    },
    //altera o input vindo do pai (way data bind <-)
    value(newValue) {
      this.items = newValue;
    }, */
  },
  created() {
    //if(this.hasParentItens) { this.getData('created') }
    /*
    if(this.value && Array.isArray(this.value)) {
      return
    }
    */
    this.getPerPage(`${this.tableOf}rows`);
    if (this.startPerPage) {
      this.perPage = this.startPerPage;
    }
    if (!this.filterOptions) {
      this.submitFilterForm();
    }
  },
  methods: {
    optionClicked(event) {
      this.$emit("optionTook", event);
      //this[event]();
    },
    getPerPage(saveTo) {
      let storage = {};
      if (localStorage.getItem("itemsPerPage")) {
        storage = JSON.parse(localStorage.getItem("itemsPerPage"));
      }
      if (storage && storage[saveTo]) {
        this.perPage = storage[saveTo];
      } else {
        this.perPage = 20;
      }
    },
    updatePerpage(event) {
      this.perPage = event;
      this.$store.commit(this.namespace + "/SET_LIMIT", this.perPage);
    },
    getData() {
      //{limit: 10, offset: 1, id: '2'}
      /*
      this.filters.limit = this.perPage;
      this.filters.offset = this.currentPage;

      if (this.hasParentItens) {
        if (this.startItems.elements) {
          this.items = this.startItems.elements;
          this.itemsCount = this.startItems.count;
          this.itemsIds = this.startItems.ids;
          return;
        }
        this.items = this.startItems;
        return;
      }
      */

      //the items will be controled by the father in v-model
      if (!this.namespace) {
        return;
      }

      NProgress.start();
      if (this.perPage > 0) {
        /* this.filters.limit = this.perPage;
        this.filters.offset = (this.currentPage - 1) * this.perPage; */

        this.$store.commit(this.namespace + "/SET_FILTERS", this.filters);
        //this.$store.commit(this.namespace + "/SET_LIMIT", this.perPage);
      }
      NProgress.done();
    },
    checkType(val) {
      if (typeof val === "boolean") {
        return false;
      }
      return true;
    },
    handleClick(event, item, index) {
      if (this.clickable) {
        const element = event.target;
        if (
          element.classList.contains("link") ||
          element.classList.contains("link-1") ||
          element.classList.contains("link-2") ||
          element.tagName === "BUTTON" ||
          element.tagName === "A"
        ) {
          return;
        }
        event.stopPropagation();
        this.$emit("clickedRow", item, index);
      }
    },
    removeSpaces(stringui) {
      if (stringui) {
        return stringui.replaceAll(" ", "");
      }
    },
    setItem(item, index) {
      let itemToReturn = {};
      itemToReturn = item;
      itemToReturn.index = index;
      return itemToReturn;
    },
    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      if (this.isCheckAll) {
        this.selectedRows = [];
        for (var item in this.sortedItems) {
          this.selectedRows.push(this.items[item].id);
        }
      } else {
        this.selectedRows = [];
      }
    },
    updateCheckall() {
      //rodar isso quando a tabela alterar!!!
      if (this.selectedRows.length == this.sortedItems.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    selectAll() {
      this.selectedRows = [];
      this.selectedRows = this.$store.state[this.namespace].ids;
    },
    unselectAll() {
      this.selectedRows = [];
      this.updateCheckall();
    },
    reloadFields(event) {
      this.$emit("reloadFields", event);
    },
    submitFilterForm(event) {
      this.filters = {};
      if (event) {
        this.filters = event;
      }
      const appliedFilters = {};

      Object.keys(this.filters).forEach((filterName) => {
        const filterData = this.filters[filterName];
        if (filterData && filterData !== "null") {
          appliedFilters[filterName] = filterData;
        }
      });
      this.getData("filter");
    },
    sortBy(field) {
      this.sortControl.sort++;
      this.sortControl.key = field;

      if (this.sortControl.sort === 2) {
        this.sortControl.key = "";
      }
      if (this.sortControl.sort === 3) {
        this.sortControl.sort = 0;
        return;
      }
    },
    sortItems(items) {
      if (this.sortControl.sort === 1) {
        if (isNaN(Number(this.items[0][this.sortControl.key]))) {
          const sorted = items.sort((current, next) =>
            current[this.sortControl.key] > next[this.sortControl.key] ? -1 : 1,
          );
          return sorted;
        }
        const sorted = items.sort((current, next) =>
          Number(current[this.sortControl.key]) >
          Number(next[this.sortControl.key])
            ? 1
            : -1,
        );
        return sorted;
      }
      if (isNaN(Number(this.items[0][this.sortControl.key]))) {
        const sorted = items.sort((current, next) =>
          current[this.sortControl.key] > next[this.sortControl.key] ? 1 : -1,
        );
        return sorted;
      }
      const sorted = items.sort((current, next) =>
        Number(current[this.sortControl.key]) >
        Number(next[this.sortControl.key])
          ? -1
          : 1,
      );
      return sorted;
    },
    rollUp(field) {
      if (this.sortControl.sort === 2 || this.sortControl.sort === 0) {
        return "";
      }
      if (field === this.sortControl.key) {
        return "upRotate";
      }
      return "";
    },
    isSearching(items, search) {
      let itemsToReturn = items.filter((item) => {
        const matches = item[this.allFields[this.searchBy].key]
          .toString()
          .toLowerCase()
          .includes(search);
        return matches;

        /*
        OBS: adicionar mais tipos de matches
        const matchesDois = item[allFields[1].key].toLowerCase().includes(search)
        (...)
        return matches || matchesDois || (...));
        */
      });
      return itemsToReturn;
    },
    switchVista() {
      this.isVista = !this.isVista;
      this.$emit("switchVista", this.isVista);
    },
  },
};
</script>

<style>
.floating-buttons-wrapper {
  position: absolute;
  width: calc(100% - 3rem);
}
.floating-buttons-wrapper > .floating-buttons {
  float: right;
  position: relative;
  top: -4rem;
}
.floating-buttons-wrapper > .button-on-table {
  float: right;
  position: relative;
  top: -2.8rem;
}
.relative {
  position: relative;
}
.table td {
  border-bottom: 1px solid var(--gray-20) !important;
  padding: 0rem 0.75rem;
}

.avoid-break-line {
  white-space: nowrap;
}

.text-th {
  min-width: 5.6rem;
  color: var(--black);
  text-align: left;
  vertical-align: middle !important;
  font: normal normal bold 14px/20px Nunito Sans !important;
  letter-spacing: 0px;
}
.text-th.select-th {
  min-width: 2.5rem !important;
}
.text-td {
  color: var(--black);
  text-align: left;
  font: normal normal normal 14px/20px Nunito Sans;
  letter-spacing: 0px;
}
.text-td.align-middle.td-dark {
  background: var(--gray-10) !important;
  border-bottom: 0.25rem solid var(--gray-05) !important;
}

.nb-table-wrapper {
  max-width: 100% !important;
  overflow-x: auto;
  overflow-y: auto;
  border-radius: 4px !important;
}
.nb-table-wrapper > .table.shadow-sm.rounded.td-dark {
  box-shadow: none !important;
}
.nb-table-wrapper::-webkit-scrollbar {
  width: 0px;
  height: 0.7rem;
  padding: 3px;
}
.nb-table-wrapper > .table > .tr-nbtable {
  /* background: var(--background) */
  background: var(--gray-05) 0% 0% no-repeat padding-box;

  top: 0px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
}
.nb-table-wrapper > .table.tr-nbtable.short-thead {
  top: 38px;
  position: sticky;
  z-index: 2;
  background: var(--white);
}
.display-bar {
  height: 56px;
  max-height: 56px;
  margin-top: 0.25rem !important;
}

.total-of {
  color: var(--black);
  font: normal normal normal 14px/20px Nunito Sans;
  letter-spacing: 0px;
  margin-top: 0.3rem;
}

.nb-pagination.pagination {
  margin-right: 3.6rem;
}
.nb-pagination.pagination .page-item .page-link {
  transition: all 0.3s ease;
  background: white;
  font: normal normal 600 12px/16px Nunito Sans;
  letter-spacing: 0px;
  color: var(--black);
}
.nb-pagination.pagination .page-item.active .page-link {
  background: var(--black);
  font: normal normal bold 12px/16px Nunito Sans;
  color: white;
}
.nb-pagination .page-item.disabled .page-link {
  color: var(--gray-20);
}
.nb-pagination .page-item > .page-link {
  transition: all 0.3s ease;
  border: 0px solid var(--gray-10);
}
.nb-pagination .page-item > .page-link:hover {
  background: var(--gray-05);
}

/* checkbox */

input[type="checkbox"].nb-checkbox {
  min-width: 1rem;
  height: 1rem;
}
/* input[type="checkbox"].nb-checkbox:focus {
  box-shadow: 0px 0px 0px 1px var(--primary) !important;
} */
input[type="checkbox"].nb-checkbox:hover {
  filter: invert(20%) sepia(2%) saturate(592%) hue-rotate(238deg)
    brightness(114%) contrast(81%);
}
input[type="checkbox"].nb-checkbox:checked {
  accent-color: #34495e;
}
input[type="checkbox"].nb-checkbox:checked:hover {
  filter: none;
}
input[type="checkbox"] + label {
  margin-bottom: 0px;
  font: normal normal normal 14px/18px Nunito Sans;
  letter-spacing: 0px;
  color: var(--gray-60);
}
input[type="checkbox"] + label:hover {
  color: var(--black);
}
input[type="checkbox"]:checked + label {
  font: normal normal 600 14px/18px Nunito Sans;
  color: var(--black);
}
.sort-deactived {
  color: var(--gray-40) !important;
}
.sort-control {
  transition: all 0.5s ease;
}
.upRotate {
  transition: all 0.5s ease;
  transform: rotate(180deg);
}

.right-sticky {
  position: sticky;
  right: 0px;
  background: var(--background);
}
.right--20 {
  right: -20px !important;
}
.hover {
  transition: all 0.3s ease;
}
.hover:hover {
  background: var(--gray-10);
  cursor: pointer;
}
/* prevent break-line
table tr {
  white-space:nowrap;
}
*/
</style>
