<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        <Spinner v-if="loading" class="mx-auto" size="md" />
        <span v-else>{{ $t("ordersViewPage.title") }}{{ order.id }}</span>
      </ModalTitle>
    </ModalHeader>
    <div v-if="order">
      <div class="grid grid-cols-3 gap-4">
        <OrderDocuments :order-id="orderId" />
        <NbCard id="actions" :title="$t('actions')">
          <template #body>
            <div class="grid gap-2">
              <NbButton
                v-if="$store.state.platform === 'total'"
                variant="secondary"
                @click="showModalEdit = true"
              >
                {{ $t("edit") }}
              </NbButton>
              <NbButton variant="secondary" @click="showAddOrderCost = true">
                {{ $t("ordersViewPage.addCost") }}
              </NbButton>
              <NbButton
                variant="secondary"
                @click="showUpdateFinancialStatus = true"
              >
                {{ $t("ordersPage.updateFinancialStatus") }}
              </NbButton>
              <NbButton
                variant="secondary"
                @click="showConfirmRemoveOrder = true"
              >
                {{ $t("remove") }}
              </NbButton>
            </div>
          </template>
        </NbCard>
        <NbCard id="suggested" :title="$t('suggestedActions')">
          <template #body>
            <div class="grid gap-2">
              <NbButton v-copy="order.reference">
                {{ $t("ordersViewPage.copyTrackingNumber") }}
              </NbButton>
              <NbButton @click="trackSearch">{{
                $t("ordersViewPage.trackingPage")
              }}</NbButton>
            </div>
          </template>
        </NbCard>
      </div>

      <div class="grid gap-4 mt-4">
        <NbCard :title="$t('ordersViewPage.orderData')" id="order">
          <template #body>
            <div class="grid grid-cols-2 gap-4">
              <NbTextInput
                :name="$t('ordersViewPage.orderNumbe')"
                v-model="order.order_number"
                id="order.order_number"
                variant="borderless-gray-60"
                disabled
              />
              <NbTextInput
                :name="$t('ordersViewPage.sellerName')"
                v-model="order.shipper_name"
                id="order.shipper_name"
                variant="borderless-gray-60"
                disabled
              />

              <div class="col-span-2 d-flex justify-content-end">
                <div>
                  <label for="" class="input-label">
                    {{ $t("ordersViewPage.createdDate") }}
                  </label>
                  <small>
                    {{ moment(order.created_at).format("DD/MM/YYYY HH:mm:ss") }}
                  </small>
                </div>
              </div>
            </div>
            <Transition name="slide-fade" mode="out-in">
              <div v-show="showAllOrderData" class="grid grid-cols-2 gap-4">
                <NbTextInput
                  :name="$t('ordersViewPage.estimatedDeliveryDate')"
                  v-model="order.estimated_delivery_time"
                  id="order.estimated_delivery_time"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('ordersViewPage.trackingNumber')"
                  v-model="order.reference"
                  id="order.reference"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('ordersViewPage.isMini')"
                  v-model="order.is_mini"
                  id="order.is_mini"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('ordersViewPage.observation')"
                  v-model="order.observations"
                  id="order.observations"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('volumesPage.fields.isCommercialDestination')"
                  v-model="order.is_commercial_destination"
                  id="order.is_commercial_destination"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('volumesPage.fields.sellerRfbCode')"
                  v-model="order.seller_rfb_code"
                  id="order.seller_rfb_code"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('volumesPage.fields.sellerLegalName')"
                  v-model="order.seller_legal_name"
                  id="order.seller_legal_name"
                  variant="borderless-gray-60"
                  disabled
                />
              </div>
            </Transition>
            <NbButton
              variant="tertiary"
              @click="showAllOrderData = !showAllOrderData"
            >
              {{
                showAllOrderData
                  ? $t("ordersViewPage.collapseOrderData")
                  : $t("ordersViewPage.showAllOrderData")
              }}
            </NbButton>
          </template>
        </NbCard>

        <NbCard :title="$t('ordersViewPage.customerData')" id="customer">
          <template #body>
            <div class="grid grid-cols-2 gap-4">
              <NbTextInput
                :name="$t('ordersViewPage.name')"
                v-model="order.customer_full_name"
                id="order.customer_full_name"
                variant="borderless-gray-60"
                disabled
              />
              <NbTextInput
                :name="$t('ordersViewPage.documentType')"
                v-model="order.customer_document_type"
                id="order.customer_document_type"
                variant="borderless-gray-60"
                disabled
              />
            </div>

            <Transition name="slide-fade" mode="out-in">
              <div v-show="showAllCustomerData" class="grid grid-cols-2 gap-4">
                <NbTextInput
                  :name="$t('ordersViewPage.documentNumber')"
                  v-model="order.customer_document_number"
                  id="order.customer_document_number"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('ordersViewPage.address')"
                  v-model="order.customer_address"
                  id="order.customer_address"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('ordersViewPage.addressNumber')"
                  v-model="order.customer_address_number"
                  id="order.customer_address_number"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('ordersViewPage.addressComplement')"
                  v-model="order.customer_address_complement"
                  id="order.customer_address_complement"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('ordersViewPage.addressReference')"
                  v-model="order.customer_address_reference"
                  id="order.customer_address_reference"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('ordersViewPage.postalCode')"
                  v-model="order.customer_postal_code"
                  id="order.customer_postal_code"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('ordersViewPage.addressQuarter')"
                  v-model="order.customer_address_quarter"
                  id="order.customer_address_quarter"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('ordersViewPage.city')"
                  v-model="order.customer_city"
                  id="order.customer_city"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('ordersViewPage.state')"
                  v-model="order.customer_state"
                  id="order.customer_state"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('ordersViewPage.country')"
                  v-model="order.customer_country"
                  id="order.customer_country"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('ordersViewPage.phone')"
                  v-model="order.customer_phone"
                  id="order.customer_phone"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('ordersViewPage.email')"
                  v-model="order.customer_email"
                  id="order.customer_email"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('ordersViewPage.mailbox')"
                  v-model="order.customer_mailbox"
                  id="order.customer_mailbox"
                  variant="borderless-gray-60"
                  disabled
                />
                <NbTextInput
                  :name="$t('ordersViewPage.phoneCountryCode')"
                  v-model="order.customer_phone_country_code"
                  id="order.customer_phone_country_code"
                  variant="borderless-gray-60"
                  disabled
                />
              </div>
            </Transition>
            <NbButton
              variant="tertiary"
              @click="showAllCustomerData = !showAllCustomerData"
            >
              {{
                showAllCustomerData
                  ? $t("ordersViewPage.collapseCustomerData")
                  : $t("ordersViewPage.showAllCustomerData")
              }}
            </NbButton>
          </template>
        </NbCard>

        <NbCard :title="$t('ordersViewPage.shippingInfo')" id="shipping">
          <template #body>
            <div class="grid grid-cols-2 gap-4">
              <NbTextInput
                disabled
                variant="borderless-gray-60"
                :name="$t('ordersViewPage.currency')"
                v-model="order.currency"
                id="currency"
              />
              <NbTextInput
                disabled
                variant="borderless-gray-60"
                :name="$t('ordersViewPage.incoterm')"
                v-model="order.incoterm"
                id="order.incoterm"
              />
              <NbTextInput
                disabled
                variant="borderless-gray-60"
                :name="$t('ordersViewPage.returnInsurance')"
                v-model="order.return_insurance"
                id="order.return_insurance"
              />
              <NbTextInput
                disabled
                variant="borderless-gray-60"
                :name="$t('ordersViewPage.insuranceCoverage')"
                v-model="order.insurance_coverage"
                id="order.insurance_coverage"
              />
              <NbTextInput
                disabled
                variant="borderless-gray-60"
                :name="$t('ordersViewPage.returnModality')"
                v-model="order.return_modality"
                id="order.return_modality"
              />
              <NbTextInput
                disabled
                variant="borderless-gray-60"
                :name="$t('ordersViewPage.freightValue')"
                v-model="reviewValues.freightValue"
                id="reviewValues.freightValue"
              />
              <NbTextInput
                disabled
                variant="borderless-gray-60"
                :name="$t('ordersViewPage.total')"
                v-model="reviewValues.totalValue"
                id="reviewValues.totalValue"
              />
            </div>
          </template>
        </NbCard>

        <NbCard :title="$t('ordersViewPage.reviewValues')" id="summary">
          <template #body>
            <div class="grid grid-cols-2 gap-4">
              <NbTextInput
                :name="$t('ordersViewPage.declaredValue')"
                v-model="reviewValues.declaredValue"
                id="reviewValues.declaredValue"
                variant="borderless-gray-60"
                disabled
              />
              <NbTextInput
                :name="$t('ordersViewPage.taxValue')"
                v-model="reviewValues.taxValue"
                id="reviewValues.taxValue"
                variant="borderless-gray-60"
                disabled
              />
              <NbTextInput
                :name="$t('ordersViewPage.total')"
                v-model="reviewValues.totalDeclaredValue"
                id="reviewValues.totalValue"
                variant="borderless-gray-60"
                disabled
              />

              <NbTextInput
                :name="$t('ordersViewPage.estimatedTaxValue')"
                disabled
                variant="borderless-gray-60"
                v-model="reviewValues.estimatedTaxValue"
                id="reviewValues.estimatedTaxValue"
              />
              <NbTextInput
                :name="$t('ordersViewPage.freightCost')"
                disabled
                variant="borderless-gray-60"
                v-model="reviewValues.freightCostValue"
                id="reviewValues.freightCostValue"
              />
            </div>
          </template>
        </NbCard>

        <NbCard :title="$t('ordersViewPage.generalCosts')" id="general-costs">
          <template #body>
            <NbTablev2
              class="mt-5"
              tableOf="orderCosts"
              :hasParentItens="true"
              ref="orderCostsRef"
              :hiddeTableOptions="true"
              height="fit-content"
              :allFields="orderCostsAllFields"
              :fields="orderCostsFields"
              @reloadFields="orderCostsFields = $event"
              :startItems="order.order_costs || []"
            >
              <template #cell(payment_date)="data">
                <div>
                  {{ paymentDate(data.item.payment_date) }}
                </div>
              </template>
              <template #cell(value)="data">
                <div>
                  {{ currency.parse(data.item.value) }}
                </div>
              </template>
            </NbTablev2>
          </template>
        </NbCard>

        <NbCard :title="$t('ordersViewPage.relatedObjects')" id="related">
          <template #body>
            <div class="grid grid-cols-2 gap-4">
              <NbTextInput
                :name="$t('ordersViewPage.salesChannelID')"
                v-model="order.sales_channel_id"
                id="order.sales_channel_id"
                variant="borderless-gray-60"
                disabled
              />
              <NbTextInput
                :name="$t('ordersViewPage.sellerIDcomp')"
                v-model="order.seller_id"
                id="order.seller_id"
                variant="borderless-gray-60"
                disabled
                :link="`/users_management/sellers?id=${order.seller_id}`"
                link-id=""
              />
              <NbTextInput
                :name="$t('ordersViewPage.contractID')"
                v-model="order.contract_id"
                id="order.contract_id"
                variant="borderless-gray-60"
                disabled
                :link="`/contracts/contracts/`"
                :link-id="order.contract_id"
              />
              <NbTextInput
                :name="$t('ordersViewPage.overpackID')"
                v-model="order.overpack_id"
                id="order.overpack_id"
                variant="borderless-gray-60"
                disabled
                :link="`/shipping/overpacks?id=`"
                :link-id="order.overpack_id"
              />
            </div>
          </template>
        </NbCard>

        <NbCard title="Volumes" id="volumes">
          <template #body>
            <NbTablev2
              class="mt-5"
              tableOf="volumesOrderPage"
              :hasParentItens="true"
              ref="volumesOrderPageRef"
              :hiddeTableOptions="true"
              height="fit-content"
              :allFields="volumesAllFields"
              :fields="volumesFields"
              @reloadFields="volumesFields = $event"
              :startItems="order.volumes || []"
            >
              <template #cell(id)="data">
                <router-link
                  class="link-1"
                  :to="`/shipping/volumes?id=` + data.item.id"
                >
                  {{ data.item.id }}
                </router-link>
              </template>
              <template #cell(weight)="data">
                {{ weight.format(data.item.weight) }}
              </template>
              <template #cell(cubed_weight)="data">
                {{ weight.format(data.item.cubed_weight) }}
              </template>
              <template #cell(order_items)="data">
                {{ data.item.order_items.length }}
              </template>
              <template #cell(last_mile_carrier_number)="data">
                {{ data.item.last_mile_carrier_number || "-" }}
              </template>
              <template #cell(products_value)="data">
                {{ currency.format(data.item.products_value, order.currency) }}
              </template>
              <template #cell(freight_value)="data">
                {{ currency.format(data.item.freight_value, order.currency) }}
              </template>
              <template #cell(estimated_freight_cost)="data">
                {{
                  currency.format(
                    data.item.estimated_freight_cost,
                    order.currency,
                  )
                }}
              </template>
              <template #cell(tax_value)="data">
                {{ currency.format(data.item.tax_value, order.currency) }}
              </template>
              <template #cell(collected_icms)="data">
                {{
                  currency.format(
                    data.item.collected_icms,
                    order.collected_taxes_currency || "USD",
                  )
                }}
              </template>
              <template #cell(collected_ii)="data">
                {{
                  currency.format(
                    data.item.collected_ii,
                    order.collected_taxes_currency || "USD",
                  )
                }}
              </template>
            </NbTablev2>
          </template>
        </NbCard>
      </div>
    </div>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
    </ModalFooter>

    <Modal v-model="showModalEdit" size="lg">
      <OrderEdit :order="order" @success="updateOrderData" />
    </Modal>

    <Modal v-model="showConfirmRemoveOrder">
      <RemoveOrder :order-id="orderId" @deleted="$emit('load')" />
    </Modal>

    <Modal v-model="showUpdateFinancialStatus">
      <UpdateFinancialStatus
        :order-ids="[orderId]"
        :current-status="order?.financial_status"
        @new-status="setNewStatus"
      />
    </Modal>

    <Modal v-model="showAddOrderCost">
      <AddCosts
        :order-id="orderId"
        :currency="order?.currency"
        @success="onAddGeneralCost"
      />
    </Modal>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import Spinner from "@/components/Spinner.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import { currency as currencyFilter } from "@/utils/filters/currency.js";
import { weight as weightFilter } from "@/utils/filters/weight.js";
import { copyClipboard } from "@/directives/copy-clipboard";
import moment from "moment";
import OrderService from "@/services/OrderService";
import OrderDocuments from "./OrderDocuments.vue";
import Modal from "@/components/modal/Modal.vue";
import RemoveOrder from "./RemoveOrder.vue";
import UpdateFinancialStatus from "./UpdateFinancialStatus.vue";
import AddCosts from "./AddCosts.vue";
import OrderEdit from "./OrderEdit.vue";

const orderService = new OrderService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    Spinner,
    NbCard,
    NbButton,
    NbTablev2,
    NbTextInput,
    OrderDocuments,
    Modal,
    RemoveOrder,
    UpdateFinancialStatus,
    AddCosts,
    OrderEdit,
  },
  directives: {
    copy: copyClipboard,
  },
  props: {
    orderId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      order: null,
      volumesFields: [],
      orderCostsFields: [],
      reviewValues: {
        declaredValue: "",
        taxValue: "",
        estimatedTaxValue: "",
        totalValue: "",
        freightCostValue: "",
        freightValue: "",
        totalDeclaredValue: "",
      },
      currency: currencyFilter,
      weight: weightFilter,
      moment,
      showAllCustomerData: false,
      showAllOrderData: false,
      trackingBaseUrl: process.env.VUE_APP_TRACKING_BASE_URL,
      showConfirmRemoveOrder: false,
      showUpdateFinancialStatus: false,
      showAddOrderCost: false,
      showModalEdit: false,
    };
  },
  computed: {
    volumesAllFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "weight",
          label: this.$t("ordersViewPage.volumesFields.Weight"),
          sortable: false,
        },
        {
          key: "updated_weight",
          label: this.$t("ordersViewPage.volumesFields.updatedWeight"),
          sortable: false,
        },
        {
          key: "cubed_weight",
          label: this.$t("ordersViewPage.volumesFields.cubedWeight"),
          sortable: false,
        },
        {
          key: "last_mile_tracking_number",
          label: this.$t("ordersViewPage.volumesFields.lastMileTrackingNumber"),
          sortable: false,
        },
        {
          key: "first_mile_tracking_number",
          label: this.$t("volumesPage.fields.firstMileTrackingNumber"),
          sortable: false,
        },
        {
          key: "last_mile_carrier_number",
          label: this.$t("ordersViewPage.volumesFields.lastMileCarrierNumber"),
          sortable: false,
        },
        {
          key: "products_value",
          label: this.$t("ordersViewPage.volumesFields.productValue"),
          sortable: false,
        },
        {
          key: "freight_value",
          label: this.$t("ordersViewPage.volumesFields.freightValue"),
          sortable: false,
        },
        {
          key: "estimated_freight_cost",
          label: this.$t("ordersViewPage.volumesFields.estimatedFreightCost"),
          sortable: false,
        },
        {
          key: "tax_value",
          label: this.$t("ordersViewPage.volumesFields.taxValue"),
          sortable: false,
        },
        {
          key: "order_items",
          label: this.$t("ordersViewPage.volumesFields.totalItems"),
          sortable: false,
        },
        {
          key: "collected_icms",
          label: this.$t("volumesPage.fields.collectedIcms"),
          sortable: false,
        },
        {
          key: "collected_ii",
          label: this.$t("volumesPage.fields.collectedIi"),
          sortable: false,
        },
      ];
    },
    orderCostsAllFields() {
      return [
        {
          key: "external_id",
          label: this.$t("ordersViewPage.fieldsCurrentOrderCosts.externalID"),
        },
        {
          key: "name",
          label: this.$t("ordersViewPage.fieldsCurrentOrderCosts.type"),
        },
        {
          key: "value",
          label: this.$t("ordersViewPage.fieldsCurrentOrderCosts.value"),
        },
        {
          key: "payment_date",
          label: this.$t("paymentDate"),
        },
      ];
    },
  },
  methods: {
    updateOrderData(data) {
      this.showModalEdit = false;
      this.order = {
        ...this.order,
        seller_tax_number: data.seller_tax_number,
        customer_address: data.customer_address,
        customer_city: data.customer_city,
        customer_address_complement: data.customer_address_complement,
        customer_address_number: data.customer_address_number,
        customer_postal_code: data.customer_postal_code,
        customer_state: data.customer_state,
        volumes: data.volumes_attributes.map((item) => {
          const volume = this.order.volumes.find(
            (orderVolume) => orderVolume.id === item.id,
          );
          return {
            ...volume,
            weight: item.weight,
            freight_cost: Number(item.freight_cost),
          };
        }),
      };
    },
    paymentDate(value) {
      return Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
        timeStyle: undefined,
        timeZone: "UTC",
      }).format(new Date(value));
    },
    onAddGeneralCost() {
      this.loadOrder();
      this.$emit("load");
    },
    setNewStatus(newStatus) {
      this.order.financial_status = newStatus;
      this.$emit("load");
    },
    navigateTo(path) {
      this.$router.push(path);
    },
    trackSearch() {
      if (this.order?.reference) {
        const lastCharOfTrackingBaseUrl = this.trackingBaseUrl.slice(-1);
        if (lastCharOfTrackingBaseUrl === "/") {
          this.trackingBaseUrl = this.trackingBaseUrl.slice(0, -1);
        }

        window.open(
          this.trackingBaseUrl + "?trackingNumber=" + this.order.reference,
        );
        return;
      }

      location.replace(this.trackingBaseUrl + "404");
    },
    async loadOrder() {
      try {
        this.loading = true;
        const { data } = await orderService.getOrder(this.orderId);
        this.order = Object.assign(data.data, {
          is_mini: data.data.is_mini ? this.$t("yes") : this.$t("no"),
          is_commercial_destination: data.data.is_commercial_destination
            ? this.$t("yes")
            : this.$t("no"),
        });
        this.setValues();
      } finally {
        this.loading = false;
      }
    },
    setValues() {
      const orderCurrency = this.order.currency;
      const declaredValue = this.currency.format(
        this.order.declared_value,
        orderCurrency,
      );
      const products = this.currency.parse(this.order.products_value);
      const freight = this.currency.parse(this.order.freight_value);

      const taxValue = this.currency.format(
        this.order.tax_value,
        orderCurrency,
      );
      const dutiesTax = this.currency.parse(this.order.duties_tax_value);
      const salesTax = this.currency.parse(this.order.sales_tax_value);

      const freightValue = this.currency.format(
        this.order.freight_value,
        orderCurrency,
      );
      const totalValue = this.currency.format(
        this.order.total_value,
        orderCurrency,
      );
      const totalDeclaredValue = this.currency.format(
        this.order.declared_value + this.order.tax_value,
        orderCurrency,
      );
      const freightCostValue = this.currency.format(
        this.order.freight_cost,
        orderCurrency,
      );

      const estimatedTaxValue = this.currency.format(
        this.order.estimated_tax_value,
        orderCurrency,
      );
      const estimatedSalesTax = this.currency.parse(
        this.order.estimated_sales_tax_value,
      );
      const estimatedDutiesTax = this.currency.parse(
        this.order.estimated_duties_tax_value,
      );

      this.reviewValues = {
        declaredValue: `${declaredValue} (Products ${products} + Freight ${freight})`,
        taxValue: `${taxValue} (Duties Tax ${dutiesTax} + Sales Tax ${salesTax})`,
        estimatedTaxValue: `${estimatedTaxValue} (Sales Tax ${estimatedSalesTax} + Duties Tax ${estimatedDutiesTax})`,
        totalDeclaredValue,
        freightCostValue,
        freightValue,
        totalValue,
      };
    },
  },
  created() {
    this.loadOrder();
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
