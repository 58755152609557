<template>
  <div>
    <NbPageTitle
      class="pb-3"
      :title="$t('checkpointsPage.title')"
      :subTitle="$t('checkpointsPage.subTitle')"
    >
      <template #title>
        <NbHelpText
          id="checkpoint-help"
          class="mx-1 text-gray-40"
          size="lg"
          placement="topright"
        >
          {{ $t("checkpointsPage.helpTextTitle") }}
        </NbHelpText>
      </template>
      <!-- 
      <template #afterTitle>
        <a class="link-2">
          {{ $t("checkpointsPage.moreAboutCheckpoint") }}
        </a>
      </template>
      -->
      <!-- Botoes -->
    </NbPageTitle>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

      <NbNobordistCheckpoints
        :class="
          currentTable == 'nobordistCheckpoints' ? '' : 'display-hidde-absolute'
        "
        @switchTab="switchTab($event)"
        @total="totalNobordist = $event"
      />

      <NbReceivedCheckpoints
        :class="
          currentTable == 'receivedCheckpoints' ? '' : 'display-hidde-absolute'
        "
        @switchTab="switchTab($event)"
        @total="totalReceived = $event"
      />

      <NbStatusCheckpoints
        :class="
          currentTable == 'statusCheckpoints' ? '' : 'display-hidde-absolute'
        "
        @switchTab="switchTab($event)"
        @total="totalStatus = $event"
      />

      <NbVolumeCheckpoints
        :class="
          currentTable == 'volumeCheckpoints' ? '' : 'display-hidde-absolute'
        "
        @switchTab="switchTab($event)"
        @total="totalVolume = $event"
      />

      <NbTrackingNotifications
        :class="
          currentTable == 'trackingNotifications'
            ? ''
            : 'display-hidde-absolute'
        "
        @switchTab="switchTab($event)"
        @total="totalTtracking = $event"
      />
    </section>

    <NbFooter
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import NbNobordistCheckpoints from "@/views/checkpoints/nobordist_checkpoints/NbNobordistCheckpoints.vue";
import NbReceivedCheckpoints from "@/views/checkpoints/received_checkpoints/NbReceivedCheckpoints.vue";
import NbStatusCheckpoints from "@/views/checkpoints/status_checkpoints/NbStatusCheckpoints.vue";
import NbVolumeCheckpoints from "@/views/checkpoints/volume_checkpoints/NbVolumeCheckpoints.vue";
import NbTrackingNotifications from "@/views/checkpoints/tracking_notifications/NbTrackingNotifications.vue";

export default {
  name: "CheckpointList",
  components: {
    NbPageTitle,
    NbHelpText,
    NbTabs,
    NbFooter,
    NbNobordistCheckpoints,
    NbReceivedCheckpoints,
    NbStatusCheckpoints,
    NbVolumeCheckpoints,
    NbTrackingNotifications,
  },
  data: () => {
    return {
      currentTable: "nobordistCheckpoints",
      totalBoxes: 0,
      totalProducts: 0,
      totalNobordist: 0,
      totalReceived: 0,
      totalStatus: 0,
      totalVolume: 0,
      totalTtracking: 0,
    };
  },
  methods: {
    switchTab(tab) {
      this.currentTable = tab;
    },
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: "nobordistCheckpoints",
          label: this.$t("checkpointsPage.nobordistCheckpoints"),
          found: this.totalNobordist || 0,
          current: this.currentTable,
        },
        {
          id: "receivedCheckpoints",
          label: this.$t("checkpointsPage.receivedCheckpoints"),
          found: this.totalReceived || 0,
          current: this.currentTable,
        },
        {
          id: "statusCheckpoints",
          label: this.$t("checkpointsPage.statusCheckpoints"),
          found: this.totalStatus || 0,
          current: this.currentTable,
        },

        {
          id: "volumeCheckpoints",
          label: this.$t("checkpointsPage.volumeCheckpoints"),
          found: this.totalVolume || 0,
          current: this.currentTable,
        },

        {
          id: "trackingNotifications",
          label: this.$t("checkpointsPage.trackingNotifications"),
          found: this.totalTtracking || 0,
          current: this.currentTable,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
