<template>
  <div>
    <NbModal
      v-show="modalActive"
      id="volume-modal"
      width="1200px"
      @close="closeModal"
    >
      <template #header>
        <NbPageTitle
          :title="`${$t('volumesViewPage.title')}${volume?.id || ''}`"
        />
      </template>
      <Spinner v-if="loading" size="md" class="m-auto" />
      <template v-if="volume?.id" #body>
        <div class="cards grid grid-cols-2 mb-4">
          <VolumeCardLabels
            :volume-id="volume.id"
            :order-id="volume.order.id"
          />
          <NbCard id="actions" :title="$t('actions')">
            <template #body>
              <div class="grid grid-cols-2">
                <NbButton
                  @click="navigateTo(`/shipping/orders?id=${volume.order.id}`)"
                >
                  {{ $t("volumesViewPage.goToOrder") }}
                </NbButton>
                <NbButton
                  variant="secondary"
                  v-copy="volume.reference"
                  v-b-tooltip.hover
                  :title="$t('clickToCopy')"
                >
                  {{ $t("volumesViewPage.copyReference") }}
                </NbButton>
              </div>
            </template>
          </NbCard>
        </div>
        <form>
          <NbCard id="general-data" :title="$t('generalData')">
            <template #body>
              <div class="grid grid-cols-2">
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesViewPage.height')"
                  v-model="volume.height"
                  id="volume.height"
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  v-if="
                    volume.last_volume_dimension &&
                    volume.last_volume_dimension.height != volume.height
                  "
                  :name="$t('volumesViewPage.heightbycurrier')"
                  v-model="volume.last_volume_dimension.height"
                  id="volume.last_volume_dimension.height"
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesViewPage.width')"
                  v-model="volume.width"
                  id="volume.width"
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  v-if="
                    volume.last_volume_dimension &&
                    volume.last_volume_dimension.width != volume.width
                  "
                  :name="$t('volumesViewPage.widthbycurrier')"
                  v-model="volume.last_volume_dimension.width"
                  id="volume.last_volume_dimension.width"
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesViewPage.weight')"
                  v-model="volume.weight"
                  id="volume.weight"
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesViewPage.cubed_weight') + ' (kg)'"
                  v-model="volume.cubed_weight"
                  id="volume.cubed_weight"
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  v-if="
                    volume.last_volume_dimension &&
                    volume.last_volume_dimension.weight != volume.weight
                  "
                  :name="$t('volumesViewPage.weightbycurrier')"
                  v-model="volume.last_volume_dimension.weight"
                  id="volume.last_volume_dimension.weight"
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesViewPage.length')"
                  v-model="volume.length"
                  id="volume.length"
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  v-if="
                    volume.last_volume_dimension &&
                    volume.last_volume_dimension.length != volume.length
                  "
                  :name="$t('volumesViewPage.lengthbycurrier')"
                  v-model="volume.last_volume_dimension.length"
                  id="volume.last_volume_dimension.length"
                />

                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  v-if="volume.updated_weight"
                  :name="$t('volumesViewPage.updatedWeight')"
                  v-model="volume.updated_weight"
                  id="volume.updated_weight"
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesViewPage.orderID')"
                  v-model="volume.order_id"
                  id="volume.order_id"
                  :link="`/shipping/orders?id=${volume.order_id}`"
                  link-id=""
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  v-if="volume.current_status"
                  :name="$t('volumesViewPage.currentStatus')"
                  v-model="volume.current_status.title"
                  id="volume.current_status.title"
                />

                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesViewPage.reference')"
                  v-model="volume.reference"
                  id="volume.reference"
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesViewPage.lastMileCarrierNumber')"
                  v-model="volume.last_mile_carrier_number"
                  id="volume.last_mile_carrier_number"
                />

                <div class="d-flex align-items-end w-full">
                  <NbTextInput
                    :name="$t('volumesViewPage.lastMileTrackingNumber')"
                    v-model="volume.last_mile_tracking_number"
                    id="volume.last_mile_tracking_number"
                    disabled
                    class="flex-grow-1"
                    variant="borderless-gray-60"
                  />
                  <NbButton
                    type="button"
                    variant="quaternary"
                    class="mb-1"
                    @click="showModalUpdateLmt"
                  >
                    <NbIcon
                      icon="edit"
                      :attributes="{ width: '18px', height: '18px' }"
                    />
                  </NbButton>
                </div>

                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesPage.fields.firstMileTrackingNumber')"
                  v-model="volume.first_mile_tracking_number"
                  id="volume.first_mile_tracking_number"
                />

                <div
                  :class="{ 'link-1': !!volume.dir_number }"
                  @click="redirectToFiscalPayments()"
                >
                  <NbTextInput
                    id="volumeID"
                    variant="borderless-gray-10"
                    placeholder="-"
                    :class="['w-100', { 'link-1': !!volume.dir_number }]"
                    :name="$t('volumesViewPage.dirNumber')"
                    v-model="volume.dir_number"
                    disabled
                  />
                </div>
              </div>
              <div class="d-flex justify-content-end mt-2">
                <div>
                  <label for="" class="input-label">
                    {{ $t("volumesViewPage.creationDate") }}
                  </label>
                  <small class="d-block">
                    {{ volume.created_at | formatedDate }}
                  </small>
                </div>
              </div>
            </template>
          </NbCard>
          <NbCard id="values" :title="$t('volumesViewPage.summaryValues')">
            <template #body>
              <div class="grid grid-cols-2">
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesViewPage.declaredValue')"
                  v-model="values.declaredValue"
                  id="values.declaredValue"
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesViewPage.taxValue')"
                  v-model="values.taxValue"
                  id="values.taxValue"
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesViewPage.total')"
                  v-model="values.total"
                  id="values.total"
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesViewPage.estimatedTaxValue')"
                  v-model="values.estimatedTaxValue"
                  id="values.estimatedTaxValue"
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesViewPage.freightCost')"
                  v-model="values.freightCost"
                  id="values.freightCost"
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesViewPage.estimatedFreightCost')"
                  v-model="values.estimatedFreightCost"
                  id="values.estimatedFreightCost"
                />

                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesPage.fields.collectedIcms')"
                  v-model="values.collectedIcms"
                  id="values.collectedIcms"
                />
                <NbTextInput
                  disabled
                  variant="borderless-gray-60"
                  :name="$t('volumesPage.fields.collectedIi')"
                  v-model="values.collectedIi"
                  id="values.collectedIi"
                />
              </div>
            </template>
          </NbCard>
          <NbCard :title="$t('items')" id="items">
            <template #body>
              <NbTablev2
                class="mt-5"
                tableOf="itemsVolumePage"
                :hasParentItens="true"
                ref="itemsVolumePage"
                :hiddeTableOptions="true"
                height="fit-content"
                :allFields="itemsAllFields"
                :fields="itemsFields"
                @reloadFields="itemsFields = $event"
                :startItems="volume.order_items || []"
              >
                <template #cell(unit_products_value)="data">
                  {{
                    data.item.unit_products_value | toCurrency(volume.currency)
                  }}
                </template>
                <template #cell(unit_freight_value)="data">
                  {{
                    data.item.unit_freight_value | toCurrency(volume.currency)
                  }}
                </template>
                <template #cell(unit_tax_value)="data">
                  {{ data.item.unit_tax_value | toCurrency(volume.currency) }}
                </template>
              </NbTablev2>
            </template>
          </NbCard>

          <NbCard :title="$t('volumesViewPage.trackinghistory')" id="items">
            <template #body>
              <NbTablev2
                class="mt-5"
                tableOf="checkpointVolumePage"
                :hasParentItens="true"
                ref="checkpointVolumePage"
                :hiddeTableOptions="true"
                height="fit-content"
                :allFields="checkpointAllFields"
                :fields="checkpointFields"
                @reloadFields="checkpointFields = $event"
                :startItems="volume.volume_checkpoints || []"
              >
                <template #cell(date_iso)="data">
                  {{ data.item.date_iso.slice(0, 10) }}
                </template>
                <template #cell(id)="data">
                  <div
                    class="link-1"
                    @click="loadCheckpointModal(data.item.id)"
                  >
                    {{ data.item.id }}
                  </div>
                </template>
                <template #cell(checkpoint.tracking_code)="data">
                  {{ data.item?.checkpoint?.tracking_code }}
                </template>

                <template #cell(received_checkpoint.tracking_function)="data">
                  {{ data.item?.received_checkpoint?.tracking_function || "-" }}
                </template>

                <template #cell(message)="data">
                  {{ data.item?.message || "-" }}
                </template>

                <template #cell(checkpoint.title)="data">
                  {{ data.item.checkpoint.title || "-" }}
                </template>

                <template #cell(status_code)="data">
                  <NbButton
                    v-if="data.item?.received_checkpoint?.status_code"
                    variant="tertiary"
                    @click="loadModalData(data.item.received_checkpoint.id)"
                  >
                    {{ data.item.received_checkpoint.status_code }}
                  </NbButton>
                  <div v-else>-</div>
                </template>
              </NbTablev2>
            </template>
          </NbCard>
        </form>
      </template>
      <template #footer>
        <NbButton
          class="d-flex ml-auto"
          data-dismiss="modal"
          aria-label="close"
        >
          {{ $t("close") }}
        </NbButton>
      </template>
    </NbModal>
    <ModalVolumeCheckpointsView
      @close="modalActive = true"
      :itemId="currentCheckpointId"
    />

    <ModalReceivedCheckpointsView
      @close="modalActive = true"
      :itemId="currentItemId"
    />

    <NbModal id="ModalUpdateLmt" prevent-close @close="modalActive = true">
      <template #header>
        <NbPageTitle :title="$t('volumesViewPage.editLastTrackingNumber')" />
      </template>
      <template #body>
        <div>
          <NbTextInput
            v-model="lastMileTrackingNumber"
            :name="$t('volumesViewPage.warningOnUpdateLastMileTrackingNumber')"
            id="lastMileTrackingNuber"
          />
        </div>
      </template>

      <template #footer>
        <div class="flex justify-content-end">
          <NbButton
            variant="secondary"
            @click="$helpers.closeModal('ModalUpdateLmt')"
          >
            {{ $t("close") }}
          </NbButton>
          <NbButton @click="updateLastMileTrackingNumber" class="ml-2">
            {{ $t("save") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>
<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import VolumeService from "@/services/VolumeService";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import moment from "moment";
import Spinner from "@/components/Spinner.vue";
import { copyClipboard } from "@/directives/copy-clipboard";
import VolumeCardLabels from "./VolumeCardLabels.vue";
import { currency, parseNumber } from "@/utils/parser";
import ModalVolumeCheckpointsView from "../../checkpoints/volume_checkpoints/components/ModalVolumeCheckpointsView.vue";
import ModalReceivedCheckpointsView from "@/views/checkpoints/received_checkpoints/components/ModalReceivedCheckpointsView.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

const volumeService = new VolumeService();

export default {
  components: {
    NbModal,
    NbButton,
    NbPageTitle,
    NbCard,
    NbTablev2,
    NbTextInput,
    Spinner,
    VolumeCardLabels,
    ModalVolumeCheckpointsView,
    ModalReceivedCheckpointsView,
    NbIcon,
  },
  directives: {
    copy: copyClipboard,
  },
  filters: {
    formatedDate(value) {
      return value && moment(value).format("DD/MM/YYYY HH:mm:ss");
    },
    toCurrency(value, defaultCurrency) {
      return currency(value, defaultCurrency);
    },
  },
  data() {
    return {
      loading: false,
      currentCheckpointId: "",
      currentItemId: "",
      volume: {},
      modalActive: true,
      values: {
        declaredValue: "",
        taxValue: "",
        total: "",
        estimatedTaxValue: "",
        freightCost: "",
        estimatedFreightCost: "",
        collectedIcms: "",
        collectedIi: "",
      },
      itemsFields: [],
      checkpointFields: [],
      lastMileTrackingNumber: "",
    };
  },
  computed: {
    itemsAllFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "name",
          label: this.$t("volumesViewPage.fields.name"),
          sortable: false,
        },
        {
          key: "quantity",
          label: this.$t("volumesViewPage.fields.quantity"),
          sortable: false,
        },
        {
          key: "hs_code",
          label: this.$t("volumesViewPage.fields.hsCode"),
          sortable: false,
        },
        {
          key: "origin_country",
          label: this.$t("volumesViewPage.fields.originCountry"),
          sortable: false,
        },
        {
          key: "unit_products_value",
          label: this.$t("volumesViewPage.fields.productValue"),
          sortable: false,
        },
        {
          key: "unit_freight_value",
          label: this.$t("volumesViewPage.fields.freightValue"),
          sortable: false,
        },
        {
          key: "unit_tax_value",
          label: this.$t("volumesViewPage.fields.taxValue"),
          sortable: false,
        },
      ];
    },
    checkpointAllFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "checkpoint.tracking_code",
          label: "Tracking Code",
          sortable: false,
        },
        {
          key: "checkpoint.title",
          label: this.$t("volumesViewPage.checkpointFields.title"),
          sortable: false,
        },
        {
          key: "status_code",
          label: this.$t("volumesViewPage.checkpointFields.statusCode"),
          sortable: false,
        },
        {
          key: "date_iso",
          label: this.$t("volumesViewPage.checkpointFields.dateISO"),
          sortable: false,
        },
        {
          key: "message",
          label: this.$t("volumesViewPage.checkpointFields.message"),
          sortable: false,
        },
        {
          key: "received_checkpoint.tracking_function",
          label: this.$t("volumesViewPage.checkpointFields.trackingFunction"),
          sortable: false,
        },
      ];
    },
  },
  methods: {
    redirectToFiscalPayments() {
      if (this.volume?.dir_number) {
        this.navigateTo(
          `/finances/fiscal_payments?dir_number=${this.volume.dir_number}`,
        );
      }
    },
    navigateTo(path) {
      this.closeModal();
      this.$router.push(path);
    },
    closeModal() {
      this.modalActive = true;
      this.$helpers.closeModal("volume-modal");
      this.$emit("close");
    },
    setValues() {
      const volumeCurrency = this.volume.currency;
      const declaredValue = currency(
        this.volume.declared_value,
        volumeCurrency,
      );
      const products = parseNumber(this.volume.products_value);
      const freight = parseNumber(this.volume.freight_value);
      this.values.declaredValue = `${declaredValue} (Products ${products} + Freight ${freight})`;

      const taxValue = currency(this.volume.tax_value, volumeCurrency);
      const dutiesTax = parseNumber(this.volume.duties_tax_value);
      const salesTax = parseNumber(this.volume.sales_tax_value);
      this.values.taxValue = `${taxValue} (Duties Tax ${dutiesTax} + Sales Tax ${salesTax})`;

      this.values.total = currency(
        this.volume.declared_value + this.volume.tax_value,
        volumeCurrency,
      );

      const estimatedTaxValue = currency(
        this.volume.estimated_tax_value,
        volumeCurrency,
      );
      const estimatedDutiesTax = parseNumber(
        this.volume.estimated_duties_tax_value,
      );
      const estimatedSalesTax = parseNumber(
        this.volume.estimated_sales_tax_value,
      );
      this.values.estimatedTaxValue = `${estimatedTaxValue} (Sales Tax ${estimatedDutiesTax} + Duties Tax ${estimatedSalesTax})`;

      this.values.freightCost = currency(
        this.volume.freight_cost,
        volumeCurrency,
      );

      this.values.estimatedFreightCost = currency(
        this.volume.estimated_freight_cost,
        volumeCurrency,
      );

      this.values.collectedIcms = currency(
        this.volume.collected_icms,
        this.volume.order.collected_taxes_currency,
        "USD",
      );

      this.values.collectedIi = currency(
        this.volume.collected_ii,
        this.volume.order.collected_taxes_currency,
        "USD",
      );
    },
    async loadVolume() {
      try {
        this.loading = true;
        const response = await volumeService.getVolume(this.$route.query?.id);
        this.volume = response.data.data;

        this.setValues();
      } catch (error) {
        this.$bvToast.toast(this.$t("errorMessages.generi bscError"), {
          variant: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    showModal(volumeId) {
      if (this.volume?.id != volumeId) {
        this.loadVolume();
      }
      this.$helpers.openModal("volume-modal");
    },
    loadCheckpointModal(id) {
      if (id) {
        this.modalActive = false;
        this.currentCheckpointId = id;
        this.$helpers.openModal("ModalVolumeCheckpointsView");
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.modalActive = false;
        this.currentItemId = id;
        this.$helpers.openModal("ModalReceivedCheckpointsView");
        return;
      }
    },
    showModalUpdateLmt() {
      this.modalActive = false;
      this.lastMileTrackingNumber = "";
      this.$helpers.openModal("ModalUpdateLmt");
    },
    updateLastMileTrackingNumber() {
      volumeService
        .updateLastMileTrackingNumber(
          this.volume.id,
          this.lastMileTrackingNumber,
        )
        .then(() => {
          this.$helpers.closeModal("ModalUpdateLmt");
          this.loadVolume();
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.grid {
  display: grid;
  gap: 1rem;
  @media screen and (min-width: 768px) {
    &.grid-cols-2 {
      grid-template-columns: repeat(2, 1fr);
    }
    &.grid-cols-3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
form {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
}
</style>
