<template>
  <div>
    <div class="header">
      <NbPageTitle
        class="pb-3"
        :title="$t('usersPage.title')"
        :subTitle="$t('usersPage.subtitle')"
      >
      </NbPageTitle>
      <NbButton
        v-if="!isSuper"
        pill
        variant="primary"
        @click="showCreateUserModal"
        class="text-left"
        style="width: 13.12rem"
      >
        <NbIcon icon="plus" />
        {{ $t("usersPage.addNewUser") }}
      </NbButton>
    </div>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

      <NbTablev2
        tableOf="users"
        :namespace="namespace"
        ref="usersTable"
        :clickable="true"
        :allFields="allFields"
        :fields="fields"
        :tabs="true"
        :selectable="true"
        :optionsWidth="260"
        :filterOptions="filterOptions"
        @optionTook="optionTook($event)"
        :buttonOptions="buttonsTable"
        :selectedItems.sync="rowSelection"
        @reloadFields="fields = $event"
        @total="totalUsers = $event"
        :buttonsTable="buttonsTable"
        @clickedRow="showEditUserModal($event)"
      >
        <template #cell(company_name)="data">
          <template v-if="data.item.profile === 'carrier'">
            <router-link
              :to="`/users_management/carriers?id=` + data.item.carrier_id"
            >
              {{ data.value }}
            </router-link>
          </template>
          <template v-else-if="data.item.profile === 'seller'">
            <router-link
              :to="`/users_management/sellers?id=` + data.item.seller_id"
            >
              {{ data.value }}
            </router-link>
          </template>
          <template v-else>
            {{ data.value }}
          </template>
        </template>
        <template #cell(is_blocked)="status">
          <template v-if="!status.item.is_blocked">
            <NbBadge :text="$t('usersPage.allowed')" type="success" />
          </template>
          <template v-else>
            <NbBadge :text="$t('usersPage.blocked')" type="danger" />
          </template>
        </template>
        <template #cell(group_name)="group">
          <template v-if="group.item.group_name == 'Admin'">
            <router-link
              :to="`/users_management/groups/` + group.item.group_id"
            >
              <NbBadge :text="group.item.group_name" />
            </router-link>
          </template>
          <template v-else>
            <router-link
              :to="`/users_management/groups/` + group.item.group_id"
            >
              <NbBadge :text="group.item.group_name" type="warning" />
            </router-link>
          </template>
        </template>

        <template #cell(api_token)="data">
          <template v-if="data.item.profile === 'seller'">
            <NbButton
              data-toggle="modal"
              data-target="#apiTokenModal"
              @click="getAPIToken(data.item.id)"
              size="sm"
            >
              {{ $t("usersPage.denerateAPIToken") }}
            </NbButton>
          </template>
        </template>

        <!-- <template #cell(actions)="data">
          <NbButton
            class="mb-2"
            size="sm"
            variant="tertiary"
            @click="showEditUserModal(data.item)"
          >
            {{ $t("usersPage.editUser") }}</NbButton
          >
        </template> -->
      </NbTablev2>
    </section>

    <NbFooter
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />

    <NbModal id="modal-archive-user" prevent-close>
      <NbPageTitle :title="$t('usersPage.archive')" />
      <p>
        {{ $t("usersPage.confirmArchiveUser") }}
      </p>
      <template #footer>
        <div class="d-flex justify-content-between">
          <NbButton
            variant="secondary"
            data-dismiss="modal"
            aria-label="Close"
            @click="$helpers.openModal('modal-user')"
          >
            {{ $t("cancel") }}
          </NbButton>
          <NbButton @click="archiveUser">
            {{ $t("confirm") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <NbModal id="download-modal" modalConfig="modal-dialog-centered">
      <template #header>
        <NbPageTitle :title="$t('usersPage.downloadUsers')" />
      </template>
      <p>{{ $t("usersPage.processingDownloads") }}</p>
      <template #footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal" aria-label="Close">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton @click="downloadUsers">
            {{ $t("usersPage.downloadUsers") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <NbModal
      id="modal-user"
      width="1024px"
      modalConfig="modal-dialog-centered"
      @close="setUrlSearchParam()"
    >
      <template #header>
        <NbPageTitle
          :title="
            selectedUserId
              ? $t('usersPage.editUser')
              : $t('usersPage.addNewUser')
          "
          :subTitle="!selectedUserId ? $t('usersPage.insertUserData') : ''"
        />
      </template>

      <div class="mb-4">
        <NbCardBox class="personal-data">
          <h3 class="heading-3">{{ $t("usersPage.personalData") }}</h3>

          <div class="form-group">
            <NbTextInput
              v-model="user.name"
              id="name"
              :name="$t('usersPage.name')"
              required
              :error="errors['name']"
              :placeholder="$t('fullName')"
            />
            <NbTextInput
              v-model="user.email"
              id="email"
              :name="$t('usersPage.email')"
              required
              :error="errors['email']"
              placeholder="example@mail.com"
            />
            <NbTextInput
              v-model="user.phone"
              id="phone"
              :name="$t('usersPage.phone')"
              :error="errors['phone']"
              placeholder="+00 0 0000-0000"
              :masks="['+#*']"
            />
            <NbTextInput
              v-model="user.whatsapp_number"
              id="whatsapp_number"
              :name="$t('usersPage.whatsappNumber')"
              :error="errors['whatsapp_number']"
              placeholder="+00 0 0000-0000"
              :masks="['+#*']"
            />
          </div>
        </NbCardBox>

        <NbCardBox class="permissions">
          <h3 class="heading-3">{{ $t("usersPage.permissionsAndAccess") }}</h3>

          <Spinner v-if="loadingGroups" class="mx-auto" size="lg" />
          <template v-else>
            <div class="form-group">
              <NbSelectInput
                v-model="user.profile"
                :name="$t('usersPage.profile')"
                required
                :options="profileOptions"
                id="profile"
                :error="errors['profile']"
              />
              <NbSelectInput
                v-model="user.group_id"
                :name="$t('usersPage.group')"
                required
                :options="profileGroupOptions"
                id="group_id"
                :disabled="user.profile ? false : true"
                :error="errors['group_id']"
              />

              <div class="col-span-2">
                <NbSelectInput
                  v-if="user.profile == 'seller'"
                  v-model="user.seller_id"
                  :name="$t('usersPage.seller')"
                  required
                  :options="sellerOptions"
                  id="seller_id"
                  :error="errors['seller_id']"
                />

                <NbSelectInput
                  v-if="user.profile == 'carrier'"
                  v-model="user.carrier_id"
                  :name="$t('usersPage.carrier')"
                  required
                  :options="carrierOptions"
                  id="carrier_id"
                  :error="errors['carrier_id']"
                />
              </div>
            </div>

            <div class="statuses">
              <NbRadio
                v-model="user.is_blocked"
                id="is_blocked"
                :error="errors['is_blocked']"
                :name="$t('usersPage.userStatus')"
                required
                classes="d-flex flex-column"
                :options="[
                  { text: $t('usersPage.active'), value: false },
                  { text: $t('usersPage.blocked'), value: true },
                ]"
              />

              <template v-if="selectedUserId">
                <div class="d-flex flex-column">
                  <label for="" class="input-label mb-1">{{
                    $t("usersPage.archiveUser")
                  }}</label>
                  <NbButton
                    variant="tertiary"
                    data-toggle="modal"
                    data-target="#modal-archive-user"
                    @click="$helpers.closeModal('modal-user')"
                  >
                    <NbIcon icon="trash-2" />
                    {{ $t("usersPage.yeahArchiveUser") }}
                  </NbButton>
                </div>
              </template>
            </div>
          </template>
        </NbCardBox>
      </div>

      <template #footer>
        <div class="d-flex justify-content-between">
          <NbButton
            variant="secondary"
            data-dismiss="modal"
            aria-label="Close"
            id="addUserClose"
            @click="closeUserModal"
          >
            {{ $t("cancel") }}
          </NbButton>
          <NbButton
            @click="!selectedUserId ? addUser() : editUser()"
            :disabled="isLoading || loadingGroups"
          >
            {{ $t("save") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <NbModal id="apiTokenModal" width="800px">
      <template #header>
        <h3 class="heading-3">{{ $t("usersPage.aPIToken") }}</h3>
      </template>
      <p class="text-break">
        {{ apiToken }}
      </p>
    </NbModal>
  </div>
</template>

<script>
import UsersService from "../../../services/UsersService";
import DownloadsService from "@/services/DownloadsService";
import NProgress from "nprogress";
import moment from "moment";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbCardBox from "@/components/cards/NbCardBox.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbRadio from "@/components/buttons/NbRadio.vue";
import GroupService from "@/services/GroupService";
import Spinner from "@/components/Spinner.vue";
import queryParamsMixin from "../../../mixins/query-params-mixin";

const usersService = new UsersService();
const downloadsService = new DownloadsService();

const groupsService = new GroupService();

export default {
  name: "Users",
  mixins: [queryParamsMixin],
  components: {
    NbFooter,
    NbPageTitle,
    NbTabs,
    NbTablev2,
    NbButton,
    NbBadge,
    NbIcon,
    NbModal,
    NbCardBox,
    NbTextInput,
    NbSelectInput,
    NbRadio,
    Spinner,
  },
  data: () => {
    return {
      currentTab: "allUsers",
      totalUsers: 0,
      rowSelection: [],
      filterOptions: [],
      fields: [],
      currentUserPage: 1,
      profiles: ["admin", "seller", "carrier"],
      errors: [],
      isLoading: false,
      selectedUserId: null,
      user: {
        name: "",
        email: "",
        phone: "",
        whatsapp_number: "",
        group_id: "",
        profile: "",
        is_blocked: false,
        seller_id: "",
        carrier_id: "",
        platform_id: "",
      },
      selected: [],
      apiToken: "TOKEN DO DATA",
      groups: [],
      sellers: [],
      carriers: [],
      namespace: "users",
      platform_field: {
        key: "platform_name",
        label: "Platform",
        sortable: true,
        thClass: ["medium-content"],
      },
      groupOptions: [],
      sellerOptions: [],
      carrierOptions: [],
      loadingGroups: false,
    };
  },
  computed: {
    allFields() {
      const fields = [
        {
          key: "id",
          label: "Id",
          sortable: false,
        },
        {
          key: "name",
          label: this.$t("usersPage.fields.name"),
          sortable: false,
        },
        {
          key: "carrier_id",
          label: this.$t("usersPage.fields.carrierID"),
          sortable: false,
        },
        {
          key: "seller_id",
          label: this.$t("usersPage.fields.sellerID"),
          sortable: false,
        },
        {
          key: "company_name",
          label: this.$t("usersPage.fields.company"),
          sortable: false,
        },
        {
          key: "is_blocked",
          label: this.$t("usersPage.fields.status"),
          sortable: false,
        },
        {
          key: "email",
          label: this.$t("usersPage.fields.email"),
          sortable: false,
        },
        {
          key: "profile",
          label: this.$t("usersPage.fields.profile"),
          sortable: false,
        },
        {
          key: "group_name",
          label: this.$t("usersPage.fields.group"),
          sortable: false,
        },
        {
          key: "created_at",
          label: this.$t("usersPage.fields.creationDate"),
          sortable: true,
        },
        {
          key: "api_token",
          label: this.$t("usersPage.fields.aPIToken"),
          sortable: false,
        },
        /* {
          key: "actions",
          label: this.$t("actions"),
          class: "right--20 right-sticky",
          isCustomizable: false,
        }, */
      ];

      if (this.isSuper) {
        fields.push(this.platform_field);
      }

      return fields;
    },
    profileOptions() {
      return this.profiles.map((value) => ({ text: value, value }));
    },
    buttonsTable() {
      return [
        {
          text: this.$t("usersPage.downloadUsers"),
          value: "showModalDownloadUsers",
          disabled: !this.rowSelection.length,
        },
      ];
    },
    tabsOptions() {
      return [
        {
          id: "allUsers",
          label: this.$t("usersPage.allUsers"),
          found: this.totalUsers || 0,
          current: this.currentTab,
        },
      ];
    },
    items() {
      return this.$store.state[this.namespace].items;
    },
    isSuper() {
      return this.$store.state.user.is_super;
    },
    profileGroups() {
      let finalGroup = {
        admin: this.groups.filter((group) => {
          return group.name.toLowerCase().includes("platform");
        }),
        seller: this.groups.filter((group) => {
          return group.name.toLowerCase().includes("seller");
        }),
        carrier: this.groups.filter((group) => {
          return group.name.toLowerCase().includes("freightforwarder");
        }),
      };

      if (this.user.profile) {
        return finalGroup[this.user.profile];
      }

      return [];
    },
    profileGroupOptions() {
      return this.profileGroups.map((group) => ({
        text: group.name,
        value: group.id,
      }));
    },
  },
  beforeMount() {
    this.setFilterOptions();
    this.loadGroups();
    this.loadSellers();
    this.loadCarriers();
  },
  methods: {
    closeUserModal() {
      this.selectedUserId = null;
      this.setUrlSearchParam("id");
    },
    archiveUser() {
      if (!this.selectedUserId) return;
      this.isLoading = true;
      usersService
        .updateUser(this.selectedUserId, {
          ...this.user,
          is_archived: true,
          is_blocked: true,
        })
        .then(() => {
          this.$helpers.closeModal("modal-archive-user");
          this.setUrlSearchParam("id");
          this.loadItems();
        })
        .catch((error) => {
          this.$bvToast.toast(error.response.data.messages[0], {
            title: "Error",
            solid: true,
            variant: "danger",
            autoHideDelay: 2000,
          });
        })
        .finally(() => (this.isLoading = false));
    },
    showCreateUserModal() {
      this.setUrlSearchParam();
      this.selectedUserId = null;
      this.initializeNewUser();
      this.$helpers.openModal("modal-user");
    },
    showEditUserModal(data) {
      if (data.id != this.$route.query?.id) {
        this.setUrlSearchParam("id", data.id);
      }
      this.selectedUserId = data.id;
      this.user = {
        name: data?.name || "",
        email: data?.email || "",
        phone: data?.phone || "",
        whatsapp_number: data?.whatsapp_number || "",
        group_id: data?.group_id || "",
        seller_id: data?.seller_id || "",
        carrier_id: data?.carrier_id || "",
        profile: data?.profile || "",
        is_blocked: data?.is_blocked,
        platform_id: data?.platform_id || this.$store.state.user.platform_id,
      };
      this.errors = [];
      this.$helpers.openModal("modal-user");
    },
    setFilterOptions() {
      this.filterOptions = [
        { key: "name", label: this.$t("usersPage.fields.name") },
        {
          key: "group_id",
          label: this.$t("usersPage.group"),
          type: "select",
          options: [{ value: null, text: "Select" }],
        },
        {
          key: "profile",
          label: this.$t("usersPage.fields.profile"),
          type: "select",
          options: [
            { value: null, text: "Select" },
            { value: "admin", text: "Platform Admin" },
            { value: "seller", text: "Seller" },
            { value: "carrier", text: "Carrier" },
          ],
        },
        { key: "email", label: this.$t("usersPage.fields.email") },
        {
          key: "is_blocked",
          label: this.$t("usersPage.blocked"),
          type: "select",
          options: [
            { value: true, text: this.$t("usersPage.blocked") },
            { value: false, text: this.$t("usersPage.allowed") },
          ],
        },
        { key: "company_name", label: this.$t("usersPage.fields.company") },
        {
          key: "seller_id",
          label: this.$t("usersPage.fields.sellerID"),
          type: "select",
          options: [{ value: null, text: "Select" }],
        },
        {
          key: "carrier_id",
          label: this.$t("usersPage.fields.carrierID"),
          type: "select",
          options: [{ value: null, text: "Select" }],
        },
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
      ];
    },
    switchTab(tab) {
      this.currentTab = tab;
    },
    optionTook(event) {
      this[event]();
    },
    addSelectOptionsToFilter(key, options) {
      this.filterOptions.find(
        (filterOption) => filterOption.key === key,
      ).options = options;
    },
    async loadGroups() {
      try {
        this.loadingGroups = true;
        const {
          data: { data },
        } = await groupsService.getGroups();
        this.groups = data;
        const groupsOption = data?.map((item) => ({
          value: item.id,
          text: item.name,
        }));

        this.groupOptions = groupsOption;
        this.addSelectOptionsToFilter("group_id", groupsOption);
      } finally {
        this.loadingGroups = false;
      }
    },
    loadSellers() {
      this.$store.dispatch("sellers/fetchAllItems", "sellers");
      this.sellers = this.$store.state["sellers"].all_items;
      let sellersOption = this.sellers.sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      sellersOption = sellersOption.map((item) => ({
        value: item.id,
        text: `${item.name}`,
        // text: `(${item.id}) ${item.name}`,
      }));

      this.sellerOptions = sellersOption;
      this.addSelectOptionsToFilter("seller_id", sellersOption);
    },
    loadCarriers() {
      this.$store.dispatch("carriers/fetchAllItems", "carriers");
      this.carriers = this.$store.state.carriers.all_items;

      let carriersOption = this.carriers.sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      carriersOption = carriersOption.map((item) => ({
        value: item.id,
        text: `${item.name}`,
        // text: `(${item.id}) ${item.name}`,
      }));

      this.carrierOptions = carriersOption;
      this.addSelectOptionsToFilter("carrier_id", carriersOption);
    },
    loadItems() {
      this.$store.dispatch(this.namespace + "/fetchItems", this.namespace);
    },
    initiateFileCreation(dataQuery) {
      downloadsService.loadProcessing(dataQuery).then(() => {
        this.$helpers.closeModal("download-modal");
        this.$router.push("/archives");
      });
    },
    showModalDownloadUsers() {
      this.$helpers.openModal("download-modal");
    },
    downloadUsers() {
      this.initiateFileCreation({
        data_type: "users",
        reference_ids: this.rowSelection,
      });
    },
    addUser() {
      this.errors = [];
      this.isLoading = true;
      usersService
        .setUser(this.user)
        .then(() => {
          document.getElementById("addUserClose").click();
          this.loadItems();
        })
        .catch((error) => {
          document
            .getElementById("modal-user")
            .scrollTo({ top: 0, behavior: "smooth" });
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
            this.$bvToast.toast("Failed, please try later", {
              solid: true,
              variant: "danger",
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
          NProgress.done();
        });
    },
    editUser() {
      this.errors = [];
      this.isLoading = true;
      usersService
        .updateUser(this.selectedUserId, this.user)
        .then(() => {
          document.getElementById("addUserClose").click();
          this.loadItems();
        })
        .catch((error) => {
          document
            .getElementById("modal-user")
            .scrollTo({ top: 0, behavior: "smooth" });
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
            this.$bvToast.toast("Failed, please try later", {
              solid: true,
              variant: "danger",
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
          NProgress.done();
        });
    },
    resetProfile() {
      if (this.user.profile == "seller") {
        this.user.carrier_id = "";
      }
      if (this.user.profile == "carrier") {
        this.user.seller_id = "";
      }
      if (this.user.profile == "admin") {
        this.user.carrier_id = "";
        this.user.seller_id = "";
      }
    },
    initializeNewUser() {
      this.user.name = "";
      this.user.email = "";
      this.user.phone = "";
      this.user.whatsapp_number = "";
      this.user.group_id = "";
      this.user.seller_id = "";
      (this.user.carrier_id = ""), (this.user.profile = "");
      this.user.is_blocked = false;
      this.user.platform_id = this.$store.state.user.platform_id;
      this.errors = [];
    },
    moment: function (date) {
      let formated = moment(date);
      return formated;
    },
    getAPIToken(userId) {
      usersService
        .getAPIToken(userId)
        .then((response) => {
          this.apiToken = response.data.data;
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
          NProgress.done();
        });
    },
    getUser(id) {
      usersService.getUser(id).then((response) => {
        this.showEditUserModal(response.data.data);
      });
    },
  },
  mounted() {
    const userId = this.$route.query?.id;
    if (userId) {
      this.getUser(userId);
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  @media screen and (min-width: 720px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.personal-data {
  margin-bottom: 1rem;
  .form-group {
    display: grid;
    gap: 1rem;
    @media screen and (min-width: 720px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
.permissions {
  .form-group {
    display: grid;
    gap: 1rem;
    @media screen and (min-width: 720px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .col-span-2 {
    grid-column: span 2 / span 2;
  }
}

.statuses {
  display: flex;
  gap: 2rem;
  align-items: baseline;
}
</style>
