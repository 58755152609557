<template>
  <div>
    <NbPageTitle :title="$t('fiscalPaymentsPage.title')" />

    <Modal
      v-model="showFiscalPayment"
      size="lg"
      @close="setUrlSearchParam('id')"
    >
      <FiscalPayment />
    </Modal>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" />

      <NbTablev2
        tableOf="fiscalPaymentsTable"
        namespace="fiscal_payments"
        ref="fiscalPaymentsTableRef"
        :clickable="true"
        :allFields="tableFields"
        :fields="fields"
        :tabs="true"
        :optionsWidth="260"
        :filterOptions="tableFilters"
        @reloadFields="fields = $event"
        @total="totalFiscalPayments = $event"
      >
        <template #cell(reference_number)="data">
          <NbButton
            variant="tertiary"
            @click="showFiscalPaymentDetails(data.item.id)"
          >
            {{ data.item.reference_number }}
          </NbButton>
        </template>
        <template #cell(boleto_gnre)="data">
          <DownloadGNRE :fiscal-payment-id="data.item.id" />
        </template>
        <template #cell(status)="data">
          <FiscalPaymentStatus :status="data.item.status" />
        </template>

        <template #cell(payment_date)="data">
          {{ data.item.payment_date | datetime }}
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | datetime }}
        </template>

        <template #cell(dir_number)="data">
          {{ data.item.fiscal_parcel.dir_number }}
        </template>
        <template #cell(customer_name)="data">
          {{ data.item.fiscal_parcel.customer_name }}
        </template>
        <template #cell(customer_cpf)="data">
          {{ data.item.fiscal_parcel.customer_cpf }}
        </template>
        <template #cell(customer_cep)="data">
          {{ data.item.fiscal_parcel.customer_cep }}
        </template>
        <template #cell(tracking_number)="data">
          {{ data.item.fiscal_parcel.tracking_number }}
        </template>
        <template #cell(products_description)="data">
          {{ data.item.fiscal_parcel.products_description }}
        </template>
        <template #cell(customer_state)="data">
          {{ data.item.fiscal_parcel.customer_state }}
        </template>
        <template #cell(seller_id)="data">
          {{ data.item.fiscal_parcel.seller_id }}
        </template>
        <template #cell(dir_date)="data">
          {{ data.item.fiscal_parcel.dir_date | formatDate }}
        </template>
      </NbTablev2>
    </section>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import FiscalPaymentStatus from "./components/FiscalPaymentStatus.vue";
import FiscalPayment from "./components/FiscalPayment.vue";
import Modal from "@/components/modal/Modal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import DownloadGNRE from "./components/DownloadGNRE.vue";
import queryParamsMixin from "@/mixins/query-params-mixin";
import moment from "moment";

export default {
  mixins: [queryParamsMixin],
  components: {
    NbPageTitle,
    NbTablev2,
    NbTabs,
    FiscalPaymentStatus,
    FiscalPayment,
    Modal,
    NbButton,
    DownloadGNRE,
  },
  filters: {
    datetime(value) {
      return (value && moment(value).format("DD/MM/YYYY HH:mm:ss")) || "-";
    },
    formatDate(value) {
      return (value && moment(value).format("DD/MM/YYYY")) || "-";
    },
  },
  data() {
    return {
      currentTab: "all",
      totalFiscalPayments: 0,
      showFiscalPayment: false,
      fields: [],
      filterByDirNumber: "",
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: this.currentTab,
          label: this.$t("all"),
          current: this.currentTab,
          found: this.totalFiscalPayments,
        },
      ];
    },
    tableFields() {
      return [
        {
          key: "reference_number",
          label: this.$t("fiscalPaymentsPage.fields.reference_number"),
        },
        { key: "barcode", label: this.$t("fiscalPaymentsPage.fields.barcode") },
        {
          key: "authentication",
          label: this.$t("fiscalPaymentsPage.fields.authentication"),
        },
        {
          key: "boleto_gnre",
          label: this.$t("fiscalPaymentsPage.fields.boletoGNRE"),
        },
        { key: "status", label: this.$t("fiscalPaymentsPage.fields.status") },
        {
          key: "payment_type",
          label: this.$t("fiscalPaymentsPage.fields.payment_type"),
        },
        { key: "value", label: this.$t("fiscalPaymentsPage.fields.value") },
        {
          key: "fiscal_parcel_id",
          label: this.$t("fiscalPaymentsPage.fields.fiscal_parcel_id"),
        },
        {
          key: "payment_date",
          label: this.$t("fiscalPaymentsPage.fields.payment_date"),
        },
        {
          key: "created_at",
          label: this.$t("fiscalPaymentsPage.fields.created_at"),
        },
        {
          key: "dir_number",
          label: this.$t("fiscalPaymentsPage.fields.dir_number"),
        },
        {
          key: "customer_name",
          label: this.$t("fiscalPaymentsPage.fields.customer_name"),
        },
        {
          key: "customer_cpf",
          label: this.$t("fiscalPaymentsPage.fields.customer_cpf"),
        },
        {
          key: "customer_cep",
          label: this.$t("fiscalPaymentsPage.fields.customer_cep"),
        },
        {
          key: "tracking_number",
          label: this.$t("fiscalPaymentsPage.fields.tracking_number"),
        },
        {
          key: "products_description",
          label: this.$t("fiscalPaymentsPage.fields.products_description"),
        },
        {
          key: "customer_state",
          label: this.$t("fiscalPaymentsPage.fields.customer_state"),
        },
        {
          key: "seller_id",
          label: this.$t("fiscalPaymentsPage.fields.seller_id"),
        },
        {
          key: "dir_date",
          label: this.$t("fiscalPaymentsPage.fields.dir_date"),
        },
      ];
    },
    paymentTypeOptions() {
      return [
        "icms",
        "darf",
        "fine689",
        "fine702",
        "fine703",
        "fine711",
        "fine715",
        "fine725",
        "fine728",
      ];
    },
    tableFilters() {
      return [
        {
          key: "reference_number",
          label: this.$t("fiscalPaymentsPage.fields.reference_number"),
        },
        {
          key: "barcode",
          label: this.$t("fiscalPaymentsPage.fields.barcode"),
        },
        {
          key: "authentication",
          label: this.$t("fiscalPaymentsPage.fields.authentication"),
        },
        {
          key: "status",
          label: this.$t("fiscalPaymentsPage.fields.status"),
          type: "select",
          options: [
            {
              value: "processing",
              text: this.$t("fiscalPaymentsPage.status.processing"),
            },
            {
              value: "processed",
              text: this.$t("fiscalPaymentsPage.status.processed"),
            },
            {
              value: "error",
              text: this.$t("fiscalPaymentsPage.status.error"),
            },
          ],
        },
        {
          key: "fiscal_parcel_id",
          label: this.$t("fiscalPaymentsPage.fields.fiscal_parcel_id"),
          type: "number",
        },
        {
          key: "payment_type",
          label: this.$t("fiscalPaymentsPage.fields.payment_type"),
          options: this.paymentTypeOptions.map((value) => ({
            text: value,
            value,
          })),
          type: "select",
        },
        {
          key: "payment_date",
          label: this.$t("fiscalPaymentsPage.fields.paymentDate"),
          type: "dates",
        },
        {
          key: "created_at",
          label: this.$t("registrationDate"),
          type: "dates",
        },
        {
          key: "start_value",
          label: this.$t("fiscalPaymentsPage.fields.start_value"),
        },
        {
          key: "end_value",
          label: this.$t("fiscalPaymentsPage.fields.end_value"),
        },
        {
          key: "fiscal_parcels.dir_number",
          label: this.$t("fiscalPaymentsPage.fields.dir_number"),
          initialVal: this.filterByDirNumber,
        },
        {
          key: "fiscal_parcels.start_dir_date",
          label: this.$t("fiscalPaymentsPage.fields.dir_date"),
          type: "dates",
          startDateKey: "fiscal_parcels.start_dir_date",
          endDateKey: "fiscal_parcels.end_dir_date",
        },
        {
          key: "fiscal_parcels.customer_name",
          label: this.$t("fiscalPaymentsPage.fields.customer_name"),
        },
        {
          key: "fiscal_parcels.customer_cpf",
          label: this.$t("fiscalPaymentsPage.fields.customer_cpf"),
        },
        {
          key: "fiscal_parcels.customer_cep",
          label: this.$t("fiscalPaymentsPage.fields.customer_cep"),
        },
        {
          key: "fiscal_parcels.tracking_number",
          label: this.$t("fiscalPaymentsPage.fields.tracking_number"),
        },
        {
          key: "fiscal_parcels.products_description",
          label: this.$t("fiscalPaymentsPage.fields.products_description"),
        },
        {
          key: "fiscal_parcels.customer_state",
          label: this.$t("fiscalPaymentsPage.fields.customer_state"),
        },
        {
          key: "fiscal_parcels.seller_id",
          label: this.$t("fiscalPaymentsPage.fields.seller_id"),
        },
      ];
    },
  },
  methods: {
    showFiscalPaymentDetails(id) {
      this.setUrlSearchParam("id", id);
      this.showFiscalPayment = true;
    },
  },
  created() {
    const { id, dir_number } = this.$route.query;
    if (!isNaN(Number(id))) {
      this.showFiscalPaymentDetails(id);
    }

    if (dir_number) {
      this.filterByDirNumber = dir_number;
      this.setUrlSearchParam("dir_number");
    }
  },
};
</script>

<style lang="scss" scoped></style>
