<template>
  <div>
    <div
      class="heading d-md-flex justify-content-md-between align-items-md-center"
    >
      <NbPageTitle
        :title="$t('masterboxesPage.title')"
        :subTitle="$t('masterboxesPage.subtitle')"
      />

      <NbButton
        @click="openCreateMasterboxModal = true"
        class="btn-add d-flex justify-content-start align-items-center"
      >
        <NbIcon icon="plus" class="mr-2" />
        {{ $t("masterboxesPage.createMasterbox") }}
      </NbButton>
    </div>

    <section class="page-container">
      <NbTablev2
        namespace="masterboxes"
        class="mt-5"
        tableOf="masterboxesPage"
        ref="masterboxesRef"
        :clickable="true"
        :allFields="allFields"
        :fields="fields"
        :filter-options="filterOptions"
        @reloadFields="fields = $event"
        @clickedRow="showMasterbox($event.id)"
      >
        <template #cell(id)="data">
          <NbButton variant="tertiary" @click="showMasterbox(data.item.id)">
            {{ data.item.id }}
          </NbButton>
        </template>
        <template #cell(carriers)="data">
          <div v-if="data.item.carriers?.length > 0">
            <router-link
              v-for="carrier in data.item.carriers"
              :key="carrier.id"
              class="link-1"
              :to="'/contracts/carriers?carrierId=' + carrier.id"
            >
              {{ carrier.name ? carrier.name : "-" }}
            </router-link>
          </div>
          <span v-else>-</span>
        </template>
        <template #cell(shipment_id)="data">
          <router-link
            v-if="data.item.shipment_id"
            class="link-1"
            :to="'/shipping/shipments?id=' + data.item.shipment_id"
          >
            {{ data.item.shipment_id }}
          </router-link>
          <span v-else>-</span>
        </template>
        <template #cell(reference)="data">
          <NbButton
            variant="tertiary"
            v-copy="data.item.reference"
            v-b-tooltip.hover
            :title="$t('clickToCopy')"
          >
            {{ data.item.reference }}
          </NbButton>
        </template>
        <template #cell(cn35_label)="data">
          <NbButton variant="tertiary" @click="generateLabel(data.item.id)">
            {{ $t("download") }}
            <NbIcon class="ml-1" icon="download" />
          </NbButton>
        </template>
      </NbTablev2>
    </section>

    <MasterboxModal
      v-if="masterboxSelectedId"
      :masterbox-id="masterboxSelectedId"
      @close="closeModal"
    />

    <MasterboxCreateModal
      v-if="openCreateMasterboxModal"
      @close="openCreateMasterboxModal = false"
      @success="$refs?.masterboxesRef?.getData()"
    />
  </div>
</template>

<script>
import MasterBoxService from "@/services/MasterBoxService";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import { copyClipboard } from "@/directives/copy-clipboard";
import MasterboxModal from "./components/MasterboxModal.vue";
import queryParamsMixin from "@/mixins/query-params-mixin";
import MasterboxCreateModal from "./components/MasterboxCreateModal.vue";

const masterBoxService = new MasterBoxService();

export default {
  name: "MasterBoxes",
  directives: {
    copy: copyClipboard,
  },
  mixins: [queryParamsMixin],
  components: {
    NbPageTitle,
    NbTablev2,
    NbButton,
    NbIcon,
    MasterboxModal,
    MasterboxCreateModal,
  },
  data: () => {
    return {
      masterboxSelectedId: null,
      openCreateMasterboxModal: false,
      fields: [],
    };
  },
  computed: {
    items() {
      return this.$store.state[this.namespace].items;
    },
    allFields() {
      return [
        { key: "id", label: "ID", sortable: false, thClass: ["small-content"] },
        {
          key: "dispatch_id",
          label: this.$t("masterboxesPage.dispatchID"),
        },
        {
          key: "reference",
          label: this.$t("masterboxesPage.reference"),
        },
        {
          key: "cn35_label",
          label: this.$t("masterboxesPage.cN35Label"),
        },
        {
          key: "shipment_id",
          label: this.$t("masterboxesPage.shipmentID"),
        },
        {
          key: "created_at",
          label: this.$t("masterboxesPage.creationDate"),
        },
        {
          key: "carriers",
          label: this.$t("masterboxesPage.mbCarriers"),
        },
      ];
    },
    filterOptions() {
      return [
        {
          key: "dispatch_id",
          label: this.$t("masterboxesPage.dispatchID"),
        },
        {
          key: "reference",
          label: this.$t("masterboxesPage.reference"),
        },
        {
          key: "shipment_id",
          label: this.$t("masterboxesPage.shipmentID"),
        },
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
      ];
    },
  },
  methods: {
    showMasterbox(id) {
      this.masterboxSelectedId = id;
      this.setUrlSearchParam("id", id);
    },
    closeModal(loadContent = false) {
      this.masterboxSelectedId = null;
      this.setUrlSearchParam("id");

      if (loadContent) {
        this.$refs?.masterboxesRef?.getData();
      }
    },
    generateLabel(id) {
      masterBoxService.generateMasterBoxLabel(id).then((response) => {
        if (
          // eslint-disable-next-line no-prototype-builtins
          response.data.hasOwnProperty("data") &&
          // eslint-disable-next-line no-prototype-builtins
          response.data.data.hasOwnProperty("download_url")
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
  },
  mounted() {
    const queryId = this.$route.query?.id;
    if (queryId) {
      this.showMasterbox(queryId);
    }
  },
};
</script>
