<template>
  <div>
    <NbModal
      v-show="modalActive"
      :id="id"
      modalConfig="modal-dialog-centered"
      width="76rem"
      @close="$emit('close')"
    >
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ `${$t("deliveryMethodsViewPage.title")} ${itemId}` }}
          </h5>
        </div>
      </template>
      <div class="d-flex justify-content-between mb-5">
        <Cannot :profiles="[Profile.CARRIER]">
          <NbCard
            id="actions-card"
            :title="$t('actions')"
            class="px-3"
            width="fit-content"
          >
            <template #body>
              <div class="d-flex justify-content-between flex-wrap pt-3">
                <div id="editdeliverymethod-btn" class="w-100">
                  <NbButton
                    :disabled="deliveryMethod.is_made_by_seller"
                    class="w-100 my-1 px-2 text-left text-14-20"
                    icon="edit"
                    data-toggle="modal"
                    data-target="#editDeliveryMethod"
                    @click="
                      copyDeliveryMethod();
                      modalActive = false;
                    "
                  >
                    {{ $t("deliveryMethodsViewPage.editDeliveryMethod") }}
                  </NbButton>
                  <b-popover
                    v-if="deliveryMethod.is_made_by_seller"
                    placement="top"
                    target="editdeliverymethod-btn"
                    triggers="hover focus"
                    variant="dark"
                  >
                    You can not edit delivery method created by a seller
                  </b-popover>
                </div>
              </div>
            </template>
          </NbCard>
        </Cannot>
      </div>
      <div>
        <NbCard
          id="checkpoint_chard"
          :title="$t('generalInformation')"
          class="bg-gray-05"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                id="name-deliverymethods-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-49-5 mb-2 mt-2"
                :name="$t('deliveryMethodsViewPage.name')"
                v-model="deliveryMethod.name"
                disabled
              />
              <div
                :class="`w-49-5 mb-2 mt-2 ${
                  deliveryMethod.carrierName ? 'link-1' : ''
                } `"
                @click="
                  pushTo(
                    `/users_management/carriers/${deliveryMethod.carrier_id}`,
                  )
                "
              >
                <NbTextInput
                  id="carrier_id-deliverymethods-view"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  :name="$t('deliveryMethodsViewPage.carrier')"
                  v-model="deliveryMethod.carrierName"
                  disabled
                />
              </div>
              <NbTextInput
                id="service-deliverymethods-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 mb-2 mt-2"
                :name="$t('deliveryMethodsViewPage.service')"
                v-model="deliveryMethod.service"
                disabled
              />
              <NbTextInput
                v-if="['correios', 'skypostal'].includes(deliveryMethod.slug)"
                id="nationalization-deliverymethods-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 mb-2 mt-2"
                :name="$t('deliveryMethodsViewPage.nationalization')"
                v-model="deliveryMethod.additional_settings.nationalization"
                disabled
              />
              <NbTextInput
                id="type-deliverymethods-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 mb-2 mt-2"
                :name="$t('deliveryMethodsViewPage.type')"
                v-model="deliveryMethod.type"
                disabled
              />
              <NbTextInput
                id="slug-deliverymethods-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-100 mb-2 mt-2"
                :name="$t('deliveryMethodsViewPage.cooperationSlug')"
                v-model="deliveryMethod.slug"
                disabled
              />
            </div>
          </template>
        </NbCard>
        <div class="d-flex justify-content-between mb-5">
          <!-- <NbCard
            v-if="deliveryMethod?.tracking_api_credentials"
            id="trackingCredentials_chard"
            class="bg-gray-05 w-48-5 mt-3"
          >
            <template #body>
              <div class="border border-gray-20 my-1 py-2 px-3 h-fit">
                <span class="w-100 heading-1 mb-3">
                  {{ $t("deliveryMethodsViewPage.trackingCredentials") }}
                </span>
                <div
                  v-for="(
                    tracking_api_credentials, key
                  ) in deliveryMethod.tracking_api_credentials"
                  :key="tracking_api_credentials.id"
                  class="border-top border-gray-20 mt-2 py-1"
                >
                  <NbTextInput
                    :id="`${key + tracking_api_credentials}
                    -tracking-credential`"
                    variant="borderless-gray-10"
                    :placeholder="$t('notFound')"
                    :name="key"
                    v-model="deliveryMethod.tracking_api_credentials[key]"
                    disabled
                  />
                </div>
              </div>
            </template>
          </NbCard> -->

          <NbCard id="dispatchCredentials_chard" class="bg-gray-05 w-100 mt-3">
            <template #body>
              <div class="border border-gray-20 my-1 py-2 px-3 h-fit">
                <span class="w-100 heading-1 mb-3">
                  {{ $t("deliveryMethodsPage.dispatchCredentials") }}
                </span>
                <div
                  v-for="(
                    dispatch_api_credentials, key
                  ) in deliveryMethod.dispatch_api_credentials"
                  :key="dispatch_api_credentials.id"
                  class="border-top border-gray-20 mt-2 py-1"
                >
                  <NbTextInput
                    :id="`${key + dispatch_api_credentials}
                    -dispatch-credentials`"
                    variant="borderless-gray-10"
                    :placeholder="$t('notFound')"
                    :name="key"
                    :textarea="true"
                    v-model="deliveryMethod.dispatch_api_credentials[key]"
                    disabled
                  />
                </div>
              </div>
            </template>
          </NbCard>
        </div>

        <NbFooter
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
            @click="resetQuery()"
          >
            {{ $t("close") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <ModalDeliveryMethodsCreate
      id="editDeliveryMethod"
      @close="modalActive = true"
      :deliveryMethod.sync="newDeliveryMethod"
      @reloadDeliveryMethods="getData()"
    />
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ModalDeliveryMethodsCreate from "@/views/contracts/delivery_methods/components/ModalDeliveryMethodsCreate.vue";

import DeliveryMethodsService from "@/services/DeliveryMethodsService";
import CarrierService from "@/services/CarrierService";
import Cannot from "../../../../components/Cannot.vue";
import { Profile } from "../../../../constants";

const deliveryMethodsService = new DeliveryMethodsService();
const carrierService = new CarrierService();

export default {
  components: {
    NbModal,
    NbButton,
    NbTextInput,
    NbCard,
    NbFooter,
    ModalDeliveryMethodsCreate,
    Cannot,
  },
  name: "ModalDeliveryMethodView",
  props: {
    id: {
      type: String,
      default: "ModalDeliveryMethodView",
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      Profile,
      modalActive: true,
      deliveryMethod: { carrierName: "" },
      newDeliveryMethod: {},
      currentItemId: "",
    };
  },
  created() {},
  computed: {},
  methods: {
    pushTo(path) {
      this.$helpers.closeModal(this.id);
      this.$router.push({
        path: path,
      });
    },
    copyDeliveryMethod() {
      this.newDeliveryMethod = { ...this.deliveryMethod };
    },
    resetQuery() {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({ query: {} });
        return;
      }
    },
    getData() {
      deliveryMethodsService
        .getOneDeliveryMethod(this.itemId)
        .then((response) => {
          this.deliveryMethod = response.data.data;
          this.deliveryMethod.slug = this.deliveryMethod?.slug?.title;
          carrierService
            .getOneCarrier(this.deliveryMethod.carrier_id)
            .then((response) => {
              this.deliveryMethod.carrierName = response.data.data.name;
            });
        });
    },
  },
  watch: {
    itemId(newVal) {
      if (newVal) {
        this.getData();
      }
    },
  },
};
</script>

<style scoped></style>
