export default {
  computed: {
    recipientOptions() {
      return [
        { text: this.$t("notificationsPage.user"), value: "user" },
        { text: this.$t("notificationsPage.seller"), value: "seller" },
      ];
    },
    sellerNotificationTitles() {
      return {
        0: this.$t("notificationsPage.finance"),
        1: this.$t("notificationsPage.insufficientBalance"),
        2: this.$t("notificationsPage.trackingIssue"),
        3: this.$t("notificationsPage.newTicket"),
      };
    },
    userNotificationTitles() {
      return {
        0: this.$t("notificationsPage.finance"),
        1: this.$t("notificationsPage.insufficientBalance"),
        2: this.$t("notificationsPage.trackingIssue"),
        3: this.$t("notificationsPage.newTicket"),
        4: this.$t("notificationsPage.dataUpdate"),
        5: this.$t("notificationsPage.contractPermission"),
        6: this.$t("notificationsPage.contractUpdate"),
        7: this.$t("notificationsPage.priceTableUpdate"),
      };
    },
    users() {
      return this.$store.state.sellers.all_items?.map((item) => ({
        text: item.name,
        value: item.id,
      }));
    },
    sellers() {
      return this.$store.state.users.all_items?.map((item) => ({
        text: item.name,
        value: item.id,
      }));
    },
    recipients() {
      if (!this.formData.recipient) {
        return [];
      }

      if (this.formData.recipient === "user") {
        return this.users;
      }

      return this.sellers;
    },
  },
};
