<template>
  <div>
    <NbPageTitle
      class="pb-3 page-container"
      :title="$t('deliveryMethodsPage.title')"
      :subTitle="$t('deliveryMethodsPage.subTitle')"
    >
      <Cannot :profiles="[Profile.CARRIER]">
        <div class="btn-groups d-flex flex-column">
          <NbButton
            class="text-left my-1"
            data-toggle="modal"
            data-target="#addDeliveryMethod"
            @click="initializeDeliveryMethod()"
          >
            <NbIcon class="mr-2" icon="plus" />
            {{ $t("deliveryMethodsPage.addDeliveryMethod") }}
          </NbButton>
          <NbButton
            class="text-left my-1"
            variant="secondary"
            data-toggle="modal"
            data-target="#addCooperation"
          >
            <NbIcon class="mr-2" icon="plus" />
            {{ $t("deliveryMethodsPage.cooperations") }}
          </NbButton>
        </div>
      </Cannot>
    </NbPageTitle>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

      <NbTablev2
        tableOf="deliveryMethodsTable"
        :namespace="namespace"
        ref="deliveryMethodsTable"
        :clickable="true"
        :allFields="allFields"
        :fields="fields"
        :filterOptions="filterOptions"
        @reloadFields="fields = $event"
        @total="totalDeliveryMethods = $event"
        @clickedRow="
          loadModalData(
            $event.id,
            'deliveryMethodId',
            'ModalDeliveryMethodView',
          )
        "
      >
        <template v-slot:cell(carrier_name)="data">
          <router-link
            class="link-1"
            :to="`/contracts/carriers?carrierId=` + data.item.carrier_id"
          >
            {{ getCarrier(data.item.carrier_name) }}
          </router-link>
        </template>
        <template v-slot:cell(slug)="data">
          {{ data.item.slug?.title }}
        </template>
      </NbTablev2>
    </section>

    <NbFooter
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />

    <!-- modals -->
    <ModalDeliveryMethodsCreate
      id="addDeliveryMethod"
      :deliveryMethod.sync="newDeliveryMethod"
      @reloadDeliveryMethods="reloadDeliveryMethods"
    />
    <ModalCooperationCreate
      id="addCooperation"
      :cooperation.sync="newCooperation"
    />
    <ModalDeliveryMethodView
      @close="resetQuery()"
      :itemId="currentItemId"
      @reloadDeliveryMethods="reloadDeliveryMethods"
    />
    <!--
    <ModalTrackingRuleView
      :itemId="currentItemId"
      @reloadDeliveryMethods="reloadDeliveryMethods"
    />
    -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ModalDeliveryMethodsCreate from "@/views/contracts/delivery_methods/components/ModalDeliveryMethodsCreate.vue";
import ModalCooperationCreate from "@/views/contracts/delivery_methods/components/ModalCooperationCreate.vue";
import ModalDeliveryMethodView from "@/views/contracts/delivery_methods/components/ModalDeliveryMethodView.vue";
import Cannot from "../../../components/Cannot.vue";
import { Profile } from "../../../constants";

export default {
  name: "DeliveryMethods",
  components: {
    NbPageTitle,
    NbIcon,
    NbButton,
    NbTabs,
    NbTablev2,
    NbFooter,
    ModalDeliveryMethodsCreate,
    ModalCooperationCreate,
    ModalDeliveryMethodView,
    Cannot,
  },
  data: () => {
    return {
      Profile,
      newDeliveryMethod: {
        name: "",
        service: "",
        type: "",
        carrier_id: "",
        owner: null,
        owner_reference: null,
        dispatch_api_credentials: {},
        slug_id: null,
        additional_settings: { nationalization: null },
      },
      fields: [],
      errors: [],
      //carriers: [],
      namespace: "delivery_methods",
      cooperation: "",
      newCooperation: { title: "" },
      newCooperationError: "",
      ifNationalization: false,
      additional_settings: {},
      currentTable: "all",
      totalDeliveryMethods: -1,
      currentItemId: "",
    };
  },
  created() {
    this.fields = this.allFields;
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    setSlug(slug_id) {
      this.newDeliveryMethod.slug_id = slug_id;
      const selectedSlug = this.cooperations.find((c) => c.id === slug_id);
      const credentials = selectedSlug.integration_credentials.map(
        (c) => c.credential,
      );
      this.setNationalization(selectedSlug.title);
      this.setcredentialsDefault(selectedSlug.title, credentials);
    },
    setNationalization(selectedSlug) {
      this.ifNationalization = ["correios", "skypostal"].includes(selectedSlug);
      this.newDeliveryMethod.additional_settings.nationalization = null;
    },
    setcredentialsDefault(selectedSlug, credentials) {
      this.newDeliveryMethod.dispatch_api_credentials = {};
      this.mandatoryCredential = credentials;
      credentials.forEach((c) => {
        this.addDCredential("", c);
      });
    },
    checkQuery() {
      if (this.$route.query.deliveryMethodId) {
        this.loadModalData(
          this.$route.query.deliveryMethodId,
          "deliveryMethodId",
          "ModalDeliveryMethodView",
        );
        return;
      }
    },
    loadModalData(id, queryKey, modalId) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query[queryKey]) {
          this.$router.push({ query: { [queryKey]: id } });
        }
        /* this.$router.push({ query: {} }); */

        this.$helpers.openModal(modalId);
        return;
      }
    },
    resetQuery() {
      this.$router.push({ query: {} });
    },
    switchTab(tab) {
      this.currentTable = tab;
    },
    reloadDeliveryMethods() {
      this.$refs?.deliveryMethodsTable?.getData();
    },
    getCarrier(id) {
      const carrier = this.carriers.filter((carrier) => carrier.id === id);
      if (carrier[0]) {
        return carrier[0].name;
      }
    },
    initializeDeliveryMethod() {
      this.newDeliveryMethod.type = "";
      this.newDeliveryMethod.carrier_id = "";
      this.newDeliveryMethod.service = "";
      this.newDeliveryMethod.name = "";
      this.newDeliveryMethod.owner = null;
      this.newDeliveryMethod.owner_reference = null;
      this.newDeliveryMethod.dispatch_api_credentials = {};
      this.errors = [];
    },
  },
  computed: {
    ...mapState({
      site: (state) => state.site,
    }),
    items() {
      return this.$store.state[this.namespace].items;
    },
    carriers() {
      return this.$store.state.carriers.all_items;
    },
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("deliveryMethodsPage.title"),
          found: this.totalDeliveryMethods,
          current: this.currentTable,
        },
      ];
    },
    allFields() {
      return [
        {
          key: "name",
          label: this.$t("deliveryMethodsPage.fields.name"),
          sortable: false,
          thClass: ["large-content"],
        },
        {
          key: "service",
          label: this.$t("deliveryMethodsPage.fields.service"),
          sortable: true,
          thClass: ["small-content"],
        },
        {
          key: "type",
          label: this.$t("deliveryMethodsPage.fields.type"),
          sortable: true,
          thClass: ["small-content"],
        },
        {
          key: "carrier_name",
          label: this.$t("deliveryMethodsPage.fields.carrier"),
          sortable: false,
          thClass: ["large-content"],
        },
        {
          key: "carrier_id",
          label: this.$t("deliveryMethodsPage.fields.carrierID"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "created_at",
          label: this.$t("deliveryMethodsPage.fields.creationDate"),
          sortable: true,
          thClass: ["medium-content"],
        },
        {
          key: "slug",
          label: this.$t("deliveryMethodsPage.fields.cooperationSlug"),
          sortable: false,
          thClass: ["large-content"],
        },
      ];
    },
    filterOptions() {
      return [
        { key: "name", label: this.$t("deliveryMethodsPage.fields.name") },
        {
          key: "service",
          label: this.$t("deliveryMethodsPage.fields.service"),
        },
        {
          key: "type",
          label: this.$t("deliveryMethodsPage.fields.type"),
          type: "select",
          options: [
            { value: null, text: "Select" },
            { value: "express", text: "Express" },
            { value: "standard", text: "standard" },
          ],
        },
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
      ];
    },
  },
};
</script>

<style scoped>
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.2rem 0.6rem;
  margin-left: 3px;
  margin-right: 4px;
}
.botton-button {
  position: absolute;
  bottom: 24px;
}
</style>
