<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("sellersPage.downloadSellers") }}
      </ModalTitle>
    </ModalHeader>

    <div>
      <NbDropFile
        v-model="chosenFile"
        :accept="['application/json']"
        :label="$t('alertLogsPage.browseFilesUpload')"
      />
      <div v-if="errors.length > 0">
        <p
          class="text-center text-danger"
          v-for="(error, index) in errors"
          :key="index"
        >
          {{ error }}
        </p>
      </div>
    </div>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose class="mr-2" />
      <NbButton @click="uploadFile" :disabled="loading">
        {{ $t("upload") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import SellerService from "@/services/SellerService";
import NbDropFile from "@/components/input/drop-file/NbDropFile.vue";

const sellersService = new SellerService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    NbDropFile,
  },
  data() {
    return {
      loading: false,
      chosenFile: "",
      errors: [],
    };
  },
  methods: {
    async uploadFile() {
      try {
        if (!this.chosenFile) return;
        this.loading = true;
        const formData = new FormData();
        formData.append("file", this.chosenFile);
        await sellersService.importSeller(formData);
        this.$emit("success");
      } catch (error) {
        const errors = error?.response?.data?.messages;
        if (errors.length > 0) {
          this.errors = errors;
          this.$bvToast.toast(this.$t("errorMessages.genericError"), {
            variant: "danger",
          });
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
