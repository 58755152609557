<template>
  <NbModal
    id="updateFlightModal"
    modalConfig="modal-dialog-centered"
    width="1000px"
    @close="reset"
  >
    <template v-slot:header>
      <div class="heading-4">
        {{ $t("flightsViewPage.updateFlight") }}
      </div>
    </template>
    <template v-slot:body>
      <div class="d-flex justify-content-between">
        <div class="right-side w-48-5">
          <div class="heading-2 mb-4">
            {{ $t("flightsViewPage.information") }}
          </div>
          <NbTextInput
            id="flight_number"
            :placeholder="$t('flightsViewPage.flightNumber')"
            :name="$t('flightsViewPage.flightNumber')"
            type="number"
            class="w-100 my-2"
            :error="errors.flight_number"
            v-model="newFlight.flight_number"
          />
          <div class="d-flex justify-content-between flex-wrap">
            <NbTextInput
              id="departure_airport"
              :placeholder="$t('flightsViewPage.departureAirport')"
              class="w-32-5 my-2"
              :name="$t('flightsViewPage.departureAirport')"
              :error="errors.departure_airport"
              v-model="newFlight.departure_airport"
            />
            <NbTextInput
              id="arrival_airport"
              :placeholder="$t('flightsViewPage.arrivalAirport')"
              class="w-32-5 my-2"
              :name="$t('flightsViewPage.arrivalAirport')"
              :error="errors.arrival_airport"
              v-model="newFlight.arrival_airport"
            />
            <NbTextInput
              id="stopover_airport"
              :placeholder="$t('flightsViewPage.stopoverAirport')"
              class="w-32-5 my-2"
              :name="$t('flightsViewPage.stopoverAirport')"
              :error="errors.stopover_airport"
              v-model="newFlight.stopover_airport"
            />
            <!-- datas -->
            <NbDatePicker
              id="departure_date"
              size="sm"
              class="w-46 my-2"
              :name="$t('flightsViewPage.departureDate')"
              :error="errors.departure_date"
              @shortDate="flight.departure_date_short = $event"
              v-model="newFlight.departure_date"
            />

            <NbDatePicker
              id="arrival_date"
              size="sm"
              class="w-46 my-2"
              :name="$t('flightsViewPage.arrivalDate')"
              :error="errors.arrival_date"
              @shortDate="flight.arrival_date_short = $event"
              v-model="newFlight.arrival_date"
            />

            <!-- horas -->
            <NbTextInput
              id="departure_time"
              :placeholder="$t('notFound')"
              class="w-49-5 my-2"
              :name="$t('flightsViewPage.departureTime')"
              type="time"
              :error="errors.departure_time"
              v-model="newFlight.departure_time"
            />
            <NbTextInput
              id="arrival_time"
              :placeholder="$t('notFound')"
              class="w-49-5 my-2"
              :name="$t('flightsViewPage.arrivalTime')"
              type="time"
              :error="errors.arrival_time"
              v-model="newFlight.arrival_time"
            />

            <!-- ultimas informaçoes -->
            <NbTextInput
              id="airline"
              :placeholder="$t('flightsViewPage.airline')"
              class="w-32-5 my-2"
              :name="$t('flightsViewPage.airline')"
              :error="errors.airline"
              v-model="newFlight.airline"
            />
            <NbTextInput
              id="freight_value"
              placeholder="0.00"
              class="w-32-5 my-2"
              :name="$t('ordersViewPage.freightValue')"
              type="money"
              :decimals="2"
              :min="0.0"
              :error="errors.freight_value"
              v-model="newFlight.freight_value"
            />
            <NbTextInput
              id="sub_total"
              :placeholder="$t('flightsViewPage.subtotal')"
              class="w-32-5 my-2"
              :name="$t('flightsViewPage.subtotal')"
              type="money"
              :decimals="2"
              :min="0.0"
              :error="errors.sub_total"
              v-model="newFlight.sub_total"
            />
            <NbTextInput
              id="charges_per_kilo"
              :placeholder="$t('flightsViewPage.chargesKilo')"
              class="w-49-5 my-2"
              :name="$t('flightsViewPage.chargesKilo')"
              type="number"
              :error="errors.charges_per_kilo"
              v-model="newFlight.charges_per_kilo"
            />
            <NbTextInput
              id="other_charges"
              :placeholder="$t('flightsViewPage.otherCharges')"
              class="w-49-5 my-2"
              :name="$t('flightsViewPage.otherCharges')"
              type="number"
              :error="errors.other_charges"
              v-model="newFlight.other_charges"
            />

            <NbTextInput
              id="mawb_number"
              :placeholder="$t('flightsViewPage.mawbNumber')"
              class="w-100 my-2"
              :name="$t('flightsViewPage.mawbNumber')"
              :error="errors.mawb_number"
              v-model="newFlight.mawb_number"
            />
          </div>
        </div>
        <div class="left-side w-48-5">
          <div class="heading-2 mb-4">
            {{ $t("flightsViewPage.mawb") }}
          </div>
          <NbDropFile
            id="upload-file"
            v-model="newFlight.mawb_file_temp"
            :pdfUrl="pdfUrl"
            :showMiniaturePdf="true"
            :showPreviewFiles="
              newFlight.mawb_file_format == 'binary' ? true : false
            "
            :accept="['application/pdf']"
            :label="$t('alertLogsPage.browseFilesUpload')"
            @stringFile="stringFile"
            @click="changeLinkBinary('binary')"
            minHeight="365px"
            embedHeight="306px"
          >
            <template #labelSlot>
              <NbButton
                v-if="has_mawb_file"
                variant="tertiary"
                @click="$emit('generateFlightLabel', newFlight.id)"
              >
                {{ $t("flightsViewPage.downloadCurrent") }}
              </NbButton>
            </template>
            <div class="py-4 text-in-nbdropfile">
              <div class="text-center">
                {{ $t("flightsViewPage.noMawbDocument") }}
              </div>
              <div class="text-center">{{ $t("clickHereUploadFile") }}</div>
              <div class="text-center mb-3">
                {{ $t("selectPdfFileUpload") }}
              </div>
            </div>
          </NbDropFile>
          <div class="my-2">
            <NbButton
              class="mx-1"
              size="sm"
              :variant="
                newFlight.mawb_file_format == 'binary' ? 'primary' : 'secondary'
              "
              @click="openUploadFile"
            >
              {{ $t("uploadFromComputer") }}
            </NbButton>
            <NbButton
              class="mx-1"
              size="sm"
              :variant="
                newFlight.mawb_file_format == 'link' ? 'primary' : 'secondary'
              "
              @click.stop="changeLinkBinary('link')"
            >
              {{ $t("uploadFromLinks") }}
            </NbButton>
          </div>
          <NbTextInput
            v-if="newFlight.mawb_file_format == 'link'"
            id="mawb-file-text"
            :placeholder="$t('sitePlaceholder')"
            class="w-100 my-2"
            :name="$t('flightsViewPage.mawbFileLink')"
            :error="errors.departure_airport"
            v-model="newFlight.mawb_file_temp"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <NbButton
        class="float-right mr-3"
        @click="handleSubmit"
        :disabled="loading"
        >{{ $t("save") }}</NbButton
      >
      <NbButton
        variant="secondary"
        class="float-right mr-2"
        @click="closeModal()"
        >{{ $t("cancel") }}</NbButton
      >
    </template>
  </NbModal>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbDatePicker from "@/components/generic/NbDatePicker.vue";
import NbDropFile from "@/components/input/drop-file/NbDropFile.vue";
import FlightService from "@/services/FlightService";

const flightService = new FlightService();

export default {
  name: "UpdateFlightModal",
  components: { NbModal, NbTextInput, NbButton, NbDatePicker, NbDropFile },
  props: {
    flight: {
      type: Object,
      required: true,
    },
    has_mawb_file: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      loading: false,
      errors: {},
      newFlight: {
        stopover_airport: "",
        departure_airport: "",
        arrival_airport: "",
        departure_time: "",
        departure_date: "",
        arrival_time: "",
        arrival_date: "",
        mawb_number: "",
        mawb_file: "",
        mawb_file_temp: "",
        mawb_file_format: "link",
        airline: "",
        flight_number: "",
        sub_total: "",
        charges_per_kilo: "",
        other_charges: "",
        freight_value: "",
      },
    };
  },
  computed: {
    pdfUrl() {
      if (!this.newFlight.mawb_file_temp) {
        return false;
      }
      if (
        this.newFlight.mawb_file_temp &&
        this.newFlight.mawb_file_format !== "link"
      ) {
        return (
          "data:application/pdf;base64," +
          this.newFlight.mawb_file_temp +
          "#toolbar=0&navpanes=0&scrollbar=0"
        );
      }
      return (
        this.newFlight.mawb_file_temp + "#toolbar=0&navpanes=0&scrollbar=0"
      );
    },
  },
  methods: {
    reset() {
      this.errors = {};
      this.$emit("close");
    },
    openUploadFile() {
      this.changeLinkBinary("binary");
      setTimeout(() => {
        document.getElementById("upload-file-trigger").click();
      }, 500);
    },
    changeLinkBinary(val) {
      if (val) {
        this.newFlight.mawb_file_format = val;
        return;
      }
      if (this.newFlight.mawb_file_format == "binary") {
        this.newFlight.mawb_file_format = "link";
        return;
      }

      this.newFlight.mawb_file_format = "binary";
    },
    stringFile(event) {
      this.newFlight.mawb_file = event;
    },
    handleSubmit() {
      if (this.newFlight.mawb_file_format == "link") {
        this.newFlight.mawb_file = this.newFlight.mawb_file_temp;
      }

      this.newFlight.arrival_date = this.newFlight.arrival_date_short;
      this.newFlight.departure_date = this.newFlight.departure_date_short;
      this.loading = true;
      flightService
        .updateFlightInfo(this.newFlight.id, this.newFlight)
        .then(() => {
          this.errors = {};
          this.closeModal();
          this.$emit("loadCurrentFlight");
        })
        .catch((error) => {
          this.errors = error.response.data.messages[0];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeModal() {
      this.$helpers.closeModal("updateFlightModal");
    },
    showModal() {
      this.$helpers.openModal("updateFlightModal");
    },
    processFile(event) {
      this.newFlight.mawb_file_format = "binary";
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        const parts = reader.result.split("base64,");
        this.newFlight.mawb_file = parts[1];
      };
    },
  },
  mounted() {
    this.newFlight = this.flight;
    this.$helpers.openModal("updateFlightModal");
  },
};
</script>

<style lang="scss" scoped>
#upload-file-trigger {
  height: 600px;
}
.text-in-nbdropfile {
  margin-top: calc(50% - 100px);
}
</style>
