<template>
  <div class="search-input-wrapper" :class="error[0] ? 'is-invalid' : ''">
    <label v-if="name" class="input-label" :for="id">
      {{ configuredName }}
      <NbHelpText
        v-if="helpText"
        :id="`${id}-popover`"
        class="mx-1"
        :size="helpTextSize"
        placement="topright"
      >
        {{ helpText }}
      </NbHelpText>
    </label>
    <NbHelpText
      v-else-if="helpText"
      :id="`${id}-popover`"
      :class="`helper-text-fixer ${size}`"
      :size="helpTextSize"
      placement="topright"
    >
      {{ helpText }}
    </NbHelpText>
    <input
      type="text"
      class="form-control input mr-2"
      :id="id"
      autocomplete="off"
      :placeholder="placeholder"
      v-model="textContent"
      :class="error[0] ? `is-invalid ${size}` : `${size}`"
      @focus="$emit('focus')"
    />
    <span :class="`search-icon ${size} ${name ? 'fix-top' : ''}`">
      <NbIcon icon="search"
    /></span>
    <ErrorFeedback :error="error[0]" />
  </div>
</template>

<script>
import ErrorFeedback from "../../generic/ErrorFeedback.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  name: "NbBorderTextInput",
  components: {
    ErrorFeedback,
    NbHelpText,
    NbIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      required: false,
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
    prepend: {
      type: String,
      required: false,
    },
    append: {
      type: String,
      required: false,
    },
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      textContent: this.value,
    };
  },
  computed: {
    configuredName() {
      if (this.required) {
        return `${this.name} *`;
      }
      return this.name;
    },
  },
  watch: {
    //altera o input
    textContent(newValue) {
      this.$emit("input", newValue);
    },
    //altera o modal do pai
    value(newValue) {
      this.textContent = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-input-wrapper {
  position: relative;
}

.search-input-wrapper > .search-icon {
  position: absolute;
  top: 0.5rem;
  left: 0.8rem;
  color: var(--gray-40);
}
.search-input-wrapper > .search-icon.sm {
  position: absolute;
  top: 0.35rem !important;
  left: 0.8rem;
  color: var(--gray-40);
}

.search-input-wrapper > .search-icon.fix-top {
  position: absolute;
  top: 2.2rem;
  left: 0.8rem;
  color: var(--gray-40);
}
.search-input-wrapper > .search-icon.sm.fix-top {
  position: absolute;
  top: 2.2rem !important;
  left: 0.8rem;
  color: var(--gray-40);
}
.search-input-wrapper > .helper-text-fixer {
  position: absolute;
  top: 0.55rem;
  right: 0.8rem;
}
.search-input-wrapper > .helper-text-fixer.sm {
  position: absolute;
  top: 0.45rem;
  right: 0.8rem;
}
.form-control.input.is-invalid:focus + .search-icon,
.form-control.input:focus + .search-icon,
.form-control.input.is-invalid:not(:placeholder-shown) + .search-icon,
.form-control.input:not(:placeholder-shown) + .search-icon {
  color: var(--black);
}
.form-control.input:not(:placeholder-shown),
.form-control.input.is-invalid:not(:placeholder-shown) {
  border: 1px solid #000000;
}
.form-control.input {
  height: 2.5rem;
  box-shadow: none;
  border: 1px solid transparent;
  border-radius: 4px;
  font: normal normal normal 14px/20px var(--font-family-base);
  padding: 0rem 0.75rem 0rem 2.4rem;
}
.form-control.input:hover {
  background: var(--gray-10) 0% 0% no-repeat padding-box;
}
.form-control.input:focus {
  box-shadow: none;
  border: 1px solid #000000;
}
.form-control.input::placeholder {
  font: normal normal normal 14px/20px var(--font-family-base);
  color: var(--gray-40);
}

.form-control.input.is-invalid {
  border: 1px solid var(--danger);
  background-image: none;
}
.form-control.input.is-invalid.sm,
.form-control.input.sm {
  padding: 0rem 0.75rem 0rem 2.3rem;
  height: 2rem;
}
.form-control.input.is-valid {
  border: 1px solid var(--success);
  background-image: none;
}
.form-control.input.is-valid.sm,
.form-control.input.sm {
  padding: 0rem 0.75rem 0rem 2.3rem;
  height: 2rem;
}

.form-control.input.is-invalid:hover {
  background: var(--gray-10) 0% 0% no-repeat padding-box;
}
.form-control.input:disabled,
.form-control.input[disabled] {
  background-color: var(--gray-20) !important;
  color: var(--gray-30) !important;
  border: 1px solid var(--gray-20) !important;
}
.input-label {
  color: var(--black);
  text-align: left;
  font: normal normal 600 12px/16px var(--font-family-base);
  letter-spacing: 0px;
  display: inline-block;
  margin-bottom: 0.5rem;
  cursor: default;
}
</style>
