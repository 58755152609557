<template>
  <div class="tab-wrapper">
    <button
      v-for="(tab, index) in tabs"
      :key="index"
      class="button-tab"
      :class="tab.id == tab.current ? 'selected' : ''"
      @click="switchTab(tab.id)"
    >
      <span class="text-underline">{{ tab.label }}</span>
      <span
        v-if="tab.found > -1"
        class="nb-badge-light"
        :class="tab.id == tab.current ? 'selected' : ''"
        >{{ tab.found }}</span
      >
    </button>
  </div>
</template>

<script>
export default {
  name: "NbTabs",
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    switchTab(tabLabel) {
      this.$emit("switchTab", tabLabel);
    },
  },
};
</script>

<style scoped>
.tab-wrapper {
  border-bottom: 1px solid var(--gray-20);
}
.width-45 {
  width: 45vw !important;
  min-width: 45vw !important;
}
.button-tab {
  transition: all 0.5s;
  font: normal normal normal 14px/20px Nunito Sans;
  background: transparent 0% 0% no-repeat padding-box;
  border: 0px;

  padding: 0.69rem 1rem;
  color: #6e6e82;
}
.button-tab:hover .text-underline {
  text-decoration: underline;
}
.button-tab:hover .nb-badge-light {
  text-decoration: none !important;
}
.button-tab.selected {
  font: normal normal bold 14px/18px Nunito Sans;
  transition: all 0.5s;
  border-bottom: 2px solid #000014;
  color: black;
}
.button-tab.selected:hover {
  text-decoration: none;
}
.nb-badge-light {
  background: #f0f0fa;
  border-radius: 4px;
  padding: 4px 8px;
}
</style>
