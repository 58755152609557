<template>
  <div>
    <NbModal
      v-show="modalActive"
      id="masterbox-modal"
      @close="closeModal"
      width="1024px"
    >
      <template #header>
        <div v-if="masterbox?.id" class="d-flex">
          <NbPageTitle
            v-if="masterbox?.reference"
            :title="$t('masterboxesPage.masterboxData')"
            :subTitle="masterbox?.reference"
          />
        </div>
      </template>

      <template #body>
        <div class="grid grid-cols-3">
          <NbCard
            id="documents"
            :title="$t('masterboxesPage.documentsAndLabels')"
          >
            <template #body>
              <div class="grid">
                <NbButton
                  variant="secondary"
                  v-b-tooltip.hover
                  :title="$t('clickToDownload')"
                  @click="generateLabel"
                  :disabled="isDownloading"
                >
                  {{ $t("masterboxesPage.cN35Label") }}
                </NbButton>
              </div>
            </template>
          </NbCard>
          <NbCard id="actions" :title="$t('actions')">
            <template #body>
              <div class="grid">
                <NbButton variant="secondary" @click="showDeleteModal">
                  {{ $t("delete") }}
                </NbButton>
              </div>
            </template>
          </NbCard>
          <NbCard
            v-if="masterbox.shipment_id"
            id="other"
            :title="$t('suggestedActions')"
          >
            <template #body>
              <div class="grid">
                <NbButton
                  @click="
                    navigateTo(
                      `/shipping/shipments?id=${masterbox.shipment_id}`,
                    )
                  "
                >
                  {{ $t("masterboxesPage.goToShipment") }}
                </NbButton>
              </div>
            </template>
          </NbCard>
        </div>

        <div class="cards my-4">
          <NbCard
            :title="$t('masterboxesPage.masterboxData')"
            id="general-data"
          >
            <template #body>
              <div class="grid grid-cols-3">
                <NbTextInput
                  id="reference"
                  :placeholder="$t('masterboxesPage.reference')"
                  :name="$t('masterboxesPage.reference')"
                  v-model="masterbox.reference"
                  disabled
                  variant="borderless-gray-10"
                />
                <NbTextInput
                  id="shipment_sequence_number"
                  :placeholder="$t('masterboxesPage.shipmentSequenceNumber')"
                  :name="$t('masterboxesPage.shipmentSequenceNumber')"
                  v-model="masterbox.shipment_sequence_number"
                  disabled
                  variant="borderless-gray-10"
                />
                <NbTextInput
                  id="shipment_id"
                  :placeholder="$t('masterboxesPage.shipmentID')"
                  :name="$t('masterboxesPage.shipmentID')"
                  v-model="masterbox.shipment_id"
                  disabled
                  variant="borderless-gray-10"
                />
                <NbTextInput
                  id="dispatch_id"
                  :placeholder="$t('masterboxesPage.dispatchID')"
                  :name="$t('masterboxesPage.dispatchID')"
                  v-model="masterbox.dispatch_id"
                  disabled
                  variant="borderless-gray-10"
                />
              </div>
              <div class="d-flex justify-content-end">
                <div class="d-flex flex-column">
                  <label for="" class="input-label">{{
                    $t("masterboxesPage.creationDate")
                  }}</label>
                  <small>{{ masterbox.created_at | dateFormat }}</small>
                </div>
              </div>
            </template>
          </NbCard>

          <NbCardBox>
            <div class="d-flex align-items-center justify-content-between">
              <h3 class="heading-3">{{ $t("masterboxesPage.volumes") }}</h3>
              <NbButton
                class="d-flex ml-auto"
                @click="downloadMasterboxVolumes"
                >{{ $t("download") }}</NbButton
              >
            </div>
            <NbTablev2
              class="mt-5"
              tableOf="volumesMasterbox"
              :hasParentItens="true"
              :hiddeTableOptions="true"
              height="fit-content"
              :allFields="volumesAllFields"
              :fields="volumesTableFields"
              @reloadFields="volumesTableFields = $event"
              :startItems="masterbox.volumes"
            >
              <template #cell(weight)="data">
                {{ standardizeValue(data.value) }}
              </template>
              <template #cell(cubed_weight)="data">
                {{ standardizeValue(data.value) }} kg
              </template>
              <template #cell(order_items)="data">
                {{ data.item.items_count }}
              </template>
              <template #cell(last_mile_carrier_number)="data">
                {{ data.item.last_mile_carrier_number }}
              </template>
              <template #cell(actions)="data">
                <NbButton
                  @click="navigateTo(`/shipping/volumes?id=` + data.item.id)"
                  variant="tertiary"
                >
                  {{ $t("seeDetails") }} >
                </NbButton>
              </template>
            </NbTablev2>
          </NbCardBox>
        </div>
      </template>
    </NbModal>
    <NbModal id="delete-masterbox" prevent-close>
      <template #header>
        <NbPageTitle
          :title="$t('masterboxesPage.deleteMasterbox')"
          :subTitle="masterbox.reference"
        />
      </template>
      <template #body>
        <p class="body-4">{{ deleteMessage }}</p>

        <NbButton v-if="requestId" v-copy="requestId">
          {{ $t("clickToCopy") }}
        </NbButton>
      </template>
      <template #footer>
        <NbButton
          v-if="requestId"
          class="d-flex ml-auto"
          variant="secondary"
          @click="backToMasterboxes"
        >
          {{ $t("close") }}
        </NbButton>
        <div v-else class="d-flex justify-content-between">
          <NbButton variant="secondary" @click="closeDeleteMasterboxModal">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton :disabled="loading" @click="deleteMasterbox">
            {{ $t("delete") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import { copyClipboard } from "@/directives/copy-clipboard";
import moment from "moment";
import MasterboxService from "../../../../services/MasterBoxService";
import NbCardBox from "@/components/cards/NbCardBox.vue";

const masterboxService = new MasterboxService();

export default {
  components: {
    NbModal,
    NbPageTitle,
    NbCard,
    NbButton,
    NbTextInput,
    NbTablev2,
    NbCardBox,
  },
  directives: {
    copy: copyClipboard,
  },
  props: {
    masterboxId: {
      type: [Number, String],
      required: true,
    },
  },
  filters: {
    dateFormat(value) {
      return value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "";
    },
  },
  data() {
    return {
      loading: false,
      isDownloading: false,
      requestId: null,
      deleteMessage: this.$t("masterboxesPage.confirmDeleteMasterbox"),
      modalActive: true,
      masterbox: {
        dispatch_id: null,
        platform_id: null,
        reference: "",
        shipment_id: null,
        shipment_sequence_number: null,
        volumes: [],
        overpacks: [],
        created_at: "",
      },
      volumesTableFields: [],
    };
  },
  computed: {
    volumesAllFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "weight",
          label: this.$t("masterboxesPage.weight"),
        },
        {
          key: "cubed_weight",
          label: this.$t("masterboxesPage.cubedWeight"),
        },
        {
          key: "last_mile_tracking_number",
          label: this.$t("masterboxesPage.lastMileTrackingNumber"),
        },
        {
          key: "last_mile_carrier_number",
          label: this.$t("masterboxesPage.lastMileCarrierNumber"),
        },
        {
          key: "order_items",
          label: this.$t("masterboxesPage.totalItems"),
        },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ];
    },
  },
  methods: {
    downloadMasterboxVolumes() {
      const data = {
        item_id: this.masterboxId,
        data_type: "masterbox_volumes",
      };
      masterboxService.downloadMasterboxVolumes(data).then(() => {
        this.closeModal();
        this.$router.push("/archives");
      });
    },
    navigateTo(path) {
      this.closeModal();
      this.$router.push(path);
    },
    standardizeValue(val) {
      const finalVal = parseFloat(val).toFixed(2);
      if (finalVal === "NaN") {
        return "0.00";
      }
      return finalVal;
    },
    closeModal(loadItem = false) {
      this.$helpers.closeModal("masterbox-modal");
      this.$emit("close", loadItem);
    },
    async loadMasterbox() {
      try {
        this.modalActive = true;
        this.loading = true;
        const { data } = await masterboxService.getMasterBox(this.masterboxId);
        this.masterbox = data.data;
      } catch (error) {
        this.$bvToast.toast(this.$t("errorMessages.genericError"), {
          variant: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    async generateLabel() {
      try {
        this.isDownloading = true;
        const { data } = await masterboxService.generateMasterBoxLabel(
          this.masterboxId,
        );
        if (data?.data?.download_url) {
          window.open(data.data.download_url);
        }
      } finally {
        this.isDownloading = false;
      }
    },
    showDeleteModal() {
      this.modalActive = false;
      this.$helpers.openModal("delete-masterbox");
    },
    async deleteMasterbox() {
      try {
        this.loading = true;
        const { data } = await masterboxService.deleteMasterBox(
          this.masterbox.reference,
        );

        this.requestId = data.data.request_id;
        this.deleteMessage = data.messages[0].replace(
          "request_id",
          this.requestId,
        );
      } catch (error) {
        this.deleteMessage = this.$t("masterboxesPage.confirmDeleteMasterbox");
        this.requestId = null;
      } finally {
        this.loading = false;
      }
    },
    closeDeleteMasterboxModal() {
      this.modalActive = true;
      this.$helpers.closeModal("delete-masterbox");
    },
    backToMasterboxes() {
      this.$helpers.closeModal("delete-masterbox");
      this.closeModal(true);
    },
  },
  mounted() {
    this.$helpers.openModal("masterbox-modal");
    this.loadMasterbox();
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  gap: 1rem;
  &.grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  &.grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.cards {
  display: grid;
  gap: 1rem;
}
</style>
