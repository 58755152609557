import i18n from "./i18n";
import Vue from "vue";
import { ToastPlugin } from "bootstrap-vue";

Vue.use(ToastPlugin);
const vm = new Vue();

const possibleLangs = {
  en: "en-US",
  zh_CN: "zh-Hans-CN",
  pt_BR: "pt-br",
  es: "es",
};

export default {
  /**
   * Converts an array into a json string
   *
   * @param {*} array
   */
  arrayToJsonString(array = []) {
    let jsonString = "{";

    array.forEach((item) => {
      jsonString += item;
      jsonString += ",";
    });

    jsonString = jsonString.substring(0, jsonString.length - 1) + "}";

    return jsonString;
  },

  //Session control
  getPlatform(isPrefix) {
    let platforms = {
      total: "total",
      nobordist: "nobordist",
      asia: "asia",
      ecross: "e-cross",
      anjun: "anjun",
      tiendamia: "tiendamia",
      mirakl: "mirakl",
      skypostal: "skypostal",
      jt: "jt",
      correios: "correios",
      shopee: "shopee",
      loggi: "loggi",
    };
    if (isPrefix === "prefix") {
      //set new value return values if platform use prefix
      platforms.nobordist = "nb";
    }

    if (window.location.pathname.toLowerCase().startsWith("/nb-")) {
      return platforms.nobordist;
    }
    if (window.location.pathname.toLowerCase().startsWith("/total-")) {
      return platforms.total;
    }
    if (window.location.pathname.toLowerCase().startsWith("/asia-")) {
      return platforms.asia;
    }
    if (window.location.pathname.toLowerCase().startsWith("/e-cross-")) {
      return platforms.ecross;
    }
    if (window.location.pathname.toLowerCase().startsWith("/anjun-")) {
      return platforms.anjun;
    }
    if (window.location.pathname.toLowerCase().startsWith("/tiendamia-")) {
      return platforms.tiendamia;
    }
    if (window.location.pathname.toLowerCase().startsWith("/mirakl-")) {
      return platforms.mirakl;
    }
    if (window.location.pathname.toLowerCase().startsWith("/skypostal-")) {
      return platforms.skypostal;
    }
    if (window.location.pathname.toLowerCase().startsWith("/jt-")) {
      return platforms.jt;
    }
    if (window.location.pathname.toLowerCase().startsWith("/correios-")) {
      return platforms.correios;
    }
    if (window.location.pathname.toLowerCase().startsWith("/shopee-")) {
      return platforms.shopee;
    }
    if (window.location.pathname.toLowerCase().startsWith("/loggi-")) {
      return platforms.loggi;
    }

    const url = window.location.host;
    const urlPlatform = url.split(".")[0];

    if (urlPlatform.toLowerCase().includes("total")) {
      return platforms.total;
    }
    if (urlPlatform.toLowerCase().includes("asia")) {
      return platforms.asia;
    }
    if (url.toLowerCase().includes("e-cross")) {
      return platforms.ecross;
    }
    if (url.toLowerCase().includes("anjun")) {
      return platforms.anjun;
    }
    if (url.toLowerCase().includes("tiendamia")) {
      return platforms.tiendamia;
    }
    if (url.toLowerCase().includes("mirakl")) {
      return platforms.mirakl;
    }
    if (url.toLowerCase().includes("skypostal")) {
      return platforms.skypostal;
    }
    if (url.toLowerCase().includes("jt")) {
      return platforms.jt;
    }
    if (url.toLowerCase().includes("shopee")) {
      return platforms.shopee;
    }
    if (url.toLowerCase().includes("loggi")) {
      return platforms.loggi;
    }

    return platforms.nobordist;
  },

  /**
   * Extract error messages from exception
   *
   * @param {*} error
   */
  extractErrorMessages(error) {
    let errorMessages = [];

    // eslint-disable-next-line no-prototype-builtins
    if (
      error.hasOwnProperty("response") &&
      // eslint-disable-next-line no-prototype-builtins
      error.response.hasOwnProperty("data") &&
      // eslint-disable-next-line no-prototype-builtins
      error.response.data.hasOwnProperty("messages")
    ) {
      error.response.data.messages.forEach((element) => {
        if (typeof element == "object") {
          Object.keys(element).forEach((elementKey) => {
            if (Array.isArray(element[elementKey])) {
              element[elementKey].forEach((erroMessage) => {
                if (elementKey === "base") {
                  errorMessages.push(erroMessage);
                } else {
                  errorMessages.push(elementKey + " " + erroMessage);
                }
              });
            } else if (
              typeof element[elementKey] == "string" &&
              elementKey != "codigo_retorno" &&
              elementKey != "descricao_retorno"
            ) {
              errorMessages.push(element[elementKey]);
            }
          });
        } else if (typeof element == "string") {
          errorMessages.push(element);
        }
      });
    } else {
      errorMessages.push(error.message);
    }

    return errorMessages;
  },

  formatShortDate(string, options) {
    const locale = possibleLangs[i18n.locale];
    const day = new Date(string);

    return day.toLocaleDateString(locale, options);
  },
  formatShortTime(string, options) {
    const locale = possibleLangs[i18n.locale];
    const day = new Date(string);

    return day.toLocaleTimeString("en" || locale, options);
  },
  formatTimestamp(string, options) {
    if (!string) {
      return "";
    }

    return `${this.formatShortDate(string, options)} ${this.formatShortTime(
      string,
      options,
    )}`;
  },
  formatMoney(string, currency) {
    if (!string) return "";

    const value = parseFloat(string).toFixed(2);

    if (value === "NaN") {
      return "0.00";
    }

    return `${(currency || "")
      .toUpperCase()
      .replace("USD", "$")} ${value}`.trim();
  },
  toCamelCase: (text) => text.replace(/[-_]./g, (m) => m[1].toUpperCase()),
  closeModal(modalId) {
    if (!modalId) {
      document.querySelector("[data-dismiss=modal]").click();
    }
    document.getElementById(`${modalId}-close`).click();
  },
  closeAllModals() {
    // Find all modals
    const openedModals = document.querySelectorAll(".modal.show");
    openedModals.forEach((modal) => {
      modal.classList.remove("show");
      modal.style.display = "none";
      document.body.classList.remove("modal-open");
      var backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.remove();
      }
    });
  },
  openModal(modalId) {
    document.getElementById(`${modalId}-open`)?.click();
  },
  toast(message, variant, time) {
    const hash = Math.random().toString(36).substring(7);
    const crtHtml = vm.$createElement;
    const $closeButton = crtHtml("i", {
      class: "fas fa-times",
      on: { click: () => vm.$bvToast.hide(hash) },
      style: {
        float: "right",
        cursor: "pointer",
        padding: "6px",
        height: "28px",
        top: "calc(50% - 14px)",
        right: "3px",
        position: "absolute",
      },
    });
    vm.$bvToast.toast([message, $closeButton], {
      id: hash,
      toaster: "b-toaster-bottom-center",
      solid: true,
      variant: variant ? variant : "warning",
      autoHideDelay: time ? time : 2000,
      noCloseButton: true,
    });
  },
  inputRules(newValue, rules, required) {
    let internalErrors = [];
    if (required) {
      internalErrors.length = 0;
      if (newValue == "") {
        internalErrors.push("Can't be blank");
        return internalErrors;
      } else {
        internalErrors.push("Refresh");
        internalErrors.pop();
      }
    }
    if (rules) {
      const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let rulesArray = rules.split("|");
      const isValid = EMAIL_REGEX.test(newValue);
      for (let rule in rulesArray) {
        let ruleDetails = rulesArray[rule].split(":");
        switch (ruleDetails[0]) {
          case "email":
            internalErrors.length = 0;
            if (!isValid) {
              internalErrors.push(i18n.t("errorMessages.invalidEmail"));
              return internalErrors;
            } else {
              internalErrors.push("Refresh");
              internalErrors.pop();
            }
            break;

          case "length":
            internalErrors.length = 0;
            if (newValue.length < ruleDetails[1]) {
              internalErrors.push(
                "Can't be lower than " + ruleDetails[1] + " digits",
              );
              return internalErrors;
            } else {
              internalErrors.push("Refresh");
              internalErrors.pop();
            }
            break;
          case "minLength":
            internalErrors.length = 0;
            if (newValue.length < ruleDetails[1]) {
              internalErrors.push(
                "Can't be lower than " + ruleDetails[1] + " digits",
              );
              return internalErrors;
            } else {
              internalErrors.push("Refresh");
              internalErrors.pop();
            }
            break;
          case "maxLength":
            internalErrors.length = 0;
            if (newValue.length > ruleDetails[1]) {
              internalErrors.push(
                "Can't be more than " + ruleDetails[1] + " digits",
              );
              return internalErrors;
            } else {
              internalErrors.push("Refresh");
              internalErrors.pop();
            }
            break;
          case "notLength":
            internalErrors.length = 0;
            if (newValue.length == ruleDetails[1]) {
              internalErrors.push("Can't be " + ruleDetails[1] + " digits");
              return internalErrors;
            } else {
              internalErrors.push("Refresh");
              internalErrors.pop();
            }
            break;

          case "required":
            internalErrors.length = 0;
            if (newValue == "") {
              internalErrors.push("Can't be blank");
              return internalErrors;
            } else {
              internalErrors.push("Refresh");
              internalErrors.pop();
            }
            break;

          /* more rules example
          case "number":
            this.error.length = 0
            if (newValue != "") {
              let regex = /^[0-9]*\.?[0-9]*$/
              if (!regex.test(newValue.toLowerCase())) {
                internalErrors.push('is not a number')
                return internalErrors;
              } else {
                internalErrors.push('Refresh')
                internalErrors.pop()
              }
            }
            break;

          case "numberValue":
            this.error.length = 0
            if (newValue != "") {
              let regex = /^[0-9]*\.?[0-9]*$/
              if (!regex.test(newValue.toLowerCase())) {
                internalErrors.push('is not a number (use dot insted comma)')
                return internalErrors;
              } else {
                internalErrors.push('Refresh')
                internalErrors.pop()
              }
            }
            break;

          case "minVal":
            this.error.length = 0
            if (newValue < parseFloat(ruleDetails[1])) {
              internalErrors.push("Can't be lower than " + ruleDetails[1])
              return internalErrors;
            } else {
              internalErrors.push('Refresh')
              internalErrors.pop()
            }
            break;
          
          case "maxVal":
            this.error.length = 0
            if (newValue > parseFloat(ruleDetails[1])) {
              internalErrors.push("Can't be more than " + ruleDetails[1])
              return internalErrors;
            } else {
              internalErrors.push('Refresh')
              internalErrors.pop()
            }
            break;
           */
        }
      }
    }
    return internalErrors;
  },
};
