<template>
  <div>
    <NbTablev2
      tableOf="nobordistCheckpointTable"
      namespace="nobordist_checkpoints"
      ref="nobordistCheckpointTable"
      :clickable="true"
      :allFields="allFields"
      :fields="fields"
      :tabs="true"
      :selectable="true"
      :optionsWidth="260"
      :filterOptions="filterOptions"
      @optionTook="optionTook($event)"
      :buttonOptions="buttonOptions"
      :selectedItems.sync="rowSelection"
      @reloadFields="fields = $event"
      @total="$emit('total', $event)"
      @clickedRow="loadModalData($event.id)"
    >
    </NbTablev2>

    <ModalNobordistCheckpointsView :itemId="currentItemId" />
  </div>
</template>
<script>
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import DownloadsService from "@/services/DownloadsService";
import ModalNobordistCheckpointsView from "@/views/checkpoints/nobordist_checkpoints/components/ModalNobordistCheckpointsView.vue";

const downloadsService = new DownloadsService();

export default {
  name: "NobordistCheckpoints",
  components: {
    NbTablev2,
    ModalNobordistCheckpointsView,
  },
  props: {},
  data: () => {
    return {
      rowSelection: [],
      fields: [],
      currentItemId: "",
    };
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    checkQuery() {
      if (this.$route.query.nobordistCheckpointId) {
        this.$emit("switchTab", "nobordistCheckpoints");
        this.loadModalData(this.$route.query.nobordistCheckpointId);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query.nobordistCheckpointId) {
          this.$router.push({ query: { nobordistCheckpointId: id } });
        }
        /* this.$router.push({ query: {} }); */

        this.$helpers.openModal("ModalNobordistCheckpointsView");
        return;
      }
    },
    initiateFileCreation(dataQuery) {
      downloadsService.loadProcessing(dataQuery).then(() => {
        this.$router.push("/archives");
      });
    },
    downloadCheckpointCsv() {
      this.initiateFileCreation({
        data_type: "checkpoints",
        reference_ids: this.rowSelection,
        doc_type: "csv",
      });
    },
    downloadCheckpointXls() {
      this.initiateFileCreation({
        data_type: "checkpoints",
        reference_ids: this.rowSelection,
        doc_type: "xls",
      });
    },
    optionTook(event) {
      this[event]();
    },
  },
  computed: {
    allFields() {
      return [
        /* { key: "id", label: "ID", sortable: false }, */
        {
          key: "tracking_code",
          label: this.$t("nobordistCheckpointsPage.fields.trackingCode"),
          sortable: false,
        },
        {
          key: "type",
          label: this.$t("nobordistCheckpointsPage.fields.type"),
          sortable: false,
        },
        {
          key: "slug",
          label: this.$t("nobordistCheckpointsPage.fields.slug"),
          sortable: false,
        },
        {
          key: "name",
          label: this.$t("nobordistCheckpointsPage.fields.name"),
          sortable: false,
        },
        {
          key: "title",
          label: this.$t("nobordistCheckpointsPage.fields.title"),
          sortable: false,
        },
      ];
    },
    filterOptions() {
      return [
        { key: "id", label: "ID" },
        {
          key: "type",
          label: this.$t("nobordistCheckpointsPage.fields.type"),
          type: "select",
          options: [
            { value: "MACRO", text: "MACRO" },
            { value: "MICRO", text: "MICRO" },
          ],
        },
        { key: "slug", label: this.$t("nobordistCheckpointsPage.fields.slug") },
        { key: "name", label: this.$t("nobordistCheckpointsPage.fields.name") },
        {
          key: "title",
          label: this.$t("nobordistCheckpointsPage.fields.title"),
        },
        {
          key: "tracking_code",
          label: this.$t("nobordistCheckpointsPage.fields.trackingCode"),
        },
      ];
    },
    buttonOptions() {
      return [
        {
          text: this.$t("nobordistCheckpointsPage.downloadCheckpointsCSV"),
          value: "downloadCheckpointCsv",
        },
        {
          text: this.$t("nobordistCheckpointsPage.downloadCheckpointsExcel"),
          value: "downloadCheckpointXls",
        },
      ];
    },
  },
  watch: {},
};
</script>
