<template>
  <div id="app">
    <loading-bar />
    <PasswordNew
      v-if="!user && $router.currentRoute.name === 'reset_password'"
    />
    <Login v-else-if="!user" />
    <base-template v-if="user" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import LoadingBar from "./components/LoadingBar.vue";
import BaseTemplate from "./views/BaseTemplate.vue";
import Login from "./views/logins/Login.vue";
import PasswordNew from "./views/PasswordNew.vue";

export default {
  name: "App",
  components: { BaseTemplate, Login, LoadingBar, PasswordNew },
  computed: {
    ...mapState({
      user: (state) => state.user,
      platform: (state) => state.platform,
    }),
  },
  watch: {
    platform(val) {
      const platformCommand = val.replaceAll("-", "");
      this[`load${platformCommand}Platform`]();
    },
  },
  mounted() {
    this.$store.dispatch("accessibility/load");
    this.setPlatform();
  },
  methods: {
    setPlatform() {
      const pathName = window.location.pathname;
      let platform = this.platform;

      if (pathName.includes("total-")) {
        platform = "total";
      }
      if (pathName.includes("nb-")) {
        platform = "nb";
      }
      if (pathName.includes("e-cross-")) {
        platform = "e-cross";
      }
      if (pathName.includes("anjun-")) {
        platform = "anjun";
      }
      if (pathName.includes("asia-")) {
        platform = "asia";
      }
      if (pathName.includes("tiendamia-")) {
        platform = "tiendamia";
      }
      if (pathName.includes("mirakl-")) {
        platform = "mirakl";
      }
      if (pathName.includes("skypostal-")) {
        platform = "skypostal";
      }
      if (pathName.includes("jt-")) {
        platform = "jt";
      }
      if (pathName.includes("correios-")) {
        platform = "correios";
      }
      if (pathName.includes("shopee-")) {
        platform = "shopee";
      }
      if (pathName.includes("loggi-")) {
        platform = "loggi";
      }

      this.$store.commit("SET_PLATFORM", platform);
      const platformCommand = platform.replaceAll("-", "");
      this[`load${platformCommand}Platform`]();
    },
    loadtotalPlatform() {
      const root = document.querySelector(":root");
      // colors
      root.style.setProperty("--primary", "#e11e25");
      root.style.setProperty("--secondary", "#22315e");
      root.style.setProperty("--info", "#22315e");
      root.style.setProperty("--danger", "#940409");

      root.style.setProperty("--green", "#248F3D");
      root.style.setProperty("--darkGreen", "#006838");
      root.style.setProperty("--light", "#f5f9fd");
      root.style.setProperty("--light-transparent", "#ffffff75");
      root.style.setProperty("--light-hover", "#fff");
      root.style.setProperty("--dark", "#003650");
      root.style.setProperty("--lightBlue", "#394dc9");
      root.style.setProperty("--blue", "#006a96");
      root.style.setProperty("--blue-transparent", "#005e8685");

      root.style.setProperty("--dark-soft", "#4c646f");
      root.style.setProperty("--dark-transparent", "#00365075");
      root.style.setProperty("--dark-hover", "#003650");

      root.style.setProperty("--bg-top-bar", "#0D1442");
      root.style.setProperty("--bg-side-bar", "#0D1442");
      root.style.setProperty("--bg-side-colapse", "#10184e");
      root.style.setProperty("--selected-option", "#248F3D");

      root.style.setProperty("--top-bar-text", "#f5f9fd");
      root.style.setProperty("--top-bar-focus", "#e11e25");
      root.style.setProperty("--activeMenuHover", "#fff");

      root.style.setProperty("--side-bar-text", "#f5f9fd");
      root.style.setProperty("--side-bar-focus", "#e11e25");

      //scrollbar
      /* root.style.setProperty("--scrollbarStartColor", "#940409");
      root.style.setProperty("--scrollbarEndColor", "#1e2448");
      root.style.setProperty("--scrollbarStartColorHover", "#e11e25");
      root.style.setProperty("--scrollbarEndColorHover", "#22315e"); */

      //font
      root.style.setProperty("--font-family-base", "Nunito Sans");

      //new layout
      //login-bg
      root.style.setProperty("--backgroundLogin", "#800000");
      //VARIAVEIS ALTERAVEIS
      root.style.setProperty("--primary", "#22315e");
      root.style.setProperty("--primary-70", "#22315eB3");
      root.style.setProperty("--primary-70-hover", "#22315eCC");
      root.style.setProperty("--primary-80", "#22315eCC");
      root.style.setProperty("--primary-90", "#690000"); //login-footer-bg
      root.style.setProperty("--backdrop-bg", "#e11e2463"); //modal background

      //nav
      root.style.setProperty("--activeMenu", "#e11e25"); //bg LOGO
      root.style.setProperty("--activeMenuHover", "#e11e25"); //hover do texto no menu lateral
      root.style.setProperty("--navBg", "#22315e05"); //bg menu lateral -> nao alterar?

      //layout
      root.style.setProperty("--background", "#22315e05"); //bg geral

      //change favicon
      const favicon = document.getElementById("favicon");
      favicon.href = `/favicon-total.png`;
      //change title
      document.title = "Total Express | administration";
    },
    loadnbPlatform() {
      const root = document.querySelector(":root");
      // colors
      root.style.setProperty("--primary", "#001991");
      root.style.setProperty("--secondary", "#456557");
      root.style.setProperty("--danger", "#FE4A65");

      root.style.setProperty("--green", "#248F3D");
      root.style.setProperty("--darkGreen", "#006838");
      root.style.setProperty("--light", "#f5f9fd");
      root.style.setProperty("--light-transparent", "#ffffff75");
      root.style.setProperty("--light-hover", "#fff");
      root.style.setProperty("--dark", "#003650");
      root.style.setProperty("--lightBlue", "#394dc9");
      root.style.setProperty("--blue", "#006a96");
      root.style.setProperty("--blue-transparent", "#005e8685");

      root.style.setProperty("--dark-soft", "#4c646f");
      root.style.setProperty("--dark-transparent", "#00365075");
      root.style.setProperty("--dark-hover", "#003650");

      root.style.setProperty("--bg-top-bar", "#0D1442");
      root.style.setProperty("--bg-side-bar", "#0D1442");
      root.style.setProperty("--bg-side-colapse", "#10184e");
      root.style.setProperty("--selected-option", "#248F3D");

      root.style.setProperty("--top-bar-text", "#f5f9fd");
      root.style.setProperty("--top-bar-focus", "#248F3D");
      root.style.setProperty("--activeMenuHover", "#fff");

      root.style.setProperty("--side-bar-text", "#f5f9fd");
      root.style.setProperty("--side-bar-focus", "#248F3D");

      //font
      root.style.setProperty("--font-family-base", "Nunito Sans");

      import(`./assets/scss/_variables-nobordist.scss`); //importa font do password
    },
    loadecrossPlatform() {
      const root = document.querySelector(":root");
      // colors
      root.style.setProperty("--primary", "#404041");
      root.style.setProperty("--secondary", "#009773");
      root.style.setProperty("--danger", "#d1001f");

      root.style.setProperty("--green", "#248F3D");
      root.style.setProperty("--darkGreen", "#006838");
      root.style.setProperty("--light", "#f5f9fd");
      root.style.setProperty("--light-transparent", "#ffffff75");
      root.style.setProperty("--light-hover", "#fff");
      root.style.setProperty("--dark", "#404041");
      root.style.setProperty("--lightBlue", "#394dc9");
      root.style.setProperty("--blue", "#006a96");
      root.style.setProperty("--blue-transparent", "#005e8685");

      root.style.setProperty("--dark-soft", "#4c646f");
      root.style.setProperty("--dark-transparent", "#00365075");
      root.style.setProperty("--dark-hover", "#003650");

      root.style.setProperty("--bg-top-bar", "#404041");
      root.style.setProperty("--bg-side-bar", "#404041");
      root.style.setProperty("--bg-side-colapse", "#454546");
      root.style.setProperty("--selected-option", "#00B88C");

      root.style.setProperty("--top-bar-text", "#f5f9fd");
      root.style.setProperty("--top-bar-focus", "#00B88C");
      root.style.setProperty("--activeMenuHover", "#fff");

      root.style.setProperty("--side-bar-text", "#f5f9fd");
      root.style.setProperty("--side-bar-focus", "#00B88C");

      //font
      root.style.setProperty("--font-family-base", "Nunito Sans");

      //new layout
      //login-bg
      root.style.setProperty("--backgroundLogin", "#404041");

      //VARIAVEIS ALTERAVEIS
      root.style.setProperty("--primary", "#404041");
      root.style.setProperty("--primary-70", "#404041B3");
      root.style.setProperty("--primary-80", "#404041CC");
      root.style.setProperty("--backdrop-bg", "#40404199"); //modal background
      root.style.setProperty("--secondary", "#404041"); //texto da tabela?

      //nav
      root.style.setProperty("--activeMenu", "#404041"); //bg LOGO

      //add favicon
      const favicon = document.getElementById("favicon");
      favicon.href = `/favicon-e-cross.png`;
      //change title
      document.title = "e-Cross | administration";
    },
    loadasiaPlatform() {
      const root = document.querySelector(":root");
      //new layout
      //VARIAVEIS ALTERAVEIS
      root.style.setProperty("--primary", "#080C60");
      root.style.setProperty("--primary-80", "#080C60CC");
      root.style.setProperty("--backdrop-bg", "#080C6099"); //modal background
      //change favicon
      const favicon = document.getElementById("favicon");
      favicon.href = `/favicon-asia.ico`;
      //change title
      document.title = "Asia Shipping | administration";
      this.loadnbPlatform();
    },
    loadanjunPlatform() {
      //Montserrat', sans-serif;
      //#009640 // verde main
      //#00773B // verde main pouco mais escuro
      //#053B31 //verde escuro
      //#65AE4C //verde claro
      //#E30613 // vermelho
      //#706F6F // cinza 585756
      const root = document.querySelector(":root");
      // colors
      root.style.setProperty("--primary", "#00773B");
      root.style.setProperty("--secondary", "#585756");
      root.style.setProperty("--danger", "#E30613");

      root.style.setProperty("--green", "#009640");
      root.style.setProperty("--darkGreen", "#006838");
      root.style.setProperty("--light", "#f5f9fd");
      root.style.setProperty("--light-transparent", "#ffffff75");
      root.style.setProperty("--light-hover", "#fff");
      root.style.setProperty("--dark", "#706F6F");
      root.style.setProperty("--lightBlue", "#394dc9");
      root.style.setProperty("--blue", "#006a96");
      root.style.setProperty("--blue-transparent", "#005e8685");

      root.style.setProperty("--dark-soft", "#4c646f");
      root.style.setProperty("--dark-transparent", "#00365075");
      root.style.setProperty("--dark-hover", "#003650");

      root.style.setProperty("--bg-top-bar", "#706F6F");
      root.style.setProperty("--bg-side-bar", "#706F6F");
      root.style.setProperty("--bg-side-colapse", "#585756");
      root.style.setProperty("--selected-option", "#72c157");

      root.style.setProperty("--top-bar-text", "#f5f9fd");
      root.style.setProperty("--top-bar-focus", "#72c157");
      root.style.setProperty("--activeMenuHover", "#fff");

      root.style.setProperty("--side-bar-text", "#f5f9fd");
      root.style.setProperty("--side-bar-focus", "#72c157");

      //scrollbar
      /* root.style.setProperty("--scrollbarStartColor", "#053B31");
      root.style.setProperty("--scrollbarEndColor", "#585756");
      root.style.setProperty("--scrollbarStartColorHover", "#009640");
      root.style.setProperty("--scrollbarEndColorHover", "#706F6F"); */

      //new layout

      //Montserrat', sans-serif;
      //#009640 // verde main
      //#00773B // verde main pouco mais escuro
      //#053B31 //verde escuro
      //#65AE4C //verde claro
      //#E30613 // vermelho
      //#706F6F // cinza

      //const root = document.querySelector(":root");

      //root.style.setProperty("--font-family-base", "Montserrat");

      //login-bg
      root.style.setProperty("--backgroundLogin", "#026734");

      //VARIAVEIS ALTERAVEIS
      root.style.setProperty("--primary", "#00773B");
      root.style.setProperty("--primary-70", "#00773BB3");
      root.style.setProperty("--primary-70-hover", "#009640");
      root.style.setProperty("--primary-80", "#00773BCC");
      root.style.setProperty("--primary-90", "#014724"); //login-footer-bg
      root.style.setProperty("--backdrop-bg", "#00773B99"); //modal background

      root.style.setProperty("--secondary", "#706F6F"); //texto da tabela?
      root.style.setProperty("--activeMenu", "#f0faf2"); //bg LOGO
      root.style.setProperty("--activeMenuHover", "#fff"); //hover do texto no menu lateral
      root.style.setProperty("--navBgOpen", "#f0faf2");
      root.style.setProperty("--backgroundTopBar", "#f7fcf7"); //bg topbar

      //nav
      root.style.setProperty("--navItemHover", "#009640"); //hover dos menus internos do side bar

      //layout
      root.style.setProperty("--background", "#f7fcf7"); //bg geral

      //VARIAVEIS IRELEVANTES
      root.style.setProperty("--topNavHover", "#706F6F");

      //change favicon
      const favicon = document.getElementById("favicon");
      favicon.href = `/favicon-shopee.png`;
      //change title
      document.title = "Shopee | administration";
    },
    loadtiendamiaPlatform() {
      //#00E6F0 azul main
      //#e2f9faff bg
      //#7800C8 roxo
      //#ff3c3c vermelho
      const root = document.querySelector(":root");

      // colors
      root.style.setProperty("--primary", "#00E6F0");
      root.style.setProperty("--secondary", "#00E6F0");
      root.style.setProperty("--info", "#6e6e82");
      root.style.setProperty("--danger", "#940409");

      root.style.setProperty("--green", "#248F3D");
      root.style.setProperty("--darkGreen", "#006838");
      root.style.setProperty("--light", "#f5f9fd");
      root.style.setProperty("--light-transparent", "#ffffff75");
      root.style.setProperty("--light-hover", "#fff");
      root.style.setProperty("--dark", "#6e6e82");
      root.style.setProperty("--lightBlue", "#394dc9");
      root.style.setProperty("--blue", "#006a96");
      root.style.setProperty("--blue-transparent", "#005e8685");

      root.style.setProperty("--dark-soft", "#4c646f");
      root.style.setProperty("--dark-transparent", "#00365075");
      root.style.setProperty("--dark-hover", "#003650");

      root.style.setProperty("--bg-top-bar", "#6e6e82");
      root.style.setProperty("--bg-side-bar", "#6e6e82");
      root.style.setProperty("--bg-side-colapse", "#6e6e82");
      root.style.setProperty("--selected-option", "#248F3D");

      root.style.setProperty("--top-bar-text", "#fff");
      root.style.setProperty("--top-bar-focus", "#00E6F0");
      root.style.setProperty("--activeMenuHover", "#fff");

      root.style.setProperty("--side-bar-text", "#fff");
      root.style.setProperty("--side-bar-focus", "#00E6F0");

      //scrollbar
      /* root.style.setProperty("--scrollbarStartColor", "#e2f9faff");
      root.style.setProperty("--scrollbarEndColor", "#00E6F0");
      root.style.setProperty("--scrollbarStartColorHover", "#e2f9faff");
      root.style.setProperty("--scrollbarEndColorHover", "#00E6F0"); */

      //font
      root.style.setProperty("--font-family-base", "Nunito Sans");

      //#7800C8 roxo
      //#ff3c3c vermelho

      //const root = document.querySelector(":root");

      //new layout
      //login-bg
      root.style.setProperty("--backgroundLogin", "#ff3c3c");

      //VARIAVEIS ALTERAVEIS
      root.style.setProperty("--primary", "#00E6F0");
      root.style.setProperty("--primary-70", "#00E6F0B3");
      root.style.setProperty("--primary-70-hover", "#00E6F0CC");
      root.style.setProperty("--primary-80", "#00E6F0CC");
      root.style.setProperty("--primary-90", "#9c0000"); //login-footer-bg
      root.style.setProperty("--backdrop-bg", "#e2f9fa99"); //modal background
      root.style.setProperty("--secondary", "#6e6e82"); //texto da tabela?
      root.style.setProperty("--backgroundTopBar", "#f6feff"); //bg topbar
      root.style.setProperty("--background", "#f6feff"); //bg geral
      //nav
      root.style.setProperty("--activeMenu", "#e2f9faff"); //bg LOGO
      root.style.setProperty("--activeMenuHover", "#00E6F0"); //hover do texto no menu lateral

      //change favicon
      const favicon = document.getElementById("favicon");
      favicon.href = `/favicon-tiendamia.ico`;
      //change title
      document.title = "tiendamia | administration";
    },
    loadmiraklPlatform() {
      this.loadnbPlatform();

      //change favicon
      const favicon = document.getElementById("favicon");
      favicon.href = `/favicon-mirakl.png`;
      //change title
      document.title = "Mirakl | administration";
    },
    loadskypostalPlatform() {
      this.loadnbPlatform();

      //change favicon
      const favicon = document.getElementById("favicon");
      favicon.href = `/favicon-skypostal.ico`;
      //change title
      document.title = "Skypostal | administration";
    },
    loadjtPlatform() {
      this.loadnbPlatform();

      const root = document.querySelector(":root");
      // colors
      root.style.setProperty("--primary", "#e60000");
      root.style.setProperty("--secondary", "#3c3c3c");
      root.style.setProperty("--info", "#3c3c3c");
      root.style.setProperty("--danger", "#940409");

      root.style.setProperty("--green", "#248F3D");
      root.style.setProperty("--darkGreen", "#006838");
      root.style.setProperty("--light", "#f5f9fd");
      root.style.setProperty("--light-transparent", "#ffffff75");
      root.style.setProperty("--light-hover", "#fff");
      root.style.setProperty("--dark", "#003650");
      root.style.setProperty("--lightBlue", "#394dc9");
      root.style.setProperty("--blue", "#006a96");
      root.style.setProperty("--blue-transparent", "#005e8685");

      root.style.setProperty("--dark-soft", "#4c646f");
      root.style.setProperty("--dark-transparent", "#00365075");
      root.style.setProperty("--dark-hover", "#003650");

      root.style.setProperty("--bg-top-bar", "#3c3c3c");
      root.style.setProperty("--bg-side-bar", "#3c3c3c"); //olhar
      root.style.setProperty("--bg-side-colapse", "#494949");
      root.style.setProperty("--selected-option", "#248F3D");

      root.style.setProperty("--top-bar-text", "#f5f9fd");
      root.style.setProperty("--top-bar-focus", "#e60000");
      root.style.setProperty("--activeMenuHover", "#fff");

      root.style.setProperty("--side-bar-text", "#f5f9fd");
      root.style.setProperty("--side-bar-focus", "#e60000");

      //frame content
      root.style.setProperty("--frame-background-color", "white");

      //scrollbar
      /* root.style.setProperty("--scrollbarStartColor", "#940409");
      root.style.setProperty("--scrollbarEndColor", "#1e2448");
      root.style.setProperty("--scrollbarStartColorHover", "#e60000");
      root.style.setProperty("--scrollbarEndColorHover", "#3c3c3c"); */

      //#e60000
      //#3c3c3c
      //1e2448

      //const root = document.querySelector(":root");

      //new layout
      //login-bg
      root.style.setProperty("--backgroundLogin", "#3c3c3c");

      //VARIAVEIS ALTERAVEIS
      root.style.setProperty("--primary", "#3c3c3c");
      root.style.setProperty("--primary-70", "#3c3c3cB3");
      root.style.setProperty("--primary-70-hover", "#3c3c3cCC");
      root.style.setProperty("--primary-80", "#3c3c3cCC");
      root.style.setProperty("--primary-90", "#2f2f2f"); //login-footer-bg
      root.style.setProperty("--secondary", "#9696aa");
      root.style.setProperty("--backdrop-bg", "#e11e2463"); //modal background

      //nav
      root.style.setProperty("--activeMenuHover", "#e60000"); //hover do texto no menu lateral
      //change favicon
      const favicon = document.getElementById("favicon");
      favicon.href = `/favicon-jt.png`;
      //change title
      document.title = "J&T | administration";
    },
    loadcorreiosPlatform() {
      this.loadnbPlatform();

      //#0071ad azul
      //#007bb3 azul claro
      //#00416b azul escuro (hover)
      //#ffd400 amarelo
      //#a65718 amarelo queimado

      const root = document.querySelector(":root");
      // colors
      root.style.setProperty("--primary", "#0071ad");
      root.style.setProperty("--secondary", "#00416b");

      root.style.setProperty("--green", "#248F3D");

      root.style.setProperty("--dark", "#003650");
      root.style.setProperty("--blue", "#0071ad");
      root.style.setProperty("--blue-transparent", "#00416b85");

      root.style.setProperty("--dark-hover", "#ffd400");

      root.style.setProperty("--bg-side-colapse", "#10184e");

      root.style.setProperty("--top-bar-focus", "#ffd400");
      root.style.setProperty("--activeMenuHover", "#ffd400");

      root.style.setProperty("--side-bar-focus", "#ffd400");

      //#0071ad azul
      //#007bb3 azul claro
      //#00416b azul escuro (hover)
      //#ffd400 amarelo
      //#a65718 amarelo queimado

      //const root = document.querySelector(":root");

      //new layout
      //login-bg
      root.style.setProperty("--backgroundLogin", "#00416b");
      //VARIAVEIS ALTERAVEIS
      root.style.setProperty("--primary", "#0071ad");
      root.style.setProperty("--primary-70", "#00416b");
      root.style.setProperty("--primary-70-hover", "#0071ad");
      root.style.setProperty("--primary-80", "#00416b");
      root.style.setProperty("--primary-90", "#007bb3"); //login-footer-bg
      root.style.setProperty("--secondary", "#00416b");
      root.style.setProperty("--backdrop-bg", "#ffd40063"); //modal background

      //nav
      root.style.setProperty("--navText", "#0071ad"); //texto no menu lateral
      root.style.setProperty("--activeMenuHover", "#a65718"); //hover do texto no menu lateral

      //change favicon
      const favicon = document.getElementById("favicon");
      favicon.href = `/favicon-correios.png`;
      //change title
      document.title = "Correios | administration";
    },
    loadshopeePlatform() {
      this.loadnbPlatform();

      //change favicon
      const favicon = document.getElementById("favicon");
      favicon.href = `/favicon-shopee.png`;
      //change title
      document.title = "Shopee | administration";
    },
    loadloggiPlatform() {
      this.loadnbPlatform();
      document.title = "Loggi | administration";
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss";
@import "./assets/scss/text";

@font-face {
  font-family: "Myriad Pro Regular";
  font-style: normal;
  font-weight: normal;
  src:
    local("Myriad Pro Regular"),
    url("./assets/fonts/Myriad-Font/MYRIADPRO-REGULAR.woff") format("woff");
}
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap");
/* 
  @font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham'), url('./assets/fonts/Gotham-Font/GothamBook.ttf') format('truetype');
  } 
  */
</style>
