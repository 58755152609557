import { render, staticRenderFns } from "./ModalContractsView.vue?vue&type=template&id=5277d9c3&scoped=true"
import script from "./ModalContractsView.vue?vue&type=script&lang=js"
export * from "./ModalContractsView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5277d9c3",
  null
  
)

export default component.exports