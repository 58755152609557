<template>
  <div>
    <NbButton
      class="float-right"
      variant="secondary"
      size="sm"
      data-toggle="modal"
      data-target="#addStatusCheckpoint"
      @click="initializeStatusCheckpoint()"
    >
      {{ $t("statusCheckpointsPage.addStatusCheckpoint") }}
    </NbButton>
    <NbTablev2
      tableOf="statusCheckpointTable"
      namespace="status_checkpoints"
      ref="statusCheckpointTable"
      :clickable="true"
      :allFields="allFields"
      :fields="fields"
      :searchBy="1"
      :filterOptions="filterOptions"
      @reloadFields="fields = $event"
      @total="$emit('total', $event)"
      @clickedRow="loadModalData($event.id)"
    >
      <template #cell(checkpoint_name)="data">
        <div
          class="link-1"
          @click="loadModalNobordistCheckpoint(data.item.checkpoint_id)"
        >
          {{ getCheckpointName(data.item.checkpoint_id) }}
        </div>
      </template>
      <template #cell(actions)="data">
        <div
          class="link-1 w-fit"
          data-toggle="modal"
          data-target="#editStatusCheckpoint"
          @click="initializeStatusCheckpoint(data.item)"
        >
          <span class="mr-1">{{ $t("edit") }}</span>
          <NbIcon
            icon="edit"
            :attributes="{
              width: '1rem',
              height: '1rem',
            }"
          />
        </div>
      </template>
    </NbTablev2>

    <!-- modal upload -->
    <ModalStatusCheckpointsView :itemId="currentItemId" />

    <ModalNobordistCheckpointsView
      id="StatusModalNobordistCheckpointsView"
      :itemId="currentNbCheckpointId"
    />

    <!-- <div id="editStatusCheckpoint" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="settingsTitleUpd" class="modal-title">
              {{ $t("statusCheckpointsPage.uploadStatusCheckpoint") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="status_codeUpd">{{
                    $t("statusCheckpointsPage.fields.statusCode")
                  }}</label>
                  <input
                    id="status_codeUpd"
                    v-model="newstatusCheckpoint.status_code"
                    type="text"
                    :class="{ 'is-invalid': errors['status_code'] }"
                    class="form-control"
                    aria-describedby="status_codeHelp"
                    placeholder="status_code .."
                  />
                  <div
                    v-for="(error, index) in errors['status_code']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="tracking_functionUpd">{{
                    $t("statusCheckpointsPage.fields.trackingFunction")
                  }}</label>
                  <input
                    id="tracking_functionUpd"
                    v-model="newstatusCheckpoint.tracking_function"
                    type="text"
                    :class="{ 'is-invalid': errors['tracking_function'] }"
                    class="form-control"
                    aria-describedby="tracking_functionHelp"
                    placeholder="tracking_function .."
                  />
                  <div
                    v-for="(error, index) in errors['tracking_function']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <filter-select
                  :label="$t('statusCheckpointsPage.checkpointID')"
                  :items="checkpoints"
                  placehol="Select Checkpoint .."
                  error-target="checkpoint_id"
                  item-value="id"
                  item-text="title"
                  :shows="['title']"
                  :errors="errors"
                  :val-edit="newstatusCheckpoint.checkpoint_id"
                  @emitValue="newstatusCheckpoint.checkpoint_id = $event"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a
              href="#"
              class="btn btn-primary float-right ml-3"
              @click="uploadStatusCheckpoints()"
              >{{ $t("upload") }}</a
            >
            <a
              id="editStatusCheckpointClose"
              href="#"
              class="btn btn-secondary float-right ml-3"
              data-dismiss="modal"
              >{{ $t("close") }}</a
            >
          </div>
        </div>
      </div>
    </div> -->
    <!-- end modal upload -->
    <!-- start modal save -->
    <NbModal
      id="addStatusCheckpoint"
      modalConfig="modal-dialog-centered"
      width="30rem"
    >
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ $t("statusCheckpointsPage.addNewStatusCheckpoint") }}
          </h5>
        </div>
      </template>
      <div>
        <div class="d-flex justify-content-between flex-wrap">
          <NbTextInput
            id="status_code"
            v-model="newstatusCheckpoint.status_code"
            required
            :name="$t('statusCheckpointsPage.fields.statusCode')"
            class="w-48-5"
            :error="errors['status_code']"
            placeholder="status code .."
          />
          <NbTextInput
            id="tracking_function"
            v-model="newstatusCheckpoint.tracking_function"
            required
            :name="$t('statusCheckpointsPage.fields.trackingFunction')"
            class="w-48-5 mb-3"
            :error="errors['tracking_function']"
            placeholder="tracking_ unction .."
          />
          <NbSelectInput
            required
            id="checkpoint_id"
            class="w-100 mb-3"
            v-model="newstatusCheckpoint.checkpoint_id"
            :name="$t('statusCheckpointsPage.checkpointID')"
            :error="errors['checkpoint_id']"
            :options="checkpointsOption"
          />
        </div>
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton
            id="addStatusCheckpointClose"
            class="mx-1"
            variant="secondary"
            data-dismiss="modal"
          >
            {{ $t("close") }}
          </NbButton>
          <NbButton class="mx-1" @click="addStatusCheckpoint()">
            {{ $t("save") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <!-- end modal save -->
  </div>
</template>
<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NProgress from "nprogress";
import NbModal from "@/components/modal/NbModal.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import ModalStatusCheckpointsView from "@/views/checkpoints/status_checkpoints/components/ModalStatusCheckpointsView.vue";
import ModalNobordistCheckpointsView from "@/views/checkpoints/nobordist_checkpoints/components/ModalNobordistCheckpointsView.vue";

import StatusCheckpointService from "@/services/StatusCheckpointService";

const statusCheckpointService = new StatusCheckpointService();

export default {
  name: "StatusCheckpoints",
  components: {
    NbButton,
    NbTablev2,
    NbIcon,
    NbModal,
    NbTextInput,
    NbSelectInput,
    ModalStatusCheckpointsView,
    ModalNobordistCheckpointsView,
  },
  props: {},
  data: () => {
    return {
      fields: [],
      currentItemId: "",
      currentNbCheckpointId: "",
      currentNobordistCheckpointId: "",
      newstatusCheckpoint: {
        status_code: "",
        tracking_function: "",
        checkpoint_id: "",
      },
      errors: [],
      checkpoints: [],
      checkpointsOption: [],
    };
  },
  beforeMount() {
    this.loadCheckpoints();
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    checkQuery() {
      if (this.$route.query.statusCheckpointId) {
        this.$emit("switchTab", "statusCheckpoints");
        this.loadModalData(this.$route.query.statusCheckpointId);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query.statusCheckpointId) {
          this.$router.push({ query: { statusCheckpointId: id } });
        }
        /* this.$router.push({ query: {} }); */

        this.$helpers.openModal("ModalStatusCheckpointsView");
        return;
      }
    },
    loadModalNobordistCheckpoint(id) {
      if (id) {
        this.currentNbCheckpointId = id;

        if (!this.$route.query.nobordistCheckpointId) {
          this.$router.push({ query: { nobordistCheckpointId: id } });
        }

        this.$helpers.openModal("StatusModalNobordistCheckpointsView");
        return;
      }
    },
    getCheckpointName(checkpoint_id) {
      const checkpointFound = this.checkpoints.filter(
        (checkpoint) => checkpoint.id === checkpoint_id,
      );

      if (checkpointFound && checkpointFound[0]) {
        return checkpointFound[0].title;
      }

      return "-";
    },

    addSelectOptionsToFilter(key, options) {
      this.filterOptions.find(
        (filterOption) => filterOption.key === key,
      ).options = options;
    },
    loadCheckpoints() {
      this.checkpoints = this.$store.state["nobordist_checkpoints"].all_items;
      this.checkpointsOption = this.checkpoints.map((item) => ({
        value: item.id,
        text: `(${item.tracking_code}) ${item.title}`,
      }));

      this.addSelectOptionsToFilter("checkpoint_id", this.checkpointsOption);
    },
    initializeStatusCheckpoint(data) {
      if (data) {
        this.newstatusCheckpoint.id = data.id;
        this.newstatusCheckpoint.status_code = data.status_code;
        this.newstatusCheckpoint.tracking_function = data.tracking_function;
        this.newstatusCheckpoint.checkpoint_id = data.checkpoint_id;
        this.newstatusCheckpoint.created_at = data.created_at;
        this.newstatusCheckpoint.updated_at = data.updated_at;
      } else {
        this.newstatusCheckpoint.status_code = "";
        this.newstatusCheckpoint.tracking_function = "";
        this.newstatusCheckpoint.checkpoint_id = "";
      }
      this.errors = [];
    },
    addStatusCheckpoint() {
      statusCheckpointService
        .setStatusCheckpoint(this.newstatusCheckpoint)
        .then(() => {
          document.getElementById("addStatusCheckpointClose").click();
          this.loadItems();
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
          NProgress.done();
        });
    },
    uploadStatusCheckpoints() {
      statusCheckpointService
        .updateStatusCheckpoint(
          this.newstatusCheckpoint.id,
          this.newstatusCheckpoint,
        )
        .then(() => {
          document.getElementById("editStatusCheckpointClose").click();
          //$('#editStatusCheckpoint').modal('hide');
          this.loadItems();
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
          NProgress.done();
        });
    },
  },
  computed: {
    allFields() {
      return [
        {
          key: "status_code",
          label: this.$t("statusCheckpointsPage.fields.statusCode"),
        },
        {
          key: "tracking_function",
          label: this.$t("statusCheckpointsPage.fields.trackingFunction"),
        },
        {
          key: "checkpoint_name",
          label: this.$t("statusCheckpointsPage.fields.checkpoint"),
        },
        /* 
        {
          key: "actions",
          label: this.$t("actions"),
        },
        */
      ];
    },
    filterOptions() {
      return [
        { key: "id", label: "ID" },
        {
          key: "status_code",
          label: this.$t("statusCheckpointsPage.fields.statusCode"),
        },
        {
          key: "tracking_function",
          label: this.$t("statusCheckpointsPage.fields.trackingFunction"),
        },
        {
          key: "checkpoint_id",
          label: "Checkpoint",
          type: "select",
          options: [{ value: null, text: "Select" }],
        },
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
      ];
    },
  },
  watch: {},
};
</script>
