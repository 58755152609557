<template>
  <NbCard id="users" :title="$t('sellersViewPage.users')">
    <template #body>
      <NbTablev2
        tableOf="usersPage"
        :hasParentItens="true"
        ref="usersPageRef"
        :hiddeTableOptions="true"
        height="fit-content"
        :allFields="allFields"
        :fields="fields"
        @reloadFields="fields = $event"
        :startItems="users"
      >
        <template #cell(name)="data">
          <router-link
            :to="`/users_management/users?id=` + data.item.id"
            class="link-1"
          >
            {{ data.item.name }}
          </router-link>
        </template>
        <template #cell(is_blocked)="data">
          <div class="d-flex align-item-start">
            <NbBadge
              :type="data.item.is_blocked ? 'danger' : 'success'"
              :text="
                data.item.is_blocked
                  ? $t('sellersViewPage.blocked')
                  : $t('sellersViewPage.active')
              "
            />
          </div>
        </template>
      </NbTablev2>
    </template>
  </NbCard>
</template>

<script>
import NbCard from "@/components/cards/NbCard.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";

export default {
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  components: {
    NbCard,
    NbTablev2,
    NbBadge,
  },
  data() {
    return {
      fields: [],
    };
  },
  computed: {
    allFields() {
      return [
        {
          key: "name",
          label: this.$t("sellersViewPage.fields.fullName"),
        },
        {
          key: "email",
          label: this.$t("sellersViewPage.fields.email"),
        },
        { key: "is_blocked", label: this.$t("sellersViewPage.fields.status") },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
