export default {
  components: {
    nav: {
      Overview: "Overview",
      SearchHsCode: "Search HS Code",
      Archives: "Archives",
      Requests: "Requests",
      NobordistCheckpoints: "Nobordist Checkpoints",
      ReceivedCheckpoints: "Received Checkpoints",
      StatusCheckpoints: "Status Checkpoints",
      VolumeCheckpoints: "Volume Checkpoints",
      TrackingNotifications: "Tracking Notifications",
      Checkpoints: "Checkpoints",

      Platforms: "Platforms",

      Contracts: "Contracts",
      Deliverymethods: "Delivery Methods",
      PriceTablesDeliveryTime: "Price Tables & Delivery Time",
      ShipstationContracts: "Shipstation Contracts",

      SellerAccounts: "Seller Accounts",
      DefaultPlans: "Default Plans",
      Finances: "Finances",

      AlertLogs: "Alert Logs",
      NobordistLogs: "Nobordist Logs",
      BilLogs: "Bil Logs",
      ExternalLogs: "External Logs",
      TrackingRules: "Tracking Rules",
      RejectedOrdersLogs: "Rejected Orders Logs",
      GeneralEmails: "General Emails",
      Notifications: "Notifications",
      UserNotifications: "User Notifications",
      DelayedJobs: "Delayed Jobs",
      Logs: "Logs",

      VolumesReverse: "Volumes Reverse",
      Volumes: "Volumes",
      Orders: "Orders",
      Overpacks: "Overpacks",
      Masterboxes: "Masterboxes",
      Shipments: "Shipments",
      Flights: "Flights",
      Shipping: "Shipping",
      ShippingRules: "Shipping Rules",
      DutiesAndTaxesStatus: "Duties and Taxes Status",

      Users: "Users",
      Carriers: "Carriers",
      Sellers: "Sellers",
      Groups: "Groups",
      Permissions: "Permissions",
      Usersmanagement: "Users and sellers",
      Settings: "Settings",
      Accessibility: "Accessibility",
      PrintingSetting: "Printing Setting",
      Account: "Account",
      Downloads: "Downloads",
      SendNotifications: "Send notifications",
      FiscalPayments: "Fiscal payments",
    },
    searchData: {
      printing_setting: "Printing Setting",
      volumes: "Volumes",
      volumesReverse: "Volumes Reverse",
      orders: "Orders",
      overpacks: "Overpacks",
      masterboxes: "Masterboxes",
      shipments: "Shipments",
      flights: "Flights",
      shippingRules: "Shipping Rules",
      dutiesAndTaxesStatus: "Duties and taxes status",
      users: "Users",
      carriers: "Carriers",
      sellers: "Sellers",
      sellerAccounts: "Seller accounts",
      defaultPlans: "Default plans",
      contracts: "Contracts",
      deliveryMethods: "Delivery Methods",
      priceTableAndDeliveryTime: "Price table and delivery time",
      shipstationContracts: "Shipstation contracts",
      alertLogs: "Alert logs",
      nobordistLogs: "Nobordist logs",
      billLogs: "Bill logs",
      externalLogs: "External logs",
      trackingRules: "Tracking rules",
      rejectedOrdersLogs: "Rejected orders logs",
      generalEmails: "General emails",
      notifications: "Notifications",
      delayedJobs: "Delayed jobs",
      searchHsCode: "Search Hs Code",
      archives: "Archives",
      requests: "Requests",
      nobordistCheckpoints: "Nobordist checkpoints",
      receivedCheckpoints: "Received checkpoints",
      statusCheckpoints: "Status checkpoints",
      volumeCheckpoints: "Volume checkpoints",
      trackingNotifications: "Tracking notifications",
      checkpoints: "Checkpoints",
      userProfile: "My profile",
      printingSetting: "Printing settings",
      userNotifications: "User notifications",
      accesibility: "Accessibility",
      downloads: "Downloads",
      sendNotifications: "Send notifications",
      accessibility: "Accessibility",
      fiscalPayments: "Fiscal payments",
    },
    tableFilters: {
      title: "Filters",
      subTitle: "Select the values below to create a filter",
      addNewFilter: "Add new filter",
      clearFilters: "Clear Filters",
      filterBy: "Filter by: ",
      filteredBy: "Filtered by:",
      openFilterOptions: "Open Filter Options",
      clickToClearFilter: "Click to Clear All Filters",
      clickToFilter: "Click to Filter",
      trueText: "True",
      falseText: "False",
    },
    selectTableColumns: {
      title: "Select Table Column",
      button: "Table Columns",
      unSelectAll: "Unselect All Columns",
      selectAll: "Select All Columns",
      shownColumns: "Shown columns",
      hiddenColumns: "Hidden columns",
    },
    maxRows: {
      button: "Rows",
    },
    nbTable: {
      wereFound: "were found",
      all: "All",
      onThisPage: "on this page",
      isSelected: "is selected | selected",
      worksOnlyCurrentPage:
        "This filter only works for the {onThisPageItems}  items shown on this page of the table, not the all {totalItems} items. To do this, use the filter button",
      sortBtn:
        "Sort the items on this page of the table. (Does not sort all items found)",
      properties: "Properties",
      tableOptions: "Table Options",
      changeVisibilityReorderColumns: "Change visibility and reorder columns",
    },
    itensPerPage: "Items Per Page",
    pagination: {
      first: "First",
      prev: "Prev",
      next: "Next",
      last: "Last",
    },
    topBar: {
      shiftSide: "Shifts to SIDE nav-bar",
      shiftTop: "Shifts to TOP nav-bar",
      myProfile: "My Profile",
      myNotifications: "My Notifications",
      printingSetting: "Printing Setting",
      platformList: "Platform list",
      savingChanges: "Saving changes...",
      lastChangesSaved: "Last changes saved",
    },
    footer: {
      needHelp: "Need help",
      checkOurSupport: "Check out our guides or send us a message",
    },
  },
  locales: {
    ptBR: "Portuguese (Brazil)",
    en: "English",
    cn: "Chinese",
    es: "Spanish",
  },
  //words
  metrics: "Metrics",
  filterByPeriod: "Filter by period",
  until: "To",
  showMoreData: "Show more data",
  collapseData: "Collapse data",
  false: "false",
  erro: "Erro",
  noImageAdded: "No image added",
  clickOrDrag: "Click or drag your image here",
  seller: "Seller",
  user: "User",
  wouldYouLikeToRemoveSelectedItems:
    "Would you like to remove the selected items?",
  documentsAndLabels: "Documents and labels",
  downloadReport: "Download Report",
  generalData: "General data",
  suggestedActions: "Suggested actions",
  filename: "File Name",
  seeDetails: "See details",
  volumes: "Volumes",
  clickToCopy: "Click here to copy",
  copy: "Copy",
  clickToDownload: "Click to download",
  active: "Active",
  blocked: "Blocked",
  browseFilesUpload: "Browse your file to upload",
  selectcsvFileUpload: "Select a csv file to upload",
  downloadHere: "Download here",
  instructionsXLSfile: "the instructions XLS file",
  fileUploaded: "File Uploaded",
  blankTable: "a blank table",
  sureWantDelete: "Are you sure you want to delete this permanently?",
  sitePlaceholder: "https://site.com",
  dragDropFile: "Drag and drop or click here",
  clickHereUploadFile: "Click here to upload your file",
  uploadFromComputer: "Upload from your computer",
  uploadFromLinks: "Upload from link",
  selectPdfFileUpload: "Select a PDF file to upload",
  link: "Link",
  update: "Update",
  notFound: "Not found",
  generalInformation: "General Information",
  item: "Item",
  items: "Items",
  clear: "Clear",
  confirm: "Confirm",
  connect: "Connect",
  disConnect: "Disconnect",
  connected: "Connected",
  disConnected: "Disconnected",
  contactUs: "Contact Us",
  placeholderEmail: "example@email.com",
  asynchronousRequests: "Asynchronous Requests",
  loading: "Loading",
  login: "Login",
  logout: "Logout",
  name: "Name",
  fullName: "Full name",
  company: "Company",
  phoneNumber: "Phone Number",
  whatsNumber: "Whatsapp Number",
  phone: "Phone",
  cellphone: "Cell phone",
  optional: "Optional",
  email: "Email",
  platform: "Platform",
  select: "Select",
  selected: "Selected",
  notselected: "Not selected",
  selectAll: "Select All",
  clearSelected: "Clear Selected",
  selectColumns: "Select Columns",
  clearFilter: "Clear Filters",
  filters: "Filters",
  filter: "Filter",
  all: "All",
  anyCounties: "Any country",
  close: "Close",
  save: "Save",
  cancel: "Cancel",
  actions: "Actions",
  add: "add",
  delete: "Delete",
  selectAnOption: "Select an option",
  start: "Start",
  end: "End",
  yes: "Yes",
  no: "No",
  ok: "Ok",
  attention: "Attention",
  found: "found",
  support: "Support",
  edit: "Edit",
  password: "Password",
  hidePass: "Hide Password",
  showPass: "Show Password",
  changePass: "Change Password",
  remove: "Remove",
  search: "Search",
  upload: "Upload",
  download: "Download",
  beginningDate: "Beginning Date",
  startDate: "Start Date",
  endDate: "End Date",
  between: "Between",
  betweenDates: "Between Dates",
  registrationDate: "Registration Date",
  and: "And",
  selectOption: "Select an option",
  paymentDate: "Payment Date",
  new: "New",
  allowed: "Allowed",
  invalid: "Invalid",
  errorMessages: {
    isNotPDF: "File is not .pdf",
    tryAgain: "Please, check you information and try again",
    required: "Can't be blank",
    invalidCPF: "Invalid CPF",
    invalidCNPJ: "Invalid CNPJ",
    invalidDocument: "Invalid document",
    invalidZIPCode: "Invalid zip code",
    invalidPhone: "Invalid phone",
    invalidEmail: "Invalid E-mail",
    lowerMoney: "must be greater than 0.01",
    genericError: "Something unexcepted happened.",
    whiteSpace: "should not contain white space",
    minLength: "at least %{value} characters",
    minLowercase: "at least %{value} lowercase letter",
    minUppercase: "at least %{value} uppercase letter",
    minNumber: "at least %{value} number",
    minSpecialCharacter: "at least %{value} special character",
    betweenDigits: "%{min} or %{max} digits",
  },
  //pages
  homePage: {
    greeting: `Hello, {name}`,
    subtitle: "What would you like to do today?",
    averageShippingCost: "Average shipping cost",
    completedOrders: "Completed orders",
    closedFlights: "Closed flights",
    bestSellers: "Best sellers",
    seller: "Seller",
    ordersCreated: "Orders created",
    noSellerRanking:
      "Without enough information to create a ranking of sellers",
    links: {
      orders: {
        title: "See orders",
        description: "Check orders and download reports.",
        action: "Go to orders",
      },
      sellers: {
        title: "Sellers management",
        description: "Check users and sellers.",
        action: "Go to sellers",
      },
      masterboxes: {
        title: "View Masterboxes",
        description: "Check out the masterboxes.",
        action: "Go to masterboxes",
      },
      flights: {
        title: "Manage Flights",
        description: "Check and inform which flight each order is on.",
        action: "Go to flights",
      },
      contracts: {
        title: "Manage Contracts",
        description: "Access to manage contracts.",
        action: "Go to contracts",
      },
    },
  },
  loginPage: {
    forgotPassword: "Forgot your password",
    resetPassword: "Reset password email has sent",
    checkInbox: "Check your inbox",
    sendReset: "Send me reset password instructions",
    login: "Login",
    welcome: "Welcome",
    pleaseYourAcc: "Please enter your account",
    wantoToApp: "Want to access management system",
    goToApp: "Go to {val} app",
    checkOur: "Check out our",
    generalConditionsService: "Conditions of Service",
    resetPasswordSent: "Reset password email has sent! Check your inbox.",
    sendResetPasswordInstructions: "Send me reset password instructions",
    iAgree: "I agree",
    agreementText:
      "By cheking this box, you acknowledge that you have read and agree to our General Conditions of Service to be bound by this Agreement.",
    createYourAccount: "Create your account",
    fillInfoToCreateAcc: "Fill in the following fields to create your account",
    userData: "User data",
    pleaseYourData: "Please fill your data",
    companyData: "Company data",
    pleaseCompanyData: "Please fill company data",
    hasRegistration: "Already registered",
    accessMyAcc: "Access my account",
    needHelp: "Need help",
    talkWithOur: "Talk to one of our consultants",
    ifCheckOut: "If you prefer, check out our",
  },
  pintingSettingsPage: {
    title: "Printing Settings",
    subtitle:
      "Define here standard configurations to printing options of the labels",
    cN23Label: "CN23 Label",
    commercialInvoice: "Commercial Invoice",
    packingSlip: "Packing Slip",
    cevaBatteryShipperConfirmation: "Ceva Battery Shipper Confirmation",
  },
  userProfileEditPage: {
    title: "Update My Profile",
  },
  userProfilePage: {
    title: "My Account",
    subtitle: "Check and update, if necessary, your personal information.",
    userData: "User data",
    loginInformation: "Login information",
    loginInformationSubtitle:
      "If needed, update the data you use to access the platform.",
    checkYourData: "Check and edit your data, if necessary",
    editPass: "Edit Password",
    newPass: "New Password",
    currentPass: "Current Password",
    newPassConfirm: "Type new password to change",
    editEmail: "Edit Email",
    editProfile: "Edit Profile",
    newEmail: "New Email",
    checkEmail: "Please check your email format",
    sameEmail: "The two input emails are diferent, please check it",
    confirmCurrentPassword:
      "To perform this action, please type your current password",
  },
  overViewPage: {
    title: "Overview",
    getInfoCorreios: "Get information from Correios",
    putTrackingNumber: "Put Tracking Number",
    putDispatchNumberUnitCode: "Put Dispatch Number or Unit Code",
    manuallyUpdateCN38Number: "Manually update CN38 number",
    shipmentID: "Shipment ID",
    newCN38Number: "New CN38 Number",
    updateCN38: "Update CN38",
    resetDispatchNumbersCounter: "Reset Dispatch numbers counter",
    newCounterStart: "New counter start",
    reset: "Reset",
    removeOverpack: "Remove Overpack",
    testDelayedJob: "Test Delayed Job",
    test: "Test",
    generateRequestID: "Generate Request ID",
    updateCn38Number: "Update cn38 number",
    sureOverwrite: "You are going to overwrite the CN38 number of Shipment",
    definitiveAction: "This action is definitive. Are you willing to continue",
    sureResetCorreiosCounter:
      "You are going to reset Correios counter for dispatch numbers",
    sureRemoveOverpack: "Do you want to remove this overpack",
  },
  downloadsPage: {
    title: "Archive",
    subtitle: "All downloads are centralized on this page",
    errors: "Errors",
    found: "no download found | download | downloads",
    downloadReport: "Download report",
    showErrors: "Show errors",
    status: {
      failed: "failed",
      processing: "processing",
      done: "done",
      starting_upload: "starting upload",
      checking_csv_format: "checking CSV format",
      checking_columns: "checking columns",
      checking_equal_orders_volumes: "verifying equal order volumes",
      checking_cells: "checking cells",
      validation_success:
        "validations completed successfully! creating the table",
    },
    fields: {
      id: "ID",
      type: "Type",
      status: "Status",
      fileName: "Filename",
      creationDate: "Creation date",
      messages: "Messages",
    },
  },
  checkpointsPage: {
    title: "Checkpoint",
    subTitle: "Confira pontos importantes dos pedidos de seus clientes",
    subTitleView: "Check this checkpoint data",
    moreAboutCheckpoint: "Saiba mais sobre checkpoints",
    helpTextTitle:
      "Esta página é o seu guia confiável ao longo do processo de crossboarding. Aqui, você encontrará uma série de checkpoints essenciais que irão facilitar a sua jornada, garantindo que cada etapa seja concluída com sucesso",
    nobordistCheckpoints: "Nobordist Checkpoints",
    receivedCheckpoints: "Received Checkpoints",
    statusCheckpoints: "Status Checkpoints",
    volumeCheckpoints: "Volume Checkpoints",
    trackingNotifications: "Tracking Notifications",
  },
  nobordistCheckpointsPage: {
    title: "Nobordist Checkpoints",
    downloadCheckpointsCSV: "Download Checkpoints in CSV",
    downloadCheckpointsExcel: "Download Checkpoints in Excel",
    checkDownloadsAt: "Check the processing downloads at... downloads page",
    fields: {
      type: "Type",
      slug: "Slug",
      name: "Name",
      title: "Title",
      trackingCode: "Tracking Code",
    },
  },
  nobordistCheckpointsViewPage: {
    title: "Nobordist Checkpoint #",
    type: "Type",
    slug: "Slug",
    titleField: "Title",
    titlePt: "Title Pt",
    description: "description",
    descriptionPt: "Description Pt",
    name: "Name",
    trackingCode: "Tracking Code",
  },
  receivedCheckpointsPage: {
    title: "Received Checkpoints",
    fields: {
      trackingNumber: "Tracking Number",
      trackingFunction: "Tracking Function",
      statusCode: "Status Code",
      message: "Message",
      dateIso: "Date Iso ID",
      creationDate: "Creation Date",
    },
  },
  receivedCheckpointsViewPage: {
    title: "Received Checkpoint #",
    trackingNumber: "Tracking Number",
    trackingFunction: "Tracking Function",
    statusCode: "Status Code",
    dateIso: "Date Iso",
    date: "Date",
    sentNotification: "Sent Notification",
    message: "Message",
    fields: {
      receiverType: "Receiver Type",
      notificationID: "Nb Notification ID",
      volumeID: "Volume ID",
      creationDate: "Creation Date",
    },
  },
  sendNotificationsViewPage: {
    title: "Send Service #",
    receivedNotification: "Received Notification",
    receiverType: "Receiver Type",
    email: "E-mail",
    webhook: "Webhook",
    phone: "Phone",
    notificationID: "Nb Notification ID",
    volumeID: "Volume ID",
    sellerName: "Seller Name",
    trackingCode: "Tracking Code",
    titleField: "Title",
    createdDate: "Created Date",
    time: "Time",
    receivedCheckpointID: "Received Checkpoint ID",
    trackingNumber: "Tracking Number",
    trackingFunction: "Tracking Function",
    statusCode: "Status Code",
    dateIso: "Date Iso",
  },
  statusCheckpointsPage: {
    title: "Status Checkpoints",
    addStatusCheckpoint: "Add Status Checkpoint",
    addNewStatusCheckpoint: "Add New Status Checkpoint",
    uploadStatusCheckpoint: "Upload Status Checkpoint",
    checkpointID: "Checkpoint ID",
    fields: {
      statusCode: "Status Code",
      trackingFunction: "Tracking Function",
      checkpoint: "Checkpoint",
      creationDate: "Creation Date",
      edit: "Edit",
    },
  },
  statusCheckpointsPageView: {
    title: "Status Checkpoint",
  },
  trackingNotificationsPage: {
    title: "Tracking Notifications",
    fields: {
      notificationType: "Notification Type",
      volumeCheckpointID: "Volume Checkpoint ID",
      data: "Data",
      sent: "Sent",
    },
  },
  volumeCheckpointsPage: {
    title: "Volume Checkpoints",
    fields: {
      volumeID: "Volume ID",
      receivedCheckpointID: "Received Checkpoint ID",
      convertedNotifs: "Converted Notifs",
      checkpoint: "Checkpoint",
      DateISO: "Date ISO",
      message: "Message",
    },
  },
  volumeCheckpointsViewPage: {
    title: "Volume Checkpoint #",
    checkpoint: "Checkpoint",
    volumeData: "Volume Data #",
    volumes: "Volumes",
    message: "Message",
    attachment: "Attachment",
    volumeID: "Volume ID",
    receivedCheckpointID: "Received Checkpoint ID",
    ConvertedNotifs: "Converted Notifs",
    checkpointID: "Checkpoint ID",
    created: "Created",
    dateIso: "Date Iso",
    type: "Type",
    slug: "Slug",
    name: "name",
    description: "Description",
    trackingCode: "Tracking Code",
    titleField: "Title",
    namePT: "Name PT",
    descriptionPT: "Description PT",
    titlePT: "Title PT",
    hierarchyStatus: "Hierarchy Status",
    height: "Height",
    width: "Width",
    length: "Length",
    weight: "Weight",
    cubageFactor: "Cubage Factor",
    volumeType: "Volume Type",
    orderID: "Order ID",
    lastMileTrackingNumber: "Last Mile Tracking Number",
    orderReference: "Order Reference",
    fields: {
      name: "Name",
      description: "Description",
      sku: "Sku",
      valueWithTax: "Value With Tax",
      value: "Value",
      hsCode: "Hs Code",
      quantity: "Quantity",
      originCountry: "Origin Country",
      length: "Length",
      width: "Width",
      height: "Height",
      weight: "Weight",
    },
  },
  contractsPage: {
    logo: "Logo",
    title: "Contracts",
    importContract: "Import Contract from JSON",
    subTitle: "Check and manage contract's data",
    addContracts: "Add Contracts",
    updateValidityDate: "Update Validity Date",
    showAdditionalServices: "Show Additional Services",
    addNewContract: "Add new Contract",
    name: "Name",
    slug: "Slug",
    incoterm: "Incoterm",
    taxingType: "Taxing Type",
    maximumWeight: "Maximum Weight",
    cubageFactor: "Cubage Factor",
    maximumLength: "Maximum Length",
    maximumHeight: "Maximum Height",
    maximumWidth: "Maximum Width",
    maximumPriceLimit: "Maximum Price Limit",
    additionalServices: "Additional Services",
    externalID: "External ID",
    hSCodes: "HS Codes",
    triggerOption: "Trigger Option",
    triggerCondition: "Trigger Condition",
    primaryType: "Primary Type",
    primaryValue: "Primary Value",
    primaryVariable: "Primary Variable",
    secondaryVariable: "Secondary Variable",
    secondaryType: "Secondary Type",
    secondaryValue: "Secondary Value",
    selecttype: "Select type",
    addToServices: "Add to Services",
    cancelNewService: "Cancel New Service",
    newService: "New Service",
    allowMinimus: "Allow Minimus",
    botNotifications: "Dutypay Notifications",
    isOldFlow: "Is Old Flow",
    old: "Old",
    new: "New",
    mode: "Mode",
    airporttoDoor: "Airport to Door",
    automatizePayment: "Automatize Payment",
    needSupervisorApproval: "This action needs a supervisor's approval",
    chooseSendTo: "Please choose who you are going to send the request to",
    firstMileDM: "First Mile DM",
    freightForwarderDM: "Freight Forwarder DM",
    customClearenceDM: "Custom Clearence DM",
    lastMileDM: "Last Mile DM",
    priceTable: "Price Table",
    reverseDM: "Reverse DM",
    deliveryTime: "Delivery Time",
    destinationCountry: "Destination Country",
    originCountry: "Origin Country",
    fields: {
      name: "Name",
      slug: "Slug",
      deliveryMethods: "Delivery Methods",
      firstMileDeliveryMethod: "First Mile Delivery Method",
      freightForwarderDeliveryMethod: "Freight Forwarder Delivery Method",
      customClearenceDeliveryMethod: "Custom Clearence DeliveryMethod",
      lastMileDeliveryMethod: "Last Mile Delivery Method",
      reverseDeliveryMethod: "Reverse Delivery Method",
      priceTable: "Price Table",
      deliveryTime: "Delivery Time",
      priceLmit: "Price Limit",
      creationDate: "Creation Date",
      taxingType: "Taxing Type",
      incoterm: "Incoterm",
      allowsMinimus: "Allows Minimus",
      automaticPayment: "Automatic Payment",
      maximumWeight: "Maximum Weight",
      maximumWidth: "Maximum Width",
      maximumHeight: "Maximum Height",
      maximumLength: "Maximum Length",
      cubageFactor: "Cubage Factor",
      additionalServices: "Additional Services",
    },
  },
  contractsViewPage: {
    title: "Contract #",
    downloadContractSettings: "Download Contract Settings",
    subTitle: "Check and edit, if necessary, the carrier's data",
    nameDefinitions: "Name and Definitions",
    destinationandOrigin: "Destination and Origin Country",
    destinationandOriginSubtitle: "Define which countries the contract covers",
    separeteByCommas: "Separate each code with commas",
    descriptionName: "Descriptive name (appear in the seller's app)",
    weightMeasure: "Weight and Measure",
    termContract: "Term of the Contract",
    deliveryMethods: "Delivery Methods",
    deliveryMethod: "Delivery Method",
    deliveryMethodsSubtitle:
      "Register those responsible and the corresponding delivery method for each stage of the process",
    notFoundDM: "Didn't find your delivery method",
    registerDM: "Register a new delivery method",
    uniqueDM: "Unique delivery method",
    uniqueDMSubtitle:
      "This delivery method is responsible for the entire contract",
    pricesMeasuresCubage: "Weights, measures and cubage",
    pricesMeasuresCubageSubtitle:
      "Fill in the values according to the registered carriers and methods",
    cubageCalcHelper: "Calculate based on maximum dimensions and weight",
    priceDeliveryTime: "Prices Table and Delivery Time",
    priceDeliveryTimeSubtitle:
      "These tables determine the estimated cost and delivery time of freight for this contract",
    anyCountry: "Any country",
    priceTableDeliveryTime: "Price Table and Delivery times",
    pdf: "pdf",
    csv: "csv",
    name: "Name",
    ID: "ID",
    slug: "Slug",
    created: "Created",
    firstMileDeliveryMethod: "First Mile",
    freightForwarderDeliveryMethod: "Freight Forwarder",
    customClearenceDeliveryMethod: "Custom Clearence",
    lastMileDeliveryMethod: "Last Mile",
    allowsMinimus: "Allows Minimus",
    isOldFlow: "Is Old Flow",
    priceTable: "Price Table",
    obsolete: "Obsolete",
    taxingType: "Taxing Type",
    incoterm: "Incoterm",
    automatizedpayment: "Automatized payment",
    generatePaymentLink: "Generate Payment Link",
    reverseDeliveryMethod: "Reverse Delivery Method",
    deliveryTime: "Delivery Time",
    airporttoDoor: "Airport to Door",
    maximumWeight: "Maximum Weight",
    cubageFactor: "Cubage Factor",
    maximumPrice: "Maximum Price",
    maximumLength: "Maximum Length",
    maximumHeight: "Maximum Height",
    maximumWidth: "Maximum Width",
    externalID: "External ID",
    triggerOption: "Trigger Option",
    triggerCondition: "Trigger Condition",
    primaryVariable: "Primary Variable",
    primaryValue: "Primary Value",
    secondaryVariable: "Secondary Variable",
    secondaryValue: "Secondary Value",
    additionalServices: "Additional Services",
    addedServices: "Added Services",
    additionalServicesSubtitle: "Set some rules for this contract",
    additional: "Additional",
    hSCodes: "HS Codes",
    editContract: "Edit Contract",
    cloneContract: "Clone Contract",
    slugForNewContract: "Slug For New Contract",
    isObsolete: "Is Obsolete",
    primaryType: "Primary Type",
    secondaryType: "Secondary Type",
    selecttype: "Select type",
    addServices: "Add to Services",
    cancelNewService: "Cancel New Service",
    newService: "New Service",
    allowMinimus: "Allow Minimus",
    automatizedPayment: "Automatized Payment",
    airportToDoor: "Airport to Door",
    needsSupervisorApproval: "This action needs a supervisor's approval",
    chooseWhoSendRequest:
      "Please choose who you are going to send the request to",
    wantToMakeContractObsolete: "Do you want to make this contract obsolete",
    notBePossibleVisualize:
      "It will not be possible to visualize it or make changes",
    removeService: "Remove Service",
    firstMileDM: "First Mile DM",
    freightForwarderDM: "Freight Forwarder DM",
    customClearenceDM: "Custom Clearence DM",
    lastMileDM: "Last Mile DM",
    reverseDM: "Reverse DM",
    generalData: "General Data",
    generalDataSubtitle: "Fill in the details below",
    automatized: "Automatized",
    notAutomatized: "Not Automatized",
  },
  deliveryMethodsPage: {
    title: "Delivery Methods",
    subTitle: "Manage Delivery Metods or create new cooperations",
    addDeliveryMethod: "Add Delivery Method",
    cooperationTitle: "Title",
    cooperationList: "List of cooperations",
    cooperations: "Cooperations",
    addCooperations: "Add one more cooperations",
    eachCooperationUniqueTitle: "Each cooperation has an unique title",
    name: "Name",
    service: "Service",
    selectGroup: "Select group",
    type: "Type",
    nationalization: "Nationalization",
    trackingCredentials: "Tracking Credentials",
    addTracking: "Add Tracking",
    dispatchCredentials: "Dispatch Credentials",
    addDispatch: "Add Dispatch",
    key: "Key",
    value: "Value",
    carrier: "Carrier",
    cooperationSlug: "Cooperation Slug",
    fields: {
      name: "Name",
      service: "Service",
      type: "Type",
      carrier: "Carrier",
      carrierID: "Carrier ID",
      creationDate: "Creation Date",
      cooperationSlug: "Cooperation Slug",
    },
  },
  deliveryMethodsViewPage: {
    title: "Delivery Method #",
    editDeliveryMethod: "Edit Delivery Method",
    edit: "Edit",
    name: "Name",
    service: "Service",
    selectgroup: "Select group",
    type: "Type",
    carrier: "Carrier",
    selectcarrier: "Select carrier",
    nationalization: "Nationalization",
    trackingCredentials: "Tracking Credentials",
    key: "Key",
    value: "Value",
    addTracking: "Add Tracking",
    dispatchCredentials: "Dispatch Credentials",
    addDispatch: "Add Dispatch",
    owner: "Owner",
    ownerReference: "Owner Reference",
    cooperationSlug: "Cooperation Slug",
  },
  priceTablesPage: {
    title: "Price Tables and Delivery Times",
    subTitle: "Check and price tables and delivery times of your customers",
    helpTextTitle:
      "Create, delete, download delivery times and price tables of your costumers",
    titlePrice: "Prices Table",
    titleDelivery: "Delivery Times",
    importPricesTable: "Import Prices Table",
    importDeliveryTime: "Import Delivery Time",
    suretodelete: "Are you sure to delete",
    selectCsv: "Select a csv file to upload",
    fileUploaded: "File Uploaded",
    downloadHere: "Download here",
    ablankTable: "a blank table",
    import: "Import",
    name: "Name",
    removeOrders: "Remove Orders",
    shouldRemove1: "Do you really want to remove these",
    shouldRemove2: " orders? This action is irreversible",
    shouldRemove3: " This action is irreversible",
    alreadyInMasterbox:
      "Selected Orders are already linked to a masterbox and will not be removed",
    someInMasterbox:
      "These orders are already linked to a masterbox and will not be removed.",
    toBeRemoved:
      "remaining orders are available for removing. Are you sure you want to remove them? This action is irreversible",
    filesUpload: "Browse your files and upload your",
    needsSupervisorApproval: "This action needs a supervisor's approval",
    chooseToSendRequest:
      "Please choose who you are going to send the request to",
    fields: {
      name: "Name",
      actions: "Actions",
    },
  },
  shipstationContractsPage: {
    title: "Shipstation Contracts",
    addShipstationContract: "Add Shipstation Contract",
    shipstationContract: "Shipstation Contract",
    shipstationContractName: "Shipstation Contract Name",
    seller: "Seller",
    contract: "Contract",
    fields: {
      contract: "Contract",
      sellerID: "Seller ID",
      shipstationContractName: "Shipstation Contract Name",
      actions: "Actions",
    },
  },
  defaultPlansPage: {
    title: "Seller Account Plans",
    name: "Name",
    selectMultipleSellers: "Select multiple sellers",
    email: "Email",
    applySellers: "Apply to sellers",
    newPlan: "New Plan",
    components: {
      categoryForm: {
        chargeDay: "Charge Day",
        firstChargeDay: "First Charge Day",
        chargeFrequency: "Charge Frequency",
        limit: "Limit",
        fixedChargeDay: "Fixed Charge Day",
      },
      groupCategoryCards: {
        cost: "Cost",
        trackingStatus: "Tracking Status",
        sMSCost: "SMS Cost",
        whatsappCost: "Whatsapp Cost",
      },
    },
  },
  sellerAccountEventsPage: {
    title: "Seller Account Events",
    addCredit: "Add Credit",
    filterCategory: "Filter by category",
    limit: "Limit",
    balanceIs: "Balance is",
    nBxBelowLimit: "NBx below the limit",
    noLimit: "No limit",
    seller: "Seller",
    amount: "Amount",
    currentExchange: "Current Exchange",
    description: "Description",
    defaultPlans: "Default Plans",
    choosePlan: "Choose a Plan",
    generalSettings: "General Settings",
    subscriptionFee: "Subscription Fee",
    nBXvalueUSD: "NBX value in USD",
    limitTax: "Limit Tax",
    notifications: "Notifications",
    whatsappCost: "Whatsapp Cost",
    sMSCost: "SMS Cost",
    freight: "Freight",
    freightCost: "Freight Cost",
    settings: "Settings",
    chargeDay: "Charge Day",
    firstChargeDay: "First Charge Day",
    chargeFrequency: "Charge Frequency",
    fixedChargeDay: "Fixed Charge Day",
    trackingCode: "Tracking Code",
    updateSettings: "Update Settings",
    fields: {
      sellerID: "Seller ID",
      sellerName: "Seller Name",
      category: "Category",
      attachment: "Attachment",
      value: "Value",
      exchange: "Exchange",
      description: "Description",
      creationDate: "Creation Date",
      startValue: "Start Value",
      endValue: "End Value",
    },
    currentExchangeHelpText:
      "Current exchange rate: $1 is equal to R$ %{value}",
    howMuchDoYouWantAdd: "How much do you want to add?",
  },
  alertLogsPage: {
    title: "Logs Management",
    subTitle: "Check logs generated on the platform",
    helpTextTitle:
      "Messages generated or recorded by the system are located here",
    alertLogs: "Alert Logs",
    importValuesandStatuses: "Import Values and Statuses",
    updateStatus: "Update Status",
    downloadLogs: "Download Logs",
    updateStatusInfo:
      "If you just want to update statuses, please leave the 'tax_value' column empty (column name must be maintained)",
    new: "New",
    solved: "Solved",
    priority: "Priority",
    inProgress: "In Progress",
    waitingforPayment: "Waiting for Payment",
    sentAgain: "Sent Again",
    paymentDone: "Payment Done",
    pending: "Pending",
    paidExternally: "Paid Externally",
    needSellerAction: "Need Seller Action",
    status: "Status",
    comment: "Comment",
    checktheprocessingdownloads:
      "Check the processing downloads at... downloads page",
    sureWantSave: "Are you sure you want to save the changes",
    newTrackingNumbers: "New Tracking Numbers ",
    shouldbeCommaSeparated:
      "The new numbers should be comma separated and follow the sequence of Tracking Alert Logs selected: TAL_IDs:",
    unsaveddata: "Unsaved data",
    pleasesaveunsaveddata: "Please save unsaved data or cancel",
    okay: "Okay",
    name: "Name",
    browseFilesUpload: "Browse your file to upload",
    selectcsvFileUpload: "Select a csv file to upload",
    downloadHere: "Download here",
    instructionsXLSfile: "the instructions XLS file",
    fileUploaded: "File Uploaded",
    blankTable: "a blank table",
    fields: {
      volumeId: "Volume Id",
      trackingCode: "Tracking Code",
      sellerId: "Seller ID",
      seller: "Seller",
      message: "Message",
      attachment: "Attachment",
      lastMileTrackingNumber: "Last Mile Tracking Number",
      dateISO: "Date ISO",
      creationTime: "Creation time",
      title: "Title",
      rule: "Rule",
      type: "Type",
      status: "Status",
      comment: "Comment",
      dutiesPayment: "Duties Payment",
    },
  },
  alertLogsViewPage: {
    title: "Alert Log #",
    sellerChargedAmount: "Seller Charged Amount",
    exchange: "Exchange",
    incoterm: "Incoterm",
    status: "Status",
    date: "Date",
    dateIso: "Date Iso",
    orderID: "Order ID",
    orderNumber: "Order Number",
    volumeID: "Volume ID",
    sellerID: "Seller ID",
    taxID: "Tax ID",
    lastMileNumber: "Last Mile Number",
    productsValue: "Products Value",
    freightValue: "Freight Value",
    expectedTax: "Expected Tax",
    realTax: "Real Tax",
    valueR$: "Value R$",
    editableValues: "Editable values",
  },
  bilLogsPage: {
    title: "Bil Logs",
    fields: {
      mawb: "MAWB",
      house: "House",
      volumeID: "Volume ID",
      status: "Status",
      creationDate: "Creation Date",
    },
  },
  bilLogsViewPage: {
    title: "Bill Log #",
    mawb: "Mawb",
    house: "House",
    creationDate: "Creation Date",
    status: "Status",
    volumeID: "Volume ID",
    logs: "Logs",
  },
  delayedJobsPage: {
    title: "Delayed Jobs Logs",
    fields: {
      queue: "Queue",
      priority: "Priority",
      attempts: "Attempts",
      handler: "Handler",
      lastError: "Last Error",
      runat: "Run at",
      lockedat: "Locked at",
      failedat: "Failed at",
      lockedby: "Locked by",
    },
  },
  externalLogs: {
    title: "External Apis Logs",
    fields: {
      apiName: "API Name",
      function: "Function",
      userID: "User ID",
      response: "Response",
      creationDate: "Creation Date",
    },
  },
  externalViewLogs: {
    title: "External API Log #",
    apiName: "API Name",
    userName: "User Name",
    function: "Function",
    creationDate: "Creation Date",
    updateDate: "Update Date",
    params: "Params",
    response: "Response",
  },
  generalEmailsPage: {
    title: "General Emails",
    subTitle: "Check, read and create general emails",
    createGeneralEmails: "Create General Email",
    titleGeneral: "Title",
    sellersSelected: "sellers selected",
    body: "Body",
    destination: "Destination",
    destinationsSellers: "Destinations to Sellers",
    fields: {
      titleGeneral: "Title",
      Destination: "Destination",
      Body: "Body",
      Sent: "Sent",
    },
  },
  gGeneralEmailsViewPage: {
    title: "General E-mail #",
    iD: "ID",
    sent: "Sent",
    titleGeneral: "Title",
    destination: "Destination",
    body: "Body",
    editGeneralEmail: "Edit General Email",
    deleteGeneralEmail: "Delete General Email",
    sureWantDelete: "Are you sure you want to delete this General Email",
  },
  nobordistLogsPage: {
    title: "Nobordist Logs",
    fields: {
      action: "Action",
      actionType: "Action Type",
      userID: "User ID",
      logs: "Logs",
      creationDate: "Creation Date",
    },
  },
  nobordistLogsViewPage: {
    title: "Log #",
    action: "Action",
    userName: "User Name",
    creationDate: "Creation Date",
    updateDate: "Update Date",
    logs: "Logs",
  },
  notificationsPage: {
    title: "Send notifications",
    subtitle:
      "Read notifications template or create notifications to sellers or customers",
    newNotification: "New notification",
    settingYourNotification: "Setting your notification.",
    generalData: "General data",
    settingNotificationParams: "Set notification params",
    selectRecipientType: "Select the type recipient",
    to: "To",
    recipient: "Recipient",
    subject: "Subject",
    message: "Message",
    sendNow: "Send now",
    subjects: {
      finance: "Finance",
      insufficientBalance: "Insufficient balance",
      trackingIssue: "Tracking issue",
      newTicket: "New ticket",
    },
    seller: "Seller",
    user: "User",
    readed: "Read",
    seeMessage: "See message",
    notification: "Notification",
    seeSendedNotification: "View the message that was sent",
    sendedMessage: "Sended message",
    sendDate: "Send date",
    createNotification: "Create Notification",
    body: "Body",
    respondApprovalRequest: "Respond to approval request",
    notificationMessage: "Notification Message",
    contract: "Contract",
    name: "Name",
    file: "File",
    validityDate: "Validity Date",
    contracts: "Contracts",
    click: "Click",
    hERE: "HERE",
    seeDetailedData: "to see the detailed data",
    thisRequest: "This request",
    is: "is",
    hasBeen: "has been",
    supervisorCommented: "The supervisor commented",
    addCommentResponse: "You can add a comment to your response",
    approve: "Approve",
    deny: "Deny",
    by: "by",
    ChooseRecipientType: "Choose recipient type",
    Seller: "Seller",
    User: "User",
    Title: "Title",
    finance: "Finance",
    insufficientBalance: "Insufficient Balance",
    trackingIssue: "Tracking Issue",
    newTicket: "New Ticket",
    dataUpdate: "Data Update",
    contractPermission: "Contract Permission",
    contractUpdate: "Contract Update",
    priceTableUpdate: "Price Table Update",
    fields: {
      newRead: "New/Read",
      tType: "Type",
      recipient: "Recipient",
      title: "Title",
      body: "Body",
      status: "Status",
    },
    titleNotification: "Title",
    type: "Type",
    new: "New",
  },
  rejectedOrderPage: {
    title: "Rejected Orders",
    subTitle: "Logs of rejected orders",
    sellerName: "Seller Name",
    fields: {
      orderNumber: "Order Number",
      dateImport: "Date of import",
      orderInfo: "Order Info",
      sellerID: "Seller ID",
      error: "Error",
    },
  },
  rejectedOrderViewPage: {
    title: "Rejected Order Log #",
    seller: "Seller",
    creationDate: "Creation Date",
    orderInfo: "Order Info",
    erroInfo: "Erro Info",
  },
  trackingRulesPage: {
    title: "Tracking Rules",
    subTitle: "Manage tracking rules or create new rules",
    addTrackingRule: "Add Tracking Rule",
    addNewTrackingRule: "Add New Tracking Rule",
    trackingMode: "Tracking Mode",
    receivedCheckpoint: "Received Checkpoint",
    timeBetweenTwoCheckpoints: "Time Between Two Checkpoints ",
    name: "Name",
    contract: "Contract",
    incoterm: "Incoterm",
    any: "Any",
    startCheckpoint: "Start Checkpoint",
    endCheckpoint: "End Checkpoint",
    maximumDays: "Maximum Days",
    checkpoint: "Checkpoint",
    log: "Generate Logs",
    notifySeller: "Notify Seller",
    sellerNotification: "Seller Notification",
    notifyCustomer: "Notify Customer",
    notifyNobordist: "Notify Nobordist",
    webhook: "Use Webhook",
    method: "Method",
    pOST: "POST",
    pUT: "PUT",
    headers: "Headers",
    addHeaders: "Add Headers",
    key: "Key",
    value: "Value",
    seller: "Seller",
    titleRules: "Title",
    fields: {
      name: "Name",
      startCheckpoint: "Start Checkpoint",
      endCheckpoint: "End Checkpoint",
      incoterm: "Incoterm",
      seller: "Seller",
      contract: "Contract",
      daysLimit: "Days Limit",
      mode: "Mode",
      creationDate: "Creation Date",
    },
    allSellerNotificationTitles: {
      finance: "Finance",
      insufficientBalance: "Insufficient Balance",
      trackingIssue: "Tracking Issue",
    },
  },
  trackingRulesViewPage: {
    title: "Tracking Rule #",
    tracking: "Tracking",
    editTrackingRule: "Edit Tracking Rule",
    deleteTrackingRule: "Delete Tracking Rule",
    edit: "Edit",
    name: "Name",
    contract: "Contract",
    startCheckpoint: "Start Checkpoint",
    endCheckpoint: "End Checkpoint",
    maximumDays: "Maximum Days",
    log: "Log",
    notifySeller: "Notify Seller",
    notifyCustomer: "Notify Customer",
    notifyNobordist: "Notify Nobordist",
    webhook: "Webhook",
    method: "Method",
    pOST: "POST",
    pUT: "PUT",
    headers: "Headers",
    key: "Key",
    value: "Value",
    addHeaders: "Add Headers",
    sureWantDelete: "Are you sure you want to delete this tracking Rule",
    seller: "Seller",
    customer: "Customer",
    nobordist: "Nobordist",
    daysLimit: "Days Limit",
    trackingMode: "Tracking Mode",
    sellerMailTitle: "Seller Mail Title",
    sellerMailBody: "Seller Mail Body",
    customerMailTitle: "Customer Mail Title",
    customerMailBody: "Customer Mail Body",
    nobordistMailTitle: "Nobordist Mail Title",
    destination: "Destination",
    nobordistMailBody: "Nobordist Mail Body",
    notifyWebhook: "Notify Webhook",
    endpoint: "Endpoint",
    body: "Body",
  },
  userNotificationsPage: {
    title: "User Notifications",
    subTitle: "Read notifications sent to you",
    respondRequest: "Respond to approval request",
    notificationMessage: "Notification Message",
    name: "Name",
    file: "File",
    validityDate: "Validity Date",
    contracts: "Contracts",
    contract: "Contract",
    click: "Click",
    hERE: "HERE",
    seeDetailedData: "to see the detailed data",
    thisRequest: "This F",
    is: "is",
    hasBeen: "has been",
    by: "by",
    supervisorCommented: "The supervisor commented",
    addCommentResponse: "You can add a comment to your response",
    approve: "Approve",
    deny: "Deny",
    seller: "Seller",
    fields: {
      data: "Data",
      title: "Title",
      body: "Body",
    },
    filgerOptions: {
      title: "Title",
      seller: "Seller",
      body: "Body",
      new: "New",
    },
    allSellerNotificationTitles: {
      finance: "Finance",
      insufficientBalance: "Insufficient Balance",
      trackingIssue: "Tracking Issue",
    },
    sellerNotificationTitles: {
      finance: "Finance",
      insufficientBalance: "Insufficient Balance",
      trackingIssue: "Tracking Issue",
      newTicket: "New Ticket",
    },
    userNotificationTitles: {
      finance: "Finance",
      insufficientBalance: "Insufficient Balance",
      trackingIssue: "Tracking Issue",
      newTicket: "New Ticket",
      dataUpdate: "Data Update",
      contractPermission: "Contract Permission",
      contractUpdate: "Contract Update",
      priceTableUpdate: "Price Table Update",
    },
    seconds: "seconds",
    minuteAgo: "1 minute ago",
    minutes: "minutes",
    hourAgo: "1 hour ago",
    hours: "hours",
    yesterday: "yesterday",
    days: "days",
    lastWeek: "Last week",
    weeks: "weeks",
    lastMonth: "Last month",
    months: "months",
    lastYear: "Last year",
    years: "years",
  },
  myNotificationsListPage: {
    title: "Notifications",
    found: "no notification found | notification | notifications",
    filters: {
      isNew: "New or Read",
    },
    allNotifications: "All notifications",
  },
  platformsPage: {
    title: "Platforms",
    addPlatform: "Add Platform",
    AddNewPlatform: "Add new Platform",
    platformName: "Platform Name",
    correiosCredentials: "Correios Credentials",
    addDispatch: "Add Dispatch",
    key: "Key",
    value: "Value",
    fields: {
      name: "Name",
      actions: "Actions",
    },
  },
  flightsPage: {
    title: "Flights",
    subtitle: "Check and inform which flight each order is on.",
    nextFlights: "Upcoming flights",
    createNewFlight: "Create new flight",
    newFlight: "New flight",
    selectMasterboxes: "Select masterboxes",
    findAndSelectMasterboxes:
      "Search and select the masterboxes you want to add to your flight",
    typeMasterboxesReference: 'Type the Masterbox reference and press "Enter"',
    selectedMasterboxes: "Selected masterboxes",
    reference: "Reference",
    next: "Proceed",
    insertFlightData: "Enter flight details",
    pleaseInsertFlightData: "Please enter flight details to add masterboxes.",
    howWouldLikeToUploadMawb: "How would you like to upload the MAWB?",
    generalData: "General information",
    departureAndArrive: "Departure and arrival",
    taxes: "Fees",
    returnsToPreviousStep: "Return to previous step",
    createFlight: "Create flight",
    uploadFromMyComputer: "Upload from my computer",
    documentsAndLabels: "Documents and labels",
    editFlightData: "Edit flight data",
    suggestedActions: "Suggested actions",
    copyLabelCode: "Copy MAWB number",
    taxesAndOthers: "Fees and others",
    masterboxes: "Masterboxes",
    departure: "Match",
    stopover: "Scale",
    arrival: "Arrival",
    noStopOver: "Non-stop flight",
    closeManifestConfirm:
      "Do you want to close this manifest? You will no longer be able to update your content after this action.",
    closeManifest: "Close manifest",
    confirmDeparture: "Confirm departure",
    deleteFlight: "Delete flight",
    fields: {
      airline: "Airline",
      flightNumber: "Flight Number",
      flightLabel: "Flight Label",
      mawbNumber: "Mawb Number",
      greenLight: "Green Light",
      prealertsSent: "Prealerts Sent",
      departureAirport: "Departure Airport",
      arrivalAirport: "Arrival Airport",
      departureDate: "Departure Date",
      creationDate: "Creation Date",
      status: "Status",
    },
  },
  flightsViewPage: {
    title: "Flight #",
    mawbFileLink: "Mawb File Link",
    mawbLabel: "Mawb Label",
    updateFlight: "Update Flight",
    information: "Information",
    mawb: "Mawb",
    shipments: "Shipments",
    pages: "Pages",
    closeManifest: "Close Manifest",
    confirmDeparture: "Confirm Departure",
    wantRemoveFlight: "Do you want to remove this flight permanently",
    departureAirport: "Departure Airport",
    arrivalAirport: "Arrival Airport",
    stopoverAirport: "Stopover Airport",
    departureDate: "Departure Date",
    arrivalDate: "Arrival Date",
    departureTime: "Departure Time",
    arrivalTime: "Arrival Time",
    airline: "Airline",
    flightNumber: "Flight Number",
    subtotal: "Subtotal",
    chargesKilo: "Charges per Kilo",
    otherCharges: "Other Charges",
    mawbNumber: "Mawb Number",
    mawbFileFormat: "Mawb File Format",
    binary: "binary",
    link: "link",
    mawbFile: "Mawb File",
    copyReference_id: "click here to copy the reference_id",
    greenLight: "Green Light",
    prealertsSend: "Prealerts Send",
    date: "Date",
    downloadCurrent: "Download current MAWB",
    noMawbDocument: "There is currently no MAWB document for this flight",
  },
  masterboxesPage: {
    title: "Masterboxes",
    subtitle: "Filter and check boxes with volumes and orders.",
    createMasterbox: "Create masterbox",
    documentsAndLabels: "Documents and labels",
    suggestedActions: "Suggested actions",
    masterboxData: "Masterbox data",
    labels: "Labels",
    mbCarriers: "Carriers",
    reference: "Reference",
    shipmentID: "Shipment ID",
    shipmentSequenceNumber: "Shipment Sequence Number",
    date: "Date",
    cN35Label: "CN35 Label",
    volumes: "Volumes",
    dispatchID: "Dispatch ID",
    goToShipment: "View shipment",
    creationDate: "Creation date",
    weight: "Weight",
    cubedWeight: "Cubed Weight",
    lastMileTrackingNumber: "Last Mile Tracking Number",
    lastMileCarrierNumber: "Last Mile Carrier Number",
    totalItems: "Total Items",
    deleteMasterbox: "Remove Masterbox",
    confirmDeleteMasterbox: "Do you want to remove this masterbox permanently?",
    selectVolumes: "Select volumes",
    selectedVolumes: "Selected volumes",
    findAndSelectVolumes:
      "Browse and select volumes you want to add to the masterbox",
    typeVolumeReference: 'Type volume reference and press "Enter"',
    downloadMasterboxErrors: "Download CSV of Masterbox errors",
    commentDownloadMasterboxErrors:
      "* Only volumes with last_mile_tracking_number will be added to the csv",
  },
  masterboxesViewPage: {
    title: "Masterbox #",
    dispatchID: "Dispatch ID",
    reference: "reference",
    shipmentID: "Shipment ID",
    shipmentSequenceNumber: "Shipment Sequence Number",
    date: "Date",
    cN35Label: "CN35 Label",
    volumes: "Volumes",
    copyReference_id: "click here to copy the reference_id",
    fields: {
      weight: "Weight",
      cubedWeight: "Cubed Weight",
      lastMileTrackingNumber: "Last Mile Tracking Number",
      lastMileCarrierNumber: "Last Mile Carrier Number",
      totalItems: "Total Items",
    },
  },
  ordersPage: {
    title: "Orders Management",
    subtitle: "Filter and check orders, download reports and more.",
    importMabangOrders: "Import Mabang's Orders",
    importOrderCosts: "Import Order Costs",
    downloadOrders: "Download Orders",
    downloadOrdersCosts: "Download Orders Costs",
    updateFinancialStatus: "Update Financial Status",
    lastMileCarrierNotFound: "Last Mile Carrier Nº not found",
    new: "New",
    invoiced: "Invoiced",
    toInvoice: "To Invoice",
    canceled: "Canceled",
    notCharged: "Not Charged",
    checkDownloadsAt: "Check the processing downloads at... downloads page.",
    selectOption: "Please select an option",
    orderNo: "Order No.",
    fieldsCurrentOrderCosts: {
      externalID: "External ID",
      type: "Type",
      value: "Value",
      creationDate: "Creation Date",
    },
    financial_statuses: {
      new: "New",
      toInvoice: "To Invoice",
      invoiced: "Invoiced",
      canceled: "Canceled",
      notCharged: "Not Charged",
    },
    fields: {
      sellerID: "Seller ID",
      sellerName: "Seller's name",
      sellerTaxNumber: "Seller's tax number",
      contractID: "Contract ID",
      overpackID: "Overpack ID",
      customerName: "Customer Name",
      postalCode: "C. Postal Code",
      customerCity: "Customer City",
      customerState: "Customer State",
      incoterm: "Incoterm",
      orderNumber: "Order Number",
      salesChOrderN: "Sales Ch. Order Nº",
      productsValue: "Products Value",
      freightValue: "Freight Value",
      declaredValue: "Declared Value",
      dutiesTaxValue: "Duties Tax Value",
      salesTaxValue: "Sales Tax Value",
      taxValue: "Tax Value",
      totalValue: "Total Value",
      estimatedDutiesTaxValue: "Estimated Duties Tax Value",
      estimatedSalesTaxValue: "Estimated Sales Tax Value",
      estimatedTaxValue: "Estimated Tax Value",
      freightCost: "Freight Cost",
      estimatedFreightCost: "Estimated Freight Cost",
      taxCost: "Tax Cost",
      insuranceCost: "Insurance Cost",
      insuranceCoverage: "Insurance Coverage",
      dDPCost: "DDP Cost",
      totalGeneralCosts: "Total General Costs",
      trackingNumber: "Tracking Number",
      lastMileCarrierNumber: "Last Mile Carrier Number",
      isMini: "Is Mini?",
      financialStatus: "Financial Status",
      creationDate: "Creation Date",
      mSDS: "MSDS",
      label: "Label",
      firstMileLabel: "First Mile Label",
      shipConfirmation: "Ship Confirmation",
      isLanded: "Is Landed?",
    },
    removeOrdersCount: "Remove %{count} orders",
    removeOrders: "Remove orders",
    toBeRemoved:
      "The remaining %{count} orders are available for removal. Are you sure you want to remove them? This action is irreversible",
  },
  ordersViewPage: {
    title: "Order #",
    label: "Label",
    addCost: "Add Cost",
    commercialInvoice: "Commercial Invoice",
    trackingPage: "Tracking Page",
    packingSlip: "Packing Slip",
    cevaShippersConfirmation: "Ceva Shippers Confirmation",
    cUSTOMER: "CUSTOMER",
    relatedObjects: "Related objects",
    freight: "FREIGHT",
    editOrder: "Edit Order",
    generalInformations: "GENERAL INFORMATIONS",
    valuesSummary: "VALUES SUMMARY",
    generalCosts: "General Costs",
    volumes: "Volumes",
    costType: "Cost Type",
    selecttype: "Select type",
    value: "Value",
    name: "Name",
    documentType: "Document Type",
    documentNumber: "Document Number",
    address: "Address",
    addressNumber: "Address Number",
    addressComplement: "Address Complement",
    addressReference: "Address Reference",
    postalCode: "Postal Code",
    addressQuarter: "Address Quarter",
    city: "City",
    state: "State",
    country: "Country",
    phone: "Phone",
    email: "Email",
    mailbox: "Mailbox",
    phoneCountryCode: "Phone Country Code",
    salesChannelID: "Sales Channel ID",
    sellerIDcomp: "Seller ID comp",
    contractID: "Contract ID",
    overpackID: "Overpack ID",
    currency: "Currency",
    incoterm: "Incoterm",
    returnInsurance: "Return Insurance",
    insuranceCoverage: "Insurance Coverage",
    returnModality: "Return Modality",
    freightValue: "Freight Value",
    orderNumbe: "Order Numbe",
    createdDate: "Created Date",
    sellerName: "Seller's name",
    estimatedDeliveryDate: "Estimated Delivery Date",
    trackingNumber: "Tracking Number",
    isMini: "Is Mini",
    observation: "Observation",
    declaredValue: "Declared Value",
    taxValue: "Tax Value",
    total: "Total",
    remove: "Remove",
    wantToRemore:
      "Are you sure you want to delete this order? This operation is irreversible",
    estimatedTaxValue: "Estimated Tax Value",
    freightCost: "Freight Cost",
    volumesFields: {
      Weight: "Weight",
      cubedWeight: "Cubed Weight",
      lastMileTrackingNumber: "Last Mile Tracking Number",
      lastMileCarrierNumber: "Last Mile Carrier Number",
      productValue: "Product Value",
      freightValue: "Freight Value",
      estimatedFreightCost: "Estimated Freight Cost",
      taxValue: "Tax Value",
      totalItems: "Total Items",
      updatedWeight: "Updated Weight",
    },
    fieldsCurrentOrderCosts: {
      externalID: "External ID",
      type: "Type",
      value: "Value",
      creationDate: "Creation Date",
    },
    orderData: "Order data",
    customerData: "Customer data",
    shippingInfo: "Shipping information",
    reviewValues: "Review values",
    copyTrackingNumber: "Copy tracking number",
    showAllCustomerData: "Show all customer data",
    collapseCustomerData: "Colapse customer data",
    showAllOrderData: "Show all order data",
    collapseOrderData: "Collapse order data",
  },
  overpacksPage: {
    title: "Overpacks Management",
    updateFirstMileInformation: "Update First Mile Information",
    carriername: "Carrier name",
    trackingNumber: "Tracking number",
    updateInformation: "Update Information",
    updateTrackingStatus: "Update Tracking Status",
    updateOverpacks: "Update Overpacks",
    overpackSelected: "Overpack Selected",
    selectOneStatus: "Select One Status",
    statusSelected: "Status Selected",
    fields: {
      sentDate: "Sent Date",
      trackingNumber: "Tracking Number",
      masterboxID: "Masterbox ID",
      fMTrackingN: "FM Tracking N°",
      firstMileCompany: "First Mile Company",
      creationDate: "Creation Date",
    },
    trackingOptions: {
      forwardedlocalhub: "The shipment has been forwarded to a local hub",
      arrivedHubSorting: "The shipment has arrived at a hub or sorting center",
      clearanceCompleted: "The process of customs clearance is completed",
      shipmentArrivedDestinationCountry:
        "The international shipment has arrived at the destination country",
      handedCustomsCustomsClearance:
        "The shipment has been handed over to the customs for the process of customs clearance",
      customsClearanceCompleted:
        "The process of customs clearance is completed",
      arrivedHubSortingCenter:
        "The shipment has arrived at a hub or sorting center",
    },
  },
  overpacksViewPage: {
    title: "Overpack #",
    new: "New",
    ready: "Ready",
    dispatched: "Dispatched",
    orders: "Orders",
    orderFoundOverpack: "No order found for Overpack #",
    cN23Numbers: "CN23 Numbers",
    cN23foundforOverpack: "No CN23 found for Overpack #",
    masterboxID: "Masterbox ID",
    createdDate: "Created Date",
    sentDate: "Sent Date",
    trackingNumber: "Tracking Number",
    firstMileCarrierName: "First Mile Carrier Name",
    firstMileTrackingN: "First Mile Tracking Nº",
    fields: {
      orderNumber: "Order Number",
      trackingNumber: "Tracking Number",
      creationDate: "Creation Date",
      customerName: "Customer Name",
    },
  },
  shipmentsPage: {
    title: "Shipments",
    subtitle: "Filter and check shipments, download reports and more.",
    downloadShipments: "Download Shipments",
    actualWeight: "Actual Weight",
    checkProcessingDownloads:
      "Check the processing downloads at... downloads page",
    goToFlight: "Go to flight",
    fields: {
      cn38: "cn38",
      cn38Label: "cn38 Label",
      sSellerWeight: "Seller Weight",
      actualWeight: "Actual Weight",
      category: "Category",
      flightID: "Flight ID",
      creationDate: "Creation Date",
    },
    filterOptions: {
      sellerStartWeight: "Seller Start Weight",
      sellerEndWeight: "Seller End Weight",
      actualStartWeight: "Actual Start Weight",
      actualEndWeight: "Actual End Weight",
    },
  },
  shipmentsViewPage: {
    title: "Shipment #",
    category: "Category",
    flightID: "Flight ID",
    cn38: "cn38",
    date: "Date",
    cn38Label: "cn38 Label",
    masterboxes: "Masterboxes",
    pages: "Pages",
    fields: {
      reference: "Reference",
      dispatchID: "Dispatch ID",
      lastMileTrackingNumbers: "Last Mile Tracking Numbers",
    },
  },
  volumeReversePage: {
    title: "Volumes Reverse",
    subtitle: "Filter and check orders, download reports and more.",
    fields: {
      orderNumber: "Order Number",
      orderID: "Order ID",
      volumeID: "Volume ID",
      sellerID: "Seller ID",
      name: "Name",
      creationdate: "Creation date",
      reverseNumber: "Reverse Number",
      address: "Address",
      addressNumber: "Address Number",
      addressComplement: "Address Complement",
      state: "State",
      postalCode: "Postal Code",
      country: "Country",
      city: "City",
      customerName: "Customer Name",
      orderItemsName: "Order Items Name",
      orderItemsdescription: "Order Items description",
      orderItemsHSCode: "Order Items HS Code",

      startRegistrationDate: "Start Registration Date",
      endRegistrationDate: "End Registration Date",
    },
  },
  volumeReverseViewPage: {
    title: "Volume Reverse #",
    volumeReverse: "VOLUME REVERSE",
    order: "ORDER",
    volume: "VOLUME",
    customer: "CUSTOMER",
    items: "Items",
    reverseNumber: "Reverse Number",
    createdDate: "Created Date",
    name: "Name",
    address: "Address",
    addressNumber: "Address Number",
    addressComplement: "Address Complement",
    state: "State",
    postalCode: "Postal Code",
    country: "Country",
    city: "City",
    orderID: "Order ID",
    orderNumber: "Order Number",
    salesChannelOrderNumber: "Sales Channel Order Number",
    totalValue: "Total Value",
    trackingNumber: "Tracking Number",
    volumeID: "Volume ID",
    height: "Height",
    width: "Width",
    length: "Length",
    weight: "Weight",
    lastMileTrackingNumber: "Last Mile Tracking Number",
    documentType: "Document Type",
    documentNumber: "Document Number",
    addressReference: "Address Reference",
    addressQuarter: "Address Quarter",
    phone: "Phone",
    email: "Email",
    mailbox: "Mailbox",
    phoneCountryCode: "Phone Country Code",
    fields: {
      name: "Name",
      value: "Value",
      hsCode: "Hs Code",
      Quantity: "Quantity",
      originCountry: "Origin Country",
    },
  },
  volumesPage: {
    title: "Volumes",
    new: "New",
    invoiced: "Invoiced",
    toInvoice: "To Invoice",
    canceled: "Canceled",
    notCharged: "Not Charged",
    downloadMasterboxErrors: "Download CSV of Masterbox errors",
    commentDownloadMasterboxErrors:
      "* Only volumes with last_mile_tracking_number will be added to the csv",
    wantCreateMasterbox:
      "Do you want to create a masterbox with the volumes selected below?",
    volumes: "volumes",
    copyReference_id: "click here to copy the reference_id",
    downloadVolumes: "Download volumes",
    createMasterbox: "Create masterbox",
    fields: {
      weight: "Weight",
      height: "Height",
      Width: "Width",
      length: "Length",
      cubedWeight: "Cubed Weight",
      reference: "Reference",
      lastMileCarrierNumber: "Last Mile Carrier Number",
      lastMileTrackingNumber: "Last Mile Tracking Number",
      collectedIcms: "Collected ICMS",
      collectedIi: "Collected II",
      orderIsCommercialDestination: "Order Is Commercial Destination",
      isCommercialDestination: "Is Commercial Destination",
      orderSellerRfbCode: "Order Seller RFB Code",
      sellerRfbCode: "Seller RFB Code",
      orderSellerLegalName: "Order Seller Legal Name",
      sellerLegalName: "Seller Legal Name",
      cN23: "CN23",
      firstMileLabel: "First Mile Label",
      firstMileTrackingNumber: "First Mile Tracking Number",
      creationDate: "Creation Date",
      volumeProductsValue: "Volume Products Value",
      volumeFreightValue: "Volume Freight Value",
      volumeDeclaredValue: "Volume Declared Value",
      volumeDutiesTaxValue: "Volume Duties Tax Value",
      volumeSalesTaxValue: "Volume Sales Tax Value",
      volumeTaxValue: "Volume Tax Value",
      volumeTotalValue: "Volume Total Value",
      volumeEstimatedDutiesTaxValue: "Volume Estimated Duties Tax Value",
      volumeEstimatedSalesTaxValue: "Volume Estimated Sales Tax Value",
      volumeEstimatedTaxValue: "Volume Estimated Tax Value",
      volumeFreightCost: "Volume Freight Cost",
      volumeEstimatedFreightCost: "Volume Estimated Freight Cost",
      hSCode: "HS Code",
      description: "Description",
      currentStatus: "Current Status",
      updatedWeight: "Updated Weight",
      orderID: "Order ID",
      mSDS: "MSDS",
      sellerID: "Seller ID",
      firstMileN: "First Mile Nº",
      contractID: "Contract ID",
      customerName: "Customer Name",
      cPostalCode: "C. Postal Code",
      customerCity: "Customer City",
      customerState: "Customer State",
      customerAddress: "Customer Address",
      customerAddressNumber: "Customer Address Number",
      customerAddressComplement: "Customer Address Complement",
      customerAddressReference: "Customer Address Reference",
      customerAddressQuarter: "Customer Address Quarter",
      customerDocumentType: "Customer Document Type",
      customerDocumentNumber: "Customer Document Number",
      customerCountry: "Customer Country",
      customerEmail: "Customer Email",
      customerPhone: "Customer Phone",
      currency: "Currency",
      estimatedDeliveryDate: "Estimated Delivery Date",
      incoterm: "Incoterm",
      orderNumber: "Order Number",
      salesChannel: "Sales Channel",
      salesChOrderNumber: "Sales Ch. Order Number",
      totalValue: "Total Value",
      orderReference: "Order Reference",
      financialStatus: "Financial Status",
      orderProductsValue: "Order Products Value",
      orderFreightValue: "Order Freight Value",
      orderDeclaredValue: "Order Declared Value",
      orderDutiesTaxValue: "Order Duties Tax Value",
      orderSalesTaxValue: "Order Sales Tax Value",
      orderTaxValue: "Order Tax Value",
      orderTotalValue: "Order Total Value",
      orderEstimatedDutiesTaxValue: "Order Estimated Duties Tax Value",
      orderEstimatedSalesTaxValue: "Order Estimated Sales Tax Value",
      orderEstimatedTaxValue: "Order Estimated Tax Value",
      orderFreightCost: "Order Freight Cost",
      overpackID: "Overpack ID",
      overpackSentDate: "Overpack Sent Date",
      overpackReference: "Overpack Reference",
      fMTrackingNumber: "FM Tracking Number",
      firstMileCompany: "First Mile Company",
      masterboxID: "Masterbox ID",
      dispatchID: "Dispatch ID",
      cN35: "CN35",
      cN35Label: "CN35 Label",
      shipmentID: "Shipment ID",
      shipmentCategory: "Shipment Category",
      cN38: "CN38",
      cN38Label: "CN38 Label",
      flightID: "Flight ID",
      flightN: "Flight Nº",
      flightMAWB: "Flight MAWB",
      mAWBLabel: "MAWB Label",
      flightReference: "Flight Reference",
      airline: "Airline",
      greenLight: "Green Light",
      prealertsSent: "Prealerts Sent",
      departureAirport: "Departure Airport",
      arrivalAirport: "Arrival Airport",
      departureStartDate: "Departure Start Date",
    },
    filterOptions: {
      volumeStartDate: "Volume Start Date",
      volumeEndDate: "Volume End Date",
      volumeID: "Volume ID",
      reference: "Reference",
      lastMileTrackingNumber: "Last Mile Tracking Number",
      lmtnPopover:
        "Accepts values separated by: commas, semicolons, spaces, or line breaks",
      withoutLastMileTrackingNumber: "Without Last Mile Tracking Number",
      lastMileCarrierNumber: "Last Mile Carrier Number",
      estimatedFreightCost: "Estimated Freight Cost",
      orderStartDate: "Order Start Date",
      orderEndDate: "Order End Date",
      orderID: "Order ID",
      contractID: "Contract ID",
      sellerID: "Seller ID",
      freightCost: "Freight Cost",
      firstMileN: "First Mile Nº",
      lastMileN: "Last Mile Nº",
      orderNumber: "Order Number",
      salesChannel: "Sales Channel",
      salesChOrderNumber: "Sales Ch. Order Number",
      orderReference: "Order Reference",
      incoterm: "Incoterm",
      customerName: "Customer Name",
      customerAddress: "Customer Address",
      customerAddressNumber: "Customer Address Number",
      customerAddressComplement: "Customer Address Complement",
      customerAddressReference: "Customer Address Reference",
      customerAddressQuarter: "Customer Address Quarter",
      customerDocumentType: "Customer Document Type",
      customerDocumentNumber: "Customer Document Number",
      customerCountry: "Customer Country",
      customerEmail: "Customer Email",
      customerPhone: "Customer Phone",
      financialStatus: "Financial Status",
      overpackStartDate: "Overpack Start Date",
      overpackEndDate: "Overpack End Date",
      overpackStartSendDate: "Overpack Start Send Date",
      overpackEndSendDate: "Overpack End Send Date",
      overpackID: "Overpack ID",
      overpackReference: "Overpack Reference",
      bagNumber: "Bag Number",
      firstMileTrackingN: "First Mile Tracking Nº",
      firstMileCarrierName: "First Mile Carrier Name",
      masterboxStartDate: "Masterbox Start Date",
      masterboxEndDate: "Masterbox End Date",
      masterboxID: "Masterbox ID",
      cn35: "cn35",
      dispatchID: "Dispatch ID",
      shipmentStartDate: "Shipment Start Date",
      shipmentEndDate: "Shipment End Date",
      shipmentID: "Shipment ID",
      cN38: "CN38",
      shipmentCategory: "Shipment Category",
      flightStartDate: "Flight Start Date",
      flightEndDate: "Flight End Date",
      flightID: "Flight ID",
      flightMAWB: "Flight MAWB",
      flightN: "Flight Nº",
      flightreference: "Flight reference",
      airline: "Airline",
      greenLight: "Green Light",
      departureAirport: "Departure Airport",
      arrivalAirport: "Arrival Airport",
      departureStartDate: "Departure Start Date",
      departureEndDate: "Departure End Date",
      orderDate: "Order Date",
      shipmentDate: "Shipment Date",
      flightDate: "Flight Date",
      flightDepartureDate: "Flight Departure Date",
      masterboxDate: "Masterbox Date",
      overpackDate: "Overpack Date",
      overpackSendDate: "Overpack Send Date",
    },
    downloadFields: {
      weight: "Weight",
      height: "Height",
      width: "Width",
      length: "Length",
      reference: "Reference",
      lastMileTrackingNumber: "Last Mile Tracking Number",
      creationDate: "Creation Date",
      volumeProductsValue: "Volume Products Value",
      volumeFreightValue: "Volume Freight Value",
      volumeDeclaredValue: "Volume Declared Value",
      volumeDutiesTaxValue: "Volume Duties Tax Value",
      volumeSalesTaxValue: "Volume Sales Tax Value",
      volumeTaxValue: "Volume Tax Value",
      volumeTotalValue: "Volume Total Value",
      volumeEstimatedDutiesTaxValue: "Volume Estimated Duties Tax Value",
      volumeEstimatedSalesTaxValue: "Volume Estimated Sales Tax Value",
      volumeEstimatedTaxValue: "Volume Estimated Tax Value",
      volumeFreightCost: "Volume Freight Cost",
      volumeEstimatedFreightCost: "Volume Estimated Freight Cost",
      hSCode: "HS Code",
      description: "Description",
      currentStatus: "Current Status",
      orderID: "Order ID",
      sellerID: "Seller ID",
      contractID: "Contract ID",
      customerName: "Customer Name",
      cPostalCode: "C. Postal Code",
      customerCity: "Customer City",
      customerState: "Customer State",
      customerAddress: "Customer Address",
      customerAddressNumber: "Customer Address Number",
      customerAddressComplement: "Customer Address Complement",
      customerAddressReference: "Customer Address Reference",
      customerAddressQuarter: "Customer Address Quarter",
      customerDocumentType: "Customer Document Type",
      customerDocumentNumber: "Customer Document Number",
      customerCountry: "Customer Country",
      customerEmail: "Customer Email",
      customerPhone: "Customer Phone",
      currency: "Currency",
      freightCost: "Freight Cost",
      freigtValue: "Freigt Value",
      estimatedDeliveryDate: "Estimated Delivery Date",
      incoterm: "Incoterm",
      orderNumber: "Order Number",
      salesChannel: "Sales Channel",
      salesChOrderNumber: "Sales Ch. Order Number",
      totalValue: "Total Value",
      orderReference: "Order Reference",
      financialStatus: "Financial Status",
      overpackID: "Overpack ID",
      overpackSentDate: "Overpack Sent Date",
      overpackReference: "Overpack Reference",
      fMTrackingNumber: "FM Tracking Number",
      firstMileCompany: "First Mile Company",
      masterboxID: "Masterbox ID",
      dispatchID: "Dispatch ID",
      cN35: "CN35",
      shipmentID: "Shipment ID",
      shipmentCategory: "Shipment Category",
      cN38: "CN38",
      flightID: "Flight ID",
      flightN: "Flight Nº",
      mAWBLabel: "MAWB Label",
      flightReference: "Flight Reference",
      airline: "Airline",
      greenLight: "Green Light",
      prealertsSent: "Prealerts Sent",
      departureAirport: "Departure Airport",
      arrivalAirport: "Arrival Airport",
      departureStartDate: "Departure Start Date",
    },
    financialStatuses: {
      nNew: "New",
      toInvoice: "To Invoice",
      invoiced: "Invoiced",
      canceled: "Canceled",
      notCharged: "Not Charged",
    },
  },
  volumesViewPage: {
    copyReference: "Copy reference",
    goToOrder: "Go to order",
    summaryValues: "Summary of values",
    trackingHistory: "Tracking history",
    editLastTrackingNumber: "Edit Last Mile Tracking Number",
    warningOnUpdateLastMileTrackingNumber:
      "Attention! This action cannot be undone.",
    title: "Volume #",
    label: "Label",
    cubed_weight: "Cubed Weight",
    packingSlip: "Packing Slip",
    commercialInvoice: "Commercial Invoice",
    valuesSummary: "VALUES SUMMARY",
    updatedWeight: "Updated Weight",
    items: "Items",
    trackinghistory: "Tracking history",
    pages: "Pages",
    item: "Item",
    height: "Height",
    heightbycurrier: "Height by currier",
    width: "Width",
    widthbycurrier: "Width by currier",
    weight: "Weight",
    weightbycurrier: "Weight by currier",
    length: "Length",
    lengthbycurrier: "Length by currier",
    orderID: "Order ID",
    creationDate: "Creation Date",
    currentStatus: "Current Status",
    reference: "Reference",
    lastMileCarrierNumber: "Last Mile Carrier Number",
    lastMileTrackingNumber: "Last Mile Tracking Number",
    declaredValue: "Declared Value",
    taxValue: "Tax Value",
    total: "Total",
    estimatedTaxValue: "Estimated Tax Value",
    freightCost: "Freight Cost",
    estimatedFreightCost: "Estimated Freight Cost",
    name: "Name",
    value: "Value",
    hsCode: "Hs Code",
    quantity: "Quantity",
    originCountry: "Origin Country",
    decsription: "Decsription",
    valueWithTax: "Value With Tax",
    fields: {
      name: "Name",
      quantity: "Quantity",
      hsCode: "Hs Code",
      originCountry: "Origin Country",
      productValue: "Product Value",
      freightValue: "Freight Value",
      taxValue: "Tax Value",
    },
    checkpointFields: {
      title: "Title",
      statusCode: "Status Code",
      dateISO: "Date ISO",
      message: "Message",
      trackingFunction: "Tracking Function",
    },
    dirNumber: "DIR number",
    seePayments: "See payments",
  },
  carriersPage: {
    title: "Carrier Management",
    subtitle:
      "Add and manage your carriers and their respective delivery methods.",
    legalName: "Legal Name",
    rfbCode: "RFB Code",
    country: "Country",
    carrierIsSeller: "This carrier is also a seller",
    sellerReminder: "Don't forget to change the seller information",
    importCarrier: "Import Carrier from JSON",
    downloadCarrierSettings:
      "Download Carrier, Delivery Methods and Users Settings",
    addNewCarrier: "Add New Carrier",
    addCarrier: "Add Carrier",
    sellers: "Sellers",
    sellerSelect: "Fill with Seller data",
    allowed: "Allowed",
    blocked: "Blocked",
    addnewCarrier: "Add New Carrier",
    editCarrier: "Edit Carrier",
    name: "Name",
    address: "Address",
    addressnumber: "Address Number",
    zipcode: "Zip Code",
    state: "State",
    city: "City",
    phone: "Phone",
    site: "Website",
    email: "Email",
    taxnumber: "Tax Identification Number",
    namereference: "Reference Name",
    slug: "Slug",
    trackstatusfunction: "Status Tracking Function",
    carrierCode: "Carrier Code",
    addressComplement: "Address Complement",
    airportHub: "Airport Hub",
    fields: {
      name: "Name",
      slug: "Slug",
      city: "City",
      country: "Country",
      email: "Email",
      carrierCode: "Carrier Code",
      trackFunction: "Tracking Function",
      creationDate: "Creation Date",
      isSeller: "Is Seller?",
    },
    generalData: "General Data",
    addCarrierDetails: "Add carrier details",
    carrierAddress: "Carrier address",
    addCarrierOriginAddress: "Add carrier origin address",
    newCarrier: "New carrier",
    addDeliveryMethod: "Add delivery method",
    noDeliveryMethodFound: "No delivery methods found",
    noUserFound: "No users found",
    deliveryMethods: "Delivery methods",
    users: "Users",
    carriers: "Carriers",
    deliveryMethodFields: {
      name: "Added Methods",
      type: "Type",
      service: "Service",
      originCountry: "Origin Country",
      destinationCountry: "Destination Country",
    },
    userFields: {
      name: "Name",
      email: "Email",
      groupName: "Group Name",
      status: "Status",
    },
    seeMethod: "See Method",
    seeUser: "See User",
  },
  carriersViewPage: {
    title: "Carrier #",
    deliverymethods: "Delivery methods",
    nodeliverymethodfoundfor: "No delivery method found for",
    users: "Users",
    nouserfoundfor: "No user found for",
    editCarrier: "Edit Carrier",
    updateAirportHub: "Update Airport Hub",
    airportHub: "Airport Hub",
    name: "Name",
    address: "Address",
    addressnumber: "Address number",
    addresscomplement: "Address complement",
    zipcode: "Zipcode",
    state: "State",
    city: "City",
    phone: "Phone",
    site: "Site",
    email: "E-mail",
    taxnumber: "Tax number",
    namereference: "Name reference",
    slug: "Slug",
    trackstatusfunction: "Track status function",
    carrierCode: "Carrier Code",
    country: "Country",
    taxNumber: "Tax Number",
    trackingfunction: "Tracking function",
    platform: "Platform",
    subsidiary: "Subsidiary",
    deliveryCenter: "Delivery Center",
    createdDate: "Created Date",
    activityTypes: "Activity Types",
    deliveryMethodFields: {
      name: "Name",
      type: "Type",
      service: "Service",
      originCountry: "Origin country",
      destinationCountry: "Destination country",
    },
    userFields: {
      name: "Name",
      email: "E-mail",
      groupName: "Group name",
    },
    seller: "Seller",
    thisCarrierIsSeller: "This carrier is seller",
  },
  groupsPage: {
    title: "Groups Management",
    fields: {
      name: "Name",
    },
  },
  groupsViewPage: {
    title: "Group #",
    permissions: "Permissions",
  },
  permissionsPage: {
    title: "Permissions",
    fields: {
      name: "Name",
      description: "Description",
      groupsName: "Groups Name",
    },
  },
  permissionsViewPage: {
    title: "Permission #",
    name: "Name",
    description: "Description",
  },
  saldoViewPage: {
    currentBalance: "Current Balance",
    fields: {
      fullName: "Full name",
      email: "Email",
      status: "Status",
    },
  },
  sellersPage: {
    title: "Sellers",
    carriers: "Carriers",
    carrierSelect: "Fill with Carrier data",
    addNewSeller: "Add New Seller",
    importSeller: "Import Seller from JSON",
    downloadSellers: "Download Sellers",
    processingDownloads: "Check the processing downloads at... downloads page",
    addseller: "Add seller",
    address: "Address",
    addressnumber: "Address number",
    addresscomplement: "Address complement",
    city: "City",
    state: "State",
    postalcode: "Postal code",
    name: "Name",
    phone: "Phone",
    email: "Email",
    site: "Site",
    taxnumber: "Tax number",
    legalName: "Legal Name",
    rfbCode: "RFB Code",
    signature: "Signature",
    billingEmail: "Billing Email",
    responsableContact: "Responsable Contact",
    allowedcontracts: "Allowed contracts",
    contractOperations: "Contract operations",
    issubsidiary: "Is subsidiary",
    isdistributioncenter: "Is distribution center",
    ismultiple: "Is multiple",
    country: "Country",
    fields: {
      name: "Name",
      email: "Email",
      city: "City",
      ismultiple: "Is multiple",
      currentBalance: "Current Balance",
      country: "Country",
      site: "Site",
      billingEmail: "Billing Email",
      responsableContact: "Responsable Contact",
      creationDate: "Creation Date",
      isCarrier: "Is Carrier?",
    },
  },
  sellersViewPage: {
    chooseSendRequest:
      "Please choose who you are going to send the request to:",
    downloadSellerSettings: "Download Seller and Users Settings",
    website: "Website",
    sellerIsCarrier: "This seller is also a carrier",
    carrierReminder: "Don't forget to change the carrier information",
    taxNumber: "Tax number",
    legalName: "Legal Name",
    rfbCode: "RFB Code",
    signature: "Signature",
    isSubsidiary: "Is subsidiary",
    isDistributionCenter: "Is distribution center",
    sellerID: "Seller ID",
    currentBalance: "Current Balance",
    seller: "Seller",
    name: "Name",
    email: "Email",
    phone: "Phone",
    ismultiple: "Is multiple",
    allowedContracts: "Allowed contracts",
    contractOperations: "Contract operations",
    noContractFound: "No contract found",
    creationDate: "Creation Date",
    contractLink: "Contract Link",
    notFound: "Not found",
    billingEmail: "Billing Email",
    responsableContact: "Responsable Contact",
    platform: "Platform",
    address: "Address",
    country: "Country",
    postalCode: "Postal code",
    state: "State",
    devolutionAddress: "Devolution Address",
    addressNumber: "Address Number",
    addressQuarter: "Address Quarter",
    addressComplement: "Address Complement",
    addressReference: "Address Reference",
    city: "City",
    devolutionEmail: "Devolution Email",
    useSellerDevolutionAddress: "Use seller devolution address",
    users: "Users ",
    active: "Active",
    blocked: "Blocked",
    clear: "Clear",
    noUserFoundFor: "No user found for",
    includeLogo: "Include logo",
    wantAllowContract: "Do you want to allow contract",
    forSeller: "for seller",
    needsSupervisorApproval: "This action needs a supervisor's approval",
    wantForbidContract: "Do you want to forbid contract",
    fields: {
      fullName: "Full name",
      email: "Email",
      status: "Status",
    },
    carrier: "Carrier",
    thisSellerIsCarrier: "This seller is carrier",
  },
  usersPage: {
    title: "Users",
    subtitle: "Check and add new users to the system",
    personalData: "Personal data",
    seller: "Seller",
    carrier: "Carrier",
    downloadUsers: "Download report",
    allowed: "Allowed",
    blocked: "Blocked",
    active: "Active",
    denerateAPIToken: "Generate API Token",

    processingDownloads: "Check the processing downloads at... downloads page",
    allUsers: "All users",
    addNewUser: "Add New User",
    name: "Name",
    email: "Email",
    phone: "Phone",
    whatsappNumber: "Whatsapp number",
    profile: "Profile",
    group: "Group",
    selectGroup: "Select group",
    aPIToken: "API Token",
    permissionsAndAccess: "Permissions and access",
    userStatus: "User status",
    editUser: "Edit user",
    fields: {
      name: "Name",
      carrierID: "Carrier",
      sellerID: "Seller",
      company: "Company",
      status: "Status",
      email: "Email",
      profile: "Profile",
      group: "Group",
      creationDate: "Creation Date",
      aPIToken: "API Token",
    },
    insertUserData: "Insert the data of the user you want to create.",
    archive: "Archive",
    archiveUser: "Archive user?",
    confirmArchiveUser:
      "Are you sure you want to archive the user? This can't be undone.",
    yeahArchiveUser: "Yes, archive user",
  },
  usersViewPage: {
    title: "User Profile",
    blocked: "Blocked",
    allowed: "Allowed",
    blockUser: "Block this user",
    unblockUser: "Unblock this user",
    archiveUser: "Archive this user",
    unarchiveUser: "Unarchive this user",
    company: "Company",
    editUserProfile: "Edit User Profile",
    name: "Name",
    email: "Email",
    phone: "Phone",
    whatsappNumber: "Whatsapp number",
    group: "Group",
    selectgroup: "Select group",
    profile: "Profile",
    seller: "Seller",
    carrier: "Carrier",
    phoneNumber: "Phone Number",
    createdDate: "Created Date",
    sellerID: "Seller ID",
    carrierID: "Carrier ID",
    platform: "Platform",
  },
  dutiesAndTaxesStatusPage: {
    title: "Duties and Taxes Status",
    subTitle: "Check or download duties and taxes",
    dutiesTaxes: "Duties and Taxes",
    importValuesandStatuses: "Import Values and Statuses",
    updateStatus: "Update Status",
    download: "Download",
    new: "New",
    solved: "Solved",
    priority: "Priority",
    inProgress: "In Progress",
    waitingforPayment: "Waiting for Payment",
    sentAgain: "Sent Again",
    paymentDone: "Payment Done",
    pending: "Pending",
    paidExternally: "Paid Externally",
    needSellerAction: "Need Seller Action",
    status: "Status",
    comment: "Comment",
    checktheprocessingdownloads:
      "Check the processing downloads at... downloads page",
    sureWantSave: "Are you sure you want to save the changes",
    newTrackingNumbers: "New Tracking Numbers ",
    shouldbeCommaSeparated:
      "The new numbers should be comma separated and follow the sequence of Tracking Alert Logs selected: TAL_IDs:",
    unsaveddata: "Unsaved data",
    pleasesaveunsaveddata: "Please save unsaved data or cancel",
    okay: "Okay",
    name: "Name",
    browseFilesUpload: "Browse your files and upload your file",
    selectcsvFileUpload: "Select a csv file to upload",
    downloadHere: "Download here",
    instructionsXLSfile: "the instructions XLS file",
    fileUploaded: "File Uploaded",
    blankTable: "a blank table",
    fields: {
      id: "NB Internal Payment Reference",
      sellerName: "Seller Name",
      sellerId: "Seller ID",

      orderNumber: "Order Number",
      orderCreatedAt: "Order Creation Date",
      orderCreatedAtGte: "Start Order Creation Date",
      orderCreatedAtLte: "End Order Creation Date",

      volumeId: "Volume ID",
      volumeLastMileTrackingNumber: "Last Mile Tracking Number",
      orderEstimatedTaxValue: "Estimated Total Duties and Taxes",
      orderEstimatedTaxValueGte: "Start Estimated Total Duties and Taxes",
      orderEstimatedTaxValueLte: "End Estimated Total Duties and Taxes",
      orderEstimatedDutiesTaxValue: "Estimated Duties II",
      orderEstimatedDutiesTaxValueGte: "Start Estimated Duties",
      orderEstimatedDutiesTaxValueLte: "End Estimated Duties",
      orderEstimatedSalesTaxValue: "Estimated Taxes ICMS",
      orderEstimatedSalesTaxValueGte: "Start Estimated Taxes ICMS",
      orderEstimatedSalesTaxValueLte: "End Estimated Taxes ICMS",

      currentStatusId: "Tracking",
      currentStatusTitle: "Tracking Status",
      currentStatusTrackingCode: "Tracking Code",
      currentStatusTrackingCodeGte: "Start Tracking Code",
      currentStatusTrackingCodeLte: "End Tracking Code",

      createdAt: "Customs Start Date",
      createdAtGte: "Start Customs Start Date",
      createdAtLte: "End Customs Start Date",

      statusPaymentDoneAt: "Payment Date",
      statusPaymentDoneAtGte: "Start Payment Date",
      statusPaymentDoneAtLte: "End Payment Date",

      status: "Payment Status",
      paymentCertificate: "Payment Certificate",

      realTax: "Total Paid Duties and Taxes",
      realTaxGte: "Start Total Paid Duties and Taxes",
      realTaxLte: "End Total Paid Duties and Taxes",

      exchangeRate: "Exchange Rate",
    },
  },
  dutiesAndTaxesStatusViewPage: {
    title: "Duties and Taxes Status #",
    sellerChargedAmount: "Seller Charged Amount",
    exchange: "Exchange",
    incoterm: "Incoterm",
    date: "Date",
    dateIso: "Date Iso",
    orderID: "Order ID",
    volumeID: "Volume ID",
    sellerID: "Seller ID",
    taxID: "Tax ID",
    lastMileNumber: "Last Mile Number",
    productsValue: "Products Value",
    freightValue: "Freight Value",
    expectedTax: "Expected Tax",
    valueR$: "Value R$",

    id: "NB Internal Payment Reference",
    sellerName: "Seller Name",
    sellerId: "Seller ID",

    orderNumber: "Order Number",
    orderCreatedAt: "Order Creation Date",
    orderCreatedAtGte: "Start Order Creation Date",
    orderCreatedAtLte: "End Order Creation Date",

    volumeId: "Volume ID",
    volumeLastMileTrackingNumber: "Last Mile Tracking Number",
    orderEstimatedTaxValue: "Estimated Total Duties and Taxes",
    orderEstimatedTaxValueGte: "Start Estimated Total Duties and Taxes",
    orderEstimatedTaxValueLte: "End Estimated Total Duties and Taxes",
    orderEstimatedDutiesTaxValue: "Estimated Duties II",
    orderEstimatedDutiesTaxValueGte: "Start Estimated Duties",
    orderEstimatedDutiesTaxValueLte: "End Estimated Duties",
    orderEstimatedSalesTaxValue: "Estimated Taxes ICMS",
    orderEstimatedSalesTaxValueGte: "Start Estimated Taxes ICMS",
    orderEstimatedSalesTaxValueLte: "End Estimated Taxes ICMS",

    currentStatusId: "Tracking",
    currentStatusTitle: "Tracking Status",
    currentStatusTrackingCode: "Tracking Code",
    currentStatusTrackingCodeGte: "Start Tracking Code",
    currentStatusTrackingCodeLte: "End Tracking Code",

    createdAt: "Customs Start Date",
    createdAtGte: "Start Customs Start Date",
    createdAtLte: "End Customs Start Date",

    statusPaymentDoneAt: "Payment Date",
    statusPaymentDoneAtGte: "Start Payment Date",
    statusPaymentDoneAtLte: "End Payment Date",

    status: "Payment Status",
    paymentCertificate: "Payment Certificate",

    realTax: "Total Paid Duties and Taxes",
    realTaxGte: "Start Total Paid Duties and Taxes",
    realTaxLte: "End Total Paid Duties and Taxes",

    exchangeRate: "Exchange Rate",
  },
  accessibilitySettingsPage: {
    subtitle: "Configure typography, preferences, language and more.",
    typography: "Typography",
    textPreferenceDescription: "Set your text preferences below.",
    textSize: "Text size",
    language: "Language",
    mainLanguageDescription: "Define the main language of the Platform.",
    selectLanguage: "Select the language",
    changeUnitsText: "change units of weights and measures",
    stillHavingDifficulties: "Still having difficulties?",
    weWantToHelpYou: "We want to help you! Contact us in any way you prefer",
    contactViaPhone: "Contact via telephone",
    contactViaEmail: "Contact via email",
    contactViaChat: "Contact via chat",
  },
  searchHsCodePage: {
    title: "Search HS Code",
    subtitle:
      "Do you want to know an HS Code or if the product is permitted? Search right now!",
    code: "Enter the code",
    category: "Category",
    noHscode: "Record not found.",
    tooltip:
      "An Hs Code or NCM is a 6 or 8 digits code identifying the type of product to import. This page allows you to see if a particular code is allowed to enter Brazil",
  },
  requestsPage: {
    title: "Requests",
    subTitle: "Check and Track Request Status with your ID",
    requestId: "Request ID",
    asynchronousRequests: "Asynchronous Requests",
    result: "Result",
  },
  shippingRulesPage: {
    title: "Shipping Rules",
    subtitle:
      "On this page you can define default values for specific fields, so whenever you access a certain page it will already have the defined fields pre-filled.",
    addNewRule: "Add New Rule",
    seller: "Seller",
    sellers: "Sellers",
    name: "Rule Name",
    expiration: "Expiration",
    condition: "Condition",
    conditions: "Conditions",
    options: "Options",
    operator: "Operator",
    value: "Value",
    selectMultipleSellers: "Please choose one or more sellers",
    createConditionTitle: "Add New Rule",
    createCondition: "Select the conditions your shipment needs to satisfy",
    addCondition: "Add Condition",
    createActionTitle: "Action",
    createAction:
      'In this field you will choose what should happen according to your condition. For example, for the "All orders" condition you can select "always ship from Location X"',
    is_active: "Active",
    is_active_helptext:
      "If you want to create the rule without applying it for now, just de-select this field",
    freightValue: "Freight Value",
    priority: "Priority",
    destinationCountry: "Destination Country",
    destinationStates: "destination States",
    rulePriorityOptions: {
      veryHigh: "Very high",
      high: "High",
      medium: "Medium",
      low: "Low",
      veryLow: "Very low",
    },
  },
  fiscalPaymentsPage: {
    title: "Fiscal Payments",
    fiscalInfo: "Fiscal Information",
    fiscalParcelData: "Fiscal Parcel Data",
    customerData: "Customer Data",
    fields: {
      reference_number: "Reference",
      barcode: "Barcode",
      authentication: "Authentication",
      status: "Status",
      payment_type: "Payment Type",
      value: "Value",
      fiscal_parcel_id: "Fiscal Parcel ID",
      payment_date: "Payment Date",
      created_at: "Creation Date",
      dir_number: "DIR Number",
      customer_name: "Customer Name",
      customer_cpf: "Customer CPF",
      customer_cep: "Customer CEP",
      tracking_number: "Tracking Number",
      products_description: "Products Description",
      customer_state: "Customer State",
      seller_id: "Seller ID",
      dir_date: "DIR Date",
      start_payment_date: "Start Payment Date",
      end_payment_date: "End Payment Date",
      start_created_at: "Start Creation Date",
      end_created_at: "End Creation Date",
      start_value: "Start Value",
      end_value: "End Value",
      start_dir_date: "Start DIR Date",
      end_dir_date: "End DIR Date",
      boletoGNRE: "Boleto GNRE",
      paymentDate: "Payment Date",
    },
    status: {
      processing: "Processing",
      processed: "Processed",
      error: "Error",
    },
  },
  notFoundPage: {
    pageNotFound: "Page not found",
    goToHome: "Go to home page",
  },
};
