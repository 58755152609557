<template>
  <div>
    <NbTablev2
      tableOf="deliveryTimesTable"
      namespace="delivery_times"
      ref="deliveryTimesTable"
      :allFields="allFields"
      :fields="fields"
      :filterOptions="filterOptions"
      @optionTook="optionTook($event)"
      @reloadFields="fields = $event"
      @total="$emit('total', $event)"
    >
      <template #cell(actions)="data">
        <NbButton
          variant="tertiary"
          title="Download in PDF"
          class="pl-0"
          @click="downloadPdf(data.item.id)"
        >
          Download in PDF
        </NbButton>
        <NbButton
          variant="tertiary"
          title="Download in CSV"
          @click="downloadCsv(data.item.id, data.item.name)"
        >
          Download in CSV
        </NbButton>
        <span
          v-if="data.item.is_made_by_seller"
          :id="`deliverytimes-eletebtn-${data.item.id}-${data.item.name}`"
          class="text-gray-20 not-allowed body-2 pr-2"
          variant="tertiary"
          icon="trash"
        >
          <NbIcon
            icon="trash"
            :attributes="{
              class: 'mx-2 mb-1',
              width: '0.8rem',
              height: '0.8rem',
            }"
          />{{ $t("delete") }}
          <b-popover
            placement="top"
            :target="`deliverytimes-eletebtn-${data.item.id}-${data.item.name}`"
            triggers="hover focus"
            variant="dark"
          >
            You can not remove deliverytimes created by a seller
          </b-popover>
        </span>
        <NbButton
          v-else
          variant="tertiary"
          icon="trash"
          :title="`Delete this Delivery Times ${data.item.name}`"
          data-toggle="modal"
          data-target="#modalDeleteDeliveryTimes"
          @click="deliveryTimesToDelete = data.item"
        >
          {{ $t("delete") }}
        </NbButton>
        <span
          v-if="data.item.is_made_by_seller"
          :id="`deliverytimes-editbtn-${data.item.id}-${data.item.name}`"
          variant="tertiary"
          icon="edit"
          class="text-gray-20 not-allowed body-2"
        >
          <NbIcon
            icon="edit"
            :attributes="{
              class: 'mx-2 mb-1',
              width: '0.8rem',
              height: '0.8rem',
            }"
          />{{ $t("edit") }}
          <b-popover
            placement="top"
            :target="`deliverytimes-editbtn-${data.item.id}-${data.item.name}`"
            triggers="hover focus"
            variant="dark"
          >
            You can not edit delivery times created by a seller
          </b-popover>
        </span>
        <NbButton
          v-else
          variant="tertiary"
          icon="edit"
          data-toggle="modal"
          data-target="#adddeliveryTimes"
          @click="copyDeliveryTimes(data.item)"
          :title="`Edit Delivery Times ${data.item.name}`"
        >
          {{ $t("edit") }}
        </NbButton>
      </template>
    </NbTablev2>
    <!-- modals -->
    <NbModal id="modalDeleteDeliveryTimes" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("priceTablesPage.suretodelete") }}
          ({{ deliveryTimesToDelete.name }})?
        </div>
      </template>
      <template v-slot:body> </template>
      <template v-slot:footer>
        <NbButton
          class="float-right mr-3"
          @click="deleteItem(deliveryTimesToDelete)"
        >
          {{ $t("delete") }}
        </NbButton>
        <NbButton variant="secondary" data-dismiss="modal">
          {{ $t("close") }}
        </NbButton>
      </template>
    </NbModal>

    <NbModal
      id="adddeliveryTimes"
      :preventClose="true"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("priceTablesPage.importDeliveryTime") }}
        </div>
      </template>
      <template v-slot:body>
        <NbTextInput
          required
          id="deliverytimes-name"
          :name="$t('priceTablesPage.name')"
          class="mb-4"
          :errors="errors.name"
          v-model="newItem.name"
        />

        <NbDropFile
          ref="dropFile"
          v-model="chosenFile"
          :label="$t('browseFilesUpload')"
          :accept="['text/csv']"
          :patternFile="chosenFileCsv"
        >
          <div class="py-4">
            <div class="text-center">{{ $t("dragDropFile") }}</div>
            <div class="text-center mb-3">
              {{ $t("selectcsvFileUpload") }}
            </div>

            <NbButton variant="secondary d-block mx-auto">
              {{ $t("upload") }}
            </NbButton>
            <NbButton
              variant="secondary my-2 d-block mx-auto"
              @click.stop="downloadCsvExample"
            >
              {{ $t("downloadHere") }}
              {{ $t("blankTable") }}
            </NbButton>
          </div>
        </NbDropFile>
      </template>
      <template v-slot:footer>
        <NbButton
          :disabled="checkValidation"
          class="float-right"
          @click.prevent="createDeliveryTimes()"
        >
          <NbIcon
            v-if="isLoading"
            icon="loader"
            :attributes="{
              class: 'mr-1 mb-1 spin',
              width: '1rem',
              height: '1rem',
            }"
          />
          {{ $t("save") }}
        </NbButton>
        <NbButton
          variant="secondary"
          data-dismiss="modal"
          @click="initializeDeliveryTimes()"
        >
          {{ $t("close") }}
        </NbButton>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbDropFile from "@/components/input/drop-file/NbDropFile.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

import DeliveryTimesService from "@/services/DeliveryTimesService";
import DownloadsService from "@/services/DownloadsService";

const deliveryTimesService = new DeliveryTimesService();
const downloadsService = new DownloadsService();

export default {
  name: "DeliveryTimesList",
  components: {
    NbTablev2,
    NbButton,
    NbModal,
    NbDropFile,
    NbTextInput,
    NbIcon,
  },
  props: {},
  data: () => {
    return {
      isLoading: false,
      fields: [],
      currentItemId: "",
      deliveryTimesToDelete: {},
      newItem: {
        name: "",
      },
      file: null,
      chosenFile: "",
      chosenFileCsv: null,
      errors: {},
    };
  },
  mounted() {
    this.fields = this.allFields;
    this.checkQuery();
  },
  methods: {
    initializeDeliveryTimes() {
      this.newItem = { name: "" };
      this.chosenFile = "";
      this.file = null;
      this.errors = {};
    },
    copyDeliveryTimes(table) {
      if (table) {
        this.newItem = JSON.parse(JSON.stringify(table));
        deliveryTimesService.downloadCsv(table.id).then((response) => {
          const csv = new Blob([response.data], { type: "text/csv" });
          this.file = new FormData();
          this.chosenFile = new File([csv], `delivery_time_${table.name}.csv`, {
            type: "text/csv",
          });
          this.chosenFileCsv = [this.chosenFile];
          this.file.append("file", csv, `delivery_time_${table.name}.csv`);
        });
      }
    },
    createDeliveryTimes() {
      this.isLoading = true;
      this.file = new FormData();
      this.file.append("file", this.chosenFile, this.chosenFile.name);
      this.file.append("name", this.newItem.name);
      this.file.append("item_id", this.newItem.id);
      this.file.append("approver_ids", JSON.stringify(this.selected_admins));
      this.file.append("data_type", "delivery_time");
      downloadsService.loadProcessing(this.file).catch(() => {
        this.isLoading = false;
      });
      setTimeout(() => {
        this.$helpers.closeModal("adddeliveryTimes");
        this.$router.push("/archives");
      }, 2000);
    },
    downloadCsvExample() {
      window.open("/files/delivery-time.csv", "_blank");
    },

    deleteItem(item) {
      deliveryTimesService
        .delete(item.id)
        .then(() => {
          this.$refs.deliveryTimesTable?.getData();
          this.$helpers.closeModal("modalDeleteDeliveryTimes");
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
        });
    },
    downloadPdf(id) {
      const filename = `delivery_time_${id}.pdf`;
      deliveryTimesService.downloadPdf(id).then((response) => {
        if (response.data.data && response.data.data.file) {
          let file = response.data.data.file;
          let pdf = `data:application/pdf;base64,${file}`;
          let download_link = document.createElement("a");
          download_link.href = pdf;
          download_link.download = filename;
          download_link.click();
        }
      });
    },
    downloadCsv(id, name) {
      const filename = `delivery_time_${name}.csv`;
      deliveryTimesService.downloadCsv(id).then((response) => {
        let csv =
          "data:text/csv; charset=utf-8," + encodeURIComponent(response.data);
        let anchor = document.createElement("a");
        anchor.href = csv;
        anchor.target = "_blank";
        anchor.download = filename;
        anchor.click();
      });
    },
    checkQuery() {
      if (
        this.$route.query.deliveryTimesId ||
        this.$route.query.deliveryTimesId === null
      ) {
        this.$emit("switchTab", "deliveryTimes");
        //this.loadModalData(this.$route.query.deliveryTimesId);
        return;
      }
    },
  },
  computed: {
    checkValidation() {
      if (this.isLoading) {
        return true;
      }
      if (this.chosenFile != "" && this.newItem.name.length > 0) {
        return false;
      }
      return true;
    },
    allFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "name",
          label: this.$t("priceTablesPage.fields.name"),
          sortable: false,
        },
        {
          key: "actions",
          label: this.$t("priceTablesPage.fields.actions"),
          isCustomizable: false,
          class: "right--20 right-sticky",
        },
      ];
    },
    filterOptions() {
      return [
        {
          key: "name",
          label: this.$t("priceTablesPage.name"),
        },
      ];
    },
  },
  watch: {},
};
</script>
