<template>
  <div>
    <NbModal
      :id="id"
      modalConfig="modal-dialog-centered"
      width="76rem"
      @close="
        resetQuery();
        $emit('close');
      "
    >
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ `${$t("carriersViewPage.title")} ${itemId}` }}
          </h5>
        </div>
      </template>
      <div class="d-flex justify-content-between mb-5">
        <div></div>
        <NbCard
          v-if="carrier?.id"
          id="actions-card"
          :title="$t('actions')"
          class="px-3"
          width="33rem"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap pt-3">
              <NbButton
                class="w-100 my-1 px-2 text-left text-14-20"
                @click="showEditCarrierModal()"
              >
                {{ $t("carriersViewPage.editCarrier") }}
              </NbButton>
              <NbButton
                class="w-100 my-1 px-2 text-left text-14-20"
                variant="secondary"
                data-toggle="modal"
                data-target="#updateAirportHub"
              >
                {{ $t("carriersViewPage.updateAirportHub") }}
              </NbButton>
              <NbButton
                class="w-100 my-1 px-2 text-left text-14-20"
                @click="downloadCarrierSettings()"
                :disabled="carrierSettingsDownloading"
              >
                {{ $t("carriersPage.downloadCarrierSettings") }}
              </NbButton>
            </div>
          </template>
        </NbCard>
      </div>
      <div>
        <NbCard
          id="generaldatacarrier_chard"
          :title="$t('generalInformation')"
          class="bg-gray-05"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                id="name-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersViewPage.name')"
                v-model="carrier.name"
                disabled
              />
              <NbTextInput
                id="email-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersViewPage.email')"
                v-model="carrier.email"
                disabled
              />
              <NbTextInput
                id="phone-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersViewPage.phone')"
                v-model="carrier.phone"
                disabled
              />
              <NbTextInput
                id="taxNumber-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersPage.taxnumber')"
                v-model="carrier.tax_number"
                disabled
              />
              <NbTextInput
                id="namereference-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersPage.namereference')"
                v-model="carrier.name_reference"
                disabled
              />
              <NbTextInput
                id="legalName-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersPage.legalName')"
                v-model="carrier.legal_name"
                disabled
              />
              <NbTextInput
                id="rfbCode-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersPage.rfbCode')"
                v-model="carrier.rfb_code"
                disabled
              />
              <NbTextInput
                id="trackingfunction-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersPage.trackstatusfunction')"
                v-model="carrier.track_status_function"
                disabled
              />
              <NbTextInput
                id="carrierCode-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersViewPage.carrierCode')"
                v-model="carrier.carrier_code"
                disabled
              />
              <NbTextInput
                id="airportHub-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersViewPage.airportHub')"
                v-model="carrier.airport_code"
                disabled
              />
              <NbTextInput
                id="site-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersViewPage.site')"
                v-model="carrier.site"
                disabled
              />
              <NbTextInput
                id="slug-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersViewPage.slug')"
                v-model="carrier.slug"
                disabled
              />
              <NbTextInput
                id="isSeller-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('false')"
                class="w-32-5 my-2"
                :name="$t('carriersPage.fields.isSeller')"
                v-model="carrier.is_seller"
                disabled
              />
              <NbTextInput
                v-if="isSuper"
                id="platform-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersViewPage.platform')"
                v-model="carrier.platform_name"
                disabled
              />
              <NbTextInput
                id="creationDate-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersPage.fields.creationDate')"
                v-model="carrier.created_at"
                disabled
              />
            </div>
          </template>
        </NbCard>

        <NbCard
          id="carrieraddress_chard"
          :title="$t('carriersPage.carrierAddress')"
          class="bg-gray-05 mt-3"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                id="country-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersViewPage.country')"
                v-model="carrier.country"
                disabled
              />
              <NbTextInput
                id="state-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersViewPage.state')"
                v-model="carrier.state"
                disabled
              />
              <NbTextInput
                id="zipcode-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersViewPage.zipcode')"
                v-model="carrier.zip_code"
                disabled
              />
              <NbTextInput
                id="city-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersViewPage.city')"
                v-model="carrier.city"
                disabled
              />
              <NbTextInput
                id="address-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersViewPage.address')"
                v-model="carrier.address"
                disabled
              />
              <NbTextInput
                id="addressnumber-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('carriersViewPage.addressnumber')"
                v-model="carrier.address_number"
                disabled
              />
              <NbTextInput
                id="addresscomplement-carrier-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-100 my-2"
                :name="$t('carriersViewPage.addresscomplement')"
                v-model="carrier.address_complement"
                disabled
              />
            </div>
          </template>
        </NbCard>

        <NbCard
          id="deliverymethods_chard"
          :title="$t('carriersPage.deliveryMethods')"
          class="bg-gray-05 mt-3"
        >
          <template #body>
            <CarrierDeliveryMethods
              v-if="carrier.delivery_methods?.length"
              :delivery-methods="carrier.delivery_methods"
              thClass="bg-gray-05"
            />
            <p v-else>
              {{ $t("carriersPage.noDeliveryMethodFound") }}
            </p>
          </template>
        </NbCard>

        <NbCard
          id="deliverymethods_chard"
          :title="$t('carriersPage.users')"
          class="bg-gray-05 mt-3"
        >
          <template #body>
            <CarrierUsers
              v-if="carrier.users?.length"
              :users="carrier.users"
              thClass="bg-gray-05"
            />
            <p v-else>
              {{ $t("carriersPage.noDeliveryMethodFound") }}
            </p>
          </template>
        </NbCard>

        <NbFooter
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
            @click="resetQuery()"
          >
            {{ $t("close") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <!-- modals -->
    <ModalUpdateAirportHub
      id="updateAirportHub"
      :body.sync="newBody"
      :itemId="itemId"
      @reloadCarrier="getData()"
    />
    <!-- 
    <ModalCarrierCreate
      id="carrier-create-view"
      :carrier.sync="newCarrier"
      @reloadCarrier="
        getData();
        $emit('reloadCarriers');
      "
    />
    -->
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import CarrierDeliveryMethods from "@/views/contracts/carriers/components/CarrierDeliveryMethods.vue";
import CarrierUsers from "@/views/contracts/carriers/components/CarrierUsers.vue";
import ModalUpdateAirportHub from "@/views/contracts/carriers/components/ModalUpdateAirportHub.vue";
//import ModalCarrierCreate from "@/views/contracts/carriers/components/ModalCarrierCreate.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import moment from "moment";
import { jsonToFile } from "@/utils/json-to-file";

import CarrierService from "@/services/CarrierService";
import UsersService from "@/services/UsersService";
import CountryService from "@/services/CountryService";

const carrierService = new CarrierService();
const usersService = new UsersService();
const countryService = new CountryService();

export default {
  components: {
    NbModal,
    NbButton,
    NbTextInput,
    NbCard,
    CarrierDeliveryMethods,
    CarrierUsers,
    ModalUpdateAirportHub,
    NbFooter,
    //ModalCarrierCreate,
  },
  name: "ModalCarrierView",
  props: {
    id: {
      type: String,
      default: "ModalCarrierView",
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      newBody: {},
      currentItemId: "",
      carrier: {},
      newCarrier: {
        id: "",
        name: "",
        address: "",
        address_number: "",
        address_complement: "",
        zip_code: "",
        state: "",
        city: "",
        phone: "",
        site: "",
        email: "",
        tax_number: "",
        legal_name: "",
        rfb_code: "",
        name_reference: "",
        slug: "",
        track_status_function: "",
        platform_id: "",
        country: "",
        airport_code: "",
        is_seller: false,
        carrier_code: "",
        delivery_methods: [],
        users: [],
      },
      trackingRule: {},
      checkpoints: [],
      sellers: [],
      contracts: [],
      seller_name: "",
      contract_name: "",
      start_checkpoint_name: "",
      end_checkpoint_name: "",
      hkey: "",
      hvalue: "",
      newTrackingRule: {},
      users: [],
      countries: [],
      carrierSettingsDownloading: false,
    };
  },
  created() {
    countryService.getCountries().then((response) => {
      this.countries = response.data.data;
    });
  },
  computed: {
    isSuper() {
      return this.$store.state.user.is_super;
    },
  },
  methods: {
    async downloadCarrierSettings() {
      try {
        this.carrierSettingsDownloading = true;
        const { data } = await carrierService.exportCarrier(this.itemId);
        jsonToFile(data, `carrier-${this.itemId}-settings.json`);
      } finally {
        this.carrierSettingsDownloading = false;
      }
    },
    showEditCarrierModal() {
      this.errors = [];
      this.$emit("showEditCarrierModal", this.carrier);
      this.$helpers.openModal("modal-carrier");
    },
    copyCarrier() {
      this.errors = [];
      this.newCarrier = { ...this.carrier };
    },
    replaceTrackingRules() {
      this.trackingRule = Object.assign({}, this.newTrackingRule);
    },
    copyTrackingRule() {
      this.errors = [];
      this.newTrackingRule = Object.assign({}, this.trackingRule);
    },
    resetQuery() {
      this.$router.push({ query: {} });
    },
    moment: function (date) {
      let formated = moment(date);
      return formated;
    },
    getData() {
      carrierService.getCarrier(this.itemId).then((response) => {
        this.carrier = response.data.data;
        if (!this.carrier.created_at.includes("/")) {
          this.carrier.created_at = this.carrier?.created_at
            ? moment(this.carrier.created_at).format("DD/MM/YYYY HH:mm:ss")
            : "";
        }
        if (this.carrier.users) {
          this.carrier.users.forEach((user) => {
            usersService.getUser(user.id).then((response) => {
              this.users.push(response.data.data);
            });
          });
        }
      });
    },
  },
  watch: {
    itemId(newVal) {
      if (newVal) {
        this.getData();
      }
    },
  },
};
</script>

<style scoped></style>
