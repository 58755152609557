<template>
  <NbModal
    id="flight-create-modal"
    width="1024px"
    prevent-close
    @close="closeModal()"
  >
    <template #header>
      <NbPageTitle :title="$t('flightsPage.newFlight')" />
    </template>

    <template #body>
      <div class="wrapper">
        <div class="container">
          <div class="heading">
            <div>
              <h5 class="text-uppercase">
                1. {{ $t("flightsPage.selectMasterboxes") }}
              </h5>
            </div>
            <div>
              <h5 class="text-uppercase">
                2. {{ $t("flightsPage.insertFlightData") }}
              </h5>
            </div>
          </div>
          <div class="progress-bar">
            <div
              class="bar"
              :style="{ width: currentStep.percent + '%' }"
            ></div>
          </div>
        </div>
      </div>

      <div class="steps my-4">
        <KeepAlive>
          <FlightSelectMasterboxStep
            v-if="currentStep.id === 'SELECT_MASTERBOXES'"
            @next="setNextStep($event)"
          />
        </KeepAlive>

        <KeepAlive>
          <FlightFormCreateStep
            @previous="setFirstStep()"
            @success="closeModal()"
            :masterbox-ids="masterboxIds"
            v-if="currentStep.id === 'INSERT_DATA'"
          />
        </KeepAlive>
      </div>
    </template>
    <template #footer>
      <NbButton
        variant="secondary"
        class="d-flex ml-auto"
        @click="closeModal()"
      >
        {{ $t("close") }}
      </NbButton>
    </template>
  </NbModal>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import FlightSelectMasterboxStep from "./FlightSelectMasterboxStep.vue";
import FlightFormCreateStep from "./FlightFormCreateStep.vue";

const steps = [
  {
    id: "SELECT_MASTERBOXES",
    percent: 0,
  },
  {
    id: "INSERT_DATA",
    percent: 100,
  },
];

export default {
  components: {
    NbModal,
    NbPageTitle,
    FlightSelectMasterboxStep,
    FlightFormCreateStep,
    NbButton,
  },
  data() {
    return {
      currentStep: steps[0],
      masterboxIds: [],
    };
  },
  methods: {
    setNextStep(ids) {
      this.masterboxIds = ids;
      this.currentStep = steps[1];
    },
    setFirstStep() {
      this.currentStep = steps[0];
    },
    closeModal() {
      this.$helpers.closeModal("flight-create-modal");
      this.$emit("close");
    },
  },
  mounted() {
    this.$helpers.openModal("flight-create-modal");
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  background-color: var(--gray-05);
  border-radius: 8px;
  padding: 1rem;
  .heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    h5 {
      font-size: 0.675rem;
      letter-spacing: 0.8px;
      font-weight: bold;
      width: 120px;
      text-align: center;
    }
  }
  .progress-bar {
    margin: 0 3rem;
    background-color: var(--gray-10);
    height: 0.5rem;
    border-radius: 4px;
    .bar {
      transition: width 0.3s ease;
      background-color: var(--black);
      height: 100%;
      border-radius: 4px;
      min-width: 5%;
    }
  }
}
</style>
