<template>
  <div>
    <ModalHeader>
      <div class="d-flex flex-column align-items-start">
        <ModalTitle>
          {{ $t("fiscalPaymentsPage.fiscalInfo") }}
          <span v-show="fiscalPayment?.id">#{{ fiscalPayment.id }}</span>
        </ModalTitle>

        <FiscalPaymentStatus v-if="!loading" :status="fiscalPayment.status" />
      </div>
    </ModalHeader>

    <Spinner v-if="loading" class="mx-auto" size="lg" />

    <form v-else class="grid gap-4 mt-4">
      <NbCard :title="$t('generalData')" id="general-data">
        <template #body>
          <div class="grid grid-cols-2 gap-4">
            <NbTextInput
              v-model="fiscalPayment.reference_number"
              id="fiscalPayment.reference_number"
              :name="$t('fiscalPaymentsPage.fields.reference_number')"
              variant="borderless-gray-60"
              placeholder="-"
              disabled
            />
            <NbTextInput
              v-model="fiscalPayment.barcode"
              id="fiscalPayment.barcode"
              :name="$t('fiscalPaymentsPage.fields.barcode')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
            />
            <NbTextInput
              v-model="fiscalPayment.authentication"
              id="fiscalPayment.authentication"
              :name="$t('fiscalPaymentsPage.fields.authentication')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
            />
            <NbTextInput
              v-model="fiscalPayment.payment_type"
              id="fiscalPayment.payment_type"
              :name="$t('fiscalPaymentsPage.fields.payment_type')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
            />
            <NbTextInput
              v-model="fiscalPayment.value"
              id="fiscalPayment.value"
              :name="$t('fiscalPaymentsPage.fields.value')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
            />
            <NbTextInput
              v-model="fiscalPayment.fiscal_parcel_id"
              id="fiscalPayment.fiscal_parcel_id"
              :name="$t('fiscalPaymentsPage.fields.fiscal_parcel_id')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
            />
            <NbTextInput
              v-model="fiscalPayment.payment_date"
              id="fiscalPayment.payment_date"
              :name="$t('fiscalPaymentsPage.fields.payment_date')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
            />
            <NbTextInput
              v-model="fiscalPayment.created_at"
              id="fiscalPayment.created_at"
              :name="$t('fiscalPaymentsPage.fields.created_at')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
            />
          </div>
        </template>
      </NbCard>

      <NbCard
        :title="$t('fiscalPaymentsPage.fiscalParcelData')"
        id="fiscal-parcel"
      >
        <template #body>
          <div class="grid gap-4">
            <div class="grid grid-cols-3 gap-4">
              <NbTextInput
                v-model="fiscalPayment.fiscal_parcel.dir_number"
                id="fiscalPayment.dir_number"
                :name="$t('fiscalPaymentsPage.fields.dir_number')"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />
              <NbTextInput
                v-model="fiscalPayment.fiscal_parcel.dir_date"
                id="fiscalPayment.dir_date"
                :name="$t('fiscalPaymentsPage.fields.dir_date')"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />
              <NbTextInput
                v-model="fiscalPayment.fiscal_parcel.seller_id"
                id="fiscalPayment.seller_id"
                :name="$t('fiscalPaymentsPage.fields.seller_id')"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />
            </div>
            <div class="grid gap-4">
              <NbTextInput
                v-model="fiscalPayment.fiscal_parcel.products_description"
                id="fiscalPayment.products_description"
                :name="$t('fiscalPaymentsPage.fields.products_description')"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />
              <NbTextInput
                v-model="fiscalPayment.fiscal_parcel.tracking_number"
                id="fiscalPayment.tracking_number"
                :name="$t('fiscalPaymentsPage.fields.tracking_number')"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />
            </div>
          </div>
        </template>
      </NbCard>

      <NbCard :title="$t('fiscalPaymentsPage.customerData')" id="customer-data">
        <template #body>
          <div class="grid grid-cols-2 gap-4">
            <NbTextInput
              v-model="fiscalPayment.fiscal_parcel.customer_name"
              id="fiscalPayment.customer_name"
              :name="$t('fiscalPaymentsPage.fields.customer_name')"
              variant="borderless-gray-60"
              placeholder="-"
              disabled
            />

            <NbTextInput
              v-model="fiscalPayment.fiscal_parcel.customer_cpf"
              id="fiscalPayment.customer_cpf"
              :name="$t('fiscalPaymentsPage.fields.customer_cpf')"
              variant="borderless-gray-60"
              placeholder="-"
              disabled
            />
            <NbTextInput
              v-model="fiscalPayment.fiscal_parcel.customer_cep"
              id="fiscalPayment.customer_cep"
              :name="$t('fiscalPaymentsPage.fields.customer_cep')"
              variant="borderless-gray-60"
              placeholder="-"
              disabled
            />
            <NbTextInput
              v-model="fiscalPayment.fiscal_parcel.customer_state"
              id="fiscalPayment.customer_state"
              :name="$t('fiscalPaymentsPage.fields.customer_state')"
              variant="borderless-gray-60"
              placeholder="-"
              disabled
            />
          </div>
        </template>
      </NbCard>
    </form>

    <ModalFooter>
      <ModalClose class="d-flex ml-auto" />
    </ModalFooter>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";

import FiscalPaymentService from "@/services/FiscalPaymentService";
import FiscalPaymentStatus from "./FiscalPaymentStatus.vue";
import moment from "moment";
const fiscalPaymentService = new FiscalPaymentService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    Spinner,
    NbTextInput,
    FiscalPaymentStatus,
    NbCard,
  },
  data() {
    return {
      loading: false,
      fiscalPaymentId: null,
      fiscalPayment: {
        status: null,
      },
    };
  },
  methods: {
    async loadFiscalPayment() {
      try {
        this.loading = true;
        const { data } = await fiscalPaymentService.findById(
          this.fiscalPaymentId,
        );
        this.fiscalPayment = {
          ...data.data,
          payment_date: data.data?.payment_date
            ? moment(data.data.payment_date).format("DD/MM/YYYY")
            : "-",
          created_at: moment(data.data.created_at).format(
            "DD/MM/YYYY HH:mm.ss",
          ),
          fiscal_parcel: {
            ...data.data.fiscal_parcel,
            dir_date: moment(data.data.dir_date).format("DD/MM/YYYY HH:mm.ss"),
          },
        };
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    const { id } = this.$route.query;
    if (id) {
      this.fiscalPaymentId = Number(id);
      this.loadFiscalPayment();
    }
  },
};
</script>

<style lang="scss" scoped></style>
