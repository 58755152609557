<template>
  <div>
    <NbTablev2
      tableOf="receivedCheckpointTable"
      namespace="received_checkpoints"
      ref="receivedCheckpointTable"
      :allFields="allFields"
      :clickable="true"
      :fields="fields"
      :searchBy="1"
      :filterOptions="filterOptions"
      @reloadFields="fields = $event"
      @total="$emit('total', $event)"
      @clickedRow="loadModalData($event.id)"
    >
    </NbTablev2>

    <ModalReceivedCheckpointsView :itemId="currentItemId" />
  </div>
</template>
<script>
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import ModalReceivedCheckpointsView from "@/views/checkpoints/received_checkpoints/components/ModalReceivedCheckpointsView.vue";

export default {
  name: "ReceivedCheckpoints",
  components: {
    NbTablev2,
    ModalReceivedCheckpointsView,
  },
  props: {},
  data: () => {
    return {
      fields: [],
      currentItemId: "",
    };
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    checkQuery() {
      if (this.$route.query.receivedCheckpointId) {
        this.$emit("switchTab", "receivedCheckpoints");
        this.loadModalData(this.$route.query.receivedCheckpointId);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;
        if (!this.$route.query.receivedCheckpointId) {
          this.$router.push({ query: { receivedCheckpointId: id } });
        }
        this.$helpers.openModal("ModalReceivedCheckpointsView");
        return;
      }
    },
  },
  computed: {
    allFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "tracking_number",
          label: this.$t("receivedCheckpointsPage.fields.trackingNumber"),
          sortable: false,
        },
        {
          key: "tracking_function",
          label: this.$t("receivedCheckpointsPage.fields.trackingFunction"),
          sortable: false,
        },
        {
          key: "status_code",
          label: this.$t("receivedCheckpointsPage.fields.statusCode"),
          sortable: false,
        },
        {
          key: "message",
          label: this.$t("receivedCheckpointsPage.fields.message"),
          sortable: false,
        },
        {
          key: "date_iso",
          label: this.$t("receivedCheckpointsPage.fields.dateIso"),
          sortable: true,
        },
        {
          key: "created_at",
          label: this.$t("receivedCheckpointsPage.fields.creationDate"),
          sortable: true,
        },
      ];
    },
    filterOptions() {
      return [
        {
          key: "tracking_number",
          label: this.$t("receivedCheckpointsPage.fields.trackingNumber"),
        },
        {
          key: "tracking_function",
          label: this.$t("receivedCheckpointsPage.fields.trackingFunction"),
        },
        {
          key: "status_code",
          label: this.$t("receivedCheckpointsPage.fields.statusCode"),
        },
        {
          key: "date_iso",
          label: `${this.$t("receivedCheckpointsPage.fields.dateIso")}`,
          type: "dates",
        },
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
      ];
    },
  },
  watch: {},
};
</script>
