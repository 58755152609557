<template>
  <div>
    <NbTablev2
      tableOf="pricetablesTable"
      namespace="price_tables"
      ref="pricetablesTable"
      :allFields="allFields"
      :fields="fields"
      :filterOptions="filterOptions"
      @optionTook="optionTook($event)"
      @reloadFields="fields = $event"
      @total="$emit('total', $event)"
    >
      <template #cell(actions)="data">
        <NbButton
          variant="tertiary"
          title="Download in PDF"
          class="pl-0"
          @click="downloadPdf(data.item.id)"
        >
          Download in PDF
        </NbButton>
        <NbButton
          variant="tertiary"
          title="Download in CSV"
          @click="downloadCsv(data.item.id, data.item.name)"
        >
          Download in CSV
        </NbButton>
        <span
          v-if="data.item.is_made_by_seller"
          :id="`price-eletebtn-${data.item.id}-${data.item.name}`"
          class="text-gray-20 not-allowed body-2 pr-2"
          variant="tertiary"
          icon="trash"
        >
          <NbIcon
            icon="trash"
            :attributes="{
              class: 'mx-2 mb-1',
              width: '0.8rem',
              height: '0.8rem',
            }"
          />{{ $t("delete") }}
          <b-popover
            placement="top"
            :target="`price-eletebtn-${data.item.id}-${data.item.name}`"
            triggers="hover focus"
            variant="dark"
          >
            You can not remove price table created by a seller
          </b-popover>
        </span>
        <NbButton
          v-else
          variant="tertiary"
          icon="trash"
          :title="`Delete this Price Table ${data.item.name}`"
          data-toggle="modal"
          data-target="#modalDeletePriceTable"
          @click="priceTableToDelete = data.item"
        >
          {{ $t("delete") }}
        </NbButton>
        <span
          v-if="data.item.is_made_by_seller"
          :id="`price-editbtn-${data.item.id}-${data.item.name}`"
          variant="tertiary"
          icon="edit"
          class="text-gray-20 not-allowed body-2"
        >
          <NbIcon
            icon="edit"
            :attributes="{
              class: 'mx-2 mb-1',
              width: '0.8rem',
              height: '0.8rem',
            }"
          />{{ $t("edit") }}
          <b-popover
            placement="top"
            :target="`price-editbtn-${data.item.id}-${data.item.name}`"
            triggers="hover focus"
            variant="dark"
          >
            You can not edit price table created by a seller
          </b-popover>
        </span>
        <NbButton
          v-else
          variant="tertiary"
          icon="edit"
          data-toggle="modal"
          data-target="#addpriceTables"
          @click="copyPriceTables(data.item)"
          :title="`Edit Contract ${data.item.name}`"
        >
          {{ $t("edit") }}
        </NbButton>
      </template>
    </NbTablev2>
    <!-- modals -->
    <NbModal id="modalDeletePriceTable" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("priceTablesPage.suretodelete") }}
          ({{ priceTableToDelete.name }})?
        </div>
      </template>
      <template v-slot:body> </template>
      <template v-slot:footer>
        <NbButton
          class="float-right mr-3"
          @click="deleteItem(priceTableToDelete)"
        >
          {{ $t("delete") }}
        </NbButton>
        <NbButton variant="secondary" data-dismiss="modal">
          {{ $t("close") }}
        </NbButton>
      </template>
    </NbModal>
    <NbModal
      id="addpriceTables"
      :preventClose="true"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("priceTablesPage.importPricesTable") }}
        </div>
      </template>
      <template v-slot:body>
        <NbTextInput
          required
          id="price-name"
          :name="$t('priceTablesPage.name')"
          class="mb-4"
          :errors="errors.name"
          v-model="newItem.name"
        />

        <NbDropFile
          ref="dropFile"
          v-model="chosenFile"
          :label="$t('browseFilesUpload')"
          :accept="['text/csv']"
          :patternFile="chosenFileCsv"
        >
          <div class="py-4">
            <div class="text-center">{{ $t("dragDropFile") }}</div>
            <div class="text-center mb-3">
              {{ $t("selectcsvFileUpload") }}
            </div>

            <NbButton variant="secondary d-block mx-auto">
              {{ $t("upload") }}
            </NbButton>
            <NbButton
              variant="secondary my-2 d-block mx-auto"
              @click.stop="downloadCsvExample"
            >
              {{ $t("downloadHere") }}
              {{ $t("blankTable") }}
            </NbButton>
          </div>
        </NbDropFile>
        <div v-if="newItem.id" class="my-3">
          <label class="input-label" for="checkboxes">
            <div>{{ $t("priceTablesPage.needsSupervisorApproval") }}.</div>
            <div>{{ $t("priceTablesPage.chooseToSendRequest") }}:</div>
          </label>
          <NbCheckbox
            v-for="admin in admins"
            :key="admin.value"
            :id="`checkbox-${admin.text}`"
            v-model="admin.sendConfirmation"
            @change="updateSelectedAdmins(admin)"
            :name="admin.text"
          />
        </div>
      </template>
      <template v-slot:footer>
        <NbButton
          :disabled="checkValidation"
          class="float-right"
          @click.prevent="createPriceTables()"
        >
          <NbIcon
            v-if="isLoading"
            icon="loader"
            :attributes="{
              class: 'mr-1 mb-1 spin',
              width: '1rem',
              height: '1rem',
            }"
          />
          {{ $t("save") }}
        </NbButton>
        <NbButton
          variant="secondary"
          data-dismiss="modal"
          @click="initializepriceTables()"
        >
          {{ $t("close") }}
        </NbButton>
      </template>
    </NbModal>
  </div>
</template>
<script>
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbDropFile from "@/components/input/drop-file/NbDropFile.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbCheckbox from "@/components/buttons/NbCheckbox.vue";

import PriceTablesService from "@/services/PriceTablesService";
import DownloadsService from "@/services/DownloadsService";
import PendingActionService from "@/services/PendingActionService";

const priceTablesService = new PriceTablesService();
const downloadsService = new DownloadsService();
const pendingActionService = new PendingActionService();

export default {
  name: "PriceTablesList",
  components: {
    NbTablev2,
    NbButton,
    NbModal,
    NbDropFile,
    NbTextInput,
    NbIcon,
    NbCheckbox,
  },
  props: {},
  data: () => {
    return {
      fields: [],
      currentItemId: "",
      priceTableToDelete: {},
      newItem: {
        name: "",
      },
      file: null,
      chosenFile: "",
      chosenFileCsv: null,
      errors: {},
      isLoading: false,
      admins: null,
      selected_admins: [],
    };
  },
  mounted() {
    this.fields = this.allFields;
    this.checkQuery();
    pendingActionService.fetchAdminUsers().then((response) => {
      this.admins = response.data.data.map((admin) => ({
        text: admin.name,
        value: admin.id,
        sendConfirmation: false,
      }));
    });
  },
  methods: {
    initializepriceTables() {
      this.selected_admins = [];
      this.newItem = { name: "" };
      this.chosenFile = "";
      this.file = null;
      this.errors = {};

      this.admins.forEach((admin) => {
        admin.sendConfirmation = false;
      });
    },
    updateSelectedAdmins(data) {
      if (data.sendConfirmation) {
        this.selected_admins.push(data.value);
        return;
      }
      const index = this.selected_admins.indexOf(data.value);
      if (index !== -1) {
        this.selected_admins.splice(index, 1);
      }
    },
    copyPriceTables(table) {
      if (table) {
        this.newItem = JSON.parse(JSON.stringify(table));
        priceTablesService.downloadCsv(table.id).then((response) => {
          const csv = new Blob([response.data], { type: "text/csv" });
          this.file = new FormData();
          this.chosenFile = new File([csv], `price_table_${table.name}.csv`, {
            type: "text/csv",
          });
          this.chosenFileCsv = [this.chosenFile];
          this.file.append("file", csv, `price_table_${table.name}.csv`);
        });
      }
    },
    createPriceTables() {
      this.isLoading = true;
      this.file = new FormData();
      this.file.append("file", this.chosenFile, this.chosenFile.name);
      this.file.append("name", this.newItem.name);
      this.file.append("item_id", this.newItem.id);
      this.file.append("approver_ids", JSON.stringify(this.selected_admins));
      this.file.append("data_type", "price_table");
      downloadsService.loadProcessing(this.file).catch(() => {
        this.isLoading = false;
      });
      setTimeout(() => {
        this.$helpers.closeModal("addpriceTables");
        this.$router.push("/archives");
      }, 2000);
    },
    downloadCsvExample() {
      window.open("/files/price-tables.csv", "_blank");
    },
    deleteItem(item) {
      priceTablesService
        .delete(item.id)
        .then(() => {
          this.$refs.pricetablesTable?.getData();
          this.$helpers.closeModal("modalDeletePriceTable");
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
        });
    },
    downloadPdf(id) {
      const filename = `price_table_${id}.pdf`;
      priceTablesService.downloadPdf(id).then((response) => {
        if (response.data.data && response.data.data.file) {
          //window.open(response.data.data.download_url);
          let file = response.data.data.file;
          let pdf = `data:application/pdf;base64,${file}`;
          let download_link = document.createElement("a");
          download_link.href = pdf;
          download_link.download = filename;
          download_link.click();
        }
      });
    },
    downloadCsv(id, name) {
      const filename = `price_table_${name}.csv`;
      priceTablesService.downloadCsv(id).then((response) => {
        //let universalBOM = "\uFEFF"; //?????
        let csv =
          "data:text/csv; charset=utf-8," + encodeURIComponent(response.data);
        let anchor = document.createElement("a");
        anchor.href = csv;
        anchor.target = "_blank";
        anchor.download = filename;
        anchor.click();
      });
    },
    checkQuery() {
      if (this.$route.query.pricetablesId) {
        this.$emit("switchTab", "priceTables");
        //this.loadModalData(this.$route.query.pricetablesId);
        return;
      }
    },
  },
  computed: {
    checkValidation() {
      if (this.isLoading) {
        return true;
      }
      if (this.newItem.id && this.selected_admins.length === 0) {
        return true;
      }
      if (this.chosenFile != "" && this.newItem.name.length > 0) {
        return false;
      }
      return true;
    },
    allFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "name",
          label: this.$t("priceTablesPage.fields.name"),
          sortable: false,
        },
        {
          key: "actions",
          label: this.$t("priceTablesPage.fields.actions"),
          isCustomizable: false,
          class: "right--20 right-sticky",
        },
      ];
    },
    filterOptions() {
      return [
        {
          key: "name",
          label: this.$t("priceTablesPage.name"),
        },
      ];
    },
  },
  watch: {},
};
</script>
