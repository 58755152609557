<template>
  <NbCard id="contracts" :title="$t('components.searchData.contracts')">
    <template #body>
      <div class="grid gap-4">
        <div>
          <h2 class="heading-2">
            {{ $t("sellersViewPage.allowedContracts") }}
          </h2>

          <span v-if="!contracts.length">-</span>

          <div class="d-flex gap-4">
            <router-link
              v-for="contract in contracts"
              :key="contract.id"
              class="link-1"
              :to="`/contracts/contracts?contractId=` + contract.id"
            >
              {{ contract.slug }}
            </router-link>
          </div>
        </div>

        <div>
          <h2 class="heading-2">
            {{ $t("sellersViewPage.contractOperations") }}
          </h2>

          <span v-if="!operations.length">-</span>

          <div class="d-flex gap-4">
            <NbBadge
              v-for="operation in operations"
              :key="operation"
              type="success"
              :text="operation"
            />
          </div>
        </div>
      </div>
    </template>
  </NbCard>
</template>

<script>
import NbCard from "@/components/cards/NbCard.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
export default {
  props: {
    contracts: {
      type: Array,
      required: true,
    },
    operations: {
      type: Array,
      required: true,
    },
  },
  components: {
    NbCard,
    NbBadge,
  },
};
</script>

<style lang="scss" scoped></style>
