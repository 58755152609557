import api from "./HttpService";

export default class SellerService {
  /**
   * Get sellers
   *
   * @param filters
   * @returns {Promise}
   */
  getSellers(filters = {}) {
    return api.get("/v1/sellers?offset=0&limit=1000", {
      params: filters,
    });
  }

  /**
   * Get sellers
   *
   * @param offset = number
   * @param track = obj filters to apply
   * @returns {Promise}
   */
  getTwentySellers(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
      return api.get(`/v1/sellers`, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/sellers?limit=20&offset=" + offset, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    }
  }

  downloadSellers(data) {
    return api.post("/v1/sellers/download_sellers", data);
  }

  /**
   * Get allSeller
   *
   * @returns {Promise}
   */
  getAllSellers() {
    return api.get("/v1/sellers?limit=1000&offset=0");
  }

  /**
   * Get current seller
   *
   * @returns {Promise}
   */
  getCurrentSeller() {
    return api.get("/v1/current-seller");
  }

  /**
   * Get one seller
   *
   * @param {*} id
   * @returns {Promise}
   */
  getOneSeller(id) {
    return api.get("/v1/sellers/" + id);
  }

  /**
   * Delete sellers
   *
   * @param {*} id
   * @returns {Promise}
   */
  deleteSeller(id) {
    return api.delete("/v1/sellers/" + id);
  }

  /**
   * Add seller
   *
   * @param data
   * @returns {Promise}
   */
  addSeller(data) {
    return api.post("/v1/sellers", data);
  }

  /**
   * Import seller
   *
   * @param data
   * @returns {Promise}
   */
  importSeller(data) {
    return api.post("/v1/sellers/import", data);
  }

  /**
   * Export seller
   *
   * @param id
   * @returns {Promise}
   */
  exportSeller(id) {
    return api.get("/v1/sellers/" + id + "/export");
  }

  /**
   * Update seller
   *
   * @param {*} id
   * @param {*} data
   * @returns {Promise}
   */
  updateSeller(id = null, data = {}) {
    return api.put("/v1/sellers/" + id, data);
  }

  /**
   * Upload seller ad
   *
   * @param {*} id
   * @param {*} file
   * @returns {Promise}
   */
  uploadAd(id, file) {
    var formData = new FormData();
    formData.append("ad", file);

    return api.put("/v1/sellers/" + id + "/upload-ad", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  saveImage(id, logo) {
    let data = new FormData();
    data.append("logo", logo);
    return api.put("/v1/sellers/" + id + "/logo", data, {
      headers: { "content-type": "multipart/form-data" },
    });
  }

  /**
   * Add Allowed contract seller
   *
   * @param {*} id
   * @param {*} data
   * @returns {Promise}
   */
  addContract(data, id) {
    return api.post("/v1/sellers/" + id + "/allow-contract", data);
  }

  /**
   * Remove Allowed contract seller
   *
   * @param {*} id
   * @param {*} data
   * @returns {Promise}
   */
  removeContract(data, id) {
    return api.post(
      "/v1/sellers/" +
        id +
        "/disallow-contract?contract_id=" +
        data.contract_id,
      data,
    );
  }
}
