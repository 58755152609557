<template>
  <div>
    <div
      class="d-flex flex-column align-items-start flex-md-row justify-content-md-between"
    >
      <NbPageTitle :title="$t('sellersPage.title')" />
      <div class="d-flex flex-column">
        <NbButton class="mb-2 text-left" @click="sellerCreateModal = true">
          <NbIcon icon="plus" :size="18" />
          {{ $t("sellersPage.addNewSeller") }}
        </NbButton>
        <NbButton
          class="text-left"
          variant="secondary"
          @click="onOpenImportSellerModal"
        >
          <NbIcon icon="upload" :size="18" />
          {{ $t("sellersPage.importSeller") }}
        </NbButton>
      </div>
    </div>

    <section class="page-container mt-4">
      <NbTabs class="mb-4" :tabs="tabsOptions" />

      <NbTablev2
        tableOf="sellersTable"
        namespace="sellers"
        ref="sellersTableRef"
        :clickable="true"
        :allFields="allFields"
        :fields="fields"
        :tabs="true"
        :optionsWidth="260"
        :filterOptions="filterOptions"
        :buttonOptions="buttonOptions"
        selectable
        :selectedItems.sync="selectedSellers"
        @reloadFields="fields = $event"
        @total="sellersCount = $event"
        @optionTook="optionTook"
        @clickedRow="showSellerData($event.id)"
      >
        <template #cell(country)="data">
          {{ extractCountryName(data.item.country) }}
        </template>
        <template #cell(is_multiple)="data">
          <IsCheckedIcon :checked="data.item.is_multiple" />
        </template>
        <template #cell(current_balance)="data">
          R$ {{ data.item.current_balance }}
        </template>
        <template #cell(is_carrier)="data">
          <IsCheckedIcon :checked="data.item.is_carrier" />
        </template>
      </NbTablev2>
    </section>

    <Modal v-model="sellerCreateModal" size="lg">
      <SellerCreate />
    </Modal>

    <Modal v-model="sellerDataModal" size="lg" @close="setUrlSearchParam('id')">
      <Seller @updated="loadItems" />
    </Modal>

    <Modal v-model="downloadSellersModal">
      <SellersDownload :seller-ids="selectedSellers" />
    </Modal>

    <Modal v-model="showImportSellerModal">
      <ImportSeller @success="onImportSeller" />
    </Modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import Modal from "@/components/modal/Modal.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import SellersDownload from "./components/SellersDownload.vue";
import ImportSeller from "./components/ImportSeller.vue";
import SellerCreate from "./components/SellerCreate.vue";
import queryParamsMixin from "@/mixins/query-params-mixin.js";
import Seller from "./components/Seller.vue";
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";

export default {
  name: "Sellers",
  mixins: [queryParamsMixin],
  components: {
    NbButton,
    NbIcon,
    Modal,
    NbPageTitle,
    NbTabs,
    NbTablev2,
    SellersDownload,
    ImportSeller,
    SellerCreate,
    Seller,
    IsCheckedIcon,
  },
  data: () => {
    return {
      sellersCount: 0,
      currentTab: "all",
      fields: [],
      downloadSellersModal: false,
      sellerCreateModal: false,
      sellerDataModal: false,
      showImportSellerModal: false,
      selectedSellers: [],
    };
  },
  beforeMount() {
    const { id } = this.$route.query;
    if (!isNaN(Number(id))) {
      this.showSellerData(id);
    }
  },
  methods: {
    showSellerData(id) {
      this.setUrlSearchParam("id", id);
      this.sellerDataModal = true;
    },
    optionTook(event) {
      this[event]();
    },
    onDownloadSellers() {
      this.downloadSellersModal = true;
    },
    onOpenImportSellerModal() {
      this.errors = [];
      this.showImportSellerModal = true;
    },
    loadItems() {
      this.$refs?.sellersTableRef?.getData();
    },
    onImportSeller() {
      this.showImportSellerModal = false;
      this.loadItems();
    },
    selectedRows(items) {
      this.selected = items;
    },
    formattedFilterValue(x) {
      if (x.value == true) {
        return "Yes";
      } else {
        return "No";
      }
    },
    extractCountryName(country) {
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].alpha2_code == country) {
          return this.countries[i].name;
        }
      }
      return country;
    },
    ...mapActions("contracts", {
      fetchContracts: "fetchContracts",
    }),
  },
  computed: {
    buttonOptions() {
      return [
        {
          text: this.$t("sellersPage.downloadSellers"),
          value: "onDownloadSellers",
        },
      ];
    },
    tabsOptions() {
      return [
        {
          id: this.currentTab,
          label: this.$t("all"),
          current: this.currentTab,
          found: this.sellersCount,
        },
      ];
    },
    countries() {
      return this.$store.state.countries.all_items;
    },
    countries_options() {
      let options = [];
      this.countries.map((country) => {
        options.push({ text: country.name, value: country.alpha2_code });
      });
      return options;
    },
    filterOptions() {
      const options = [
        { key: "name", label: this.$t("sellersPage.fields.name") },
        {
          key: "country",
          label: "Country",
          type: "select",
          options: this.countries_options,
        },
        {
          key: "city",
          label: this.$t("sellersPage.fields.city"),
          sortable: true,
        },
        {
          key: "site",
          label: this.$t("sellersPage.fields.site"),
          sortable: true,
        },
        {
          key: "email",
          label: this.$t("sellersPage.fields.email"),
          sortable: true,
        },
        {
          key: "billing_email",
          label: this.$t("sellersPage.fields.billingEmail"),
        },
        {
          key: "responsable_contact",
          label: this.$t("sellersPage.fields.responsableContact"),
        },
        {
          key: "is_distribution_center",
          label: "Distribution Center",
          type: "boolean",
        },
        { key: "is_subsidiary", label: "Is Subsidiary", type: "boolean" },
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
        { key: "legal_name", label: this.$t("sellersPage.legalName") },
        { key: "rfb_code", label: this.$t("sellersPage.rfbCode") },
      ];
      return options;
    },
    isSuper() {
      return this.$store.state.user.is_super;
    },
    allFields() {
      const data = [
        { key: "id", label: "ID", sortable: true },
        {
          key: "name",
          label: this.$t("sellersPage.fields.name"),
          sortable: true,
        },
        {
          key: "email",
          label: this.$t("sellersPage.fields.email"),
          sortable: true,
        },
        {
          key: "city",
          label: this.$t("sellersPage.fields.city"),
          sortable: true,
        },
        {
          key: "is_multiple",
          label: this.$t("sellersPage.fields.ismultiple"),
          sortable: true,
        },
        {
          key: "current_balance",
          label: this.$t("sellersPage.fields.currentBalance"),
          sortable: true,
        },
        {
          key: "country",
          label: this.$t("sellersPage.fields.country"),
          sortable: true,
        },
        {
          key: "site",
          label: this.$t("sellersPage.fields.site"),
          sortable: true,
        },
        {
          key: "billing_email",
          label: this.$t("sellersPage.fields.billingEmail"),
          sortable: true,
        },
        {
          key: "responsable_contact",
          label: this.$t("sellersPage.fields.responsableContact"),
          sortable: true,
        },
        {
          key: "created_at",
          label: this.$t("sellersPage.fields.creationDate"),
          sortable: true,
        },
        {
          key: "rfb_code",
          label: this.$t("sellersPage.rfbCode"),
          sortable: true,
        },
        {
          key: "legal_name",
          label: this.$t("sellersPage.legalName"),
          sortable: true,
        },
        {
          key: "is_carrier",
          label: this.$t("sellersPage.fields.isCarrier"),
        },
      ];

      if (this.isSuper) {
        data.push(this.platform_field);
      }
      return data;
    },
  },
};
</script>
<style scoped></style>
