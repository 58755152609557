export default {
  components: {
    nav: {
      Overview: "Visão Geral",
      SearchHsCode: "Buscar HS Code",
      Archives: "Arquivos",
      Requests: "Solicitações",
      NobordistCheckpoints: "Pontos de Verificação da Nobordist",
      ReceivedCheckpoints: "Pontos de Verificação Recebidos",
      StatusCheckpoints: "Status dos Pontos de verificação",
      VolumeCheckpoints: "Pontos de Verificação do Volume",
      TrackingNotifications: "Notificações de Rastreamento",
      Checkpoints: "Pontos de Verificação",

      Platforms: "Plataformas",

      Contracts: "Contratos",
      Deliverymethods: "Métodos de Entrega",
      PriceTablesDeliveryTime: "Tabelas de Preços & Prazo de Entrega",
      ShipstationContracts: "Contratos da Shipstation",

      SellerAccounts: "Contas de Vendedor",
      DefaultPlans: "Planos Padrões",
      Finances: "Finanças",

      AlertLogs: "Registros de Alerta",
      NobordistLogs: "Registros da Nobordist",
      BilLogs: "Registros de Contas",
      ExternalLogs: "Registros Externos",
      TrackingRules: "Regras de Rastreamento",
      RejectedOrdersLogs: "Registros de Pedidos Rejeitados",
      GeneralEmails: "E-mails Gerais",
      Notifications: "Notificações",
      UserNotifications: "User Notifications",
      DelayedJobs: "Delayed Jobs",
      Logs: "Registros",

      VolumesReverse: "Volumes Reversa",
      Volumes: "Volumes",
      Orders: "Pedidos",
      Overpacks: "Pacotes",
      Masterboxes: "Masterboxes",
      Shipments: "Remessas",
      Flights: "Voos",
      Shipping: "Envio",
      ShippingRules: "Regras de Envio",
      DutiesAndTaxesStatus: "Status de taxas e impostos",

      Users: "Usuários",
      Carriers: "Transportadoras",
      Sellers: "Vendedores",
      Groups: "Grupos",
      Permissions: "Permissões",
      Usersmanagement: "Usuários e vendedores",
      Settings: "Configurações",
      Accessibility: "Acessibilidade",
      PrintingSetting: "Printing Setting",
      Account: "Conta",
      Downloads: "Downloads",
      SendNotifications: "Enviar Notificações",
      FiscalPayments: "Pagamentos Fiscais",
    },
    searchData: {
      printing_setting: "Printing Setting",
      volumes: "Volumes",
      volumesReverse: "Volumes Reverse",
      orders: "Pedidos",
      overpacks: "Overpacks",
      masterboxes: "Masterboxes",
      shipments: "Envios",
      flights: "Vôos",
      shippingRules: "Regras de envio",
      dutiesAndTaxesStatus: "Status de taxas e impostos",
      users: "Usuários",
      carriers: "Transportadoras",
      sellers: "Vendedores",
      sellerAccounts: "Contas do vendedor",
      defaultPlans: "Planos padrões",
      contracts: "Contratos",
      deliveryMethods: "Métodos de envio",
      priceTableAndDeliveryTime: "Tabelas de preços e prazo de entrega",
      shipstationContracts: "Contratos da Shipstation",
      alertLogs: "Registros de Alerta",
      nobordistLogs: "Registros da Nobordist",
      billLogs: "Registros de Contas",
      externalLogs: "Registros externos",
      trackingRules: "Regras de Rastreamento",
      rejectedOrdersLogs: "Registros de Pedidos Rejeitados",
      generalEmails: "E-mails Gerais",
      notifications: "Notificações",
      delayedJobs: "Delayed Jobs",
      searchHsCode: "Buscar HS Code",
      archives: "Arquivos",
      requests: "Solicitações",
      nobordistCheckpoints: "Pontos de Verificação da Nobordist",
      receivedCheckpoints: "Pontos de Verificação Recebidos",
      statusCheckpoints: "Status dos Pontos de verificação",
      volumeCheckpoints: "Pontos de Verificação do Volume",
      trackingNotifications: "Notificações de Rastreamento",
      checkpoints: "Pontos de Verificação",
      userProfile: "Meu perfil",
      printingSetting: "Configurações de impressão",
      userNotifications: "Notificações do usuário",
      accesibility: "Acessibilidade",
      downloads: "Downloads",
      sendNotifications: "Enviar Notificações",
      accessibility: "Acessibilidade",
      fiscalPayments: "Pagamentos fiscais",
    },
    tableFilters: {
      title: "Filtros",
      subTitle: "Selecione os valores abaixo para criar um filtro",
      addNewFilter: "Adicionar novo filtro",
      clearFilters: "Limpar Filtros",
      filterBy: "Filtrar por: ",
      filteredBy: "Filtrado por:",
      openFilterOptions: "Abrir Opções de Filtro",
      clickToClearFilter: "Clicar para Limpar Todos os Filtros",
      clickToFilter: "Clicar para Filtrar",
      trueText: "Verdadeiro",
      falseText: "Falso",
    },
    selectTableColumns: {
      title: "Selecionar Coluna da Tabela",
      button: "Colunas da Tabela",
      unSelectAll: "Desmarcar Todas as Colunas",
      selectAll: "Selecionar Todas as Colunas",
      shownColumns: "Colunas visiveis",
      hiddenColumns: "Colunas ocultas",
    },
    maxRows: {
      button: "Linhas",
    },
    nbTable: {
      wereFound: "foram encontrados",
      all: "Todos",
      onThisPage: "nesta página",
      isSelected: "está selecionado | selecionados",
      worksOnlyCurrentPage:
        "Este filtro só funciona para os {onThisPageItems} itens mostrados nesta página da tabela, não para todos os {totalItems} itens. Para fazer isso, use o botão de filtro",
      sortBtn:
        "Ordenar os itens nesta página da tabela. (Não ordena todos os itens encontrados)",
      properties: "Propriedades",
      tableOptions: "Opçoes da Tabela",
      changeVisibilityReorderColumns:
        "Mude a visibilidade ou reorganize as colunas",
    },
    itensPerPage: "Itens Por Página",
    pagination: {
      first: "Primeiro",
      prev: "Anterior",
      next: "Próximo",
      last: "Último",
    },
    topBar: {
      shiftSide: "Mudar para a barra de navegação ao lado",
      shiftTop: "Mudar para a barra de navegação a cima",
      myProfile: "Meu Perfil",
      myNotifications: "Minhas Notificações",
      printingSetting: "Configuração Para Impressão",
      platformList: "Lista de plataformas",
      savingChanges: "Salvando alterações...",
      lastChangesSaved: "Últimas alterações salvas",
    },
    footer: {
      needHelp: "Precisa de ajuda",
      checkOurSupport: "Confira nossos guias ou envie-nos uma mensagem",
    },
  },
  locales: {
    ptBR: "Português (BR)",
    en: "Inglês",
    cn: "Chinês",
    es: "Espanhol",
  },
  //words
  metrics: "Métricas",
  filterByPeriod: "Filtre pelo período",
  until: "Até",
  showMoreData: "Exibir mais dados",
  collapseData: "Colapsar dados",
  false: "false",
  erro: "Erro",
  noImageAdded: "Nenhuma imagem adicionada",
  clickOrDrag: "Clique ou arraste sua imagem aqui",
  seller: "Vendedor",
  user: "Usuário",
  wouldYouLikeToRemoveSelectedItems:
    "Você gostaria de remover os itens selecionados?",
  documentsAndLabels: "Documentos e etiquetas",
  downloadReport: "Baixar relatório",
  generalData: "Dados gerais",
  suggestedActions: "Ações sugeridas",
  filename: "Nome do arquivo",
  seeDetails: "Ver detalhes",
  volumes: "Volumes",
  clickToCopy: "Clique para copiar",
  copy: "Copiar",
  clickToDownload: "Clique para fazer o download",
  active: "Ativo",
  blocked: "Bloqueado",
  browseFilesUpload: "Procure seu arquivo para fazer upload",
  selectcsvFileUpload: "Selecione um arquivo CSV para fazer upload",
  downloadHere: "Baixe aqui",
  instructionsXLSfile: "o arquivo de instruções XLS",
  fileUploaded: "Arquivo enviado",
  blankTable: "uma tabela em branco",
  sureWantDelete: "Tem certeza de que deseja excluir isso permanentemente?",
  sitePlaceholder: "https://site.com",
  dragDropFile: "Arraste e solte ou clique aqui",
  clickHereUploadFile: "Clique aqui para fazer upload do seu arquivo",
  uploadFromComputer: "Fazer upload do seu computador",
  uploadFromLinks: "Fazer upload a partir de um link",
  selectPdfFileUpload: "Selecione um arquivo PDF para fazer upload",
  link: "Link",
  update: "Atualizar",
  notFound: "Não encontrado",
  generalInformation: "Informações Gerais",
  item: "Item",
  items: "Itens",
  clear: "Limpar",
  confirm: "Confirmar",
  connect: "Conectar",
  disConnect: "Desconectar",
  connected: "Conectado",
  disConnected: "Desconectado",
  contactUs: "Contate-nos",

  placeholderEmail: "example@email.com",
  asynchronousRequests: "Solicitações assíncronas",
  loading: "Carregando",
  login: "Login",
  logout: "Sair",
  name: "Nome",
  fullName: "Nome completo",
  company: "Empresa",
  phoneNumber: "Número de Telefone",
  whatsNumber: "Número de Whatsapp",
  optional: "Opcional",
  phone: "Telefone",
  cellphone: "Celular",
  email: "E-mail",
  platform: "Plataforma",
  select: "Selecione",
  selected: "Selecionado",
  notselected: "Não Selecionado",
  selectAll: "Selecionar Todos",
  clearSelected: "Limpar Selecionados",
  selectColumns: "Selecionar Colunas",
  clearFilter: "Limpar Filtros",
  filters: "Filtros",
  filter: "Filtro",
  all: "Todos",
  anyCounties: "Qualquer país",
  close: "Fechar",
  save: "Salvar",
  cancel: "Cancelar",
  actions: "Ações",
  add: "adicionar",
  delete: "Deletar",
  selectAnOption: "Selecionar Uma Opção",
  start: "Começo",
  end: "Fim",
  yes: "Sim",
  no: "Não",
  ok: "Ok",
  attention: "Atenção",
  found: "encontrado",
  support: "Suporte",
  edit: "editar",
  password: "Senha",
  hidePass: "Esconder Senha",
  showPass: "Mostrar Senha",
  changePass: "Mudar Senha",
  remove: "Remover",
  search: "Procurar",
  upload: "Carregar",
  download: "Baixar",
  beginningDate: "Data de início",
  startDate: "Data Inicial",
  endDate: "Data Final",
  between: "Entre",
  betweenDates: "Entre Datas",
  registrationDate: "Data de Registro",
  and: "E",
  selectOption: "Select an option",
  paymentDate: "Data de Pagamento",
  new: "Novo",
  allowed: "Permitido",
  invalid: "Inválido",
  errorMessages: {
    isNotPDF: "O arquivo não é .pdf",
    tryAgain: "Por favor, verifique suas informações e tente novamente",
    required: "Não pode ficar em branco",
    invalidCPF: "CPF inválido",
    invalidCNPJ: "CNPJ inválido",
    invalidDocument: "Documento inválido",
    invalidZIPCode: "CEP inválido",
    invalidPhone: "Telefone inválido",
    invalidEmail: "E-mail inválido",
    lowerMoney: "deve ser maior que 0,01",
    genericError: "Algo de errado aconteceu.",
    whiteSpace: "Não deve ter espaço em branco",
    minLength: "pelo menos %{value} caracteres",
    minLowercase: "pelo menos %{value} letras minúsculas",
    minUppercase: "pelo menos %{value} letras maiúsculas",
    minNumber: "pelo menos %{value} número",
    minSpecialCharacter: "pelo menos %{value} caractere especial",
    betweenDigits: "%{min} ou %{max} dígitos",
  },
  //pages
  homePage: {
    greeting: `Olá, {name}`,
    subtitle: "O que você gostaria de fazer hoje?",
    averageShippingCost: "Custo médio do frete",
    completedOrders: "Pedidos finalizados",
    closedFlights: "Closed flights",
    bestSellers: "Melhores vendedores",
    seller: "Vendedor",
    ordersCreated: "Pedidos Criados",
    noSellerRanking:
      "Sem informação suficiente para montar um ranking de vendedores",
    links: {
      orders: {
        title: "Ver pedidos",
        description: "Confira pedidos e baixe relatórios.",
        action: "Ir para pedidos",
      },
      sellers: {
        title: "Gerenciar vendedores",
        description: "Confira usuários e vendedores.",
        action: "Ir para vendedores",
      },
      masterboxes: {
        title: "Ver masterboxes",
        description: "Confira as masterboxes.",
        action: "Ir para masterboxes",
      },
      flights: {
        title: "Gerenciar Vôos",
        description: "Confira e informe em qual vôo se encontra cada pedido.",
        action: "Ir para vôos",
      },
      contracts: {
        title: "Gerenciar contratos",
        description: "Acesse para gerenciar contratos.",
        action: "Ir para contratos",
      },
    },
  },
  loginPage: {
    forgotPassword: "Esqueceu sua senha",
    resetPassword: "O e-mail de redefinição de senha foi enviado",
    checkInbox: "Verifique sua caixa de entrada",
    sendReset: "Envie-me instruções de redefinição de senha",
    login: "Login",
    welcome: "Bem-vindo",
    pleaseYourAcc: "Por favor, insira sua conta",
    wantoToApp: "Quer acessar o sistema de gestão",
    goToApp: "Quer acessar o sistema de gestão",
    checkOur: "Confira nosso",
    generalConditionsService: "Condições de Serviço",
    resetPasswordSent:
      "E-mail de redefinição de senha enviado! Verifique sua caixa de entrada.",
    sendResetPasswordInstructions: "Envie-me instruções para redefinir a senha",
    iAgree: "Eu concordo",
    agreementText:
      "Ao marcar esta caixa, você reconhece que leu e concorda com nossas Condições Gerais de Serviço para estar vinculado a este Acordo.",
    createYourAccount: "Crie sua conta",
    fillInfoToCreateAcc: "Preencha os campos a seguir para criar sua conta",
    userData: "Dados do usuário",
    pleaseYourData: "Por favor, preencha seus dados",
    companyData: "Dados da empresa",
    pleaseCompanyData: "Por favor, preencha os dados da empresa",
    hasRegistration: "Já registrado",
    accessMyAcc: "Acessar minha conta",
    needHelp: "Precisa de ajuda",
    talkWithOur: "Fale com um de nossos consultores",
    ifCheckOut: "Se preferir, confira nosso",
  },
  pintingSettingsPage: {
    title: "Printing Settings",
    subtitle:
      "Defina aqui configurações padrão para opções de impressão das etiquetas",
    cN23Label: "Etiqueta CN23",
    commercialInvoice: "Fatura Comercial",
    packingSlip: "Guia de Remessa",
    cevaBatteryShipperConfirmation: "Ceva Battery Shipper Confirmation",
  },
  userProfileEditPage: {
    title: "Atualizar meu perfil",
  },
  userProfilePage: {
    title: "Minha conta",
    subtitle: "Confira e atualize, se preciso, suas informações pessoais.",
    userData: "Dados do usuário",
    loginInformation: "Informações de login",
    loginInformationSubtitle:
      "Se preciso, edite os dados que utiliza para acessar a plataforma.",
    checkYourData: "Confira e edite seus dados, se preciso",
    editPass: "Editar Senha",
    newPass: "Nova senha",
    currentPass: "Senha Atual",
    newPassConfirm: "Digite nova senha para alterar",
    editEmail: "Editar E-mail",
    editProfile: "Editar Perfil",
    newEmail: "Novo E-mail",
    checkEmail: "Verifique o formato do seu e-mail",
    sameEmail:
      "Os dois e-mails informados são diferentes, por favor, verifique",
    confirmCurrentPassword:
      "Para realizar essa ação, por favor digite a sua senha atual.",
  },
  overViewPage: {
    title: "Visão Geral",
    getInfoCorreios: "Obtenha informações dos Correios",
    putTrackingNumber: "Informe o Número de Rastreamento",
    putDispatchNumberUnitCode:
      "Informe o Número de Despacho ou Código da Unidade",
    manuallyUpdateCN38Number: "Atualize manualmente o número CN38",
    shipmentID: "ID da Remessa",
    newCN38Number: "Novo Número CN38",
    updateCN38: "Atualizar CN38",
    resetDispatchNumbersCounter: "Redefinir contador de números de despacho",
    newCounterStart: "Novo início do contador",
    reset: "Reiniciar",
    removeOverpack: "Remover Pacotes",
    testDelayedJob: "Testar Delayed Job",
    test: "Teste",
    generateRequestID: "Gerar ID de Solicitação",
    updateCn38Number: "Atualizar número CN38",
    sureOverwrite: "Você irá substituir o número CN38 da Remessa",
    definitiveAction: "Esta ação é definitiva. Você está disposto a continuar",
    sureResetCorreiosCounter:
      "Você vai zerar o contador dos Correios para números de despacho",
    sureRemoveOverpack: "Você deseja remover este pacote",
  },
  downloadsPage: {
    title: "Arquivos",
    subtitle: "Todos os downloads estão centralizados nesta página",
    errors: "Erros",
    found: "nenhum download encontrado | download | downloads",
    downloadReport: "Baixar relatório",
    showErrors: "Mostrar erros",
    status: {
      failed: "falhou",
      processing: "carregando",
      done: "completo",
      starting_upload: "iniciando upload",
      checking_csv_format: "verificando formato csv",
      checking_columns: "verificando colunas",
      checking_equal_orders_volumes: "verificando volumes de pedidos iguais",
      checking_cells: "verificando células",
      validation_success: "validações concluídas com sucesso! criando a tabela",
    },

    fields: {
      id: "ID",
      type: "Tipo",
      status: "Situação",
      fileName: "Nome do Arquivo",
      creationDate: "Data de Criação",
      messages: "Mensagens",
    },
  },
  checkpointsPage: {
    title: "Checkpoint",
    subTitle: "Confira pontos importantes dos pedidos de seus clientes",
    subTitleView: "Confira os dados desse checkpoint",
    moreAboutCheckpoint: "Saiba mais sobre checkpoints",
    helpTextTitle:
      "Esta página é o seu guia confiável ao longo do processo de crossboarding. Aqui, você encontrará uma série de checkpoints essenciais que irão facilitar a sua jornada, garantindo que cada etapa seja concluída com sucesso",
    nobordistCheckpoints: "Nobordist Checkpoints",
    receivedCheckpoints: "Received Checkpoints",
    statusCheckpoints: "Status Checkpoints",
    volumeCheckpoints: "Volume Checkpoints",
    trackingNotifications: "Tracking Notifications",
  },
  nobordistCheckpointsPage: {
    title: "Pontos de Verificação da Nobordist",
    downloadCheckpointsCSV: "Baixar Pontos de Verificação em CSV",
    downloadCheckpointsExcel: "Baixar Pontos de Verificação em Excel",
    checkDownloadsAt:
      "Verifique os downloads de processamento em... página de downloads",
    fields: {
      type: "Tipo",
      slug: "Slug",
      name: "Nome",
      title: "Título",
      trackingCode: "Código de Rastreamento",
    },
  },
  nobordistCheckpointsViewPage: {
    title: "Pontos de Verificação da Nobordist #",
    type: "Tipo",
    slug: "Slug",
    titleField: "Título",
    titlePt: "Título em Pt",
    description: "descrição",
    descriptionPt: "Descrição em Pt",
    name: "Nome",
    trackingCode: "Código de Rastreamento",
  },
  receivedCheckpointsPage: {
    title: "Pontos de Verificação Recebidos",
    fields: {
      trackingNumber: "Número de Rastreio",
      trackingFunction: "Função de Rastreamento",
      statusCode: "Código de Status",
      message: "Mensagem",
      dateIso: "Data Iso ID",
      creationDate: "Data de Criação",
    },
  },
  receivedCheckpointsViewPage: {
    title: "Ponto de Verificação Recebido #",
    trackingNumber: "Número de Rastreio",
    trackingFunction: "Função de Rastreamento",
    statusCode: "Código de Status",
    dateIso: "Data Iso",
    date: "Data",
    sentNotification: "Sent Notification",
    message: "Mensagem",
    fields: {
      receiverType: "Tipo do Destinatário",
      notificationID: "Nb Notificação ID",
      volumeID: "Volume ID",
      creationDate: "Data de Criação",
    },
  },
  sendNotificationsViewPage: {
    title: "Enviar Serviço #",
    receivedNotification: "Notificação Recebida",
    receiverType: "Tipo do Destinatário",
    email: "E-mail",
    webhook: "Webhook",
    phone: "Telefone",
    notificationID: "Nb Notificação ID",
    volumeID: "Volume ID",
    sellerName: "Nome do Vendedor",
    trackingCode: "Código de Rastreamento",
    titleField: "Título",
    createdDate: "Data de Criação",
    time: "Horário",
    receivedCheckpointID: "ID do Ponto de Verificação Recebido",
    trackingNumber: "Número de Rastreio",
    trackingFunction: "Função de Rastreamento",
    statusCode: "Código de Status",
    dateIso: "Data Iso",
  },
  statusCheckpointsPage: {
    title: "Status dos Pontos de Verificação",
    addStatusCheckpoint: "Adicionar Status de Pontos de Verificação",
    addNewStatusCheckpoint: "Adicionar Novo Status de Pontos de Verificação",
    uploadStatusCheckpoint: "Carregar Status de Pontos de Verificação",
    checkpointID: "ID do Ponto de Verificação",
    fields: {
      statusCode: "Código de Status",
      trackingFunction: "Função de Rastreamento",
      checkpoint: "Ponto de Verificação",
      creationDate: "Data de Criação",
      edit: "Editar",
    },
  },
  statusCheckpointsPageView: {
    title: "Status Checkpoint",
  },
  trackingNotificationsPage: {
    title: "Notificações de Rastreamento",
    fields: {
      notificationType: "Tipo de Notificação",
      volumeCheckpointID: "ID do Ponto de Verificação do Volume",
      data: "Dados",
      sent: "Enviado",
    },
  },
  volumeCheckpointsPage: {
    title: "Pontos de Verificação do Volume",
    fields: {
      volumeID: "Volume ID",
      receivedCheckpointID: "ID do Ponto de Verificação Recebido",
      convertedNotifs: "Notificações Convertidas",
      checkpoint: "Ponto de Verificação",
      DateISO: "Data ISO",
      message: "Mensagem",
    },
  },
  volumeCheckpointsViewPage: {
    title: "Ponto de Verificação do Volume #",
    checkpoint: "Ponto de Verificação",
    volumeData: "Volume Data #",
    volumes: "Volumes",
    message: "Mensagem",
    attachment: "Anexo",
    volumeID: "Volume ID",
    receivedCheckpointID: "ID do Ponto de Verificação Recebido",
    ConvertedNotifs: "Notificações Convertidas",
    checkpointID: "ID do Ponto de Verificação",
    created: "Criação",
    dateIso: "Data Iso",
    type: "Tipo",
    slug: "Slug",
    name: "nome",
    description: "Descrição",
    trackingCode: "Código de Rastreamento",
    titleField: "Título",
    namePT: "Nome PT",
    descriptionPT: "Descrição em PT",
    titlePT: "Título em PT",
    hierarchyStatus: "Status Hierárquico",
    height: "Altura",
    width: "Largura",
    length: "Comprimento",
    weight: "Peso",
    cubageFactor: "Fator de Cubagem",
    volumeType: "Tipo do Volume",
    orderID: "Pedido ID",
    lastMileTrackingNumber: "Número de rastreamento da última milha",
    orderReference: "Referência do Pedido",
    fields: {
      name: "Nome",
      description: "Descrição",
      sku: "Sku",
      valueWithTax: "Valor com Imposto",
      value: "Valor",
      hsCode: "Hs Code",
      quantity: "Quantidade",
      originCountry: "País de Origem",
      length: "Comprimento",
      width: "Largura",
      height: "Altura",
      weight: "Peso",
    },
  },
  contractsPage: {
    logo: "Logo",
    title: "Contratos",
    importContract: "Importar Contrato à partir de JSON",
    subTitle: "Check and manage contract's data",
    addContracts: "Adicionar Contratos",
    updateValidityDate: "Atualizar Data de Validade",
    showAdditionalServices: "Mostrar Serviços Adicionais",
    addNewContract: "Adicionar Novo Contrato",
    name: "Nome",
    slug: "Slug",
    incoterm: "Termos Internacionais de Comércio",
    taxingType: "Tipo de Tributação",
    maximumWeight: "Peso Máximo",
    cubageFactor: "Fator de Cubagem",
    maximumLength: "Comprimento Máximo",
    maximumHeight: "Altura Máxima",
    maximumWidth: "Largura Máxima",
    maximumPriceLimit: "Limite Máximo de Preço",
    additionalServices: "Serviços Adicionais",
    externalID: "ID Externo:",
    hSCodes: "HS Codes:",
    triggerOption: "Opção de Gatilho:",
    triggerCondition: "Condição de Gatilho",
    primaryType: "Tipo Primário:",
    primaryValue: "Valor Primário:",
    primaryVariable: "Variável Primária",
    secondaryVariable: "Variável Secundária:",
    secondaryType: "Tipo Secundário:",
    secondaryValue: "Valor Secundário:",
    selecttype: "Selecione o tipo",
    addToServices: "Adicionar aos Serviços",
    cancelNewService: "Cancelar Novo Serviço",
    newService: "Novo Serviço",
    allowMinimus: "Mínimo Permitido",
    botNotifications: "Dutypay Notifications",
    isOldFlow: "É Fluxo Antigo",
    old: "Antigo",
    new: "Novo",
    mode: "Mode",
    airporttoDoor: "Aeroporto até a Porta",
    automatizePayment: "Autorizar Pagamento",
    needSupervisorApproval: "Essa ação precisa da aprovação do supervisor",
    chooseSendTo: "Por favor, escolha para quem você vai enviar a solicitação",
    firstMileDM: "Primeira Milha DM",
    freightForwarderDM: "Despachante DM",
    customClearenceDM: "Despacho Aduaneiro DM",
    lastMileDM: "Última Milha DM",
    priceTable: "Tabela de Preços",
    reverseDM: "Reversa DM",
    deliveryTime: "Prazo de Entrega",
    destinationCountry: "País de destino",
    originCountry: "País de origem",
    fields: {
      name: "Nome",
      slug: "Slug",
      deliveryMethods: "Delivery Methods",
      firstMileDeliveryMethod: "Método de Entrega da Primeira Milha",
      freightForwarderDeliveryMethod: "Método de Entrega do Despachante",
      customClearenceDeliveryMethod:
        "Método de Entrega de Desembaraço Aduaneiro",
      lastMileDeliveryMethod: "Método de Entrega da Última Milha",
      reverseDeliveryMethod: "Método de Entrega de Reversa",
      priceTable: "Tabela de Preços",
      deliveryTime: "Prazo de Entrega",
      priceLmit: "Preço Limite",
      creationDate: "Data de Criação",
      taxingType: "Tipo de Tributação",
      incoterm: "Termos Internacionais de Comércio",
      allowsMinimus: "Mínimos Permitidos",
      automaticPayment: "Pagamento Automático",
      maximumWeight: "Peso Máximo",
      maximumWidth: "Largura Máxima",
      maximumHeight: "Altura Máxima",
      maximumLength: "Comprimento Máximo",
      cubageFactor: "Fator de Cubagem",
      additionalServices: "Serviços Adicionais",
    },
  },
  contractsViewPage: {
    title: "Contrato #",
    downloadContractSettings: "Baixar Configurações de Contrato",
    subTitle: "Confira e edite, se preciso, os dados da transportadora",
    nameDefinitions: "Nome e Definições",
    destinationandOrigin: "País de Destino e Origem",
    destinationandOriginSubtitle: "Defina quais países o contrato cobre",
    separeteByCommas: "Separe cada código com vírgulas",
    descriptionName: "Nome descritivo (aparece no aplicativo do vendedor)",
    weightMeasure: "Peso e Medida",
    termContract: "Termo do Contrato",
    deliveryMethods: "Métodos de Entrega",
    deliveryMethod: "Método de Entrega",
    deliveryMethodsSubtitle:
      "Registre os responsáveis e o método de entrega correspondente para cada etapa do processo",
    notFoundDM: "Não encontrou seu método de entrega",
    registerDM: "Registre um novo método de entrega",
    uniqueDM: "Método de entrega único",
    uniqueDMSubtitle:
      "Este método de entrega é responsável por todo o contrato",
    pricesMeasuresCubage: "Pesos, medidas e cubagem",
    pricesMeasuresCubageSubtitle:
      "Preencha os valores de acordo com os transportadores e métodos registrados",
    cubageCalcHelper: "Calcular com base nas dimensões e peso máximos",
    priceDeliveryTime: "Tabela de Preços e Prazo de Entrega",
    priceDeliveryTimeSubtitle:
      "Estas tabelas determinam o custo estimado e o prazo de entrega do frete para este contrato",
    anyCountry: "Qualquer país",
    priceTableDeliveryTime: "Tabela de Preços e Prazo de Entrega",
    pdf: "pdf",
    csv: "csv",
    name: "Nome",
    ID: "ID",
    slug: "Slug",
    created: "Criação",
    firstMileDeliveryMethod: "Método de Entrega da Primeira Milha",
    freightForwarderDeliveryMethod: "Método de Entrega do Despachante",
    customClearenceDeliveryMethod: "Método de Entrega de Desembaraço Aduaneiro",
    lastMileDeliveryMethod: "Método de Entrega da Última Milha",
    allowsMinimus: "Mínimo Permitidos",
    isOldFlow: "É Fluxo Antigo",
    priceTable: "Tabela de Preços",
    obsolete: "Obsoleto",
    generatePaymentLink: "Gerar Link de Pagamento",
    taxingType: "Tipo de Tributação",
    incoterm: "Termos Internacionais de Comércio",
    automatizedpayment: "Pagamento Automatizado",
    reverseDeliveryMethod: "Método de Entrega de Reversa",
    deliveryTime: "Prazo de Entrega",
    airporttoDoor: "Aeroporto até a Porta",
    maximumWeight: "Peso Máximo",
    cubageFactor: "Fator de Cubagem",
    maximumPrice: "Preço Máximo",
    maximumLength: "Comprimento Máximo",
    maximumHeight: "Altura Máxima",
    maximumWidth: "Largura Máxima",
    externalID: "ID Externo",
    triggerOption: "Opção de Gatilho",
    triggerCondition: "Condição de Gatilho",
    primaryVariable: "Variável Primária",
    primaryValue: "Valor Primário",
    secondaryVariable: "Variável Secundária",
    secondaryValue: "Valor Secundário",
    additionalServices: "Serviços Adicionais",
    addedServices: "Added Services",
    additionalServicesSubtitle: "Set some rules for this contract",
    additional: "Additional",
    hSCodes: "HS Codes",
    editContract: "Editar Contrato",
    cloneContract: "Clonar Contrato",
    slugForNewContract: "Slug Para o Novo Contrato",
    isObsolete: "É Obsoleto",
    primaryType: "Tipo Primário",
    secondaryType: "Tipo Secundário",
    selecttype: "Selecione o tipo",
    addServices: "Adicionar aos Serviços",
    cancelNewService: "Cancelar Novo Serviço",
    newService: "Novo Serviço",
    allowMinimus: "Mínimo Permitido",
    automatizedPayment: "Pagamento Automatizado",
    airportToDoor: "Aeroporto até a Porta",
    needsSupervisorApproval: "Esta ação precisa da aprovação de um supervisor",
    chooseWhoSendRequest:
      "Por favor, escolha para quem você vai enviar a solicitação",
    wantToMakeContractObsolete: "Você quer tornar este contrato obsoleto",
    notBePossibleVisualize:
      "Não será possível visualizá-lo ou fazer alterações",
    removeService: "Remover Serviço",
    firstMileDM: "Primeira Milha DM",
    freightForwarderDM: "Despachante DM",
    customClearenceDM: "Despacho Aduaneiro DM",
    lastMileDM: "Última Milha DM",
    reverseDM: "Reversa DM",
    generalData: "Dados Gerais",
    generalDataSubtitle: "Preencha os detalhes abaixo",
    automatized: "Automatizado",
    notAutomatized: "Não automatizado",
  },
  deliveryMethodsPage: {
    title: "Métodos de Entrega",
    subTitle: "Gerencie Métodos de Entrega ou crie novas cooperações",
    addDeliveryMethod: "Adicionar Método de Entrega",
    cooperationTitle: "Título",
    cooperationList: "Lista de cooperações",
    cooperations: "Cooperações",
    addCooperations: "Adicione mais uma cooperação",
    eachCooperationUniqueTitle: "Cada cooperação tem um título único",
    name: "Nome",
    service: "Serviço",
    selectGroup: "Selecione o grupo",
    type: "Tipo",
    nationalization: "Nacionalização",
    trackingCredentials: "Credenciais de Rastreamento",
    addTracking: "Adicionar Rastreamento",
    dispatchCredentials: "Despachar Credenciais",
    addDispatch: "Adicionar Despacho",
    key: "Chave de autenticação",
    value: "Valor",
    carrier: "Transportadora",
    cooperationSlug: "Slug da Cooperação",
    fields: {
      name: "Nome",
      service: "Serviço",
      type: "Tipo",
      carrier: "Transportadora",
      carrierID: "ID da Transportadora",
      creationDate: "Data de Criação",
      cooperationSlug: "Slug da Cooperação",
    },
  },
  deliveryMethodsViewPage: {
    title: "Método de Entrega #",
    editDeliveryMethod: "Editar, Método de Entrega",
    edit: "Editar",
    name: "Nome",
    service: "Serviço",
    selectgroup: "Selecione o grupo",
    type: "Tipo",
    carrier: "Transportadora",
    selectcarrier: "Selecione a Transportadora",
    nationalization: "Nacionalização",
    trackingCredentials: "Credenciais de Rastreamento",
    key: "Chave de autenticação",
    value: "Valor",
    addTracking: "Adicionar Rastreamento",
    dispatchCredentials: "Despachar Credenciais",
    addDispatch: "Adicionar Despacho",
    owner: "Proprietário",
    ownerReference: "Referência do Proprietário",
    cooperationSlug: "Slug da Cooperação",
  },
  priceTablesPage: {
    title: "Tabela de preços e Prazos de entrega",
    subTitle:
      "Confira as tabelas de preços e prazos de entrega de seus clientes",
    helpTextTitle:
      "Create, delete, download delivery times and price tables of your costumers",
    titlePrice: "Tabela de Preços",
    titleDelivery: "Prazos de Entrega",
    importPricesTable: "Importar Tabela de Preços",
    importDeliveryTime: "Importar Prazo de Entrega",
    suretodelete: "Você tem certeza que quer deletar",
    selectCsv: "Selecione um arquivo csv para carregar",
    fileUploaded: "Arquivo enviado",
    downloadHere: "Baixe Aqui",
    ablankTable: "uma tabela vazia",
    import: "Importar",
    name: "Nome",
    removeOrders: "Remove Orders",
    shouldRemove1: "Quer mesmo remover estes",
    shouldRemove2: " pedidos? Esta ação é irreversível",
    shouldRemove3: " Esta ação é irreversível",
    alreadyInMasterbox:
      "Pedidos Selecionados já estão atrelados a uma masterbox e não serão removido",
    someInMasterbox:
      "Alguns pedidos já estão atrelados a uma masterbox e não serão removidos.",
    toBeRemoved:
      "remaining orders are available for removing. Are you sure you want to remove them? This action is irreversible",
    filesUpload: "Navegue pelos seus arquivos e carregue o seu",
    needsSupervisorApproval: "Esta ação precisa da aprovação de um supervisor",
    chooseToSendRequest:
      "Por favor, escolha para quem você vai enviar a solicitação",
    fields: {
      name: "Nome",
      actions: "Ações",
    },
  },
  shipstationContractsPage: {
    title: "Contratos da Shipstation",
    addShipstationContract: "Adicionar Contratos da Shipstation",
    shipstationContract: "Contratos da Shipstation",
    shipstationContractName: "Nome dos Contratos da Shipstation",
    seller: "Vendedor",
    contract: "Contrato",
    fields: {
      contract: "Contratos",
      sellerID: "Vendedor ID",
      shipstationContractName: "Nome dos Contratos da Shipstation",
      actions: "Ações",
    },
  },
  defaultPlansPage: {
    title: "Planos de Conta do Vendedor",
    name: "Nome",
    selectMultipleSellers: "Selecione Vários Vendedores",
    email: "E-mail",
    applySellers: "Aplicar aos Vendedores",
    newPlan: "Novo Plano",
    components: {
      categoryForm: {
        chargeDay: "Dia de Cobrança",
        firstChargeDay: "Primeiro Dia de Cobrança",
        chargeFrequency: "Frequência de Carga",
        limit: "Limite",
        fixedChargeDay: "Dia de Cobrança Fixa",
      },
      groupCategoryCards: {
        cost: "Custo",
        trackingStatus: "Status de Rastreamento",
        sMSCost: "Custo de SMS",
        whatsappCost: "Custo de Whatsapp",
      },
    },
  },
  sellerAccountEventsPage: {
    title: "Eventos da conta do vendedor",
    addCredit: "Adicionar Crédito",
    filterCategory: "Filtrar por categoria",
    limit: "Limite",
    balanceIs: "O saldo é",
    nBxBelowLimit: "NBx abaixo do limite",
    noLimit: "Sem limite",
    seller: "Vendedor",
    amount: "Quantia",
    currentExchange: "Câmbio Actual",
    description: "Descrição",
    defaultPlans: "Planos padrão",
    choosePlan: "Escolha um plano",
    generalSettings: "Configurações Gerais",
    subscriptionFee: "Taxa de inscrição",
    nBXvalueUSD: "Valor NBX em USD",
    limitTax: "Limite de Imposto",
    notifications: "Notificações",
    whatsappCost: "Custo de Whatsapp",
    sMSCost: "Custo de SMS",
    freight: "Frete",
    freightCost: "Custo do Frete",
    settings: "Configurações",
    chargeDay: "Dia de Cobrança",
    firstChargeDay: "Primeiro Dia de Cobrança",
    chargeFrequency: "Frequência de Carga",
    fixedChargeDay: "Dia de Cobrança Fixa",
    trackingCode: "Código de Rastreamento",
    updateSettings: "Editar Configurações",
    fields: {
      sellerID: "ID do Vendedor",
      sellerName: "Nome do Vendedor",
      category: "Categoria",
      attachment: "Anexo",
      value: "Valor",
      exchange: "Câmbio",
      description: "Descrição",
      creationDate: "Data de Criação",
      startValue: "Começo do Valor",
      endValue: "Fim do Valor",
    },
    currentExchangeHelpText: "Taxa do câmbio atual: $1 é igual a R$ %{value}",
    howMuchDoYouWantAdd: "Quanto você quer adicionar?",
  },
  alertLogsPage: {
    title: "Gerenciamento de Registros",
    subTitle: "Confira todos os logs gerados na plataforma.",
    helpTextTitle:
      "Messages generated or recorded by the system are located here",
    alertLogs: "Alert Logs",
    importValuesandStatuses: "Importar Valores e Status",
    updateStatus: "Atualizar Status",
    downloadLogs: "Baixar Registros",
    updateStatusInfo:
      "Se você deseja apenas atualizar status, deixe a coluna 'tax_value' vazia (o nome da coluna deve ser mantido)",
    new: "Novo",
    solved: "Resolvido",
    priority: "Prioridade",
    inProgress: "Em Progresso",
    waitingforPayment: "Esperando pelo Pagamento",
    sentAgain: "Enviado Novamente",
    paymentDone: "Pagamento Efetuado",
    pending: "Pendente",
    paidExternally: "Pago Externamente",
    needSellerAction: "Necessita de Ação do Vendedor",
    status: "Status",
    comment: "Comentário",
    checktheprocessingdownloads:
      "Verifique os downloads de processamento em... página de downloads",
    sureWantSave: "Tem certeza de que deseja salvar as alterações",
    newTrackingNumbers: "Novo número de rastreamento ",
    shouldbeCommaSeparated:
      "Os novos números devem ser separados por vírgula e seguir a sequência de Tracking Alert Logs selecionados: TAL_IDs:",
    unsaveddata: "Dados não Salvos",
    pleasesaveunsaveddata: "Salve os dados não salvos ou cancele",
    okay: "OK",
    name: "Nome",
    browseFilesUpload:
      "Navegue pelos seus arquivos e faça o upload do seu arquivo",
    selectcsvFileUpload: "Selecione um arquivo csv para carregar",
    downloadHere: "Baixe Aqui",
    instructionsXLSfile: "o arquivo XLS de instruções",
    fileUploaded: "Arquivo enviado",
    blankTable: "um arquivo em branco",
    fields: {
      volumeId: "Volume Id",
      trackingCode: "Código de Rastreamento",
      sellerId: "Id do Vendedor",
      seller: "Vendedor",
      message: "Mensagem",
      attachment: "Anexo",
      lastMileTrackingNumber: "Número de rastreamento da última milha",
      dateISO: "Data ISO",
      creationTime: "Hora de Criação",
      title: "Título",
      rule: "Regra",
      type: "Tipo",
      status: "Status",
      comment: "Comentário",
      dutiesPayment: "Pagamento de taxas",
    },
  },
  alertLogsViewPage: {
    title: "Registro de Alerta #",
    sellerChargedAmount: "Valor cobrado do vendedor",
    exchange: "Câmbio",
    incoterm: "Termos Internacionais de Comércio",
    status: "Status",
    date: "Data",
    dateIso: "Data Iso",
    orderID: "Pedido ID",
    orderNumber: "Número do Pedido",
    volumeID: "Volume ID",
    sellerID: "Vendedor ID",
    taxID: "Número de Identificação Fiscal",
    lastMileNumber: "Número da última milha",
    productsValue: "Valor dos Produtos",
    freightValue: "Valor do frete",
    expectedTax: "Imposto esperado",
    realTax: "Imposto Real",
    valueR$: "Valor R$",
    editableValues: "Editable values",
  },
  bilLogsPage: {
    title: "Registros de Contas",
    fields: {
      mawb: "MAWB",
      house: "House",
      volumeID: "Volume ID",
      status: "Status",
      creationDate: "Data de Criação",
    },
  },
  bilLogsViewPage: {
    title: "Registro de Faturamento #",
    mawb: "Mawb",
    house: "House",
    creationDate: "Data de Criação",
    status: "Status",
    volumeID: "Volume ID",
    logs: "Registros",
  },
  delayedJobsPage: {
    title: "Registros Delayed Jobs",
    fields: {
      queue: "Fila",
      priority: "Prioridade",
      attempts: "Tentativas",
      handler: "Gerenciador",
      lastError: "Último Erro",
      runat: "Executado em",
      lockedat: "Bloqueado em",
      failedat: "Falha em ",
      lockedby: "Bloqueado por",
    },
  },
  externalLogs: {
    title: "Registros externos de APIs",
    fields: {
      apiName: "Nome API",
      function: "Função",
      userID: "Usuário ID",
      response: "Resposta",
      creationDate: "Data de Criação",
    },
  },
  externalViewLogs: {
    title: "Registo API Externo #",
    apiName: "Nome API",
    userName: "Nome do Usuário",
    function: "Função",
    creationDate: "Data de Criação",
    updateDate: "Data de Atualização",
    params: "Parâmetros",
    response: "Resposta",
  },
  generalEmailsPage: {
    title: "E-mails Gerais",
    subTitle: "Check, read and create general emails",
    createGeneralEmails: "Criar e-mail geral",
    titleGeneral: "Título",
    sellersSelected: "Selecionar Vendedores",
    body: "Corpo do e-mail",
    destination: "Destinatário",
    destinationsSellers: "Destinatários dos Vendedores",
    fields: {
      titleGeneral: "Título",
      Destination: "Destinatário",
      Body: "Corpo do e-mail",
      Sent: "Enviado",
    },
  },
  gGeneralEmailsViewPage: {
    title: "E-mails Gerais #",
    iD: "ID",
    sent: "Enviado",
    titleGeneral: "Título",
    destination: "Destinatário",
    body: "Corpo do e-mail",
    editGeneralEmail: "Edit General Email",
    deleteGeneralEmail: "Deletar E-mails Gerais",
    sureWantDelete: "Tem certeza de que deseja excluir este e-mail geral",
  },
  nobordistLogsPage: {
    title: "Registros Nobordist",
    fields: {
      action: "Ação",
      actionType: "Tipo de Ação",
      userID: "Usuário ID",
      logs: "Registros",
      creationDate: "Data de Criação",
    },
  },
  nobordistLogsViewPage: {
    title: "Registro #",
    action: "Ação",
    userName: "Nome do Usuário",
    creationDate: "Data de Criação",
    updateDate: "Data de Atualização",
    logs: "Registros",
  },
  notificationsPage: {
    title: "Enviar notificações",
    subtitle: "Envie e-mails e notificações para seus clientes.",
    newNotification: "Nova notificação",
    settingYourNotification: "Configure sua notificação.",
    generalData: "Dados gerais",
    settingNotificationParams: "Defina os parâmetros da notificação",
    selectRecipientType: "Selecione o tipo de destinatário",
    to: "Para",
    recipient: "Destinatário",
    subject: "Assunto",
    message: "Mensagem",
    sendNow: "Enviar agora",
    subjects: {
      finance: "Finance",
      insufficientBalance: "Insufficient balance",
      trackingIssue: "Tracking issue",
      newTicket: "New ticket",
    },
    finance: "Finança",
    insufficientBalance: "Saldo Insuficiente",
    trackingIssue: "Problema de Rastreamento",
    newTicket: "Novo Ticket",
    seller: "Vendedor",
    user: "Usuário",
    readed: "Lido",
    seeMessage: "Ver mensagem",
    notification: "Notificação",
    seeSendedNotification: "Visualize a notificação que foi enviada.",
    sendedMessage: "Mensagem enviada",
    sendDate: "Data de envio",
    createNotification: "Create Notification",
    body: "Mensagem",
    respondApprovalRequest: "Responder à solicitação de aprovação",
    notificationMessage: "Mensagem de Notificação",
    contract: "Contrato",
    name: "Nome",
    file: "Arquivo",
    validityDate: "Data de Validade",
    contracts: "Contratos",
    click: "Clique",
    hERE: "AQUI",
    seeDetailedData: "para ver os dados detalhados",
    thisRequest: "Esse pedido",
    is: "é",
    hasBeen: "foi",
    supervisorCommented: "O supervisor comentou",
    addCommentResponse: "Você pode adicionar um comentário à sua resposta",
    approve: "Aprovar",
    deny: "Negar",
    by: "por",
    ChooseRecipientType: "Escolha o tipo de destinatário*",
    Seller: "Seller",
    User: "User",
    Title: "Título",
    dataUpdate: "Editar Data",
    contractPermission: "Permissão de Contrato",
    contractUpdate: "Editar Contrato",
    priceTableUpdate: "Atualizar Tabela de Preços",
    fields: {
      newRead: "Novo/Lido",
      tType: "Tipo",
      recipient: "Destinatário",
      title: "Título",
      body: "Mensagem",
      status: "Status",
    },
    titleNotification: "Título",
    type: "Tipo",
    new: "Novo",
  },
  rejectedOrderPage: {
    title: "Registros de Pedidos Rejeitados",
    subTitle: "Logs dos pedidos rejeitados",
    sellerName: "Nome do Vendedor",
    fields: {
      orderNumber: "Número do Pedido",
      dateImport: "Data da importação",
      orderInfo: "Informações do Pedido",
      sellerID: "Vendedor ID",
      error: "Erro",
    },
  },
  rejectedOrderViewPage: {
    title: "Registo de Pedidos Rejeitados #",
    seller: "Vendedor",
    creationDate: "Data de Criação",
    orderInfo: "Informações do Pedido",
    erroInfo: "Informações erros",
  },
  trackingRulesPage: {
    title: "Regras de Rastreamento",
    subTitle: "Gerencie regras de rastreamento ou crie novas regras",
    addTrackingRule: "Adicionar Regra de Rastreamento",
    addNewTrackingRule: "Adicionar Nova Regra de Rastreamento",
    trackingMode: "Modo de Rastreamento",
    receivedCheckpoint: "Ponto de Verificação Recebido",
    timeBetweenTwoCheckpoints: "Tempo entre dois pontos de verificação ",
    name: "Nome",
    contract: "Contrato",
    incoterm: "Termos Internacionais de Comércio",
    any: "Qualquer",
    startCheckpoint: "Começo Ponto de Verificação",
    endCheckpoint: "Fim Ponto de Verificação",
    maximumDays: "Máximo de Dias",
    checkpoint: "Ponto de Verificação",
    log: "Gera Registros",
    notifySeller: "Notifique o vendedor",
    sellerNotification: "Notificação do Vendedor",
    notifyCustomer: "Notificar Cliente",
    notifyNobordist: "Notificar Nobordist",
    webhook: "Usar Webhook",
    method: "Método",
    pOST: "POST",
    pUT: "PUT",
    headers: "Cabeçalhos",
    addHeaders: "Adicionar Cabeçalhos",
    key: "Chave de autenticação",
    value: "Valor",
    seller: "Vendedor",
    titleRules: "Título",
    fields: {
      name: "Nome",
      startCheckpoint: "Começo Ponto de Verificação",
      endCheckpoint: "Fim Ponto de Verificação",
      incoterm: "Termos Internacionais de Comércio",
      seller: "Vendedor",
      contract: "Contrato",
      daysLimit: "Limite de Dias",
      mode: "Modo",
      creationDate: "Data de Criação",
    },
    allSellerNotificationTitles: {
      finance: "Finança",
      insufficientBalance: "Saldo Insuficiente",
      trackingIssue: "Problema de Rastreamento",
    },
  },
  trackingRulesViewPage: {
    title: "Regra de Rastreamento #",
    tracking: "Tracking",
    editTrackingRule: "Editar Regra de Rastreamento",
    deleteTrackingRule: "Deletar Regra de Rastreamento",
    edit: "Editar",
    name: "Nome",
    contract: "Contrato",
    startCheckpoint: "Começo Ponto de Verificação",
    endCheckpoint: "Fim Ponto de Verificação",
    maximumDays: "Máximo de Dias",
    log: "Registro",
    notifySeller: "Notifique o Vendedor",
    notifyCustomer: "Notificar Cliente",
    notifyNobordist: "Notificar Nobordist",
    webhook: "Webhook",
    method: "Método",
    pOST: "POST",
    pUT: "PUT",
    headers: "Cabeçalhos",
    key: "Chave de autenticação",
    value: "Valor",
    addHeaders: "Adicionar Cabeçalhos",
    sureWantDelete:
      "Tem certeza de que deseja excluir esta regra de rastreamento",
    seller: "Vendedor",
    customer: "Customer",
    nobordist: "Nobordist",
    daysLimit: "Limite de Dias",
    trackingMode: "Modo de Rastreamento",
    sellerMailTitle: "Título do e-mail do vendedor",
    sellerMailBody: "Corpo do e-mail do vendedor",
    customerMailTitle: "Título do Email do Cliente",
    customerMailBody: "Corpo do e-mail do Cliente",
    nobordistMailTitle: "Título do Email Nobordist",
    destination: "Destinatário",
    nobordistMailBody: "Corpo do e-mail Nobordist",
    notifyWebhook: "Webhook de notificação",
    endpoint: "Endpoint",
    body: "Mensagem",
  },
  userNotificationsPage: {
    title: "Notificações do Usuário",
    subTitle: "Read notifications sent to you",
    respondRequest: "Responder à solicitação de aprovação",
    notificationMessage: "Mensagem de Notificação",
    name: "Nome",
    file: "Arquivo",
    validityDate: "Data de Validade",
    contracts: "Contratos",
    contract: "Contrato",
    click: "Clique",
    hERE: "AQUI",
    seeDetailedData: "para ver os dados detalhados",
    thisRequest: "Esse pedido",
    is: "é",
    hasBeen: "foi",
    by: "por",
    supervisorCommented: "O supervisor comentou",
    addCommentResponse: "Você pode adicionar um comentário à sua resposta",
    approve: "Aprovar",
    deny: "Negar",
    seller: "Vendedor",
    fields: {
      data: "Data",
      title: "Título",
      body: "Mensagem",
    },
    filgerOptions: {
      title: "Título",
      seller: "Vendedor",
      body: "Mensagem",
      new: "Novo",
    },
    allSellerNotificationTitles: {
      finance: "Finança",
      insufficientBalance: "Saldo Insuficiente",
      trackingIssue: "Problema de Rastreamento",
    },
    sellerNotificationTitles: {
      finance: "Finança",
      insufficientBalance: "Saldo Insuficiente",
      trackingIssue: "Problema de Rastreamento",
      newTicket: "Novo Ticket",
    },
    userNotificationTitles: {
      finance: "Finança",
      insufficientBalance: "Saldo Insuficiente",
      trackingIssue: "Problema de Rastreamento",
      newTicket: "Novo Ticket",
      dataUpdate: "Atualizar Dados",
      contractPermission: "Permissão de Contrato",
      contractUpdate: "Editar Contrato",
      priceTableUpdate: "Editar Tabela de Preços",
    },
    seconds: "segundos",
    minuteAgo: "1 minuto atrás",
    minutes: "minutos",
    hourAgo: "1 hora atrás",
    hours: "horas",
    yesterday: "ontem",
    days: "dias",
    lastWeek: "Última semana",
    weeks: "semanas",
    lastMonth: "Último mês",
    months: "mêses",
    lastYear: "Último ano",
    years: "anos",
  },
  myNotificationsListPage: {
    title: "Notificações",
    found: "nenhuma notificação encontrada | notificação | notificações",
    filters: {
      isNew: "Nova ou Lida",
    },
    allNotifications: "Ver todas as notificações",
  },
  platformsPage: {
    title: "Plataformas",
    addPlatform: "Adicionar Plataformas",
    AddNewPlatform: "Adicionar Nova Plataforma",
    platformName: "Nome da Plataforma",
    correiosCredentials: "Credenciais dos Correios",
    addDispatch: "Adicionar Despacho",
    key: "Chave de autenticação",
    value: "Valor",
    fields: {
      name: "Nome",
      actions: "Ações",
    },
  },
  flightsPage: {
    title: "Vôos",
    subtitle: "Confira e informe em qual vôo se encontra cada pedido.",
    nextFlights: "Próximos vôos",
    createNewFlight: "Criar novo vôo",
    newFlight: "Novo vôo",
    selectMasterboxes: "Selecionar masterboxes",
    findAndSelectMasterboxes:
      "Procure e selecione as masterboxes que deseja adicionar ao seu vôo",
    typeMasterboxesReference:
      'Digite a referência da Masterbox e pressione "Enter"',
    selectedMasterboxes: "Masterboxes selecionadas",
    reference: "Referência",
    next: "Prosseguir",
    insertFlightData: "Inserir dados do vôo",
    pleaseInsertFlightData:
      "Por favor, insira os dados do vôo para adicionar as masterboxes.",
    howWouldLikeToUploadMawb: "Como gostaria de fazer o upload do MAWB?",
    generalData: "Informações gerais",
    departureAndArrive: "Partida e chegada",
    taxes: "Taxas",
    returnsToPreviousStep: "Retornar à etapa anterior",
    createFlight: "Criar vôo",
    uploadFromMyComputer: "Upload do meu computador",
    documentsAndLabels: "Documentos e etiquetas",
    editFlightData: "Editar dados do vôo",
    suggestedActions: "Ações sugeridas",
    copyLabelCode: "Copiar código MAWB",
    taxesAndOthers: "Taxas e outros",
    masterboxes: "Masterboxes",
    departure: "Partida",
    stopover: "Escala",
    arrival: "Chegada",
    noStopOver: "Vôo sem escala",

    closeManifestConfirm:
      "Deseja fechar este manifesto? Você não poderá mais atualizar seu conteúdo após esta ação.",
    closeManifest: "Fechar Manifesto",
    confirmDeparture: "Confirmar Partida",
    deleteFlight: "Remover vôo",
    fields: {
      airline: "Companhia Aérea",
      flightNumber: "Número do Voo",
      flightLabel: "Etiqueta de Voo",
      mawbNumber: "Número Mawb",
      greenLight: "Green Light",
      prealertsSent: "Pré-alertas enviados",
      departureAirport: "Aeroporto de Partida",
      arrivalAirport: "Aeroporto de Chegada",
      departureDate: "Data de Partida",
      creationDate: "Data de Criação",
      status: "Status",
    },
  },
  flightsViewPage: {
    title: "Voo #",
    mawbFileLink: "Mawb File Link",
    mawbLabel: "Etiqueta de Voo",
    updateFlight: "Update Flight",
    information: "Information",
    mawb: "Mawb",
    shipments: "Remessas",
    pages: "Páginas",
    closeManifest: "Fechar Manifesto",
    confirmDeparture: "Confirmar Partida",
    wantRemoveFlight: "Deseja remover este voo permanentemente",
    departureAirport: "Aeroporto de Partida",
    arrivalAirport: "Aeroporto de Chegada",
    stopoverAirport: "Aeroporto de Escala",
    departureDate: "Data de Partida",
    arrivalDate: "Data de Chegada",
    departureTime: "Hora de Partida",
    arrivalTime: "Hora da Chegada",
    airline: "Companhia Aérea",
    flightNumber: "Número do Voo",
    subtotal: "Subtotal",
    chargesKilo: "Cobranças por quilo",
    otherCharges: "Outras Cobranças",
    mawbNumber: "Número Mawb",
    mawbFileFormat: "Formato de Arquivo Mawb",
    binary: "binário",
    link: "link",
    mawbFile: "Arquivo Mawb",
    copyReference_id: "clique aqui para copiar o reference_id",
    greenLight: "Green Light",
    prealertsSend: "Envio de pré-alertas",
    date: "Data",
    downloadCurrent: "Download este MAWB",
    noMawbDocument: "Atualmente não há documento MAWB para este voo",
  },
  masterboxesPage: {
    title: "Masterboxes",
    subtitle: "Filtre e confira caixas com os volumes e pedidos.",
    createMasterbox: "Criar masterbox",
    documentsAndLabels: "Documentos e etiquetas",
    suggestedActions: "Ações sugeridas",
    masterboxData: "Dados da Masterbox",
    labels: "Etiquetas",
    mbCarriers: "Transportadoras",
    reference: "Referência",
    shipmentID: "ID da remessa",
    shipmentSequenceNumber: "Número de Sequência de Remessa",
    date: "Data",
    cN35Label: "Etiqueta CN35",
    volumes: "Volumes",
    dispatchID: "ID de despacho",
    goToShipment: "Visualizar remessa",
    creationDate: "Data de Criação",
    weight: "Peso",
    cubedWeight: "Peso Cubado",
    lastMileTrackingNumber: "Número de rastreamento da última milha",
    lastMileCarrierNumber: "Número da operadora da última milha",
    totalItems: "Total de Itens",
    deleteMasterbox: "Remover Masterbox",
    confirmDeleteMasterbox:
      "Você deseja remover essa masterbox permanentemente?",
    selectVolumes: "Selecione os volumes",
    selectedVolumes: "Volumes selecionados",
    findAndSelectVolumes:
      "Procure e selecione volumes que deseja adicionar a masterbox",
    typeVolumeReference: 'Digite a referência do Volume e pressione "Enter"',
    downloadMasterboxErrors: "Baixar CSV de erros do Masterbox",
    commentDownloadMasterboxErrors:
      "* Somente volumes com last_mile_tracking_number serão adicionados ao csv",
  },
  masterboxesViewPage: {
    title: "Masterbox #",
    dispatchID: "ID de despacho",
    reference: "referência",
    shipmentID: "ID da remessa",
    shipmentSequenceNumber: "Número de Sequência de Remessa",
    date: "Data",
    cN35Label: "Etiqueta CN35",
    volumes: "Volumes",
    copyReference_id: "clique aqui para copiar o reference_id",
    fields: {
      weight: "Peso",
      cubedWeight: "Peso Cubado",
      lastMileTrackingNumber: "Número de rastreamento da última milha",
      lastMileCarrierNumber: "Número da operadora da última milha",
      totalItems: "Total de Itens",
    },
  },
  ordersPage: {
    title: "Gestão de Pedidos",
    subtitle: "Filtre e confira os pedidos, baixe relatórios e mais.",
    importMabangOrders: "Importar Pedidos de Mabang",
    importOrderCosts: "Importar Custo de Pedidos",
    downloadOrders: "Baixar Pedidos",
    downloadOrdersCosts: "Baixar Custos de Pedidos",
    updateFinancialStatus: "Atualizar Situação Financeira",
    lastMileCarrierNotFound: "Nº da operadora da última milha não encontrado",
    new: "Novo",
    invoiced: "Faturado",
    toInvoice: "Faturar",
    canceled: "Cancelado",
    notCharged: "Não Cobrado",
    checkDownloadsAt:
      "Verifique os downloads de processamento na página de downloads.",
    selectOption: "Por favor selecione uma opção",
    orderNo: "Pedido N°",
    fieldsCurrentOrderCosts: {
      externalID: "ID Externo",
      type: "Tipo",
      value: "Valor",
      creationDate: "Data de Criação",
    },
    financial_statuses: {
      new: "Novo",
      toInvoice: "Faturar",
      invoiced: "Faturado",
      canceled: "Cancelado",
      notCharged: "Não Cobrado",
    },
    fields: {
      sellerID: "Vendedor ID",
      sellerName: "Nome do vendedor",
      sellerTaxNumber: "Número fiscal do vendedor",
      contractID: "Contrato ID",
      overpackID: "Pacote ID",
      customerName: "Nome do Cliente",
      postalCode: "Código Postal",
      customerCity: "Cidade do Cliente",
      customerState: "Estado do Cliente",
      incoterm: "Termos Internacionais de Comércio",
      orderNumber: "Número do Pedido",
      salesChOrderN: "Nº do Pedido do Canal de Vendas",
      productsValue: "Valor dos Produtos",
      freightValue: "Valor do frete",
      declaredValue: "Valor declarado",
      dutiesTaxValue: "Valor do imposto de direitos",
      salesTaxValue: "Valor do imposto sobre vendas",
      taxValue: "Valor Fiscal",
      totalValue: "Valor Total",
      estimatedDutiesTaxValue: "Valor de Imposto Estimado de Direitos",
      estimatedSalesTaxValue: "Valor estimado do imposto sobre vendas",
      estimatedTaxValue: "Valor fiscal estimado",
      freightCost: "Custo do Frete",
      estimatedFreightCost: "Custo estimado de frete",
      taxCost: "Custo fiscal",
      insuranceCost: "Custo de seguro",
      insuranceCoverage: "Cobertura do seguro",
      dDPCost: "Custo DDP",
      totalGeneralCosts: "Custos Gerais Totais",
      trackingNumber: "Número de Rastreio",
      lastMileCarrierNumber: "Número da operadora da última milha",
      isMini: "É Mini?",
      financialStatus: "Status financeiro",
      creationDate: "Data de Criação",
      mSDS: "MSDS",
      label: "Etiqueta",
      firstMileLabel: "Etiqueta da primeira milha",
      shipConfirmation: "Confirmação de envio",
      isLanded: "Está desembarcado?",
    },
    removeOrdersCount: "Remover %{count} pedidos",
    removeOrders: "Remover pedidos",
    toBeRemoved:
      "Os %{count} pedidos restantes estão disponíveis para remoção. Tem certeza de que deseja removê-los? Esta ação é irreversível",
  },
  ordersViewPage: {
    title: "Pedido #",
    label: "Etiqueta",
    addCost: "Adicionar Custo",
    commercialInvoice: "Fatura Comercial",
    trackingPage: "Página de Rastreamento",
    packingSlip: "Guia de Remessa",
    cevaShippersConfirmation: "Confirmação Ceva Remetentes",
    cUSTOMER: "CLIENTES",
    relatedObjects: "Objetos relacionados",
    freight: "FRETE",
    editOrder: "Editar Pedido",
    generalInformations: "INFORMAÇÕES GERAIS",
    valuesSummary: "RESUMO DE VALORES",
    generalCosts: "Despesas Gerais",
    volumes: "Volumes",
    costType: "Tipo de Custo",
    selecttype: "Selecione o tipo",
    value: "Valor",
    name: "Nome",
    documentType: "Tipo de Documento",
    documentNumber: "Número do Documento",
    address: "Endereço",
    addressNumber: "Número do endereço",
    addressComplement: "Complemento de Endereço",
    addressReference: "Referência do Endereço",
    postalCode: "Código Postal",
    addressQuarter: "Bairro do Endereço",
    city: "Cidade",
    state: "Estado",
    country: "País",
    phone: "Telefone",
    email: "E-mail",
    mailbox: "Caixa de correio",
    phoneCountryCode: "Telefone Código do País",
    salesChannelID: "ID do canal de vendas",
    sellerIDcomp: "Vendedor ID comp",
    contractID: "Contrato ID",
    overpackID: "Pacote ID",
    currency: "Moeda",
    incoterm: "Termos Internacionais de Comércio",
    returnInsurance: "Seguro Devolução",
    insuranceCoverage: "Cobertura do Seguro",
    returnModality: "Modalidade de Devolução",
    freightValue: "Valor do Frete",
    orderNumbe: "Número do Pedido",
    createdDate: "Data de Criação",
    sellerName: "Nome do Vendedor",
    estimatedDeliveryDate: "Data de Entrega Estimada",
    trackingNumber: "Número de Rastreio",
    isMini: "É Mini",
    observation: "Observação",
    declaredValue: "Valor declarado",
    taxValue: "Valor Fiscal",
    total: "Total",
    remove: "remover",
    wantToRemore: "Quer mesmo remover este pedido? Esta ação é irreversível",
    estimatedTaxValue: "Valor fiscal estimado",
    freightCost: "Custo do Frete",
    volumesFields: {
      Weight: "Peso",
      cubedWeight: "Peso Cubado",
      lastMileTrackingNumber: "Número de rastreamento da última milha",
      lastMileCarrierNumber: "Número da operadora da última milha",
      productValue: "Valor do Produto",
      freightValue: "Valor do frete",
      estimatedFreightCost: "Custo estimado de frete",
      taxValue: "Valor Fiscal",
      totalItems: "Total de Itens",
      updatedWeight: "Updated Weight",
    },
    fieldsCurrentOrderCosts: {
      externalID: "ID Externo",
      type: "Tipo",
      value: "Valor",
      creationDate: "Data de Criação",
    },
    orderData: "Dados do pedido",
    customerData: "Dados do cliente",
    shippingInfo: "Informações de envio",
    reviewValues: "Resumos dos valores",
    copyTrackingNumber: "Copiar Número de Rastreio",
    showAllCustomerData: "Mostrar todos os dados do cliente",
    collapseCustomerData: "Colapsar dados do cliente",
    showAllOrderData: "Mostrar todos os dados do pedido",
    collapseOrderData: "Colapsar dados do pedido",
  },
  overpacksPage: {
    title: "Gerenciamente de Pacotes",
    updateFirstMileInformation: "Atualizar Informações da Primeira Milha",
    carriername: "Nome da Transportadora",
    trackingNumber: "Número de Rastreio",
    updateInformation: "Atualizar Informação",
    updateTrackingStatus: "Atualizar Status de Rastreamento",
    updateOverpacks: "Atualizar Overpacks",
    overpackSelected: "Pacote Selecionado",
    selectOneStatus: "Selecione um Status",
    statusSelected: "Status Selecionado",
    fields: {
      sentDate: "Data de envio",
      trackingNumber: "Número de Rastreio",
      masterboxID: "Masterbox ID",
      fMTrackingN: "N° de Rastreamento PM",
      firstMileCompany: "Companhia da Primeira Milha",
      creationDate: "Data de Criação",
    },
    trackingOptions: {
      forwardedlocalhub: "A remessa foi encaminhada para um hub local",
      arrivedHubSorting: "A remessa chegou a um hub ou centro de triagem",
      clearanceCompleted: "O processo de desembaraço aduaneiro é concluído",
      shipmentArrivedDestinationCountry:
        "A remessa internacional chegou ao país de destino",
      handedCustomsCustomsClearance:
        "A remessa foi entregue à alfândega para o processo de desembaraço aduaneiro",
      customsClearanceCompleted:
        "O processo de desembaraço aduaneiro é concluído",
      arrivedHubSortingCenter: "A remessa chegou a um hub ou centro de triagem",
    },
  },
  overpacksViewPage: {
    title: "Pacote #",
    new: "Novo",
    ready: "Preparar",
    dispatched: "Despachado",
    orders: "Pedidos",
    orderFoundOverpack: "Nenhum pedido encontrado para o Pacote #",
    cN23Numbers: "CN23 Números",
    cN23foundforOverpack: "Nenhum CN23 encontrado para o Pacote #",
    masterboxID: "Masterbox ID",
    createdDate: "Data de Criação",
    sentDate: "Data de envio",
    trackingNumber: "Número de Rastreio",
    firstMileCarrierName: "Nome da Transportadora da Primeira Milha",
    firstMileTrackingN: "N° de Rastreamento da Primeira Milha",
    fields: {
      orderNumber: "Número do Pedido",
      trackingNumber: "Número de Rastreio",
      creationDate: "Data de Criação",
      customerName: "Nome do Cliente",
    },
  },
  shipmentsPage: {
    title: "Remessas",
    subtitle: "Filtre e confira as remessas, baixe relatórios e mais.",
    downloadShipments: "Baixar Remessas",
    actualWeight: "Atual Peso",
    checkProcessingDownloads:
      "Verifique os downloads de processamento em... página de downloads",
    goToFlight: "Ir para o Vôo",
    fields: {
      cn38: "cn38",
      cn38Label: "Etiqueta cn38",
      sSellerWeight: "Peso - Vendedor",
      actualWeight: "Peso Atual",
      category: "Categoria",
      flightID: "Voo ID",
      creationDate: "Data de Criação",
    },
    filterOptions: {
      sellerStartWeight: "Vendedor - Começo do Peso",
      sellerEndWeight: "Vendedor - Fim do Peso",
      actualStartWeight: "Começo do Peso Atual",
      actualEndWeight: "Fim do Peso Atual",
    },
  },
  shipmentsViewPage: {
    title: "Remessa #",
    category: "Categoria",
    flightID: "Voo ID",
    cn38: "cn38",
    date: "Data",
    cn38Label: "Etiqueta cn38",
    masterboxes: "Masterboxes",
    pages: "Páginas",
    fields: {
      reference: "Referência",
      dispatchID: "ID de despacho",
      lastMileTrackingNumbers: "Números de rastreamento da última milha",
    },
  },
  volumeReversePage: {
    title: "Reversa dos Volumes",
    subtitle: "Filtre e confira pedidos, baixe relatórios e mais.",
    fields: {
      orderNumber: "Número do Pedido",
      orderID: "Pedido ID",
      volumeID: "Volume ID",
      sellerID: "Vendedor ID",
      name: "Nome",
      creationdate: "Data de Criação",
      reverseNumber: "Número da Reversa",
      address: "Endereço",
      addressNumber: "Número do Endereço",
      addressComplement: "Complemento do Endereço",
      state: "Estado",
      postalCode: "Código Postal",
      country: "País",
      city: "Cidade",
      customerName: "Nome do Cliente",
      orderItemsName: "Nome dos Itens do Pedido",
      orderItemsdescription: "Descrição dos Itens do Pedido",
      orderItemsHSCode: "HS Code dos Itens do Pedido",

      startRegistrationDate: "Data de início do registro",
      endRegistrationDate: "Data final do registro",
    },
  },
  volumeReverseViewPage: {
    title: "Reversa do Volume #",
    volumeReverse: "REVERSA DO VOLUME",
    order: "PEDIDO",
    volume: "VOLUME",
    customer: "CLIENTE",
    items: "Itens",
    reverseNumber: "Número da Reversa",
    createdDate: "Data de Criação",
    name: "Nome",
    address: "Endereço",
    addressNumber: "Número do Endereço",
    addressComplement: "Complemento do Endereço",
    state: "Estado",
    postalCode: "Código Postal",
    country: "País",
    city: "Cidade",
    orderID: "Pedido ID",
    orderNumber: "Número do Pedido",
    salesChannelOrderNumber: "Número do Pedido do Canal de Vendas",
    totalValue: "Valor Total",
    trackingNumber: "Número de Rastreio",
    volumeID: "Volume ID",
    height: "Altura",
    width: "Largura",
    length: "Comprimento",
    weight: "Peso",
    lastMileTrackingNumber: "Número de rastreamento da última milha",
    documentType: "Tipo de Documento",
    documentNumber: "Número do Documento",
    addressReference: "Referência do Endereço",
    addressQuarter: "Bairro do Endereço",
    phone: "Telefone",
    email: "E-mail",
    mailbox: "Caixa de correio",
    phoneCountryCode: "Telefone Código do País",
    fields: {
      name: "Nome",
      value: "Valor",
      hsCode: "Hs Code",
      Quantity: "Quantidade",
      originCountry: "País de Origem",
    },
  },
  volumesPage: {
    title: "Volumes",
    new: "Novo",
    invoiced: "Faturado",
    toInvoice: "Faturar",
    canceled: "Cancelado",
    notCharged: "Não Cobrado",
    downloadMasterboxErrors: "Baixar CSV de erros do Masterbox",
    commentDownloadMasterboxErrors:
      "* Somente volumes com last_mile_tracking_number serão adicionados ao csv",
    wantCreateMasterbox:
      "Você quer criar uma masterbox os volumes abaixo selecionados?",
    volumes: "volumes",
    copyReference_id: "clique aqui para copiar o reference_id",
    downloadVolumes: "Baixar volumes",
    createMasterbox: "Criar masterbox",
    fields: {
      weight: "Peso",
      height: "Altura",
      Width: "Width",
      length: "Comprimento",
      cubedWeight: "Peso Cubado",
      reference: "Referência",
      lastMileCarrierNumber: "Número da operadora da última milha",
      lastMileTrackingNumber: "Número de rastreamento da última milha",
      collectedIcms: "ICMS recolhido",
      collectedIi: "II recolhido",
      orderIsCommercialDestination: "Pedido - É Destino Comercial",
      isCommercialDestination: "É Destino Comercial",
      orderSellerRfbCode: "Código RFB do Vendedor do pedido",
      sellerRfbCode: "Código RFB do Vendedor",
      orderSellerLegalName: "Nome Legal do Vendedor do pedido",
      sellerLegalName: "Nome Legal do Vendedor",
      firstMileTrackingNumber: "Número de rastreamento da primeira milha",
      cN23: "CN23",
      firstMileLabel: "First Mile Label",
      creationDate: "Data de Criação",
      volumeProductsValue: "Valor dos produtos do volume",
      volumeFreightValue: "Valor do frete de volume",
      volumeDeclaredValue: "Valor declarado do volume",
      volumeDutiesTaxValue: "Valor do Imposto sobre Volume",
      volumeSalesTaxValue: "Valor do imposto sobre vendas por volume",
      volumeTaxValue: "Valor do imposto de volume",
      volumeTotalValue: "Volume Total Value",
      volumeEstimatedDutiesTaxValue: "Valor de Imposto Estimado de Volume",
      volumeEstimatedSalesTaxValue:
        "Valor estimado do imposto sobre vendas por volume",
      volumeEstimatedTaxValue: "Valor de imposto estimado de volume",
      volumeFreightCost: "Custo de frete por volume",
      volumeEstimatedFreightCost: "Custo de Frete Estimado por Volume",
      hSCode: "HS Code",
      description: "Descrição",
      currentStatus: "Status atual",
      updatedWeight: "Peso Atualizado",
      orderID: "Pedido ID",
      mSDS: "MSDS",
      sellerID: "Vendedor ID",
      firstMileN: "Primeira Milha Nº",
      contractID: "Contrato ID",
      customerName: "Nome do Cliente",
      cPostalCode: "C. Código Postal",
      customerCity: "Cidade do Cliente",
      customerState: "Estado do Cliente",
      customerAddress: "Endereço do Cliente",
      customerAddressNumber: "Número do Endereço do Cliente",
      customerAddressComplement: "Complemento de Endereço do Cliente",
      customerAddressReference: "Referência de Endereço do Cliente",
      customerAddressQuarter: "Bairro do Endereço do Cliente",
      customerDocumentType: "Tipo de Documento do Cliente",
      customerDocumentNumber: "Número do Documento do Cliente",
      customerCountry: "País do Cliente",
      customerEmail: "E-mail do Cliente",
      customerPhone: "Telefone do Cliente",
      currency: "Moeda",
      estimatedDeliveryDate: "Data de Entrega Estimada",
      incoterm: "Termos Internacionais de Comércio",
      orderNumber: "Número do Pedido",
      salesChannel: "Canal de Vendas",
      salesChOrderNumber: "N° Pedido do Canal de Vendas",
      totalValue: "Valor Total",
      orderReference: "Referência do Pedido",
      financialStatus: "Status Financeiro",
      orderProductsValue: "Valor dos Produtos do Pedido",
      orderFreightValue: "Valor do Frete do Pedido",
      orderDeclaredValue: "Valor Declarado do Pedido",
      orderDutiesTaxValue: "Valor do Imposto de Direitos do Pedido",
      orderSalesTaxValue: "Valor do Imposto Sobre Vendas do Pedido",
      orderTaxValue: "Valor do Imposto do Pedido",
      orderTotalValue: "Valor Total do Pedido",
      orderEstimatedDutiesTaxValue:
        "Valor do Imposto Estimado de Direitos do Pedido",
      orderEstimatedSalesTaxValue:
        "Valor Estimado do Imposto Sobre Vendas do Pedido",
      orderEstimatedTaxValue: "Valor Fiscal Estimado do Pedido",
      orderFreightCost: "Custo de Frete do Pedido",
      overpackID: "Pacote ID",
      overpackSentDate: "Data de envio do Pacote",
      overpackReference: "Referência do Overpack",
      fMTrackingNumber: "PM Numero de Rastreio",
      firstMileCompany: "Companhia da Primeira Milha",
      masterboxID: "Masterbox ID",
      dispatchID: "ID de despacho",
      cN35: "CN35",
      cN35Label: "Etiqueta CN35",
      shipmentID: "ID da remessa",
      shipmentCategory: "Categoria de Envio",
      cN38: "CN38",
      cN38Label: "Etiqueta CN38",
      flightID: "Voo ID",
      flightN: "Voo Nº",
      flightMAWB: "Voo MAWB",
      mAWBLabel: "Etiqueta MAWB",
      flightReference: "Referência do Voo",
      airline: "Companhia Aérea",
      greenLight: "Green Light",
      prealertsSent: "Pré-alertas enviados",
      departureAirport: "Aeroporto de Partida",
      arrivalAirport: "Aeroporto de Chegada",
      departureStartDate: "Início da Data de Partida",
    },
    filterOptions: {
      volumeStartDate: "Data de Início do Volume",
      volumeEndDate: "Data de Fim do Volume",
      volumeID: "Volume ID",
      reference: "Referência",
      lastMileTrackingNumber: "Número de rastreamento da última milha",
      lmtnPopover:
        "Aceita valores separados por: virgúlas, pontos e virgulas, espaços, ou quebras de linhas",
      withoutLastMileTrackingNumber:
        "Sem Número de Rastreamento da Última Milha",
      lastMileCarrierNumber: "Número da operadora da última milha",
      estimatedFreightCost: "Custo estimado de frete",
      orderStartDate: "Data de Início do Pedido",
      orderEndDate: "Data de Fim do Pedido",
      orderID: "Pedido ID",
      contractID: "Contrato ID",
      sellerID: "Vendedor ID",
      freightCost: "Custo do Frete",
      firstMileN: "Primeira Milha Nº",
      lastMileN: "Última Milha Nº",
      orderNumber: "Número do Pedido",
      salesChannel: "Canal de Vendas",
      salesChOrderNumber: "N° Pedido do Canal de Vendas",
      orderReference: "Referência do Pedido",
      incoterm: "Termos Internacionais de Comércio",
      customerName: "Nome do Cliente",
      customerAddress: "Endereço do Cliente",
      customerAddressNumber: "Número do Endereço do Cliente",
      customerAddressComplement: "Complemento de Endereço do Cliente",
      customerAddressReference: "Referência de Endereço do Cliente",
      customerAddressQuarter: "Bairro do Endereço do Cliente",
      customerDocumentType: "Tipo de Documento do Cliente",
      customerDocumentNumber: "Número do Documento do Cliente",
      customerCountry: "País do Cliente",
      customerEmail: "E-mail do Cliente",
      customerPhone: "Telefone do Cliente",
      financialStatus: "Status Financeiro",
      overpackStartDate: "Data de Início do Pacote",
      overpackEndDate: "Data de Fim do Pacote",
      overpackStartSendDate: "Início da Data de Envio do Pacote",
      overpackEndSendDate: "Fim da Data de Envio do Pacote",
      overpackID: "Pacote ID",
      overpackReference: "Referência do Overpack",
      bagNumber: "Número da Bolsa",
      firstMileTrackingN: "N° de Rastreamento da Primeira Milha",
      firstMileCarrierName: "Nome da Transportadora da Primeira Milha",
      masterboxStartDate: "Data de Início Masterbox",
      masterboxEndDate: "Data de Fim Masterbox",
      masterboxID: "Masterbox ID",
      cn35: "cn35",
      dispatchID: "ID de despacho",
      shipmentStartDate: "Data de Início da Remessa",
      shipmentEndDate: "Data de Fim da Remessa",
      shipmentID: "ID da remessa",
      cN38: "CN38",
      shipmentCategory: "Categoria de Envio",
      flightStartDate: "Data de Início do Voo",
      flightEndDate: "Data de Fim do Voo",
      flightID: "Voo ID",
      flightMAWB: "Voo MAWB",
      flightN: "Voo Nº",
      flightreference: "Referência do Voo",
      airline: "Companhia Aérea",
      greenLight: "Green Light",
      departureAirport: "Aeroporto de Partida",
      arrivalAirport: "Aeroporto de Chegada",
      departureStartDate: "Início da Data de Partida",
      departureEndDate: "Data de Fim da Partida",
      orderDate: "Data do pedido",
      shipmentDate: "Data da remessa",
      flightDepartureDate: "Data de partida do vôo",
      departureDate: "Data de partida do vôo",
      masterboxDate: "Data da Masterbox",
      overpackDate: "Data do Overpack",
      overpackSendDate: "Data de envio do Overpack",
    },
    downloadFields: {
      weight: "Peso",
      height: "Altura",
      width: "Largura",
      length: "Comprimento",
      reference: "Referência",
      lastMileTrackingNumber: "Número de rastreamento da última milha",
      creationDate: "Data de Criação",
      volumeProductsValue: "Valor dos produtos do volume",
      volumeFreightValue: "Valor do frete de volume",
      volumeDeclaredValue: "Valor declarado do volume",
      volumeDutiesTaxValue: "Valor do Imposto sobre Volume",
      volumeSalesTaxValue: "Valor do imposto sobre vendas por volume",
      volumeTaxValue: "Valor do imposto de volume",
      volumeTotalValue: "Valor total do volume",
      volumeEstimatedDutiesTaxValue: "Valor de Imposto Estimado de Volume",
      volumeEstimatedSalesTaxValue:
        "Valor estimado do imposto sobre vendas por volume",
      volumeEstimatedTaxValue: "Valor de imposto estimado de volume",
      volumeFreightCost: "Custo de frete por volume",
      volumeEstimatedFreightCost: "Custo de Frete Estimado por Volume",
      hSCode: "HS Code",
      description: "Descrição",
      currentStatus: "Status atual",
      orderID: "Pedido ID",
      sellerID: "Vendedor ID",
      contractID: "Contrato ID",
      customerName: "Nome do Cliente",
      cPostalCode: "C. Código Postal",
      customerCity: "Cidade do Cliente",
      customerState: "Estado do Cliente",
      customerAddress: "Endereço do Cliente",
      customerAddressNumber: "Número do Endereço do Cliente",
      customerAddressComplement: "Complemento de Endereço do Cliente",
      customerAddressReference: "Referência de Endereço do Cliente",
      customerAddressQuarter: "Bairro do Endereço do Cliente",
      customerDocumentType: "Tipo de Documento do Cliente",
      customerDocumentNumber: "Número do Documento do Cliente",
      customerCountry: "País do Cliente",
      customerEmail: "E-mail do Cliente",
      customerPhone: "Telefone do Cliente",
      currency: "Moeda",
      freightCost: "Custo do Frete",
      freigtValue: "Freigt Value",
      estimatedDeliveryDate: "Data de Entrega Estimada",
      incoterm: "Termos Internacionais de Comércio",
      orderNumber: "Número do Pedido",
      salesChannel: "Canal de Vendas",
      salesChOrderNumber: "N° Pedido do Canal de Vendas",
      totalValue: "Valor Total",
      orderReference: "Referência do Pedido",
      financialStatus: "Status Financeiro",
      overpackID: "Pacote ID",
      overpackSentDate: "Data de envio do Pacote",
      overpackReference: "Referência do Overpack",
      fMTrackingNumber: "PM Numero de Rastreio",
      firstMileCompany: "Companhia da Primeira Milha",
      masterboxID: "Masterbox ID",
      dispatchID: "ID de despacho",
      cN35: "CN35",
      shipmentID: "ID da remessa",
      shipmentCategory: "Categoria de Envio",
      cN38: "CN38",
      flightID: "Voo ID",
      flightN: "Voo Nº",
      mAWBLabel: "MAWB Label",
      flightReference: "Referência do Voo",
      airline: "Companhia Aérea",
      greenLight: "Green Light",
      prealertsSent: "Pré-alertas enviados",
      departureAirport: "Aeroporto de Partida",
      arrivalAirport: "Arrival Airport",
      departureStartDate: "Início da Data de Partida",
    },
    financialStatuses: {
      nNew: "Novo",
      toInvoice: "Faturar",
      invoiced: "Faturado",
      canceled: "Cancelado",
      notCharged: "Não Cobrado",
    },
  },
  volumesViewPage: {
    copyReference: "Copiar referência",
    goToOrder: "Ir para o pedido",
    summaryValues: "Resumo dos valores",
    trackingHistory: "Histórico de Rastreamento",
    editLastTrackingNumber: "Editar Last Mile Tracking Number",
    warningOnUpdateLastMileTrackingNumber:
      "Atenção! Essa ação não pode ser desfeita.",
    title: "Volume #",
    label: "Etiqueta",
    cubed_weight: "Peso Cubado",
    packingSlip: "Guia de Remessa",
    commercialInvoice: "Fatura Comercial",
    valuesSummary: "RESUMO DE VALORES",
    updatedWeight: "Peso atualizado",
    items: "Itens",
    trackinghistory: "Histórico de Rastreamento",
    pages: "Páginas",
    item: "Item",
    height: "Altura",
    heightbycurrier: "Altura pela Transportadora",
    width: "Largura",
    widthbycurrier: "Largura pela Transportadora",
    weight: "Peso",
    weightbycurrier: "Peso pela Transportadora",
    length: "Comprimento",
    lengthbycurrier: "Comprimento pela Transportadora",
    orderID: "Pedido ID",
    creationDate: "Data de Criação",
    currentStatus: "Status atual",
    reference: "Referência",
    lastMileCarrierNumber: "Número da operadora da última milha",
    lastMileTrackingNumber: "Número de rastreamento da última milha",
    declaredValue: "Valor declarado",
    taxValue: "Valor Fiscal",
    total: "Total",
    estimatedTaxValue: "Valor fiscal estimado",
    freightCost: "Custo do Frete",
    estimatedFreightCost: "Custo estimado de frete",
    name: "Nome",
    value: "Valor",
    hsCode: "Hs Code",
    quantity: "Quantidade",
    originCountry: "País de Origem",
    decsription: "Descrição",
    valueWithTax: "Valor com Imposto",
    fields: {
      name: "Nome",
      quantity: "Quantidade",
      hsCode: "Hs Code",
      originCountry: "País de Origem",
      productValue: "Valor do Produto",
      freightValue: "Valor do frete",
      taxValue: "Valor Fiscal",
    },
    checkpointFields: {
      title: "Título",
      statusCode: "Código de Status",
      dateISO: "Data ISO",
      message: "Mensagem",
      trackingFunction: "Função de Rastreamento",
    },
    dirNumber: "Número DIR",
    seePayments: "Ver pagamentos",
  },
  carriersPage: {
    title: "Gerenciamento de Transportadoras",
    subtitle:
      "Adicione e gerencie suas transportadoras e respectivos métodos de entrega.",
    legalName: "Nome Legal",
    rfbCode: "Código de RFB",
    country: "País",
    carrierIsSeller: "Este carrier também é seller",
    sellerReminder: "Não se esqueça de alterar o cadastro do seller",
    importCarrier: "Importar Transportadora à partir de JSON",
    downloadCarrierSettings:
      "Baixar Configurações de Transportadora, Métodos de Entrega e Usuários",
    addNewCarrier: "Adicionar Nova Transportadora",
    addCarrier: "Adicionar transportadora",
    sellers: "Vendedores",
    sellerSelect: "Preencha com os dados do vendedor",
    allowed: "Permitida",
    blocked: "Bloqueada",
    addnewCarrier: "Adicionar Nova Transportadora",
    editCarrier: "Editar transportadora",
    name: "Nome",
    address: "Endereço",
    addressnumber: "Número do Endereço",
    zipcode: "Cep",
    state: "Estado",
    city: "Cidade",
    phone: "Telefone",
    site: "Site",
    email: "E-mail",
    taxnumber: "Número de identificação fiscal",
    namereference: "Nome de Referência",
    slug: "Slug",
    trackstatusfunction: "Função de rastreamento de status",
    carrierCode: "Código da Transportadora",
    addressComplement: "Complemento do Endereço",
    airportHub: "Hub do Aeroporto",
    fields: {
      name: "Nome",
      slug: "Slug",
      city: "Cidade",
      country: "País",
      email: "E-mail",
      carrierCode: "Código da Transportadora",
      trackFunction: "Função de rastreamento",
      creationDate: "Data de Criação",
      isSeller: "É Vendedor?",
    },
    generalData: "Dados gerais",
    addCarrierDetails: "Adicione os dados da transportadora",
    carrierAddress: "Endereço da transportadora",
    addCarrierOriginAddress: "Adicione os dados de origem da transportadora",
    newCarrier: "Nova transportadora",
    addDeliveryMethod: "Adicionar método de entrega",
    noDeliveryMethodFound: "Nenhum método de entrega encontrado",
    noUserFound: "Nenhum usuário encontrado",
    deliveryMethods: "Métodos de Entrega",
    users: "Usuários",
    carriers: "Transportadoras",
    deliveryMethodFields: {
      name: "Métodos adicionados",
      type: "Tipo",
      service: "Serviço",
      originCountry: "País de Origem",
      destinationCountry: "País de Destino",
    },
    userFields: {
      name: "Nome",
      email: "E-mail",
      groupName: "Nome do Grupo",
      status: "Status",
    },
    seeMethod: "Ver método",
    seeUser: "Ver usuário",
  },
  carriersViewPage: {
    title: "Transportadora #",
    deliverymethods: "Métodos de Entrega",
    nodeliverymethodfoundfor: "Nenhum método de entrega encontrado",
    users: "Usuários",
    nouserfoundfor: "Nenhum usuário encontrado",
    editCarrier: "Editar Transportadora",
    updateAirportHub: "Atualizar hub do Aeroporto",
    airportHub: "Hub do Aeroporto",
    name: "Nome",
    address: "Endereço",
    addressnumber: "Número do Endereço",
    addresscomplement: "Complemento do Endereço",
    zipcode: "CEP",
    state: "Estado",
    city: "Cidade",
    phone: "Telefone",
    site: "Site",
    email: "E-mail",
    taxnumber: "Número de identificação fiscal",
    namereference: "Nome de Referência",
    slug: "Slug",
    trackstatusfunction: "Função de rastreamento de status",
    carrierCode: "Código da Transportadora",
    country: "País",
    taxNumber: "Número de identificação fiscal",
    trackingfunction: "Função de Rastreamento",
    platform: "Plataforma",
    subsidiary: "Subsidiária",
    deliveryCenter: "Centro de Entrega",
    createdDate: "Data de Criação",
    activityTypes: "Tipos de Atividade",
    deliveryMethodFields: {
      name: "Nome",
      type: "Tipo",
      service: "Serviço",
      originCountry: "País de Origem",
      destinationCountry: "País de Destino",
    },
    userFields: {
      name: "Nome",
      email: "E-mail",
      groupName: "Nome do Grupo",
    },
    seller: "Vendedor",
    thisCarrierIsSeller: "Esse transportador é um seller",
  },
  groupsPage: {
    title: "Gerenciamento de Grupos",
    fields: {
      name: "Nome",
    },
  },
  groupsViewPage: {
    title: "Grupo #",
    permissions: "Permissões",
  },
  permissionsPage: {
    title: "Permissões",
    fields: {
      name: "Nome",
      description: "Descrição",
      groupsName: "Nome dos Grupos",
    },
  },
  permissionsViewPage: {
    title: "Permissão #",
    name: "Nome",
    description: "Descrição",
  },
  saldoViewPage: {
    currentBalance: "Saldo Atual",
    fields: {
      fullName: "Nome Completo",
      email: "E-mail",
      status: "Status",
    },
  },
  sellersPage: {
    title: "Gestão de Vendedores",
    carriers: "Transportadores",
    carrierSelect: "Preencha com os dados da transportadora",
    legalName: "Nome Legal",
    rfbCode: "Código de RFB",
    addNewSeller: "Adicionar Novo Vendedor",
    importSeller: "Importar Vendedor à partir de JSON",
    downloadSellers: "Baixar Vendedores",
    processingDownloads:
      "Verifique os downloads de processamento em... página de downloads",
    addseller: "Adicionar Vendedor",
    address: "Endereço",
    addressnumber: "Número do Endereço",
    addresscomplement: "Complemento do Endereço",
    city: "Cidade",
    state: "Estado",
    postalcode: "Código Postal",
    name: "Nome",
    phone: "Telefone",
    email: "E-mail",
    site: "Site",
    taxnumber: "Número de identificação fiscal",
    signature: "Assinatura",
    billingEmail: "E-mail de Cobrança",
    responsableContact: "Contato Responsável",
    allowedcontracts: "Contratos Permitidos",
    contractOperations: "Operações de contrato",
    issubsidiary: "É Subsidiária",
    isdistributioncenter: "É centro de distribuição",
    ismultiple: "É multiplo",
    country: "País",
    fields: {
      name: "Nome",
      email: "E-mail",
      city: "Cidade",
      ismultiple: "Multiplo",
      country: "País",
      site: "Site",
      billingEmail: "E-mail de Cobrança",
      responsableContact: "Contato Responsável",
      creationDate: "Data de Criação",
      currentBalance: "Saldo Atual",
      isCarrier: "É Transportador?",
    },
  },
  sellersViewPage: {
    chooseSendRequest:
      "Por favor, escolha para quem você vai enviar a solicitação:",
    downloadSellerSettings: "Baixar Configurações de Vendedor e Usuários",
    website: "Site",
    sellerIsCarrier: "Este seller também é carrier",
    carrierReminder: "Não se esqueça de alterar o cadastro do carrier",
    legalName: "Nombre Legal",
    rfbCode: "Código de RFB",
    taxNumber: "Número de identificação fiscal",
    signature: "Assinatura",
    isSubsidiary: "É Subsidiária",
    isDistributionCenter: "É centro de distribuição",
    sellerID: "Vendedor ID",
    currentBalance: "Saldo Atual",
    seller: "Vendedor",
    ismultiple: "É multiplo",
    name: "Nome",
    email: "E-mail",
    phone: "Telefone",
    allowedContracts: "Contratos Permitidos",
    contractOperations: "Operações de contrato",
    noContractFound: "Nenhum contrato encontrado",
    creationDate: "Data de Criação",
    contractLink: "Link do Contrato",
    notFound: "Não encontrado",
    billingEmail: "E-mail de Cobrança",
    responsableContact: "Contato Responsável",
    platform: "Plataforma",
    address: "Endereço",
    country: "País",
    postalCode: "Código Postal",
    state: "Estado",
    devolutionAddress: "Endereço do Devolução",
    addressNumber: "Número do Endereço",
    addressQuarter: "Bairro do Endereço",
    addressComplement: "Complemento do Endereço",
    addressReference: "Referência do Endereço",
    city: "Cidade",
    devolutionEmail: "Email de Devolução",
    useSellerDevolutionAddress: "Use o endereço de devolução do vendedor",
    users: "Usuários ",
    active: "Ativo",
    blocked: "Bloqueado",
    clear: "Limpar",
    noUserFoundFor: "Nenhum usuário encontrado para",
    includeLogo: "Incluir logo",
    wantAllowContract: "Você quer permitir o contrato",
    forSeller: "para o vendedor",
    needsSupervisorApproval: "Esta ação precisa da aprovação de um supervisor",
    wantForbidContract: "Você quer proibir o contrato",
    fields: {
      fullName: "Nome Completo",
      email: "E-mail",
      status: "Status",
    },
    carrier: "Transportador",
    thisSellerIsCarrier: "Esse vendedor é um transportador",
  },
  usersPage: {
    title: "Users",
    subtitle: "Confira e adicione novos usuários ao sistema",
    personalData: "Dados pessoais",
    seller: "Vendedor",
    carrier: "Transportadora",
    downloadUsers: "Baixar relatório",
    allowed: "Permitido",
    blocked: "Bloqueado",
    active: "Ativo",
    denerateAPIToken: "Gerar Token da API",
    processingDownloads:
      "Verifique os downloads de processamento em... página de downloads",
    allUsers: "Todos os usuários",
    addNewUser: "Novo usuário",
    name: "Nome",
    email: "E-mail",
    phone: "Telefone",
    whatsappNumber: "Número do Whatsapp",
    profile: "Perfil",
    group: "Grupo",
    selectGroup: "Selecione o grupo",
    aPIToken: "Token da API",
    permissionsAndAccess: "Permissões e acessos",
    userStatus: "Status do usuário",
    editUser: "Editar usuário",
    fields: {
      name: "Nome",
      carrierID: "Transportadora",
      sellerID: "Vendedor",
      company: "Empresa",
      status: "Status",
      email: "E-mail",
      profile: "Perfil",
      group: "Grupo",
      creationDate: "Data de Criação",
      aPIToken: "Token da API",
    },
    insertUserData: "Insira os dados do usuário que deseja criar.",
    archive: "Arquivar",
    archiveUser: "Arquivar usuário?",
    confirmArchiveUser:
      "Tem certeza que deseja arquivar o usuário? Isso não pode ser desfeito.",
    yeahArchiveUser: "Sim, arquivar usuário",
  },
  usersViewPage: {
    title: "Perfil do Usuário",
    blocked: "Bloqueado",
    allowed: "Permitido",
    blockUser: "Bloquear este usuário",
    unblockUser: "Desbloquear este usuário",
    archiveUser: "Archive this user",
    unarchiveUser: "Unarchive this user",
    company: "Empresa",
    editUserProfile: "Editar Perfil do Usuário",
    name: "Nome",
    email: "E-mail",
    phone: "Telefone",
    whatsappNumber: "Número do Whatsapp",
    group: "Grupo",
    selectgroup: "Selecione o grupo",
    profile: "Perfil",
    seller: "Vendedor",
    carrier: "Transportadora",
    phoneNumber: "Número de Telefone",
    createdDate: "Data de Criação",
    sellerID: "Vendedor ID",
    carrierID: "Transportadora ID",
    platform: "Plataforma",
  },
  dutiesAndTaxesStatusPage: {
    title: "Status de taxas e impostos",
    subTitle: "Check or download duties and taxes",
    dutiesTaxes: "Duties and Taxes",
    importValuesandStatuses: "Importar Valores e Status",
    updateStatus: "Atualizar Status",
    download: "Baixar",
    new: "Novo",
    solved: "Resolvido",
    priority: "Prioridade",
    inProgress: "Em Progresso",
    waitingforPayment: "Esperando pelo Pagamento",
    sentAgain: "Enviado Novamente",
    paymentDone: "Pagamento Efetuado",
    pending: "Pendente",
    paidExternally: "Pago Externamente",
    needSellerAction: "Necessita de Ação do Vendedor",
    status: "Status",
    comment: "Comentário",
    checktheprocessingdownloads:
      "Verifique os downloads de processamento em... página de downloads",
    sureWantSave: "Tem certeza de que deseja salvar as alterações",
    newTrackingNumbers: "Novo número de rastreamento ",
    shouldbeCommaSeparated:
      "Os novos números devem ser separados por vírgula e seguir a sequência de Tracking Alert Logs selecionados: TAL_IDs:",
    unsaveddata: "Dados não Salvos",
    pleasesaveunsaveddata: "Salve os dados não salvos ou cancele",
    okay: "OK",
    name: "Nome",
    browseFilesUpload:
      "Navegue pelos seus arquivos e faça o upload do seu arquivo",
    selectcsvFileUpload: "Selecione um arquivo csv para carregar",
    downloadHere: "Baixe Aqui",
    instructionsXLSfile: "o arquivo XLS de instruções",
    fileUploaded: "Arquivo enviado",
    blankTable: "um arquivo em branco",
    fields: {
      id: "Referência de Pagamento Interno NB",
      sellerName: "Nome do Vendedor",
      sellerId: "ID do Vendedor",

      orderNumber: "Número do Pedido",
      orderCreatedAt: "Data de criação do pedido",
      orderCreatedAtGte: "Inicio do(a) Data de criação do pedido",
      orderCreatedAtLte: "Final do(a) Data de criação do pedido",

      volumeId: "ID do Volume",
      volumeLastMileTrackingNumber: "Número de Rastreamento Última Milha",
      orderEstimatedTaxValue: "Total estimado de taxas e impostos",
      orderEstimatedTaxValueGte:
        "Inicio do(a) Total estimado de taxas e impostos",
      orderEstimatedTaxValueLte:
        "Final do(a) Total estimado de taxas e impostos",
      orderEstimatedDutiesTaxValue: "Impostos Estimados II",
      orderEstimatedDutiesTaxValueGte: "Inicio do(a) Impostos Estimados II",
      orderEstimatedDutiesTaxValueLte: "Final do(a) Impostos Estimados II",
      orderEstimatedSalesTaxValue: "Impostos Estimados ICMS",
      orderEstimatedSalesTaxValueGte: "Inicio do(a) Impostos Estimados ICMS",
      orderEstimatedSalesTaxValueLte: "Final do(a) Impostos Estimados ICMS",

      currentStatusId: "Rastreamento",
      currentStatusTitle: "Status de Rastreamento",
      currentStatusTrackingCode: "Código de Rastreamento",
      currentStatusTrackingCodeGte: "Inicio do(a) Código de Rastreamento",
      currentStatusTrackingCodeLte: "Final do(a) Código de Rastreamento",

      createdAt: "Data de início da alfândega",
      createdAtGte: "Inicio do(a) Data de início da alfândega",
      createdAtLte: "Final do(a) Data de início da alfândega",

      statusPaymentDoneAt: "Data de pagamento",
      statusPaymentDoneAtGte: "Inicio do(a)Data de pagamento",
      statusPaymentDoneAtLte: "Final do(a) Data de pagamento",

      status: "Status do pagamento",
      paymentCertificate: "Certificado de Pagamento",

      realTax: "Total de Impostos e Taxas Pagos",
      realTaxGte: "Inicio do(a) Total de Impostos e Taxas Pagos",
      realTaxLte: "Final do(a) Total de Impostos e Taxas Pagos",

      exchangeRate: "Taxa de Câmbio",
    },
  },
  dutiesAndTaxesStatusViewPage: {
    title: "Status de taxas e impostos #",
    sellerChargedAmount: "Valor cobrado do vendedor",
    exchange: "Câmbio",
    incoterm: "Termos Internacionais de Comércio",
    date: "Data",
    dateIso: "Data Iso",
    orderID: "Pedido ID",
    volumeID: "Volume ID",
    sellerID: "Vendedor ID",
    taxID: "Número de Identificação Fiscal",
    lastMileNumber: "Número da última milha",
    productsValue: "Valor dos Produtos",
    freightValue: "Valor do frete",
    expectedTax: "Imposto esperado",
    valueR$: "Valor R$",

    id: "Referência de Pagamento Interno NB",
    sellerName: "Nome do Vendedor",
    sellerId: "ID do Vendedor",

    orderNumber: "Número do Pedido",
    orderCreatedAt: "Data de criação do pedido",
    orderCreatedAtGte: "Inicio do(a) Data de criação do pedido",
    orderCreatedAtLte: "Final do(a) Data de criação do pedido",

    volumeId: "ID do Volume",
    volumeLastMileTrackingNumber: "Número de Rastreamento Última Milha",
    orderEstimatedTaxValue: "Total estimado de taxas e impostos",
    orderEstimatedTaxValueGte:
      "Inicio do(a) Total estimado de taxas e impostos",
    orderEstimatedTaxValueLte: "Final do(a) Total estimado de taxas e impostos",
    orderEstimatedDutiesTaxValue: "Impostos Estimados II",
    orderEstimatedDutiesTaxValueGte: "Inicio do(a) Impostos Estimados II",
    orderEstimatedDutiesTaxValueLte: "Final do(a) Impostos Estimados II",
    orderEstimatedSalesTaxValue: "Impostos Estimados ICMS",
    orderEstimatedSalesTaxValueGte: "Inicio do(a) Impostos Estimados ICMS",
    orderEstimatedSalesTaxValueLte: "Final do(a) Impostos Estimados ICMS",

    currentStatusId: "Rastreamento",
    currentStatusTitle: "Status de Rastreamento",
    currentStatusTrackingCode: "Código de Rastreamento",
    currentStatusTrackingCodeGte: "Inicio do(a) Código de Rastreamento",
    currentStatusTrackingCodeLte: "Final do(a) Código de Rastreamento",

    createdAt: "Data de início da alfândega",
    createdAtGte: "Inicio do(a) Data de início da alfândega",
    createdAtLte: "Final do(a) Data de início da alfândega",

    statusPaymentDoneAt: "Data de pagamento",
    statusPaymentDoneAtGte: "Inicio do(a)Data de pagamento",
    statusPaymentDoneAtLte: "Final do(a) Data de pagamento",

    status: "Status do pagamento",
    paymentCertificate: "Certificado de Pagamento",

    realTax: "Total de Impostos e Taxas Pagos",
    realTaxGte: "Inicio do(a) Total de Impostos e Taxas Pagos",
    realTaxLte: "Final do(a) Total de Impostos e Taxas Pagos",

    exchangeRate: "Taxa de Câmbio",
  },
  accessibilitySettingsPage: {
    subtitle: "Configure tipografia, preferências, idioma e mais.",
    typography: "Tipografia",
    textPreferenceDescription: "Defina a seguir suas preferências de texto.",
    textSize: "Tamanho do texto",
    language: "Idioma",
    mainLanguageDescription:
      "Defina qual sera o idioma principal da Plataforma.",
    selectLanguage: "Selecione o idioma",
    changeUnitsText: "alterar unidades de pesos e medidas",
    stillHavingDifficulties: "Ainda com dificuldades?",
    weWantToHelpYou:
      "Queremos te ajudar! Entre em contato conosco através do modo que preferir",
    contactViaPhone: "Contato via telefone",
    contactViaEmail: "Contato via e-mail",
    contactViaChat: "Contato via chat",
  },
  searchHsCodePage: {
    title: "Buscar HS Code",
    subtitle:
      "Quer saber um HS Code ou se o produto é permitido? Busque agora mesmo!",
    code: "Informe o código",
    category: "Categoria",
    noHscode: "Registro não encontrado.",

    tooltip:
      "Um Código Hs ou NCM é um código de 6 ou 8 dígitos que identifica o tipo de produto a importar. Esta página permite que você veja se um determinado código tem permissão para entrar no Brasil",
  },
  requestsPage: {
    title: "Solicitações",
    subTitle: "Verifique e acompanhe o status da solicitação com um ID",
    requestId: "Request ID",
    asynchronousRequests: "Solicitações assíncronas",
    result: "Resultado",
  },
  shippingRulesPage: {
    title: "Regras de envio",
    subtitle:
      "Nesta página você pode definir valores padrão para campos específicos, assim sempre que acessar uma determinada página ela já terá os campos definidos pré-preenchidos.",
    addNewRule: "Adicionar nova regra",
    seller: "Vendedor",
    sellers: "Vendedores",
    name: "Nome da regra",
    expiration: "Validade",
    condition: "Condição",
    conditions: "Condições",
    options: "Opções",
    operator: "Operação",
    value: "Valor",
    selectMultipleSellers: "Escolha um ou mais vendedores",
    createConditionTitle: "Adicionar nova regra",
    createCondition:
      "Selecione as condições que sua remessa precisa satisfazer",
    addCondition: "Adicionar condição",
    createActionTitle: "Ação",
    createAction:
      'Neste campo você escolherá o que deve acontecer de acordo com sua condição. Por exemplo, para a condição "Todos os pedidos", você pode selecionar "enviar sempre do Local X"',
    is_active: "Active",
    is_active_helptext:
      "If you want to create the rule without applying it for now, just de-select this field",
    freightValue: "Valor do frete",
    priority: "Prioridade",
    destinationCountry: "País de destino",
    destinationStates: "Estados de destino",
    rulePriorityOptions: {
      veryHigh: "Muito Alta",
      high: "Alta",
      medium: "Média",
      low: "Baixa",
      veryLow: "Muito baixa",
    },
  },
  fiscalPaymentsPage: {
    title: "Pagamentos fiscais",
    fiscalInfo: "Informações fiscais",
    fiscalParcelData: "Dados da parcela fiscal",
    customerData: "Dados do cliente",
    fields: {
      reference_number: "Referência",
      barcode: "Código de barras",
      authentication: "Authenticação",
      status: "Status",
      payment_type: "Tipo de pagamento",
      value: "Valor",
      fiscal_parcel_id: "ID parcela fiscal",
      payment_date: "Data de pagamento",
      created_at: "Data de criação",
      dir_number: "Número do dir",
      customer_name: "Nome do cliente",
      customer_cpf: "CPF do cliente",
      customer_cep: "CEP do cliente",
      tracking_number: "Número de rastreamento",
      products_description: "Descrição dos produtos",
      customer_state: "Estado do cliente",
      seller_id: "ID do vendedor",
      dir_date: "Data dir",
      start_payment_date: "Data de ínico do pagamento",
      end_payment_date: "Data fim do pagamento",
      start_created_at: "Data de início da criação",
      end_created_at: "Data fim da criação",
      start_value: "Valor inicial",
      end_value: "Valor final",
      start_dir_date: "Data inicial DIR",
      end_dir_date: "Data fim DIR",
      boletoGNRE: "Boleto GNRE",
      paymentDate: "Data do pagamento",
    },
    status: {
      processing: "Processando",
      processed: "Processado",
      error: "Erro",
    },
  },
  notFoundPage: {
    pageNotFound: "Página não encontrada",
    goToHome: "Ir para a página inicial",
  },
};
