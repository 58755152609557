<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("sellerAccountEventsPage.addCredit") }}
      </ModalTitle>
    </ModalHeader>

    <form class="grid gap-4">
      <NbSelectInput
        v-if="!sellerId"
        v-model="form.sellerId"
        :options="sellers"
        id="form.sellerId"
        :name="$t('sellerAccountEventsPage.seller')"
        required
      />
      <NbTextInput
        v-model="form.amount"
        :name="
          $t('sellerAccountEventsPage.amount') +
          ` (${$t('sellerAccountEventsPage.howMuchDoYouWantAdd')})`
        "
        type="money"
        :decimals="2"
        :min="0.0"
        placeholder="R$0.00"
        id="form.amount"
        :error="[errors.get('value')]"
      />
      <NbTextInput
        v-model="form.exchange"
        :name="
          $t('sellerAccountEventsPage.currentExchange') +
          ` (${$t('sellerAccountEventsPage.currentExchangeHelpText', {
            value: form.exchange || 0,
          })})`
        "
        type="money"
        :decimals="2"
        :min="0.0"
        placeholder="R$0.00"
        id="form.exchange"
        :error="[errors.get('exchange')]"
        required
      />

      <NbTextInput
        v-model="form.description"
        :name="$t('sellerAccountEventsPage.description')"
        id="form.description"
        :error="[errors.get('description')]"
      />
    </form>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose class="mr-2" />
      <NbButton @click="addCredit" :disabled="!formIsValid || loading">
        {{ $t("sellerAccountEventsPage.addCredit") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import SellerAccountService from "@/services/SellerAccountService";
import Errors from "@/utils/Errors";

const sellerAccountService = new SellerAccountService();

export default {
  props: {
    sellerId: {
      type: [Number, undefined],
      default: null,
    },
    category: {
      type: String,
      default: "global",
    },
  },
  inject: ["context"],
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    NbTextInput,
    NbSelectInput,
  },
  data() {
    return {
      loading: false,
      errors: new Errors(),
      form: {
        sellerId: this.sellerId,
        amount: "",
        exchange: "",
        description: "",
      },
    };
  },
  computed: {
    sellers() {
      const data = this.$store.state.sellers.all_items;

      return data.map((seller) => ({
        text: `${seller.id} - ${seller.name}`,
        value: seller.id,
      }));
    },
    formIsValid() {
      return !!this.form.sellerId;
    },
  },
  methods: {
    async addCredit() {
      try {
        this.loading = true;

        const formData = new FormData();
        formData.append("seller_account_events[event_type]", "credit");
        formData.append("seller_account_events[category]", this.category);
        formData.append("seller_account_events[value]", this.form.amount);
        formData.append("seller_account_events[exchange]", this.form.exchange);
        formData.append("seller_account_events[seller_id]", this.form.sellerId);
        formData.append("seller_account_events[event_attachment]", "");
        formData.append(
          "seller_account_events[description]",
          this.form.description,
        );

        const response = await sellerAccountService.createEvent(
          this.form.sellerId,
          formData,
        );
        this.$emit("success", response.data.data);
        this.context?.close();
      } catch (error) {
        this.errors.record(error.response.data.data);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
