<template>
  <div>
    <NbTablev2
      tableOf="volumeCheckpointTable"
      namespace="volume_checkpoints"
      ref="volumeCheckpointTable"
      :clickable="true"
      :allFields="allFields"
      :fields="fields"
      :searchBy="1"
      :filterOptions="filterOptions"
      @reloadFields="fields = $event"
      @total="$emit('total', $event)"
      @clickedRow="loadModalData($event.id)"
    >
      <template #cell(volume_id)="data">
        <router-link
          class="link-1"
          :to="`/shipping/volumes?id=` + data.item.volume_id"
        >
          {{ data.item.volume_id }}
        </router-link>
      </template>
      <template #cell(received_checkpoint_id)="data">
        <div
          v-if="data.item.received_checkpoint_id"
          class="link-1"
          @click="
            loadModalReceivedCheckpointData(data.item.received_checkpoint_id)
          "
        >
          {{ data.item.received_checkpoint_id }}
        </div>
      </template>
      <template #cell(converted_notifs)="data">
        <IsCheckedIcon :checked="data.item.converted_notifs" />
      </template>
      <template #cell(checkpoint)="data">
        <div
          class="link-1"
          @click="loadModalNobordistCheckpointsData(data.item.checkpoint_id)"
        >
          {{ data.item.checkpoint.tracking_code }}
        </div>
      </template>
    </NbTablev2>

    <ModalVolumeCheckpointsView :itemId="currentItemId" />
    <ModalNobordistCheckpointsView
      id="ModalVolNobordistCheckpointsView"
      :itemId="currentNobordistCheckpointsId"
    />
    <ModalReceivedCheckpointsView
      id="ModalVolReceivedCheckpointsView"
      :itemId="currentReceivedCheckpointsId"
    />
  </div>
</template>
<script>
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import ModalNobordistCheckpointsView from "@/views/checkpoints/nobordist_checkpoints/components/ModalNobordistCheckpointsView.vue";
import ModalVolumeCheckpointsView from "@/views/checkpoints/volume_checkpoints/components/ModalVolumeCheckpointsView.vue";
import ModalReceivedCheckpointsView from "@/views/checkpoints/received_checkpoints/components/ModalReceivedCheckpointsView.vue";

import CheckpointService from "@/services/CheckpointService";
import IsCheckedIcon from "../../../components/IsCheckedIcon.vue";

const checkpointService = new CheckpointService();

export default {
  name: "VolumeCheckpoints",
  components: {
    NbTablev2,
    ModalVolumeCheckpointsView,
    ModalNobordistCheckpointsView,
    ModalReceivedCheckpointsView,
    IsCheckedIcon,
  },
  props: {},
  data: () => {
    return {
      fields: [],
      checkpoints: [],
      currentItemId: "",
      currentNobordistCheckpointsId: "",
      currentReceivedCheckpointsId: "",
    };
  },
  created() {
    this.loadCheckpoints();
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    checkQuery() {
      if (this.$route.query.volumeCheckpointId) {
        this.$emit("switchTab", "volumeCheckpoints");
        this.loadModalData(this.$route.query.volumeCheckpointId);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query.volumeCheckpointId) {
          this.$router.push({ query: { volumeCheckpointId: id } });
        }

        this.$helpers.openModal("ModalVolumeCheckpointsView");
        return;
      }
    },
    loadModalNobordistCheckpointsData(id) {
      if (id) {
        this.currentNobordistCheckpointsId = id;

        if (!this.$route.query.nobordistCheckpointId) {
          this.$router.push({ query: { nobordistCheckpointId: id } });
        }

        this.$helpers.openModal("ModalVolNobordistCheckpointsView");
        return;
      }
    },
    loadModalReceivedCheckpointData(id) {
      if (id) {
        this.currentReceivedCheckpointsId = id;

        if (!this.$route.query.receivedCheckpointId) {
          this.$router.push({ query: { receivedCheckpointId: id } });
        }

        this.$helpers.openModal("ModalVolReceivedCheckpointsView");
        return;
      }
    },

    addSelectOptionsToFilter(key, options) {
      this.filterOptions.find(
        (filterOption) => filterOption.key === key,
      ).options = options;
    },
    loadCheckpoints() {
      checkpointService.getAllCheckpoints().then((response) => {
        this.checkpoints = response.data.data.elements;
        const checkpointsOption = this.checkpoints.map((checkpoint) => ({
          value: checkpoint.tracking_code,
          text: `(${checkpoint.tracking_code}) ${checkpoint.title}`,
        }));
        this.addSelectOptionsToFilter("tracking_code", checkpointsOption);
      });
    },
  },
  computed: {
    allFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "volume_id",
          label: this.$t("volumeCheckpointsPage.fields.volumeID"),
        },
        {
          key: "received_checkpoint_id",
          label: this.$t("volumeCheckpointsPage.fields.receivedCheckpointID"),
        },
        {
          key: "converted_notifs",
          label: this.$t("volumeCheckpointsPage.fields.convertedNotifs"),
        },
        {
          key: "checkpoint",
          label: this.$t("volumeCheckpointsPage.fields.checkpoint"),
        },
        {
          key: "date_iso",
          label: this.$t("volumeCheckpointsPage.fields.DateISO"),
        },
        {
          key: "message",
          label: this.$t("volumeCheckpointsPage.fields.message"),
        },
      ];
    },
    filterOptions() {
      return [
        {
          key: "volume_id",
          label: this.$t("volumeCheckpointsPage.fields.volumeID"),
          type: "number",
        },
        {
          key: "received_checkpoint_id",
          label: this.$t("volumeCheckpointsPage.fields.receivedCheckpointID"),
          type: "number",
        },
        {
          key: "converted_notifs",
          label: this.$t("volumeCheckpointsPage.fields.convertedNotifs"),
          type: "boolean",
        },
        {
          key: "tracking_code",
          label: this.$t("volumeCheckpointsPage.fields.checkpoint"),
          type: "select",
          options: [],
        },
        {
          key: "date_iso",
          label: `${this.$t("volumeCheckpointsPage.fields.DateISO")}`,
          type: "dates",
        },
      ];
    },
  },
  watch: {},
};
</script>
